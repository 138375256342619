import { createSlice, createSelector, current, createAsyncThunk } from "@reduxjs/toolkit";
import generateRandomColor from "../RandomColor";
import { readingsApi } from "./api/readingsApi";
import * as z from 'zod';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax';
import duration from 'dayjs/plugin/duration';
import { SHORT_GRAPH_SENSORS, symptoms } from "../sensors";

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(minMax)
dayjs.extend(duration)



export const SensorPositions = z.enum(['Left', 'Right'])

export const RangePresets = z.enum(['Day', 'Week', 'Month', 'All'])

export const PresetDomainFunctions = {
	[RangePresets.Enum.Day]: (currentStart, minimumDate) => ({ min: dayjs(currentStart).startOf('day').valueOf(), max: dayjs(currentStart).endOf('day').valueOf()}),
	[RangePresets.Enum.Week]: (currentStart, minimumDate) => ({ min: dayjs(currentStart).startOf('week').valueOf(), max: dayjs(currentStart).endOf('week').valueOf()}),
	[RangePresets.Enum.Month]: (currentStart, minimumDate) => ({ min: dayjs(currentStart).startOf('month').valueOf(), max: dayjs(currentStart).endOf('month').valueOf()}),
	[RangePresets.Enum.All]: (currentStart, minimumDate) => ({ min: dayjs(minimumDate).valueOf(), max: dayjs().startOf('minute').valueOf() }),
}

export const ZoomPresets = {
	[RangePresets.Enum.Day]: [
		{
			newDomain: (currentStart, minimumDate) => ({ 
				min: dayjs(currentStart).startOf('day').add(8, 'hour').startOf('hour').valueOf(), // Start in the middle of the day
				max: dayjs(currentStart).startOf('day').add(8 + 8, 'hour').startOf('hour').valueOf()
			}),
			scrollBy: dayjs.duration(3, 'hour')
		},
		{
			newDomain: (currentStart, minimumDate) => ({ 
				min: dayjs(currentStart).startOf('day').add(9, 'hour').startOf('hour').valueOf(), 
				max: dayjs(currentStart).startOf('day').add(9 + 6, 'hour').startOf('hour').valueOf() 
			}),
			scrollBy: dayjs.duration(2, 'hour')
		},
		{
			newDomain: (currentStart, minimumDate) => ({ 
				min: dayjs(currentStart).startOf('day').add(11, 'hour').startOf('hour').valueOf(), 
				max: dayjs(currentStart).startOf('day').add(11 + 3, 'hour').startOf('hour').valueOf() 
			}),
			scrollBy: dayjs.duration(1, 'hour')
		}
	],
	[RangePresets.Enum.Week]: [
		{
			newDomain: (currentStart, minimumDate) => ({ 
				min: dayjs(currentStart).startOf('week').add(2, 'day').startOf('day').valueOf(), 
				max: dayjs(currentStart).startOf('week').add(2 + 3, 'day').startOf('day').valueOf() 
			}),
			scrollBy: dayjs.duration(1, 'day')
		}
	],
	[RangePresets.Enum.Month]: [
		{
			newDomain: (currentStart, minimumDate) =>({ 
				min: dayjs(currentStart).startOf('month').add(1, 'week').startOf('week').valueOf(), 
				max: dayjs(currentStart).startOf('month').add(1 + 2, 'week').startOf('week').valueOf() 
			}),
			scrollBy: dayjs.duration(1, 'week')
		}
	],
    [RangePresets.Enum.All]: []
}



export const sensorPriority = {
    'ecg-heartrate': 0,
    'temp': 1,
    'ppg-heartrate': 2,
    'watch-temp': 3,
    'watch-temp-ambient': 4,
}



const initialState = {
    range: RangePresets.Enum.Day,
    zoomLevel: null,
    domain: { min: null, max: null }, // PresetDomainFunctions[RangePresets.Enum.Day](dayjs()),
    current: true,
    sensors: {
        [SensorPositions.Enum.Left]: null,
        [SensorPositions.Enum.Right]: null
    },
    minimumDate: 0,  // Unix epoch
    shortDomain: { min: null, max: null },
    shortSensor: null
}



export const graphSlice = createSlice({
    name: 'graph',
    initialState: {...initialState},
    reducers: {
        initializeGraph: (state, action) => {
            const { sensors, creationDateUnixTS } = action.payload

            state.minimumDate = creationDateUnixTS
            state.range = RangePresets.Enum.Day
            state.zoomLevel = null

            const initialSensors = Object
                .keys(sensors)
                .filter((sensor) => !SHORT_GRAPH_SENSORS.has(sensor))
                .toSorted((a, b) => { return (sensorPriority[a] ?? 100) - (sensorPriority[b] ?? 100)})

            let left = initialSensors.at(0) ?? null
            let right = initialSensors.at(1) ?? null

            if (symptoms.has(left)) {
                left = 'symptoms'

                if (symptoms.has(right)) {
                    right = initialSensors.filter((sensor) => !symptoms.has(sensor)).at(0) ?? null
                }
            } else if (symptoms.has(right)) {
                right = 'symptoms'
            }

            state.sensors = { [SensorPositions.Enum.Left]: left, [SensorPositions.Enum.Right]: right }

            let min = dayjs().startOf('day')

            console.log('Left & Right: ', left, right)

            if (right !== null) {
                if (right === 'symptoms') {
                    min = [...symptoms].reduce((acc, symptom) => { return dayjs.min(acc, dayjs(sensors[symptom]?.ts ?? min.toISOString())) }, min)
                } else {
                    min = dayjs.min(min, dayjs(sensors[right].ts).startOf('day'))
                }
            }

            if (left !== null) {
                if (left === 'symptoms') {
                    min = [...symptoms].reduce((acc, symptom) => { return dayjs.min(acc, dayjs(sensors[symptom]?.ts ?? min.toISOString())) }, min)
                } else {
                    min = dayjs.min(min, dayjs(sensors[left].ts).startOf('day'))
                }
            }

            state.domain = PresetDomainFunctions[RangePresets.Enum.Day](min.valueOf(), state.minimumDate)

            
            const shortSensor = Object.keys(sensors).filter((sensor) => SHORT_GRAPH_SENSORS.has(sensor)).at(0) ?? null
            if (shortSensor !== null) {
                console.log('Sensors: ', sensors)
                state.shortDomain = { min: dayjs(sensors[shortSensor].ts).subtract(30, 'seconds').valueOf(), max: dayjs(sensors[shortSensor].ts).valueOf()  }
            }
            state.shortSensor = shortSensor
        },
        zoomIn: (state) => {
            const currentRange = state.range;
            const currentZoomLevel = state.zoomLevel;
            const currentDomain = state.domain;

            let newZoomLevel = undefined;

            if (currentRange === RangePresets.Enum.All) {
                return
            }
            
            switch(true) {
                case currentZoomLevel === null: 
                    newZoomLevel = 0;
                    break;
                case currentZoomLevel > -1:
                    newZoomLevel = currentZoomLevel + 1
                    break;
                default:
            }

            if (newZoomLevel !== undefined) {
                console.log('New Zoom Level: ', newZoomLevel)
                if ((ZoomPresets[currentRange]?.length ?? 0) > newZoomLevel) {
                    const newDomain = ZoomPresets[currentRange][newZoomLevel].newDomain(currentDomain.min, state.minimumDate)
                    state.domain = newDomain
                    state.zoomLevel = newZoomLevel
                }
            }
        },
        zoomOut: (state) => {
            const currentRange = state.range;
            const currentZoomLevel = state.zoomLevel;
            const currentDomain = state.domain;

            if (currentRange === RangePresets.Enum.All) {
                return
            }

            switch(true) {
                case currentZoomLevel === null: 
                    break;
                case currentZoomLevel === 0: {
                    const newZoomLevel = null
                    const newDomain = PresetDomainFunctions[currentRange](currentDomain.min, state.minimumDate)

                    state.domain = newDomain
                    state.zoomLevel = newZoomLevel
                    break;
                }
                case currentZoomLevel > 0: {
                    const newZoomLevel = currentZoomLevel - 1
                    const newDomain = ZoomPresets[currentRange][newZoomLevel].newDomain(currentDomain.min, state.minimumDate)

                    state.zoomLevel = newZoomLevel
                    state.domain = newDomain
                    break;
                }
                default:
            }
        },
        scrollLeft: (state) => {
            const currentDomain = state.domain
            const currentRange = state.range
            const currentZoomLevel = state.zoomLevel

            if (currentRange === RangePresets.Enum.All) {
                return
            }

            if (currentZoomLevel === null) {
                let newDomainStart = null;

                switch(currentRange) {
                    case RangePresets.Enum.Day: {
                        newDomainStart = dayjs(currentDomain.min).subtract(1, 'day').valueOf()
                        break;
                    }
                    case RangePresets.Enum.Week: {
                        newDomainStart = dayjs(currentDomain.min).subtract(1, 'week').valueOf()
                        break;
                    }
                    case RangePresets.Enum.Month: {
                        newDomainStart = dayjs(currentDomain.min).subtract(1, 'month').valueOf()
                        break;
                    }
                    default:
                }

                if (newDomainStart !== null) {
                    state.domain = PresetDomainFunctions[currentRange](newDomainStart, state.minimumDate)
                    state.current = false
                }
            } else {
                switch(currentRange) {
                    case RangePresets.Enum.Month:
                    case RangePresets.Enum.Week:
                    case RangePresets.Enum.Day:
                        const min = dayjs(currentDomain.min).subtract(ZoomPresets[currentRange][currentZoomLevel].scrollBy).valueOf()
                        const max = dayjs(currentDomain.max).subtract(ZoomPresets[currentRange][currentZoomLevel].scrollBy).valueOf()

                        const newDomain = { min, max }

                        if (dayjs().startOf('minute').isSameOrBefore(dayjs(newDomain.max).startOf('minute'))) {
                            state.current = true
                        } else {
                            state.current = false
                        }

                        state.domain = newDomain

                        break;
                    default:
                }
            }
        },
        scrollRight: (state) => {
            const currentDomain = state.domain
            const currentRange = state.range
            const currentZoomLevel = state.zoomLevel

            if (currentRange === RangePresets.Enum.All) {
                return
            }


            if (currentZoomLevel === null) {
                let newDomainStart = null;
                const currentStart = dayjs(currentDomain.min)

                switch(currentRange) {
                    case RangePresets.Enum.Day: {
                        newDomainStart = currentStart.add(1, 'day')
                        break;
                    }
                    case RangePresets.Enum.Week: {
                        newDomainStart = currentRange.add(1, 'week')
                        break;
                    }
                    case RangePresets.Enum.Month: {
                        newDomainStart = currentStart.add(1, 'month')
                        break;
                    }
                    default:
                }

                if (newDomainStart !== null) {
                    if (newDomainStart.isBefore(dayjs())) {
                        const newDomain = PresetDomainFunctions[currentRange](newDomainStart.valueOf(), state.minimumDate)

                        state.domain = newDomain

                        if (dayjs(newDomain.max).startOf('minute').isSameOrAfter(dayjs().startOf('minute'))) {
                            state.current = true
                        }
                    }
                }
            } else {
                switch(currentRange) {
                    case RangePresets.Enum.Month:
                    case RangePresets.Enum.Week:
                    case RangePresets.Enum.Day:
                        const min = dayjs(currentDomain.min).add(ZoomPresets[currentRange][currentZoomLevel].scrollBy)

                        if (min.startOf('minute').isBefore(dayjs().startOf('minute'))) {
                            const newDomain = {
                                min: min.valueOf(),
                                max: dayjs(currentDomain.max).add(ZoomPresets[currentRange][currentZoomLevel].scrollBy).valueOf()
                            }

                            if (dayjs().isSameOrBefore(dayjs(newDomain.max))) {
                                state.current = true
                            } else {
                                state.current = false
                            }

                            state.domain = newDomain
                        }

                        break;
                    default:
                }
            }
        },
        selectLeftSensor: (state, action) => {
            const { sensor, unix_ts } = action.payload

            if (sensor === null) {
                state.sensors = { ...state.sensors, [SensorPositions.Enum.Left]: null }
                return
            }

            const currentRange = state.range
            const currentZoom = state.zoomLevel

            state.sensors = {...state.sensors, [SensorPositions.Enum.Left]: sensor}

            let newDomain;

            if (currentZoom === null) {
                newDomain = PresetDomainFunctions[currentRange](unix_ts, state.minimumDate)
            } else {
                newDomain = ZoomPresets[currentRange][currentZoom].newDomain(unix_ts, state.minimumDate)
            }

            if (dayjs(newDomain.max).startOf('minute').isSameOrAfter(dayjs().startOf('minute'))) {
                state.current = true
            } else {
                state.current = false
            }

            state.domain = newDomain
        },
        selectRightSensor: (state, action) => {
            const { sensor, unix_ts } = action.payload

            if (sensor === null) {
                state.sensors = { ...state.sensors, [SensorPositions.Enum.Right]: null }
                return
            }

            const currentRange = state.range
            const currentZoom = state.zoomLevel

            state.sensors = {...state.sensors, [SensorPositions.Enum.Right]: sensor}

            let newDomain;

            if (currentZoom === null) {
                newDomain = PresetDomainFunctions[currentRange](unix_ts, state.minimumDate)
            } else {
                newDomain = ZoomPresets[currentRange][currentZoom].newDomain(unix_ts, state.minimumDate)
            }

            if (dayjs(newDomain.max).startOf('minute').isSameOrAfter(dayjs().startOf('minute'))) {
                state.current = true
            } else {
                state.current = false
            }

            state.domain = newDomain
        },
        selectShortSensor: (state, action) => {
            const { sensor, unix_ts } = action.payload

            if (sensor === null) {
                state.shortSensor = null
                return
            }

            state.shortSensor = sensor;
            state.shortDomain = { min: dayjs(unix_ts).subtract(2, 'minute').valueOf(), max: unix_ts }
        },
        selectNewRange: (state, action) => {
            const range = action.payload

            state.zoomLevel = null
            state.range = range

            const newDomain = PresetDomainFunctions[range](state.domain.min, state.minimumDate)

            if (dayjs(newDomain.max).startOf('minute').isSameOrAfter(dayjs().startOf('minute'))) {
                state.current = true
            } else {
                state.current = false
            }

            state.domain = newDomain
        },
        selectCustomRange: (state, action) => {
            const newDomain = action.payload

            state.zoomLevel = null
            
            if (dayjs(newDomain.max).startOf('minute').isSameOrAfter(dayjs().startOf('minute'))) {
                state.current = true
            } else {
                state.current = false
            }

            state.domain = newDomain
        },
        selectShortDomain: (state, action) => {
            state.shortDomain = action.payload
        },
        goToDate: (state, action) => {
            const newStartUnixTS = action.payload
            const currentRange = state.range

            if (dayjs(newStartUnixTS).isBefore(dayjs(state.minimumDate))) {
                return
            }

            if (currentRange === RangePresets.Enum.All) {
                return
            }

            state.zoomLevel = null

            const newDomain = PresetDomainFunctions[currentRange](newStartUnixTS, state.minimumDate)

            if (dayjs(newDomain.max).startOf('minute').isSameOrAfter(dayjs().startOf('minute'))) {
                state.current = true
            } else {
                state.current = false
            }

            state.domain = newDomain
        },
    },
    // extraReducers:
})



export const { initializeGraph, zoomIn, zoomOut, scrollLeft, scrollRight, selectLeftSensor, selectRightSensor, selectNewRange, selectCustomRange, goToDate, selectShortDomain, selectShortSensor } = graphSlice.actions



export const selectGraphState = (state) => {
    return state.graph
}

export const selectYAxes =  
    createSelector(
        selectGraphState,
        (graphState) => Object.values(graphState.sensors).filter((sensor) => sensor).map((sensor) => ({ id: sensor })) 
    )



export default graphSlice.reducer