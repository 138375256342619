import { useMemo } from "react"

import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { NotificationImportantSharp, PreviewSharp } from "@mui/icons-material";

dayjs.extend(localizedFormat)



export const useAlertsHistoryColumns = ({ role, viewAlert, resolveAlert, navigate }) => {
    return useMemo(() => {
        return [
            {
                field: "id",
                headerName: "ID",
                type: "string",
                disableExport: (role !== "ALACRITY"),
                filterable: (role === "ALACRITY"),
                hideable: (role === "ALACRITY"),
                align: 'left',
                headerAlign: 'left'
            },
            {
                field: "alert_ts",
                headerName: "Time Activated",
                type: "string",
                align: "left",
                headerAlign: "left",
                valueFormatter: (params) => {
                    return dayjs(params.value).format('LT')
                },
            },
            {
                field: "handled_ts",
                headerName: "Date Resolved",
                type: "string",
                align: "left",
                headerAlign: "left",
                valueFormatter: (params) => {
                    return (params.value === null) ? 'N/A' : dayjs(params.value).format('LT')
                },
            },
            {
                field: 'responderID',
                headerName: 'Responder ID',
                type: 'string',
                disableExport: (role !== "ALACRITY"),
                filterable: (role === "ALACRITY"),
                hideable: (role === "ALACRITY"),
                align: 'left',
                headerAlign: 'left'
            },
            {
                field: 'responder',
                headerName: "Responder",
                type: 'string',
                renderCell: (params) => {
                    return (
                        <>
                        {
                            (params.row.active) ?
                                <Typography
                                    variant="h6"
                                    color='text.secondary'
                                >
                                    None
                                </Typography>
                            :
                                <Tooltip
                                    title={'Go to Provider Page'}
                                >
                                    <Button
                                        variant="standard"
                                        color="info"
                                        onClick={() =>  navigate(`/dashboard/provider/${params.row.responderID}`) }
                                        sx={{
                                            fontSize: '2rem'
                                        }}
                                    >
                                        {params.row.responder}
                                    </Button>
                                </Tooltip>
                        }
                        </>
                    )
                }
            },
            {
                field: "active",
                headerName: "Resolution",
                type: "boolean",
                align: 'center',
                headerAlign: "center",
                renderCell: (params) => {
                    return (
                        <Tooltip
                            title={(params.row.active) ? 'Resolve Alert' : 'View Alert'}
                        >
                            <IconButton
                                color={(params.row.active) ? 'error' : 'success'}
                                onClick={() => { 
                                    if (params.row.active) {
                                        resolveAlert(params.row.id)
                                    } else {
                                        viewAlert(params.row.id)
                                    }
                                }}
                            >
                                {(params.row.active) ? <NotificationImportantSharp style={{ color: 'red' }} /> : <PreviewSharp style={{ color: 'green' }} />}
                            </IconButton>
                        </Tooltip>
                    )
                }
            },
        ]
    }, [role, viewAlert, resolveAlert, navigate])
}