import { AddSharp, ArrowOutwardSharp, CloseSharp, Domain, EditSharp, ExpandMoreSharp, KeyboardDoubleArrowDownSharp, KeyboardDoubleArrowUpSharp, PersonSharp, SendSharp } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Divider, Grid, IconButton, Skeleton, Stack, Switch, Tooltip, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { useGetOrgQuery, useGetOrgsQuery, useGetTrialsForOrgQuery, useGetUsersListQuery, useUpdateOrgInfoMutation } from "../../../common/redux/api/api";
import { useSelector } from "react-redux";
import { selectUser } from "../../../common/redux/userSlice";
import * as z from 'zod'
import { Controller, FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";


import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useIsQueryLoading } from "../../../common/util";
import { formatPhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { InfoPageChip } from "../../../components/styledComponents/chips/InfoPageChip";
import EntityLinkModal from "../../../components/modals/EntityLinkModal";
import FormInput from "../../../components/CustomFormInput";
import { SingleLineTextBox } from "../../../common/Theme";
import InfoPageDataField from "../../../components/styledComponents/surfaces/InfoPageDataField";
import { FIELD_HEIGHT } from "../doctor/DoctorInfo";
import PhoneNumberInput from "../../../components/PhoneNumberInput";
import InfoPageTextField from "../../../components/styledComponents/surfaces/InfoPageTextField";
import { useDispatch } from "react-redux";
import { updateCurrentEntity } from "../../../common/redux/sidebarSlice";

dayjs.extend(localizedFormat)


const PHARMA_SECTIONS = {
    'Administrators': (user) => user?.admin,
    'Pharmaceutical Employees': (user)  => !user?.admin && user?.permissions === 'PHARMA'
}

const MEDICAL_SECTIONS = {
    'Administrators': (user) => user?.admin,
    'Providers': (user) => user?.permissions === 'DOCTOR' && !user?.admin,
}



export default function OrgChart() {
    const { id: org_id } = useParams()
    const navigate = useNavigate()
    const user = useSelector(selectUser)
    const theme = useTheme()

    // Start with all sections open. Create open/close state for each section.

    const { 
        data: org, 
        isLoading: isOrgLoading, 
        isFetching: isOrgFetching, 
        isSuccess: isOrgSuccess, 
        isError: isOrgError, 
        error: orgError 
    } = useGetOrgQuery(org_id)



    const SECTIONS = useMemo(() => {
        if (!isOrgSuccess) {
            return {}
        } else {
            return (org?.org_type === 'PHARMA') ? PHARMA_SECTIONS : (org?.org_type === 'DOCTOR') ? MEDICAL_SECTIONS : {}
        }
    }, [isOrgSuccess, org])



    const formattedPhone = useMemo(() => {
        if (org?.phone?.e164NumberString) {
            if (org?.phone?.iso2?.toLowerCase() === 'us') {
                return formatPhoneNumber(org?.phone?.e164NumberString)
            } else {
                return formatPhoneNumberIntl(org?.phone?.e164NumberString)
            }
        } else {
            return 'None'
        }
    }, [org]);



    const {
        data: members,
        isLoading: areMembersLoading,
        isFetching: areMembersFetching,
        isSuccess: areMembersSuccess,
        isError: areMembersError,
        error: membersError
    } = useGetUsersListQuery(org?.doctors, { skip: !isOrgSuccess })

    const orgIsLoading = useIsQueryLoading([isOrgLoading, areMembersLoading])


    const [ openState, setOpenState ] = useState({}/*Object.keys(SECTIONS).reduce((acc, sectionName) => ({ ...acc, [sectionName]: true }), {})*/)

    const onClickSectionHeader = (sectionName) => {
        setOpenState({ ...openState, [sectionName]: !openState[sectionName] })
    }

    useEffect(() => {
        if (areMembersSuccess && Object.keys(openState).length === 0) {
            console.log('Current Sections: ', SECTIONS)
            setOpenState(Object.keys(SECTIONS).reduce((acc, sectionName) => ({ ...acc, [sectionName]: true }), {}))
        }
    }, [areMembersSuccess, openState, SECTIONS])



    const {
        data: trials,
        isUninitialized: isTrialsUninitialized,
        isSuccess: isTrialsSuccess,
        isLoading: isTrialsLoading,
        isFetching: isTrialsFetching,
        isError: isTrialsError,
        error: trialsError
    } = useGetTrialsForOrgQuery(org_id);

    const trialsAreLoading = useIsQueryLoading([isTrialsLoading, isTrialsFetching, isTrialsUninitialized])



    const [ isEditing, setIsEditing ] = useState(false);

    const { schema: orgInfoSchema, initialValues, editableValues } = useOrgInfoForm({ user, org });

    const methods = useForm({
        resolver: zodResolver(orgInfoSchema),
        defaultValues: initialValues
    });

    const { control, reset, watch, handleSubmit, formState: { isSubmitSuccessful, errors, isSubmitting } } = methods;
    
    const [ editedData, setEditedData ] = useState({...editableValues})   // Just make a copy of the provider's data that can be manipulated without losing the original copy.

    const [ updateOrg, updateOrgResult ] = useUpdateOrgInfoMutation()


    const onSubmit = (values) => {
        console.log('Edit Org: ', values)
        let newOrgInfo = {}
        for (const field in values) {
            if (values[field] !== undefined && values[field] !== '' && field !== 'countryCode') {
                newOrgInfo = Object.assign(newOrgInfo, { [field]: values[field] })
            }
        }

        if (values?.phone) {
            const phone = parsePhoneNumber(values?.phone.replace(' ', ''))

            newOrgInfo.phone = {
                iso2: values?.countryCode,
                e164NumberString: phone?.number,
                dialCode: phone?.countryCallingCode,
                ext: phone?.ext
            }
        }

        console.log('New Org Info: ', newOrgInfo)
        
        updateOrg({
            id: org_id,
            newInfo: newOrgInfo
        })
    }



    useEffect(() => {
        if (updateOrgResult.isSuccess) {
            setIsEditing(false)
            reset()
            updateOrgResult.reset()
        }
    }, [updateOrgResult, reset, setIsEditing])



    const onDeleteTrialChip = useCallback(({ trial }) => {}, [])
    
    const trialsChips = useMemo(() => {
        if (isOrgSuccess && isTrialsSuccess) {
            const mainRowChips = trials?.map((trial, index) => {
                if (editableValues?.trials) {
                    if (editableValues?.trials?.includes(trial.trial_id)) {
                        return (
                            <InfoPageChip 
                                onDelete={() => { onDeleteTrialChip(trial) }}
                                label={trial?.name}
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color:'black'
                                }}
                            />
                        )
                    } else {    // Else if not in editable values, don't add a delete button by adding an onDelete function
                        return (
                            <InfoPageChip
                                label={trial?.name}
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color: 'black'
                                }}
                            />
                        )
                    }
                } else {
                    return (
                        <InfoPageChip
                            label={trial?.name}
                            sx={{
                                backgroundColor: theme.palette.secondary.main,
                                color: 'black'
                            }}
                        />
                    )
                }
            })

            return (
                <>
                    <Stack
                        direction='row'
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'center',
                            width: '100%'
                        }}
                        spacing={2}
                    >
                        {mainRowChips}

                        {
                            (
                                user.permissions === 'ALACRITY' ||
                                (
                                    user?.orgs.includes(org_id) &&
                                    user.admin === true
                                ) 
                            ) &&
                            <IconButton
                                color="info"
                                onClick={() => { navigate('/createTrial') }}
                            >
                                <AddSharp/>
                            </IconButton>
                        }
                    </Stack>
                </>
            )
        } else {
            return (
                <Stack
                    direction='row'
                    sx={{
                        justifyContent: 'start',
                        alignItems: 'center',
                        width: '100%'
                    }}
                ></Stack>
            )
        }
    }, [editableValues, onDeleteTrialChip, org_id, user, isTrialsSuccess, theme, trials, isOrgSuccess, navigate])



    const [ expand, setExpand ] = useState(false)



    return (
        <Stack
            sx={{
                width: '100%',
                alignItems: 'start',
                p: '2rem'
            }}
            spacing={8}
        >
            <FormProvider {...methods}>
                <Box
                    display='flex'
                    flexDirection='column'
                    component='form'
                    noValidate
                    autoComplete='off'
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{
                        width: '100%',
                        borderBottom: '2px solid white',
                        justifyContent: 'start',
                        alignItems: 'center'
                    }}
                >
                        
                    <Stack
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'start',
                            height: '100%',
                            width: '100%',
                            overflowY: 'auto',
                            px: 8,
                            // py: 1
                        }}
                        spacing={8}
                    >

                        <Accordion 
                            expanded={expand} 
                            TransitionProps={{
                                unmountOnExit: true
                            }}
                            sx={{
                                width: '100%',
                                backgroundColor: 'transparent',
                                backgroundImage: 'none',
                            }}
                        >

                            <AccordionSummary
                                id='patient-bio-header'
                                sx={{
                                    width: '100%'
                                }}
                            >

                                <Stack
                                    sx={{
                                        justifyContent: 'start',
                                        alignItems: 'center',
                                        width: '100%'
                                    }}
                                    direction='row'
                                    spacing={8}
                                >

                                    {                                   
                                        ( isEditing && editableValues?.name ) ?
                                            <FormInput
                                                label='Name'
                                                type='text'
                                                name='name'
                                                sx={{
                                                    mr: 5,
                                                    maxWidth: '20%'
                                                }}
                                                focused
                                            />
                                        :
                                            <SingleLineTextBox
                                                variant='h2'
                                                tooltip={(orgIsLoading) ? null : `${org.name}`}
                                            >
                                                {(orgIsLoading) ? <Skeleton width={'6rem'} /> : `${org?.name}`}
                                            </SingleLineTextBox>
                                    }

                                    {
                                        isOrgLoading ?
                                            <Skeleton variant="circular">
                                                <Avatar     // TODO: Provide way to upload profile picture
                                                    src={org?.avatar}
                                                    sx={{
                                                        height: 60,
                                                        width: 60,
                                                        ml: '10%'
                                                    }}
                                                >
                                                    <Domain style={{ height: 40, width: 40 }}/>
                                                </Avatar>
                                            </Skeleton>
                                        :
                                            <Avatar     // TODO: Provide way to upload profile picture
                                                src={org?.avatar}
                                                sx={{
                                                    height: 60,
                                                    width: 60,
                                                    // ml: '10%',
                                                    // mr: 'auto'
                                                }}
                                            >
                                                <Domain style={{ height: 40, width: 40 }}/>
                                            </Avatar>
                                    }

                                </Stack>

                            </AccordionSummary>

                            <AccordionDetails>

                                <Stack
                                    sx={{
                                        width: '100%',
                                        justifyContent: 'space-between',
                                    }}
                                    direction='row'
                                    spacing={12}
                                >

                                    <Stack
                                        sx={{
                                            width: '100%'
                                        }}
                                        spacing={8}
                                    >

                                        {
                                            org?.org_type === 'PHARMA' &&
                                            <InfoPageDataField
                                                childSpacing={4}
                                                label='Trials'
                                                editing={isEditing}
                                                containerProps={{
                                                    height: FIELD_HEIGHT,
                                                    width: '100%',
                                                }}
                                                loading={orgIsLoading || trialsAreLoading}
                                                loadingChildren={
                                                    <Stack
                                                        direction={'row'}
                                                        width='100%'
                                                        height='100%'
                                                        spacing={3}
                                                        alignItems='center'
                                                    >
                                                        {
                                                            Array(4).fill(0).map(() => (<Skeleton variant="rounded" sx={{ height: 40, width: 100, borderRadius: 6 }}/>))
                                                        }
                                                    </Stack>
                                                }
                                            >
                                                {trialsChips}
                                            </InfoPageDataField>  
                                        }

                                        <Stack
                                            sx={{
                                                width: '100%',
                                                height: FIELD_HEIGHT,
                                                alignItems: 'end'
                                            }}
                                            direction='row'
                                            spacing={8}
                                        >
                                            {
                                                isEditing ?
                                                    <PhoneNumberInput focused />
                                                :
                                                    <InfoPageTextField
                                                        label='Phone'
                                                        type='tel'
                                                        name='phone'
                                                        loading={orgIsLoading}
                                                        editing={isEditing}
                                                        text={formattedPhone}  
                                                        boxProps={{
                                                            display: 'flex',
                                                            alignItems: 'end',
                                                            width: '100%',
                                                            height: '100%'
                                                        }}
                                                        inputProps={{
                                                            height: '3.6rem'
                                                        }}
                                                        focused                        
                                                    />
                                            }

                                            <InfoPageTextField
                                                label='Email'
                                                type='text'
                                                name='email'
                                                loading={orgIsLoading}
                                                editing={isEditing}
                                                text={(org?.email !== '') ? org?.email : undefined}  
                                                boxProps={{
                                                    display: 'flex',
                                                    alignItems: 'end',
                                                    width: '100%',
                                                    height: '100%'
                                                }}
                                                inputProps={{
                                                    height: '3.6rem'
                                                }}
                                                focused                        
                                            />

                                        </Stack>
                                        
                                        <Controller
                                            control={control}
                                            name='status_active'
                                            defaultValue={undefined}
                                            disabled={!isEditing}
                                            render={({ field }) => {
                                                const { value, ...fields} = field
                                                return (
                                                    <Stack 
                                                        direction={'row'} 
                                                        alignItems='center'
                                                    >

                                                        <Typography>
                                                            Inactive
                                                        </Typography>

                                                        <Switch 
                                                            {...fields} 
                                                            checked={(isEditing) ? value : org?.status_active}
                                                            sx={{
                                                                '& .MuiSwitch-switchBase.Mui-disabled': {
                                                                    color: theme.palette.primary.main,
                                                                },
                                                                '& .MuiSwitch-track': {
                                                                    backgroundColor: `${theme.palette.primary.main} !important`,
                                                                    opacity: '0.5 !important'
                                                                }
                                                            }}
                                                        />

                                                        <Typography>
                                                            Active
                                                        </Typography>

                                                    </Stack>
                                                )
                                            }}
                                        />

                                    </Stack>

                                    <Stack
                                        sx={{
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <LoadingButton
                                            loading={orgIsLoading}
                                            startIcon={(isEditing) ? <CloseSharp/> : <EditSharp/>}
                                            variant="outlined"
                                            color={(isEditing) ? 'error' : 'primary'}
                                            sx={{
                                                height: 'fit-content',
                                                py: 1,
                                                px: 1.5,
                                                '&:hover': {
                                                    backgroundColor: (isEditing) ? theme.palette.error.main : theme.palette.primary.main,
                                                    color: 'white' 
                                                }
                                            }}
                                            onClick={() => { reset(); setExpand(!isEditing); setIsEditing((prev) => !prev) }}
                                        >
                                            {(isEditing) ? 'Cancel' : 'Edit'}
                                        </LoadingButton>  

                                        {
                                            isEditing &&
                                            <LoadingButton
                                                loading={isSubmitting}
                                                endIcon={<SendSharp/>}
                                                variant="outlined"
                                                color={'primary'}
                                                sx={{
                                                    height: 'fit-content',
                                                    py: 1,
                                                    px: 1.5,
                                                    '&:hover': {
                                                        backgroundColor: theme.palette.primary.main,
                                                        color: 'white' 
                                                    }
                                                }}
                                                type="submit"
                                            >
                                                Submit
                                            </LoadingButton> 
                                        }     
                                        
                                    </Stack>

                                </Stack>

                            </AccordionDetails>
                        
                        </Accordion>

                    </Stack>

                    <Button
                        disabled={isEditing}
                        variant='standard'
                        endIcon={(expand) ? <KeyboardDoubleArrowUpSharp/> : <KeyboardDoubleArrowDownSharp/>}
                        onClick={(expand) ? () => setExpand(false) : () => setExpand(true)}
                        sx={{
                            fontSize: '1rem'
                        }}
                    >
                        {`See ${(expand) ? 'Less' : 'More'}`}
                    </Button> 

                </Box>
            </FormProvider>

            <Stack
                sx={{ 
                    width: '100%',
                    justifyContent: 'start' 
                }}
                spacing={4}
            >

                {
                    Object.entries(SECTIONS).map(([section, filterFn]) => {
                        return (
                            <Section
                                name={section}
                                loading={orgIsLoading}
                                open={openState[section] ?? false}
                                onClick={() => onClickSectionHeader(section)}
                                users={(members ?? []).filter(filterFn)}
                                orgType={org?.org_type}
                            />
                        )
                    })
                }

                <Divider sx={{ width: '100%' }} />

                {
                    (
                        user?.permissions === 'ALACRITY' ||
                        (
                            user?.admin &&
                            user?.orgs.includes(org?.org_id)
                        )
                    ) &&
                    <Stack
                        direction='row'
                        sx={{
                            width: '100%',
                            justifyContent: 'start',
                            alignItems: 'center'
                        }}
                    >
                        
                            <LoadingButton
                                variant="xxl"
                                startIcon={<AddSharp/>}
                                onClick={() => { navigate('/createUser', { state: { role: org?.org_type } }) }}
                                sx={{
                                    py: 1,
                                    backgroundColor: 'transparent',
                                    border: `1px solid ${theme.palette.primary.dark}`  
                                }}
                            >
                                Add User
                            </LoadingButton>

                    </Stack>
                }

            </Stack>
        </Stack>
    )
}



const BioCard = ({
    user,
    loading,
    orgType
}) => {
    const theme = useTheme();
    const navigate = useNavigate()
    const dispatch = useDispatch()



    const formattedPhone = useMemo(() => {
        if (user?.phone?.e164NumberString) {
            if (user?.phone?.iso2?.toLowerCase() === 'us') {
                return formatPhoneNumber(user?.phone?.e164NumberString)
            } else {
                return formatPhoneNumberIntl(user?.phone?.e164NumberString)
            }
        } else {
            return ''
        }
    }, [user]);



    const onClickCard = useCallback(() => {
        dispatch(updateCurrentEntity(user?.alacrity_id))
        navigate(`/dashboard/${(orgType === 'DOCTOR') ? 'provider' : 'pharma'}/${user?.alacrity_id}`)
    }, [navigate, user, orgType, dispatch])



    return (
        <Stack
            direction='row'
            spacing={1}
            justifyContent='start'
            alignItems='start'
            sx={{
                width: '100%',
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 4px 2px'
            }}
        >
            <Stack
                spacing={1}
                justifyContent='start'
                alignItems='start'
                sx={{
                    width: '100%',
                    p: 2
                }}
            >
                <Stack
                    direction={{ md: 'row', xs: 'column' }}
                    justifyContent='start'
                    alignItems='start'
                    spacing={2}
                    sx={{
                        width: '100%'
                    }}
                >
                    {
                        loading ?
                        <Skeleton variant="circular">
                            <Avatar />
                        </Skeleton>
                        :
                        <Avatar
                            src={user?.Avatar}
                            // alt={user?.first_name}
                            sx={{ 
                                backgroundColor: theme.palette.text.secondary
                            }}
                        >
                            <PersonSharp/>
                        </Avatar>
                    }

                    <Typography
                        multiline
                        rows={{ xs: 2, md: 1 }}
                        variant="h5"
                        textAlign='start'
                    >
                        { loading ? <Skeleton/> : `${user?.first_name} ${user?.last_name}${(user?.title) ? `, ${user?.title}` : "" }` }
                    </Typography>
                </Stack>

                <Typography
                    variant="body1"
                    textAlign='start'
                >
                    { loading ? <Skeleton/> : `# ${formattedPhone}` }
                </Typography>

                <Typography
                    variant="body1"
                    textAlign='start'
                >
                    { loading ? <Skeleton/> : user?.email }
                </Typography>
            </Stack>

            <Tooltip
                title={'Go to profile'}
            >
                <IconButton
                    color="text.secondary"
                    onClick={onClickCard}
                >
                    <ArrowOutwardSharp/>
                </IconButton>
            </Tooltip>
        </Stack>
    )
}



const Section = ({
    name,
    loading,
    users,
    open,
    onClick,
    orgType
}) => {
    return (
        <Accordion 
            disabled={loading}
            expanded={open}
            onChange={onClick}
        >
            <AccordionSummary expandIcon={<ExpandMoreSharp/>}>
                <Typography variant="h3">{ loading ? <Skeleton/> : name }</Typography>
            </AccordionSummary>
            <AccordionDetails>
                 {
                    users.length === 0 ?
                        <Typography
                            variant="h6"
                            color='text.secondary'
                        >
                            Empty
                        </Typography>
                    :
                        <Grid 
                            container 
                            spacing={2}
                        >
                            {
                                users.map((user) => {
                                    return (
                                        <Grid item lg={4}  md={6} xs={12}>
                                            <BioCard
                                                loading={loading}
                                                user={user}
                                                orgType={orgType}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                }
            </AccordionDetails>
        </Accordion>
    )
}



const useOrgInfoForm = ({ user, org }) => {
    return useMemo(() => {
        var schema = z.object({});
        var initialValues = {};
        var editableValues = {};

        if (
            (
                user?.admin === true && 
                user?.orgs.includes(org?.org_id)
            ) || 
            user?.permissions === 'ALACRITY'
        ) {     // If the user is looking at their own page, they can edit email & phone since they can verify.
            initialValues = {
                ...initialValues,
                name: undefined,
                email: undefined,
                phone: undefined,
                countryCode: 'US',
                trials: undefined,
                status_active: undefined
            }

            editableValues = {
                name: org?.name,
                email: org?.email,
                countryCode: org?.phone?.iso2,
                phone: org?.phone?.e164NumberString,
                trials: org?.trials,
                status_active: org?.status_active
            }

            schema = schema.extend({
                name: z.string().optional(),
                email: z.union([z.string().email('Must be a valid email.'), z.literal('')]),
                countryCode: z.string().length(2).optional(),
                phone: z.string().optional(),
                trials: z.array(z.string()).optional(),
            })
        }
    
        

        if (
            (
                (
                    user?.admin === true && 
                    user?.orgs.includes(org?.org_id)
                ) || 
                user?.permissions === 'ALACRITY'
            ) &&
            org?.org_type === 'DOCTOR'
        ) {
            initialValues = {
                ...initialValues,
                doctors: undefined,
            }

            schema = schema.extend({
                doctors: z.array(z.string()).optional()
            })

            editableValues = {
                ...editableValues,
                doctors: org?.doctors,
            }
        }



        if (
            (
                (
                    user?.admin === true && 
                    user?.orgs.includes(org?.org_id)
                ) || 
                user?.permissions === 'ALACRITY'
            ) &&
            org?.org_type === 'PHARMA'
        ) {
            initialValues = {
                ...initialValues,
                pharmas: undefined,
            }

            schema = schema.extend({
                pharmas: z.array(z.string()).optional()
            })

            editableValues = {
                ...editableValues,
                pharmas: org?.pharmas,
            }
        }


        schema = schema.superRefine((data, ctx) => {
            if (data?.phone) {
                if (!isPossiblePhoneNumber(data?.phone.replace(' ', ""))) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: 'Invalid Phone Number',
                        path: ['phone']
                    })
                }
            }
        })


        return { schema, initialValues, editableValues }
    
    }, [user, org])
}