import * as React from "react";
const SvgToxhoundDmIconBlueSmallVector = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Toxhound_dm_icon_blue_small_vector_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 76.12 60.13"
    {...props}
  >
    {/* <defs>
      <style>
        {
          `.Toxhound_dm_icon_blue_small_vector_svg__cls-1{fill:#5bacdf;stroke-width:0}`
        }
      </style>
    </defs> */}
    <path
      d="M58.34 53.83c-9.91 8.6-30.13 8.38-39.76-.6-3.96-4.11-6-9.96-5.82-15.65 0-1.37-.28-2.09-.99-3.24-2.47 2.99-6.85 4.87-9.95 1.8C-.73 32.7.26 28.09.05 24.07-.84 12.58 10.28 8.39 19.02 4.62 31.8-2.14 47.66-1.29 60.04 5.91c6.6 2.72 15.89 6.61 15.87 14.44-.02 6.19 1.11 12.37-1.71 17.31-2.16 3.78-7.6 1.47-9.58-1.98-1.55 2.02-1.14 4.52-1.32 6.92-.21 3.78-1.46 7.35-3.96 10.26m-41.9-41.3c-4.81 5.81-5.22 14.17-1.83 20.77 1.02 2.12 1.31 3.9 1.36 6.24.12 4.95 2.19 9.32 5.57 12.88 9.03 7.47 24.76 7.42 33.29-.84 2.12-2.89 3.18-5.83 3.23-9.39-.28-5.25 2.87-9.38 3.59-14.47q.62.1 1.32.33c2.03 3.07 2.57 5.14 5.28 7.6q1.2.13 2.23-.41c1.35-2.05.57-4.63.68-6.95.19-4.68-.15-9.14-4.05-12.31C53.98 5.55 31.26-2.8 17.44 11.56ZM5.89 16.54c-3 3.92-2.05 8.67-2.19 13.28-.34 3.24 1.51 5.62 4.38 2.86 3.68-5.73.35-13.18 4.26-18.75q.52-.77.41-2.04c-2.48 0-5.2 3.05-6.87 4.65Z"
      className="Toxhound_dm_icon_blue_small_vector_svg__cls-1"
      fill="#5bacdf"
      strokeWidth={0}
    />
    <path
      d="M56.03 15.52c10.83 8.88-3.73 25.93-13.87 15.85-9.27-9.11 3.61-24.02 13.87-15.85Zm-9.25 5.95c-2.43 3.28 1.28 7.15 4.73 4.95 2.84-3.37-1.13-7.93-4.73-4.95M36.11 34.85c2.48.09 6.71-.28 8.03.81 1.07 2.24-.81 4.43-2.08 6.17q-.65.77-.74 1.92c.25 3.54 5.91 4.59 7.21 1.22.45-1.77.87-3.64 1.9-4.03 9.54.74-2.81 17.56-11.23 8.59-1.04.05-1.69.53-2.5 1.16-1.86 1.43-3.82 1.4-6.09 1.16-4.04-1.09-9.27-6.5-5.95-10.57q1.14-.37 2.31-.33c2.08 1.47 1.16 4.22 3.22 5.66 2.32.82 5.53.28 5.83-2.59-.13-2.34-3.29-5.23-3.28-6.94.42-1.82 1.66-2.24 3.37-2.23M29.28 19.81c6.7 2.39 1.65 12-3.96 7.6-3.15-3.02-.45-8.65 3.96-7.6Z"
      className="Toxhound_dm_icon_blue_small_vector_svg__cls-1"
      fill="#5bacdf"
      strokeWidth={0}
    />
  </svg>
);
export default SvgToxhoundDmIconBlueSmallVector;

