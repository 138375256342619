import { useTheme, Paper, Stack, Typography } from "@mui/material";
import SvgMedicalCrossCircle from '../../../images/icons/MedicalCrossCircle'



export default function EmptyDoctorPage() {
    const theme = useTheme();



    return (
        <Paper
            sx={{
                height: '100%',
                width: '100%'
            }}
            elevation={8}
        >
            <Stack
                sx={{
                    height: '100%',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                spacing={2}
            >
                <SvgMedicalCrossCircle style={{ color: theme.palette.text.secondary, height: 180, width: 180 }} />
                <Typography
                    variant='h2'
                    color='textSecondary'
                >
                    Select a provider's name to manage their profile.
                </Typography>
            </Stack>
        </Paper>
    )
}