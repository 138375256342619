import { createTheme, responsiveFontSizes, styled, Box, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { grey, orange, purple, red } from "@mui/material/colors";
import { useRef, useState, useEffect, forwardRef } from "react";



const BaseTheme = createTheme(
    {
        palette: {
            mode: 'dark',
            primary: {
                main: '#54AEEC',
                dark: '#84AAE9',
				light: '#78CCDD'
            },
            secondary: {
                main: purple[200],
				light: purple[50],
				dark: purple[300]
            },
			warning: {
				main: orange[400],
				light: orange[300],
				dark: orange[700]
			},
			error: {
				main: '#E55B5B',
				light: red[300],
				dark: red[700]
			},
			info: {
				main: '#ffffff'
			},
            background: {
				default: '#212121'
            },
			label: {
				main: 'rgba(255, 255, 255, 0.7)'
			}
        },
        typography: {
            h1: {
                fontFamily: 'Montserrat',
				fontSize: '6rem'
            },
            h2: {
                fontFamily: 'Montserrat',
                fontWeight: 400,
                overflowWrap: 'break-word'
            },
            h3: {
                fontFamily: 'Montserrat'
            },
            h4: {
                fontFamily: 'Montserrat'
            },
            h5: {
                fontFamily: 'Montserrat'
            },
            h6: {
                fontFamily: 'Montserrat'
            },
            body1: {
                fontFamily: [
                  'Atkinson Hyperlegible',
                  'sans-serif',
                ].join(','),
              },
              body2: {
                fontFamily: [
                  'Atkinson Hyperlegible',
                  'sans-serif',
                ].join(','),
              },
              subtitle1: {
                fontFamily: [
                  'Atkinson Hyperlegible',
                  'sans-serif',
                ].join(','),
              },
              subtitle2: {
                fontFamily: [
                  'Atkinson Hyperlegible',
                  'sans-serif',
                ].join(','),
              },
              button: {
                fontFamily: [
                  'Atkinson Hyperlegible',
                  'sans-serif',
                ].join(','),
              },
              caption: {
                fontFamily: [
                  'Atkinson Hyperlegible',
                  'sans-serif',
                ].join(','),
              },
              overline: {
                fontFamily: [
                  'Atkinson Hyperlegible',
                  'sans-serif',
                ].join(','),
              },
        },
		breakpoints: {
			values: {
				xs: 0,
				sm: 425,
				md: 768,
				lg: 1024,
				xl: 1280,
				'2xl': 1536,
				'3xl': 1920,
				'4xl': 2560,
				'5xl': 3200,
			},
		}
    }
)



const responsiveTheme = responsiveFontSizes(BaseTheme, { breakpoints: ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl'], factor: 6 });



const AlacrityTheme = createTheme(
    responsiveTheme,
    {
		alacrityShadowBorder: `rgba(255, 255, 255, 0.4) 0px 0px 6px 1px`,
        alacrityBorder: `2px solid rgba(255, 255, 255, 0.7)`,
        alacrityMarginsX: '4em',
        components: {
            MuiDataGrid: {
                defaultProps: {
                    paginationModel: {
                        pageSize: 10,
                    },
                    pageSizeOptions: [10, 25, 50]
                },
                styleOverrides: {
                    root: {
                        fontFamily: 'Atkinson Hyperlegible', 
                        fontSize: 'large'
                    }
                }
            },
			MuiButton: {
				styleOverrides: {
					root: {
						'&:hover': {
							backgroundColor: '#ffffff50'
						},
						textTransform: 'none',
						fontSize: '1.5em'
					},
				},
				variants: [
					{
						props: {
							variant: 'sidebar'
						},
						style: {
							borderRadius: 0,
							border: '1px solid #898989',
							borderBottomWidth: '0',
							width: '100%',
							padding: '2rem'
						}
					},
          			{
						props: {
							variant: 'xxl'
						},
						style: {
							padding: '3rem',
							fontSize: 32,
							backgroundColor: responsiveTheme.palette.primary.main,
							width: 'max-content',
							'&:hover': {
								backgroundColor: 'rgba(255, 255, 255, 0.7)',
								color: 'black'
							}
						}
					}
				]
			},
			MuiChip: {
				variants: [
					{
						props: { 
							variant: 'appbar-action',
						},
						style: {
							backgroundColor: 'transparent',
							border: '2px solid white',
							padding: '1rem 0.5rem',
							marginRight: '2rem',
							'& .MuiChip-label': {
								fontWeight: 500,
								fontSize: '1.5em',
							}
						}
					}
				]
			},
			MuiTypography: {
				variants: [
					{
						props: { 
							variant: 'label',
						},
						style: {
							color: 'rgba(255, 255, 255, 0.7)'
						}
					}
				]
			},
    	}
  	}
);

export default AlacrityTheme;



export const DashboardBox = (props) => {
    return (
        <Box
            sx={{
                mb: '1px'
            }}
        >
            {props.children}
        </Box>
    )
}



const BootstrapTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black,
	},
}));

const TooltipText = forwardRef(function TooltipText(props, ref) {
  //  Spread the props to the underlying DOM element.
  return (
		<Typography 
			{...props} 
			ref={ref}
			sx={{
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis'
			}}
		>
			{props.children}
		</Typography>
  	);
});

export const SingleLineTextBox = (props) => {
  	const { tooltip, variant, fontWeight, fontFamily, color } = props;

  	const textElementRef = useRef();

	const compareSize = () => {
		const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
		setHover(compare);
	};

  // compare once and add resize listener on "componentDidMount"
  	useEffect(() => {
		compareSize();
		window.addEventListener('resize', compareSize);
		return () => { window.removeEventListener('resize', compareSize) }
  	}, []);

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  return(
    <BootstrapTooltip 
		title={tooltip}
		disableHoverListener={!hoverStatus}
    >
		<TooltipText 
			color={color}
			variant={variant} 
			ref={textElementRef}
			fontWeight={fontWeight}
			fontFamily={fontFamily}
		>
			{props.children}
		</TooltipText>
    </BootstrapTooltip>
  )
}