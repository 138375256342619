import { useDispatch, useSelector } from "react-redux";
import { useUpdateSelfInfoMutation } from "../../common/redux/api/userApi";
import { selectUser } from "../../common/redux/userSlice";
import * as z from 'zod';
import { useState, useEffect } from "react";
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';
import { Container, Box, Typography, ThemeProvider, styled, TextField, Button, Stack, Paper } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FormInput from "../../components/CustomFormInput";
import AlacrityTheme from "../../common/Theme";
import { EditSharp } from "@mui/icons-material";
import { AccountTheme } from "./Themes";
import { rewrite } from "../../common/redux/historySlice";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { BANNER_HEIGHT } from "../../components/Banner";



const UserInfoBox = styled(({ inputProps, InputLabelProps, ...props }) => (
    <TextField
        variant="outlined"
        disabled
        inputProps={{
            sx: {
                fontSize: '2em',
            },
            ...inputProps
        }}
        InputLabelProps={{
            sx: {
                fontSize: '1.5em',
            },
            ...InputLabelProps
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiInputLabel-root.Mui-disabled": {
        color: `${theme.palette.primary.main}`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        fontSize: '1.5em',
    },
    '& input.Mui-disabled': {
        color: 'white',
        WebkitTextFillColor: 'white'
    },
    width: '100%',
}))



export const EditButton = styled(({ ...props }) => (
    <LoadingButton variant="outlined" color="error" {...props} endIcon={<EditSharp/>}/>
))(({ theme }) => ({
    width: 'fit-content',
    ':disabled': {
        borderColor: 'red',
        color: 'red'
    },
    paddingLeft: '1em',
    paddingRight: '1em',
}))



const AccountContainer = styled(({ ...props }) => (
    <Container sx={{ width: {xl: '40%', md: '50%', xs: '90%'} }} {...props}/>
))(({ theme }) =>({
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    paddingBottom: '4rem',
    paddingTop: '2rem'
}))



export default function Account() {
    const dispatch = useDispatch();
    const accountInfo = useSelector(selectUser);
    const [ update, result ] = useUpdateSelfInfoMutation();
    const { isLoading, isError, error, isSuccess } = result;
    const [ isEditing, setIsEditing ] = useState(false);
    const [ phoneNumberData, setPhoneNumberData ] = useState(accountInfo?.phone);



    const userSchema = z.object({
        first_name: z.string().min(1, 'First Name is required.').optional(),
        last_name: z.string().min(1, 'Last Name is required.').optional(),
        email: z.union([z.string().email('Must be a valid email.'), z.literal('')]),
        password: z.union([z.string()
          .min(1, 'Password is required')
          .min(10, 'Password must be at least 10 characters'), z.literal('')]),
        confirmPassword: z.union([z.string().min(1, 'This field is required').min(10, 'Please enter the same password as above.'), z.literal('')]),
        phone: z.string().min(1, 'Phone Number is required').optional(),
        countryCode: z.string().length(2),
    }).refine((data) => {
        if (data?.password) {
            return data?.password === data?.confirmPassword
        } else {
            return true
        }
    }, {
        message: "Passwords don't match",
        path: ["confirmPassword"],
    }).superRefine((data, ctx) => {
        if (data?.phone) {
            if (!isPossiblePhoneNumber(data?.phone.replace(' ', ""))) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Invalid Phone Number',
                    path: ['phone']
                })
            }
        }
    });


    const methods = useForm({
        resolver: zodResolver(userSchema),
        defaultValues: {
            first_name: undefined,
            last_name: undefined,
            email: undefined,
            phone: undefined,
            countryCode: 'US',
            password: undefined,
            confirmPassword: undefined
        },
    });

    const { reset, handleSubmit, formState: { isSubmitSuccessful, isSubmitting } } = methods;



    const onSubmitHandler = (values) => {
        let newUserInfo = {}
        for (const field in values) {
            if (values[field] && field !== 'countryCode') {
                newUserInfo = Object.assign(newUserInfo, { [field]: values[field] })
            }
        }

        if (values?.phone) {
            const phone = parsePhoneNumber(values?.phone.replace(' ', ''))

            newUserInfo.phone = {
                iso2: values?.countryCode,
                e164NumberString: phone?.number,
                dialCode: phone?.countryCallingCode,
                ext: phone?.ext
            }
        }

        console.log(newUserInfo)
        update(newUserInfo);
    };



    useEffect(() => {
        // If we are navigating to the top level patients list, overwrite whatever the previous history was.
            dispatch(rewrite([{ name: 'Account', path: '/account'}]))
    });



    useEffect(() => {
        if (isSuccess) {
          toast.success('Account updated successfully.');
          setIsEditing(false)
        }
        if (isError) {
            console.log(error);
            if (Array.isArray((error))) {
                (error).data.error.forEach((el) =>
                    toast.error(el.message, {
                        position: 'top-center', autoClose: 1000,
                    })
                );
            } else {
                toast.error((error).error, {
                    position: 'top-center', autoClose: 1000,
                });
            }
        }
    }, [isSuccess, isError, error]);
    
    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
        }
    }, [isSubmitSuccessful]);

    

    if (isEditing) {
        return(
            <Container
                sx={{
                    width: { xl: '40%', lg: '50%', xs: '90%' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    pt: '2rem',
                    height: `calc(100vh - ${BANNER_HEIGHT}px - 16px)`
                }}
            >
                <FormProvider {...methods}>
                    <Box
                        display='flex'
                        flexDirection='column'
                        component='form'
                        noValidate
                        autoComplete='off'
                        // sx={{ 
                        //     paddingRight: { sm: '3rem' },
                        // }}
                        onSubmit={handleSubmit(onSubmitHandler)}
                    >
                        <Stack
                            height='100%'
                            width='100%'
                            justifyContent='start'
                            alignItems='start'
                            spacing={6}
                            sx={{
                                pb: '2rem'
                            }}
                        >
                            <Typography
                                variant='h1'
                                sx={{ 
                                    textAlign: 'center',
                                    mb: '1.5rem',
                                    width: '100%'
                                }}
                            >
                                Account
                            </Typography>

                            <EditButton onClick={() => { setIsEditing(false); reset() }}>
                                Cancel
                            </EditButton>

                            <Paper
                                elevation={8}
                                sx={{
                                    width: '100%',
                                    height: '100%'
                                }}
                            >
                                <Stack
                                    sx={{
                                        width: '100%',
                                        justifyContent: 'start',
                                        alignItems: 'center',
                                        px: '2rem',
                                        pt: '2rem',
                                        pb: '4rem',
                                        height:'72vh',
                                        overflowY: 'scroll',
                                        '::-webkit-scrollbar': {
                                            '-webkit-appearance': 'none',
                                            width: '7px'
                                        },  
                                        '::-webkit-scrollbar-thumb': {
                                            borderRadius: '4px',
                                            backgroundColor: 'rgba(255, 255, 255, .5)',
                                        }
                                    }}
                                    spacing={8}
                                >

                                    <FormInput
                                        label='First Name'
                                        type='text'
                                        name='first_name'
                                        focused
                                    />

                                    <FormInput
                                        label='Last Name'
                                        type='text'
                                        name='last_name'
                                        focused
                                    />

                                    <FormInput
                                        label='Enter your email'
                                        type='email'
                                        name='email'
                                        focused
                                    />

                                    
                                    <PhoneNumberInput
                                        focused
                                    />

                                    <FormInput
                                        type='password'
                                        label='Password'
                                        name='password'
                                        focused
                                    />

                                    <FormInput
                                        type='password'
                                        label='Confirm Password'
                                        name='confirmPassword'
                                        focused
                                    />

                                    <LoadingButton
                                        loading={isLoading || isSubmitting}
                                        type='submit'
                                        variant='xxl'
                                        sx={{
                                            py: '0.8rem',
                                            mt: 2,
                                            marginInline: 'auto',
                                        }}
                                    >
                                        Submit
                                    </LoadingButton>
                                </Stack>
                            </Paper>
                        </Stack>
                    </Box>
                </FormProvider>
            </Container>
        )
    } else {
        return (
            <Container
                sx={{
                    width: { xl: '40%', lg: '50%', xs: '90%' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    pt: '2rem',
                    height: `calc(100vh - ${BANNER_HEIGHT}px - 16px)`
                }}
            >
                <Stack
                    height='100%'
                    width='100%'
                    justifyContent='start'
                    alignItems='start'
                    spacing={6}
                    sx={{
                        pb: '2rem'
                    }}
                >
                    <Typography
                        variant='h1'
                        sx={{ 
                            textAlign: 'center', 
                            mb: '1.5rem' ,
                            width: '100%'
                        }}
                    >
                        Account
                    </Typography>

                    <EditButton 
                        onClick={() => { setIsEditing(true) }}
                        // disabled={accountInfo}
                    >
                        Edit
                    </EditButton>

                    <Paper
                        elevation={8}
                        sx={{
                            width: '100%',
                            height: '100%'
                        }}
                    >
                        <Stack
                            sx={{
                                width: '100%',
                                justifyContent: 'start',
                                alignItems: 'center',
                                px: '2rem',
                                pt: '2rem',
                                pb: '4rem',
                                height:'72vh',
                                overflowY: 'scroll',
                                '::-webkit-scrollbar': {
                                    '-webkit-appearance': 'none',
                                    width: '7px'
                                },  
                                '::-webkit-scrollbar-thumb': {
                                    borderRadius: '4px',
                                    backgroundColor: 'rgba(255, 255, 255, .5)',
                                }
                            }}
                            spacing={8}
                        >
                            {
                                accountInfo.permissions === 'ALACRITY' && accountInfo?.org_id &&
                                <UserInfoBox
                                    value={accountInfo.org_id}
                                    label={"Organization ID"}
                                />
                            }
                            
                            <UserInfoBox
                                value={accountInfo?.first_name ?? 'N/A'}
                                label={'First Name'}
                            />

                            <UserInfoBox
                                value={accountInfo?.last_name ?? 'N/A'}
                                label={'Last Name'}
                            />

                            <UserInfoBox
                                value={accountInfo?.email ?? 'N/A'}
                                label={'Email'}
                            />

                            <UserInfoBox
                                value={accountInfo?.phone?.e164NumberString ?? 'N/A'}
                                label={'Phone Number'}
                            />

                            <UserInfoBox
                                value={'*'.repeat(20)}
                                label={'Password'}
                            />
                        </Stack>
                    </Paper>
                </Stack>
            </Container>
        )
    }
}