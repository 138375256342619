import * as React from "react";
const SvgMedicalCrossCircle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={180}
    height={181}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.7}
      d="M90.003 1.774C41.28 1.774 1.64 41.414 1.64 90.138c0 48.724 39.639 88.364 88.363 88.364 48.725 0 88.364-39.64 88.364-88.364 0-48.724-39.639-88.364-88.364-88.364Zm0 169.2c-44.58 0-80.843-36.266-80.843-80.842 0-44.58 36.264-80.843 80.843-80.843 44.576 0 80.843 36.266 80.843 80.843 0 44.576-36.267 80.842-80.843 80.842Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.70}
      d="M104.099 43.138h-28.2v32.9H42.997v28.201h32.9v32.903H104.1v-32.903h32.903v-28.2h-32.903V43.137Z"
    />
  </svg>
);
export default SvgMedicalCrossCircle;
