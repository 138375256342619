const config = {
    dev:{
        app: {
            port: 3000,
            name: 'alacrity-frontend-dev',
        },
        db: {
            host: 'http://localhost',
            port: 3001,
            name: 'json-server',
            file: 'db.json',
            
        }
    },
    staging: {
        app: {
            port: 3000,
            name: 'alacrity-frontend-staging'
        },
        db: {
            // host: 'https://nnh54tlws8.execute-api.us-west-1.amazonaws.com/prod',
            host: 'https://api.cloudonc.com',
            port: null,
            name: 'aws-prod',
            file: null
        }
    }
}

export default config;