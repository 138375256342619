import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { redirect } from "react-router";
import { logout, prefillUser } from "../../userSlice";
import { userApi } from "../userApi";
import { customFetchBase } from "./customAuthQuery";




export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: customFetchBase,
    endpoints: (builder) => ({
        createUser: builder.mutation({
            query(userInfo) {
              return {
                url: '/users',
                method: 'POST',
                body: userInfo,
              };
            },
        }),
        loginUser: builder.mutation({
            query: (accountInfo) => ({
                url: '/auth/login',
                method: 'POST',
                body: accountInfo,
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    await dispatch(userApi.endpoints.getCurrentUser.initiate(null));
                } catch (error) {
                    console.log(error)
                }
            },
        }),
        getVerificationCodes: builder.query({
            query: () => `/auth/verify`,
        }),
        verifyContactInfo: builder.mutation({
            query: (codeObject) => ({
                url: '/auth/verify',
                method: 'POST',
                body: codeObject,
            })
        }),
        createNewPassword: builder.mutation({
            query: (passwordObject) => ({
                url: '/auth/verify',
                method: 'POST',
                body: passwordObject,
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    await dispatch(userApi.endpoints.getCurrentUser.initiate(null));
                } catch (error) {
                    console.log(error)
                }
            },
        }),
        logoutUser: builder.mutation({
            query: () => {
                return {
                    url: '/auth/logout',
                };
            },
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(logout());
                } catch (error) {
                    console.log(error)
                }
            },
        }),
        loginWithCode: builder.mutation({
            query: (code) => ({
                url: '/auth/login',
                method: 'POST',
                body: { code: code },
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(prefillUser({ email: data.email, phoneNumber: data.phone }))
                } catch (e) {
                    console.log(e)
                }
            }
        }),
    }),
})



export const {
    useLoginUserMutation,
    useCreateUserMutation,
    useLogoutUserMutation,
    useLoginWithCodeMutation,
    useGetVerificationCodesQuery,
    useVerifyContactInfoMutation,
    useCreateNewPasswordMutation,
} = authApi;