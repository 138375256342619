import { AppBar,Chip, Paper, Stack, Toolbar, useTheme } from "@mui/material"
import { Outlet, useNavigate } from "react-router"
import Banner, { BANNER_HEIGHT } from "../../components/Banner"
import ClinicalSidebar, { DRAWER_CLOSED_WIDTH } from "../../components/sidebar/ClinicalSidebar"
import { AddSharp, DomainAddSharp, PersonAddSharp } from "@mui/icons-material"
import { useSelector } from "react-redux"
import { selectUser } from "../../common/redux/userSlice"
import { canCreateOrg, canCreatePatient, canCreateTrial, useGetSize, useWidth } from "../../common/util"



export default function DashboardLayout() {
    const width = useWidth()
    const drawerClosedWidth = useGetSize(DRAWER_CLOSED_WIDTH, width)



    return (
        <Stack
            sx={{
                height: '100%',
                width: '100%'
            }}
            direction='row'
        >
            <ClinicalSidebar/>
            <Paper
                sx={{
                    height: `calc(100% - ${BANNER_HEIGHT}px - 16px)`, // The extra 16 pixels is for the padding at the top of the banner (I think lol).
                    width: `calc(100% - ${drawerClosedWidth}px)`,
                    left: drawerClosedWidth,
                    position: 'absolute',
                    borderRadius: 0
                }}
                elevation={0}
            >
                <Outlet />
            </Paper>
        </Stack>
    )
}



function AlacrityAppBar({ open }) {
    const theme = useTheme();
    const user = useSelector(selectUser)
    const navigate = useNavigate();



    return (
        <AppBar 
            position="static"
            enableColorOnDark
            open={open}
            sx={{
                height: theme.alacrityAppBarHeight
            }}
        >
            <Toolbar
                sx={{
                    justifyContent: 'center',
                }}
            >
                {
                    ( canCreateTrial(user) ) && 
                    <Chip
                        icon={<AddSharp/>}
                        variant='appbar-action'
                        label='Trial'
                        onClick={() => { navigate('/createTrial') }}
                    />
                }
                {
                    ( canCreatePatient(user) ) &&
                    <Chip
                        icon={<PersonAddSharp/>}
                        variant='appbar-action'
                        label='User'
                        onClick={() => { navigate('/createUser') }}
                    />
                }
                {
                    ( canCreateOrg(user) ) &&
                    <Chip
                        icon={<DomainAddSharp/>}
                        variant='appbar-action'
                        label='Org'
                        onClick={() => { navigate('/createOrg') }}
                    />
                }
            </Toolbar>
        </AppBar>
    )
}