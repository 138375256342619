import { useEffect } from "react";
import { toast } from "react-toastify";

export const useRTKResultToasts = ({ 
    isSuccess,
    isError,
    error,
    onSuccess = () => {},
    successMessage = "Success!",
    onError = () => {},
    toastId = '',
 }) => 
    useEffect(() => {
        if (isSuccess) {
            toast.success(successMessage, {
                position: 'top-center', autoClose: 1000,
                toastId: toastId
            });
            onSuccess()
        }
        if (isError) {
            console.log(error);
            toast.error(error?.data?.message, {
                position: 'top-center', autoClose: 1000,
                toastId: `${toastId}-error`
            });
            onError()
        }
    }, [isSuccess, isError, error, onError, onSuccess, successMessage, toastId]);



export const useRTKGetError = ({
    isError,
    error,
    navigate,
    onError = () => {}
}) => 
    useEffect(() => {
        if (isError) {
            console.log(error);
            onError();
            navigate('/error', { state: { error: { status: error?.status, message: error?.data?.message }}})
        }
    }, [isError, error, navigate, onError])