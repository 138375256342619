import { createSelector, createSlice } from "@reduxjs/toolkit";



export const decodeJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}



export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: ''
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload
        }
    }
})



export const selectAuth = (state) => {
    console.log(state)
    return state.auth
}



export const makeSelectToken = () =>
    createSelector(
        selectAuth,
        (auth) => decodeJwt(auth.token)
    )




export const { setToken } = authSlice.actions



export default authSlice.reducer