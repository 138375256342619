import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Box, InputLabel, MenuItem,  Container, Select, FormControl, Stack, Paper, FormControlLabel, Switch, CircularProgress } from '@mui/material';
import { defer, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useCreateUserMutation } from '../../common/redux/api/auth/authApi';
import * as z from 'zod';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import FormInput from '../../components/CustomFormInput';
import { LoadingButton } from '@mui/lab';
import { userApi } from '../../common/redux/api/userApi';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { parsePhoneNumber } from 'react-phone-number-input';
import PhoneNumberInput from '../../components/PhoneNumberInput';
import { BANNER_HEIGHT } from '../../components/Banner';
import { useSelector } from 'react-redux';
import { selectUser } from '../../common/redux/userSlice';
import { useGetAllOrgsQuery, useGetOrgsForUserQuery } from '../../common/redux/api/api';
import { ArrowDropDown } from '@mui/icons-material';



export default function CreateUser() {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector(selectUser);

    const role = user.permissions;
    const fixedRole = location.state?.role;

    const [ createUser, result ] = useCreateUserMutation();
    const { data, isLoading, isError, error, isSuccess } = result;



    const {
        data: orgs,
        isLoading: areOrgsLoading,
        isFetching: areOrgsFetching,
        isSuccess: areOrgsSuccess,
        isError: areOrgsError,
        error: orgsError
    } = useGetOrgsForUserQuery(user?.alacrity_id, { skip: user?.permissions === 'ALACRITY' })

    const {
        data: allOrgs,
        isLoading: areAllOrgsLoading,
        isFetching: areAllOrgsFetching,
        isSuccess: areAllOrgsSuccess,
        isError: areAllOrgsError,
        error: orgsAllError
    } = useGetAllOrgsQuery(undefined, { skip: user?.permissions !== 'ALACRITY' })




    const selectEnum = z.enum(['PHARMA', 'DOCTOR', 'PATIENT', 'ALACRITY'])

    const createUserSchema = z.object({
        permissions: selectEnum,
        first_name: z.string().min(1, 'First Name is required.'),
        last_name: z.string().min(1, 'Last Name is required.'),
        email: (fixedRole === 'PATIENT') ? z.string().email('Invalid email address.').or(z.literal('')) : z.string().min(1, 'Email is required.').email('Invalid email address.'),
        phone: z.string().min(1, 'Phone Number is required'),
        countryCode: z.string().length(2),
        diagnosis: z.string().min(2, 'Invalid name.').or(z.literal('')),
        admin: z.boolean(),
        orgs: z.string().array().min(1, 'Must select at least one organization.')
    }).superRefine((data, ctx) => {
        if (!isPossiblePhoneNumber(data?.phone.replace(' ', ""))) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Invalid Phone Number',
                path: ['phone']
            })
        }
    });



    const defaultValues = {
        permissions: (fixedRole) ? fixedRole : selectEnum.Enum.PATIENT,
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        countryCode: 'US',
        diagnosis: '',
        admin: false,
        orgs: []
    }

    const methods = useForm({
        resolver: zodResolver(createUserSchema),
        defaultValues,
    });

    const { control, reset, handleSubmit, getValues, formState: { isSubmitSuccessful, errors, isSubmitting } } = methods;



    const [ selectedRole, setSelectedRole ] = useState(null)
    
    const onSubmitHandler = (values) => {
        const phone = parsePhoneNumber(values?.phone.replace(' ', ''))
        setSelectedRole(values?.permissions)

        const userInfo = {
            first_name: values?.first_name,
            last_name: values?.last_name,
            permissions: values?.permissions,
            email: values?.email,
            phone: {
                iso2: values?.countryCode,
                e164NumberString: phone?.number,
                dialCode: phone?.countryCallingCode,
                ext: phone?.ext
            },
            admin: values?.admin,
            orgs: values?.orgs
        }

        if (values?.permissions === 'PATIENT') {
            userInfo.diagnosis = values?.diagnosis
        }

        console.log(userInfo)
        createUser(userInfo)
    };



    useEffect(() => {
        if (isSuccess) {
            toast.success('User successfully created.', {
                position: 'top-center',
                autoClose: 1000,
                theme: 'dark',
                toastId: 'user-created'
            });
            console.log(selectedRole)
            const code = data.code;
            navigate(`/createUser/${code}`, { state: { role: selectedRole } });
        }
        if (isError) {
             if (Array.isArray((error).data.error)) {
                (error).data.error.forEach((el, index) =>
                    toast.error(el.message, {
                        position: 'top-center', 
                        autoClose: 1000,
                        theme: 'dark',
                        toastId: `user-created-error-${index}`
                    })
                );
                reset()
            } else {
                toast.error((error).data.message, {
                    position: 'top-center', 
                    autoClose: 1000,
                    theme: 'dark',
                    toastId: 'user-created-error'
                });
                reset()
            }
        }
    }, [isLoading, isSuccess, isError, error, data, fixedRole, navigate, reset, selectedRole]);



    const selectOptions = useMemo(() => {
        switch (fixedRole) {
            case 'DOCTOR':
                return <MenuItem sx={{ fontSize: 32 }} key='DOCTOR' value='DOCTOR'>Provider</MenuItem>
            case 'PHARMA':
                return <MenuItem sx={{ fontSize: 32 }} key='PHARMA' value='PHARMA'>Pharmaceutical Employee</MenuItem>
            case 'PATIENT':
                return <MenuItem sx={{ fontSize: 32 }} key='PATIENT' value='PATIENT'>Patient</MenuItem>
            case 'ALACRITY':
                return <MenuItem sx={{ fontSize: 32 }} key='ALACRITY' value='ALACRITY'>Alacrity</MenuItem>
            default:
                return [
                    <MenuItem sx={{ fontSize: 32 }} key='DOCTOR' value='DOCTOR'>Provider</MenuItem>,
                    <MenuItem sx={{ fontSize: 32 }} key='PHARMA' value='PHARMA'>Pharmaceutical Employee</MenuItem>,
                    <MenuItem sx={{ fontSize: 32 }} key='PATIENT' value='PATIENT'>Patient</MenuItem>,
                    <MenuItem sx={{ fontSize: 32 }} key='ALACRITY' value='ALACRITY'>Alacrity</MenuItem>
                ]
        }
    }, [fixedRole])



    const orgOptions = useMemo(() => {
        if (user.permissions === 'ALACRITY') {
            if (areAllOrgsSuccess) {
                return allOrgs
            } else {
                return []
            }
        } else {
            if (areOrgsSuccess) {
                return orgs
            } else {
                return []
            }
        }
    }, [orgs, allOrgs, user, areOrgsSuccess, areAllOrgsSuccess])



    return (
        <Container
            sx={{
                width: { xl: '40%', lg: '50%', xs: '90%' },
                justifyContent: 'center',
                alignItems: 'center',
                pt: '2rem',
                height: `calc(100vh - ${BANNER_HEIGHT}px - 16px)`
            }}
        >
            <FormProvider {...methods}>
                <Box
                    component='form'
                    noValidate
                    autoComplete='off'
                    sx={{ 
                        width: '100%',
                        height: '100%'
                    }}
                    onSubmit={handleSubmit(onSubmitHandler)}
                >
                    <Stack
                        height='100%'
                        width='100%'
                        justifyContent='start'
                        alignItems='center'
                        spacing={6}
                        sx={{
                            pb: '2rem'
                        }}
                    >
                        <Typography
                            variant='h1'
                            sx={{ textAlign: 'center', mb: '1.5rem' }}
                        >
                            Create User
                        </Typography>

                        <Paper
                            elevation={8}
                            sx={{
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <Stack
                                sx={{
                                    width: '100%',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    px: '2rem',
                                    pt: 2,
                                    pb: 8,
                                    height:'73vh',
                                    overflowY: 'scroll',
                                    '::-webkit-scrollbar': {
                                        '-webkit-appearance': 'none',
                                        width: '7px'
                                    },  
                                    '::-webkit-scrollbar-thumb': {
                                        borderRadius: '4px',
                                        backgroundColor: 'rgba(255, 255, 255, .5)',
                                    }
                                }}
                                spacing={8}
                            >
                                <Stack
                                    sx={{
                                       alignItems: 'end',
                                       width: '100%' 
                                    }}
                                >
                                    <FormControl fullWidth required focused disabled={isLoading || isSubmitting}>
                                        <InputLabel 
                                            id='permission-select-label'
                                            sx={{ 
                                                fontSize: 24, 
                                                '&.Mui-focused': {
                                                    color: 'white'
                                                } 
                                            }} 
                                        >
                                            Select Your Role
                                        </InputLabel>

                                        <Controller
                                            control={control}
                                            name={'permissions'}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    labelId='permission-select-label'
                                                    label={'Select Your Role'}
                                                    color="info"
                                                    sx={{
                                                        fontSize: 32,
                                                        px: 2,
                                                        height: '6rem',
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            fontSize: 24,
                                                        }
                                                    }}
                                                >
                                                    {
                                                        selectOptions
                                                    }
                                                </Select>
                                            )}
                                        />
                                    </FormControl>

                                    <Controller
                                        name='admin'
                                        control={control}
                                        render={({ field }) => {
                                            if (
                                                getValues('permissions') !== 'PATIENT' &&
                                                (
                                                    role === 'ALACRITY' || 
                                                    user?.admin === true
                                                )
                                            ) {
                                                return (
                                                    <FormControlLabel
                                                        label='Admin'
                                                        control={
                                                            <Switch 
                                                                {...field}
                                                                disabled={isLoading || isSubmitting}
                                                            />
                                                        }
                                                    />
                                                )
                                            }
                                        }}
                                    />
                                    
                                </Stack>

                                <FormControl fullWidth required focused disabled={isLoading || isSubmitting || areOrgsLoading}>

                                    <InputLabel 
                                        id='orgs-select-label'
                                        sx={{ 
                                            fontSize: 24, 
                                            '&.Mui-focused': {
                                                color: 'white'
                                            } 
                                        }} 
                                    >
                                        Add new user to your organizations
                                    </InputLabel>

                                    <Controller
                                        control={control}
                                        name={'orgs'}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                multiple
                                                labelId='orgs-select-label'
                                                label={'Add new user to your organizations'}
                                                color="info"
                                                sx={{
                                                    fontSize: 32,
                                                    px: 2,
                                                    height: '6rem',
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        fontSize: 24,
                                                    }
                                                }}
                                                IconComponent={(areOrgsLoading || areAllOrgsLoading) ? CircularProgress : ArrowDropDown }
                                            >
                                                {
                                                    orgOptions.map((org) => {
                                                        return <MenuItem sx={{ fontSize: 32 }} key={org?.org_id} value={org?.org_id}>{org?.name}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        )}
                                    />

                                </FormControl>

                                <FormInput
                                    label='First Name'
                                    type='text'
                                    name='first_name'
                                    required
                                    focused
                                    disabled={isLoading || isSubmitting}
                                />

                                <FormInput
                                    label='Last Name'
                                    type='text'
                                    name='last_name'
                                    required
                                    focused
                                    disabled={isLoading || isSubmitting}
                                />

                                <FormInput
                                    label='Enter their email'
                                    type='email'
                                    name='email'
                                    focused
                                    disabled={isLoading || isSubmitting}
                                />
                                
                                <PhoneNumberInput
                                    focused
                                    disabled={isLoading || isSubmitting}
                                />

                                {
                                    getValues('permissions') === 'PATIENT' &&
                                    <FormInput
                                        label="Patient's Diagnosis"
                                        type='text'
                                        name='diagnosis'
                                        focused
                                        disabled={isLoading || isSubmitting}
                                    />
                                }

                                <LoadingButton
                                    loading={isLoading || isSubmitting}
                                    type='submit'
                                    variant='xxl'
                                    sx={{
                                        py: 1,
                                    }}
                                >
                                    Submit
                                </LoadingButton>
                            </Stack>
                        </Paper>
                    </Stack>
                </Box>
            </FormProvider>
        </Container>
    )
}