import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { setUser } from "../userSlice";
import { customFetchBase } from "./auth/customAuthQuery";




export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: customFetchBase,
    tagTypes: ['User'],
    endpoints: (builder) => ({
        getCurrentUser: builder.query({
            query: () => ({
                url: `/users`,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setUser(data.data));
                    return { data }
                } catch (error) {
                    return { error: error }
                }
            },
            providesTags: ['User']
        }),
        getUser: builder.query({
            query: (id) => `/users/${id}`,
            transformResponse: (result) => result?.data,
            providesTags: (result, error, id) => ([{ type: 'User', id }])
        }),
        // Ideally, updating this should auto-relaunch getCurrentUser, so it has updated info thanks to the tags.
        updateSelfInfo: builder.mutation({
            query: (newUserInfo) => ({
                url: '/users',
                method: 'PUT',
                body: newUserInfo,
            }),
            invalidatesTags: ['User']
        }),
        updateUserInfo: builder.mutation({
            query: ({ id, newInfo }) => ({
                url: `/users/${id}`,
                method: 'PUT',
                body: newInfo,
            }),
            invalidatesTags: (result, error, args) => ([{ type: 'User', id: args.id }])
        }),
        updateVerifyContactInfo: builder.mutation({
            query: (newContactInfo) => ({
                url: '/users',
                method: 'PATCH',
                body: newContactInfo,
                credentials: 'include'
            })
        }),
        createNewUser: builder.mutation({
            query: (newUserInfo) => ({
                url: `/users`,
                method: `POST`,
                body: newUserInfo,
                credentials: 'include'
            })
        }),
        deactiveUser: builder.mutation({
            query: (id) => ({
                url: `/users/${id}/deactivate`,
                method: 'PATCH',
                credentials: 'include'
            })
        }),
    })
})



export const {
    useGetCurrentUserQuery,
    useLazyGetCurrentUserQuery,
    useUpdateSelfInfoMutation,
    useCreateNewUserMutation,
    useDeactiveUserMutation,
    useGetUserQuery,
    useUpdateVerifyContactInfoMutation,
    useUpdateUserInfoMutation,
} = userApi;