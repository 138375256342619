import * as React from "react";
const SvgPatients = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={51}
    height={26}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.7}
      d="M12.008.72c-3.442 0-6.264 2.846-6.264 6.319 0 1.751.721 3.31 1.882 4.42a10.236 10.236 0 0 0-5.722 9.25c-.11.827.622 1.663 1.448 1.654.827-.009 1.541-.86 1.413-1.683-.026-4.044 3.174-7.295 7.182-7.322a7.228 7.228 0 0 1 6.129 3.311 10.2 10.2 0 0 0-2.68 6.957c-.112.827.62 1.663 1.447 1.654.827-.009 1.54-.86 1.413-1.684-.027-4.044 3.174-7.294 7.182-7.321 4.009-.027 7.276 3.201 7.303 7.245.016.9.702 1.672 1.43 1.7.864-.052 1.461-.848 1.43-1.73a10.225 10.225 0 0 0-2.664-6.82c2.138-3.319 6.485-4.334 9.847-2.249a7.321 7.321 0 0 1 3.448 6.213c-.12.825.604 1.67 1.43 1.67.827 0 1.55-.845 1.43-1.67-.003-3.54-1.798-6.82-4.787-8.674a10.12 10.12 0 0 0-.904-.5c1.15-1.11 1.852-2.673 1.852-4.421 0-3.473-2.821-6.32-6.264-6.32-3.442 0-6.263 2.847-6.263 6.32 0 1.76.72 3.325 1.882 4.435a10.053 10.053 0 0 0-3.915 3.342 9.997 9.997 0 0 0-.813-.456 6.032 6.032 0 0 0 1.882-4.405c0-3.472-2.822-6.319-6.264-6.319s-6.263 2.847-6.263 6.32c0 1.759.72 3.317 1.882 4.42-.273.133-.54.283-.799.44a10.174 10.174 0 0 0-3.9-3.372 6.095 6.095 0 0 0 1.853-4.405c0-3.473-2.822-6.32-6.264-6.32Zm0 2.916c1.88 0 3.373 1.506 3.373 3.403-.127 2.104-1.367 3.282-3.433 3.387-2.159-.01-3.27-1.273-3.313-3.387 0-1.897 1.493-3.403 3.373-3.403Zm26.982 0c1.88 0 3.373 1.506 3.373 3.403 0 1.896-1.493 3.402-3.373 3.402-1.88 0-3.373-1.506-3.373-3.402 0-1.897 1.493-3.403 3.373-3.403ZM25.5 6.553c1.88 0 3.372 1.506 3.372 3.402-.013 1.822-1.328 3.302-3.433 3.387-1.978-.102-3.315-1.559-3.313-3.387 0-1.896 1.493-3.402 3.373-3.402Z"
    />
  </svg>
);
export default SvgPatients;
