import { CheckCircle, CheckSharp, CloseSharp, DoneSharp, EditSharp, ReplaySharp } from "@mui/icons-material";
import { Stack, ThemeProvider, Typography, Button, CircularProgress, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { cloneElement, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useUpdateVerifyContactInfoMutation } from "../../common/redux/api/userApi";
import { makeSelectPrefilledUserInfo } from "../../common/redux/userSlice";
import AlacrityTheme from "../../common/Theme";
import { toast } from 'react-toastify';
import { LoadingButton } from "@mui/lab";
import { authApi, useCreateNewPasswordMutation, useGetVerificationCodesQuery, useVerifyContactInfoMutation } from "../../common/redux/api/auth/authApi";
import * as z from 'zod';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from "../../components/CustomFormInput";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import { useDispatch } from "react-redux";



export default function VerifyUser() {
    const navigate = useNavigate();
    const selectUser = useMemo(makeSelectPrefilledUserInfo, []);
    const { email: originalEmail, phoneNumber: originalPhoneNumber, ...rest } = useSelector((state) => selectUser(state))

    console.log('Original Email: ', originalEmail === '')

    const [ isPhoneVerified, setIsPhoneVerified ] = useState(false);
    const [ isEmailVerified, setIsEmailVerified ] = useState(originalEmail === '');

    const [ createNewPassword, result ] = useCreateNewPasswordMutation();
    const { isSuccess, isError, isLoading, error } = result;



    const passwordSchema = z.object({
        password: z.string()
          .min(1, 'Password is required')
          .min(10, 'Password must be at least 10 characters'),
        confirmPassword: z.string().min(1, 'This field is required').min(10, 'Please enter the same password as above.'),
    }).refine((data) => data.password === data.confirmPassword, {
        message: "Passwords don't match",
        path: ["confirmPassword"],
    });

    const methods = useForm({
        resolver: zodResolver(passwordSchema)
    })

    const { reset, handleSubmit, formState: { isSubmitSuccessful, errors, isSubmitting } } = methods;

    const onSubmitHandler = (values) => {
        createNewPassword({ password: values.password })
    }

    useEffect(() => {
        if (isSuccess) {
            reset()
            toast.success('Success! You\'ve completed your new account.', {
                position: 'top-center', autoClose: 1000
            })
            navigate('/dashboard');
        }
        if (isError) {
            toast.error(error?.data?.message, {
                position: 'top-center', autoClose: 1000
            })
        }
    }, [isSuccess, isError, error])



    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            console.log("Submission errors ", errors)
        }
    }, [errors])



    return (
        <ThemeProvider theme={AlacrityTheme}>
            <Stack
                sx={{
                    width: '100%',
                    alignItems: 'center',
                }}
            >
                <Typography 
                    variant="h1" 
                    textAlign={'center'}
                    my='1rem'
                >
                    Welcome,
                </Typography>
                <Typography 
                    variant="h5" 
                    textAlign={'center'}
                    fontWeight='500'
                    color={'#666666'}
                    sx={{
                        width: '35%'
                    }}
                >
                    Your administrator has put in the following email and phone number for you. Please confirm them and we will verify that you are the owner.
                </Typography>
                <Stack
                    sx={{
                        width: { xl: '40%', lg: '50%', xs: '90%' },
                        mt: '2rem'
                    }}
                    spacing={4}
                >
                    <VerificationField
                        name='phone'
                        onVerified={() => { console.log('Verified Phone'); setIsPhoneVerified(true) }}
                        validate={(data) => { /*setPhoneNumber(data);*/ return '' }}
                        initialValue={originalPhoneNumber}
                        valueFormatter={(phoneNumber) => { return (phoneNumber) ? phoneNumber?.e164NumberString : 'N/A'}}
                    >
                        <PhoneNumberInput focused />
                    </VerificationField>

                    {
                        originalEmail !== '' &&
                        <VerificationField
                            name='email'
                            initialValue={originalEmail}
                            onVerified={() => { console.log('Verified Email'); setIsEmailVerified(true) }}
                            validate={(input) => { 
                                if (input.length > 0) {
                                    const emailRegex = new RegExp(/^[\w \-.]+@([\w-]+\.)+[\w\-]{2,4}$/);
                                    if (emailRegex.test(input)) {
                                        // setEmail(input)
                                        return ''
                                    } else {
                                        return 'Not a valid email address.'
                                    }
                                } else {
                                    return 'Email is required.'
                                }
                            }}
                        >
                                {/* <ValidatedTextField 
                                textFieldProps={{
                                    id: 'email',
                                    label: 'Email Address',
                                    type: 'email',
                                    helperText: ' '
                                }}
                            /> */}
                        </VerificationField>
                    }

                    {
                        isPhoneVerified &&
                        (
                            (
                                !originalEmail
                            ) ||
                            isEmailVerified
                        ) &&
                        <FormProvider {...methods}>
                            <Box
                                component={'form'}
                                autoComplete='off'
                                display='flex'
                                flexDirection={'column'}
                                sx={{
                                    width: '100%',
                                    justifyContent: 'center',
                                    mt: '2rem'
                                }}
                                onSubmit={handleSubmit(onSubmitHandler)}
                            >
                                <Stack
                                    sx={{
                                        width: '100%',
                                        alignItems: 'center',
                                    }}
                                    spacing={2}
                                >
                                    <FormInput
                                        name='password'
                                        label='Password'
                                        type='password'
                                        required
                                    />

                                    <FormInput
                                        name='confirmPassword'
                                        label='Confirm Password'
                                        type='password'
                                        required
                                    />

                                    <LoadingButton
                                        loading={isLoading || isSubmitting}
                                        type='submit'
                                        variant='contained'
                                    >
                                        Submit
                                    </LoadingButton>
                                </Stack>
                            </Box>
                        </FormProvider>
                    }
                </Stack>
            </Stack>
        </ThemeProvider>
    )
}



const VerificationField = (props) => {
    const dispatch = useDispatch()

    const [ value, setValue ] = useState(props.initialValue);
    const [ editValue, setEditValue ] = useState('');
    const [ isEditing, setIsEditing ] = useState(false);
    const [ showVerification, setShowVerification ] = useState(false);
    const [ isVerified, setIsVerified ] = useState(false);
    const [ editError, setEditError ] = useState('');
    const [ canRequestCode, setCanRequestCode ] = useState(true);
    const [ verificationCode, setVerificationCode ] = useState('');

    const onVerified = props.onVerified;
    const validate = props.validate;
    const formatter = props.valueFormatter ? props.valueFormatter : (v) => v;
    const infoFieldName = props.name;



    const [ verifyContactInfo, verifyResult ] = useVerifyContactInfoMutation();
    const { isSuccess: verifyIsSuccess, isError: verifyIsError, isLoading: verifyIsLoading, error: verifyError } = verifyResult;
    const [ updateContactInfo, updateResult ] = useUpdateVerifyContactInfoMutation();
    const { isSuccess: updateIsSuccess, isError: updateIsError, isLoading: updateIsLoading, error: updateError } = updateResult



    const onConfirmEdit = () => {
        updateContactInfo({ [infoFieldName]: editValue })
    }

    useEffect(() => {
        if (updateIsSuccess) {
            setIsEditing(false)
            setValue(editValue)
            toast.success(`${infoFieldName} successfully updated.`, {
                position: 'top-center', autoClose: 1000
            })
        }
        if (updateIsError) {
            toast.error(updateError?.data?.message)
        }
    }, [updateIsError, updateIsSuccess, updateError])



    const onConfirmVerify = () => {
        const verifyObject = { [`${infoFieldName}_code`]: verificationCode };
        verifyContactInfo(verifyObject)
    }

    useEffect(() => {
        if (verifyIsSuccess) {
            // TODO: Check code
            if (true) {
                setShowVerification(false)
                setIsVerified(true)
                onVerified();
            }
        }
        if (verifyIsError) {
            console.log("Error: ", verifyError)
            toast.error(verifyError?.data?.message, {
                position: 'top-center', autoClose: 1000
            })
        }
    }, [verifyIsSuccess, verifyIsError, verifyError])



    const onResend = useCallback(() => {
        dispatch(authApi.endpoints.getVerificationCodes.initiate())

        toast.info('Codes resent', {
            autoClose: 1000,
            theme: 'dark',
            toastId: 'resend-code'
        })
    }, [dispatch])



    return (
        <Stack
            width={'100%'}
            height={'100%'}
            justifyContent='center'
            alignItems={'center'}
            spacing={2}
        >
            <Stack
                width={'100%'}
                direction={{lg: 'row', xs: 'column'}}
                spacing={2}
                justifyContent='space-between'
                alignItems='center'
            >
                <Typography
                    variant="h5"
                    sx={{
                        borderRadius: '10px',
                        p: '0.75rem',
                        textAlign: 'center',
                        background: '#333333',
                        overflowWrap: 'break-word',
                        flexGrow: 1,
                        minHeight: '3rem',
                        minWidth: '50%',
                        fontWeight: 600
                    }}
                >
                    {formatter(value)}
                </Typography>
                {
                    isVerified &&
                    <CheckCircle sx={{ color: 'green' }} />
                }
                {
                    !isEditing && !isVerified &&
                    <Button
                        sx={{
                            flexShrink: '0'
                        }}
                        variant="contained"
                        endIcon={<DoneSharp/>}
                        onClick={() => {
                            setShowVerification(true)
                        }}
                    >
                        Verify
                    </Button>
                }
                {/* {
                    !showVerification && !isVerified &&
                    <Button
                        variant="contained"
                        endIcon={<EditSharp/>}
                        sx={{ 
                            backgroundColor: 'red',
                            flexShrink: '0'
                        }}
                        onClick={() => { 
                            setIsEditing(true) 
                        }}
                    >
                        Edit
                    </Button>
                } */}
            </Stack>
            {
                showVerification &&
                <Stack
                    width={'100%'}
                >
                    <TextField
                        label='Verification Code'
                        type='text'
                        helperText="Please enter the code we've sent to your device."
                        value={verificationCode}
                        onChange={(e) => { setVerificationCode(e.target.value) }}
                        variant='filled'
                    />
                    <Stack
                        direction={{lg: 'row', xs: 'column'}}
                        width='100%'
                        justifyContent={'end'}
                        spacing={2}
                    >
                        <Button
                            size="small"
                            variant="contained"
                            endIcon={<CloseSharp/>}
                            sx={{ backgroundColor: 'red' }}
                            onClick={() => { 
                                setShowVerification(false); 
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            disabled={!canRequestCode}
                            sx={{ backgroundColor: 'orange' }}
                            endIcon={(canRequestCode) ? <ReplaySharp/> : <CircularProgress/> }
                            onClick={onResend}
                        >
                            Resend
                        </Button>
                        <LoadingButton
                            onClick={onConfirmVerify}
                            variant={'contained'}
                            size='small'
                            disabled={verificationCode.length === 0}
                            loading={verifyIsLoading}
                            endIcon={<CheckSharp/>}
                        >
                            Confirm
                        </LoadingButton>
                    </Stack>
                </Stack>
            }
            {
                isEditing &&
                <Stack
                    spacing={2}
                    width='100%'
                >
                    {
                        cloneElement(props.children, {
                            validate: (input) => {
                                const errorMessage = validate(input);
                                if (errorMessage.length > 0) {
                                    console.log(errorMessage)
                                    setEditError(errorMessage);
                                } else {
                                    setEditValue(input);
                                }

                                return errorMessage;
                            }
                        })
                    }
                    <Stack
                        direction={{lg: 'row', xs: 'column'}}
                        width={'100%'}
                        spacing={2}
                    >
                        <LoadingButton
                            loading={updateIsLoading}
                            size="small"
                            variant="contained"
                            disabled={editError.length !== 0}
                            endIcon={<DoneSharp/>}
                            onClick={onConfirmEdit}
                        >
                            Confirm
                        </LoadingButton>
                        
                        <Button
                            size="small"
                            variant="contained"
                            endIcon={<CloseSharp/>}
                            sx={{ backgroundColor: 'red' }}
                            onClick={() => { 
                                setIsEditing(false); 
                                setEditValue('');
                                setEditError('');
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Stack>      
            }
        </Stack>
    )
}