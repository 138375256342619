import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';



const FormInput = ({ name, sx={}, inputProps={}, InputProps={}, ...otherProps }) => {
    // ? Utilizing useFormContext to have access to the form Context
    const {
		control,
		formState: { errors },
    } = useFormContext();


	
    return (
      <Controller
        control={control}
        name={name}
        defaultValue=''
        render={({ field }) => (
			<TextField
				{...field}
				variant='outlined'
				color='info'
				sx={{ 
					width: '100%',
					'& .MuiOutlinedInput-notchedOutline': {
						fontSize: 24,
					},
					...sx
				}}
				inputProps={{
					sx: {
						fontSize: 32,
						paddingTop: '2rem',
						'&:-webkit-autofill': {
							WebkitBoxShadow: 'none',

						},
						'&:-webkit-autofill::first-line': {
							fontSize: 32
						},
						...inputProps
					}
				}}
				InputProps={{
					...InputProps
				}}
				InputLabelProps={{
					sx: {
						fontSize: 24,
					}
				}}
				FormHelperTextProps={{
					sx: {
						fontSize: 16
					}
				}}
				error={!!errors[name]}
				helperText={
					errors[name] ? errors[name]?.message : ''
				}
				{...otherProps}
			/>
        )}
      />
    );
  };
  
  export default FormInput;