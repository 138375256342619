// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useTheme, Stack, Typography, IconButton, Divider, LinearProgress, Box, Tooltip, Select, FormControl, InputLabel, MenuItem, ToggleButton, Chip, CircularProgress, Slider, } from "@mui/material";
import { useCallback, useEffect, useState, useMemo } from 'react';
import { ChevronLeft, ChevronRight, CloseSharp, FastForward, InfoSharp, Replay, SettingsEthernet, ShowChartSharp, ZoomIn, ZoomOut } from '@mui/icons-material';
import { LineHighlightPlot, LinePlot } from '@mui/x-charts/LineChart'

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax';
import duration from 'dayjs/plugin/duration';

import { useParams } from 'react-router-dom';
import { SHORT_GRAPH_SENSORS, sensorsToNames, symptoms } from '../common/sensors';
import { useGetSensorsForEntityForGraphQuery, useGetSensorsForEntityQuery } from '../common/redux/api/alertsApi';
import { useIsQueryLoading, useWidth } from '../common/util';
import * as z from 'zod'
import { ChartsAxisHighlight, ChartsReferenceLine, ChartsTooltip, ChartsXAxis, ChartsYAxis, ResponsiveChartContainer, axisClasses } from "@mui/x-charts";
import { VictoryAxis, VictoryBrushContainer, VictoryChart, VictoryLine, VictoryZoomContainer } from "victory";
import { ChartsLegend } from "@mui/x-charts/ChartsLegend";
import { LoadingButton } from "@mui/lab";
import { useGetPatientQuery } from "../common/redux/api/api";
import { useGetPatientVitalsForSensorsInRangeQuery } from "../common/redux/api/readingsApi";
import { mangoFusionPaletteDark } from "@mui/x-charts";
import { useSelector } from "react-redux";
import { RangePresets, SensorPositions, selectCustomRange, selectGraphState, selectYAxes, selectNewRange, scrollLeft, scrollRight, zoomIn, zoomOut, selectLeftSensor, selectRightSensor, goToDate, ZoomPresets, selectShortSensor, selectShortDomain } from "../common/redux/graphSlice";
import { useDispatch } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers";
import { DateRangePicker, SingleInputDateRangeField } from "@mui/x-date-pickers-pro";
import { debounce } from "lodash";

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(minMax)
dayjs.extend(duration)



const graphTypeEnum = z.enum(['NORMAL', 'ZOOMED'])

export const Graphs = () => {
	const { id: alacrity_id } = useParams();



	const {
		data: patient,
		isLoading: isPatientLoading,
		isFetching: isPatientFetching,
		isSuccess: isPatientSuccess,
		isError: isPatientError,
		error: patientError,
		isUninitialized: isPatientUninitialized
	} = useGetPatientQuery(alacrity_id);

	const patientIsLoading = useIsQueryLoading([isPatientLoading, isPatientFetching, isPatientUninitialized])



	const [ graphs, setGraphs ] = useState([graphTypeEnum.Enum.NORMAL]);



	const addGraph = (type) => {
		setGraphs((prev) => [...prev, type])
	}



	const makeUpdateGraphType = (index) =>
		(type) => {
			setGraphs((prev) => {
				const newGraphs = [...prev]
				newGraphs[index] = type
				return newGraphs
			})
		}



    return (
		<Stack
			sx={{
				// maxHeight: '70vh',
				height: '100%',
				width: '100%',
				pb: 2,
				alignItems: 'start',
				justifyContent: 'start',
				overflowY: 'auto',
				'::-webkit-scrollbar': {
					'-webkit-appearance': 'none',
					width: '7px'
				},  
				'::-webkit-scrollbar-thumb': {
					borderRadius: '4px',
					backgroundColor: 'rgba(255, 255, 255, .5)',
				}
			}}
		>

			<Stack 
				spacing={2}
				sx={{
					pr: 4,
					width: '100%',
					height: 'max-content',
					alignItems: 'start',
					justifyContent: 'start',
					// overflowY: 'auto'
				}}
				divider={
					<Divider 
						flexItem 
						sx={{ 
							borderTop: '1px solid white',
							borderBottom: '1px solid white',
							height: 6
						}} 
					/>
				}
			>
				{
					patientIsLoading ?
						<LinearProgress sx={{ width: '100%' }} />
					:
						graphs.map((graph, index) => {
							return (
								<>
								{
									index > 0 &&
									<IconButton
										key={index}
										color="error"
										onClick={() => { setGraphs((prev) => prev.toSpliced(index, 1)) }}
									>
										<CloseSharp/>
									</IconButton>
								}

								<MUIGraph 
									key={index} 
									type={graph} 
									patient={patient} 
									addGraph={addGraph}
									changeGraphType={makeUpdateGraphType(index)}
								/>
								</>
							)
						})
				}
			</Stack>

			<Divider width='100%' />
				
			<Stack direction="row" spacing={1}>
				<LoadingButton 
					size="large" 
					onClick={() => { addGraph(graphTypeEnum.Enum.NORMAL) }}
				>
				{`Add Table (+)`}
				</LoadingButton>
			</Stack>
		</Stack>
                    
    )
}



const MUIGraph = ({
	type,
	patient,
	addGraph,
	changeGraphType,
}) => {
	const size = useWidth()
	const theme = useTheme()
	const dispatch = useDispatch()

	const { range, zoomLevel, domain, sensors: currentSensors, current, shortDomain, shortSensor } = useSelector(selectGraphState)
	const yAxes = useSelector(selectYAxes)



	// Set 'now' as the current minute, so to avoid constantly updating 'current' by the second. Allows for a clean cutoff.
	const [ currentMinute, setCurrentMinute ] = useState(dayjs().startOf('minute'))



	// Used to calculate the responsive width of the Victory sparkline
	const [ boundingRect, setBoundingRect ] = useState({ width: 0, height: 0 })
	const graphRef = useCallback((node) => {
		if (node !== null) {
		  setBoundingRect(node.getBoundingClientRect());
		}
	  }, []);



	// Bootleg version of MUI sx's breakpoint dynamic sizes
	const height = useMemo(() => {
		switch(size) {
			case '2xl':
			case '3xl':
			case '4xl':
			case'5xl':
				return 600
			case 'xl':
				return 500
			case 'lg':
				return 400
			case 'md':
				return 300
			case 'sm':
				return 200
			case 'xs':
				return 150
			default: return 600
		}
	}, [size])



	const {
		data: sensors,
		isLoading: sensorsIsLoading,
		isFetching: sensorsIsFetching,
		isSuccess: sensorsIsSuccess,
		isError: sensorsIsError,
		error: sensorsError,
		isUninitialized: sensorsIsUninitialized,
		refetch: refetchSensorsForGraph
	} = useGetSensorsForEntityForGraphQuery(
		{ 
			entity_id: patient?.alacrity_id, 
			creation_time: dayjs(patient?.creation_time).valueOf() 
		}
	);

	const sensorsAreLoading = useIsQueryLoading([ sensorsIsUninitialized, sensorsIsLoading ])



	const [ currentColors, setCurrentColors ] = useState({ [SensorPositions.Enum.Left]: mangoFusionPaletteDark[0], [SensorPositions.Enum.Right]: mangoFusionPaletteDark[1] })

	
	
	const {
		data: vitalsAllTime,
		isLoading: areAlTimeVitalsLoading,
		isFetching: areAllTimeVitalsFetching,
		isSuccess: areAllTimeVitalsSuccess,
		isError: areAllTimeVitalsError,
		error: allTimeVitalsError,
		isUninitialized: areAllTimeVitalsUninitialized
	} = useGetPatientVitalsForSensorsInRangeQuery(
		{
			patient_id: patient?.alacrity_id,
			sensors: Object.keys(sensors ?? {}),
			start_ts: patient.creation_time,
			end_ts: currentMinute.toISOString()
		},
		{
			skip: !sensorsIsSuccess,
			pollingInterval: 1_000 * 60 * 60
		}
	)
	

	
	const {
		data: vitals,
		isLoading: areVitalsLoading,
		isFetching: areVitalsFetching,
		isSuccess: areVitalsSuccess,
		isError: areVitalsError,
		error: vitalsError,
		isUninitialized: areVitalsUninitialized,
		refetch: refetchVitals
	}  = useGetPatientVitalsForSensorsInRangeQuery(
		{ 
			patient_id: patient?.alacrity_id, 
			sensors: Object.values(currentSensors).filter((sensor) => sensor).flatMap((sensor) => (sensor === 'symptoms') ? [...symptoms] : sensor ),	// Filter not null
			start_ts: dayjs(domain.min ?? 0).toISOString(),	// Get entire range of patient data up to current minute
			end_ts: dayjs(domain.max ?? 0).toISOString() 
		},
		{
			skip: (
				!sensorsIsSuccess || 
				(
					currentSensors[SensorPositions.Enum.Left] === null && 
					currentSensors[SensorPositions.Enum.Right] === null
				)
			),
			pollingInterval: (current) ? 1_000 * 60 * 11 : undefined
		}
	);

	const vitalsAreLoading = useIsQueryLoading([areVitalsLoading])


	const {
		data: shortVitals,
		isLoading: areShortVitalsLoading,
		isFetching: areShortVitalsFetching,
		isSuccess: areShortVitalsSuccess,
		isError: areShortVitalsError,
		error: shortVitalsError,
		isUninitialized: areShortVitalsUninitialized,
		refetch: refetchShortVitals
	}  = useGetPatientVitalsForSensorsInRangeQuery(
		{ 
			patient_id: patient?.alacrity_id, 
			sensors: [shortSensor],	
			start_ts: dayjs(shortDomain.min ?? 0).toISOString(),	// Get entire range of patient data up to current minute
			end_ts: dayjs(shortDomain.max ?? 0).toISOString() 
		},
		{
			skip: (
				!sensorsIsSuccess || shortSensor === null
			),
			pollingInterval: (current) ? 1_000 * 60 * 11 : undefined,
			selectFromResult: (result) => {
				if (result?.isSuccess && result?.currentData) {
					const { data, ...rest } = result;

					const newData = data.flatMap((datum) => {
						return datum[shortSensor].map((value, index) => {
							return {
								ts: dayjs(datum.ts).add(index * 8, 'millisecond').valueOf(),
								[shortSensor]: value
							}
						})
					})

					return { ...rest, data: newData }
				} else {
					return result
				}
			}
		}
	);

	const shortVitalsAreLoading = useIsQueryLoading([areShortVitalsLoading, areShortVitalsUninitialized, sensorsAreLoading])



	// Every ten minutes update what the rightmost limit of the graph is (current minute +/- ten minutes)
	// And refetch vitals data if the graph is set to 'atCurrent'
	useEffect(() => {
		console.log('Ten Minute refresh')
		const interval = setInterval(() => {
			setCurrentMinute(dayjs().startOf('minute'))

			if (current && Object.values(currentSensors ?? []).filter((sensor) => sensor).length > 0) {
				refetchVitals()
			}

		}, 1_000 * 60 * 10)		// Ten Minute Interval
		
		return () => clearInterval(interval)
	}, [])



	const onBrushDomainChange = useCallback((newDomain) => {
		dispatch(selectCustomRange({
			min: newDomain.x[0],
			max: newDomain.x[1]
		}))
	}, [dispatch])

	const [ shortDomainSlider, setShortDomainSlider ] = useState(shortDomain.min)

	const onShortDomainSliderChange = useCallback((event) => {
		console.log('Slider Event: ', event)
		setShortDomainSlider(event.target.value)
	}, [])

	const onSliderCommit = useCallback((event) => {
		console.log('Commit Event: ', event)
		const newDomainMin = shortDomainSlider
		dispatch(selectShortDomain({
			min: newDomainMin,
			max: dayjs(newDomainMin).add(30, 'second').valueOf()
		}))
	}, [shortDomainSlider, dispatch])



	const onSelectPreset = useCallback((preset) => {
		dispatch(selectNewRange(preset))
	}, [dispatch])



	const makeOnSelectSensor = (position) =>
		(sensor) => {
			if (position === SensorPositions.Enum.Left) {
				dispatch(selectLeftSensor({ 
					sensor,
					unix_ts: (sensor !== null) ? dayjs(sensors[sensor].ts).valueOf() : 0
				 }))
			} else {
				dispatch(selectRightSensor({
					sensor, 
					unix_ts: (sensor !== null) ? dayjs(sensors[sensor].ts).valueOf() : 0
				}))
			}
		}



	const onGoToMostRecent = useCallback(() => {
		if (current) { return }
	
		let newStartDomain = Object.values(currentSensors ?? [])
			.filter((sensor) => sensor)
			.reduce((acc, sensor) => {
				if (sensor === 'symptoms') {
					return Object.entries(sensors)
						.filter(([sensor]) => symptoms.has(sensor))
						.reduce((acc, [sensor, { ts, units }]) => {
							return dayjs.max(acc, dayjs(ts))
						}, acc)
				} else {
					return dayjs.max(acc, dayjs(sensors[sensor].ts))
				}
			}, dayjs(patient?.creation_time))

		dispatch(goToDate(newStartDomain.valueOf()))
	}, [current, currentSensors, patient, sensors, dispatch])



	const [ showCustomNav, setShowCustomNav ] = useState(false)
	
	const onCustomNavigation = useCallback(() => {
		if (showCustomNav) {
			setShowCustomNav(false)
			dispatch(selectNewRange(range)) // Just reset to standard form of current range
		} else {
			setShowCustomNav(true)
		}
	}, [range, dispatch, showCustomNav])



	const onScrollLeft = useCallback(() => {
		dispatch(scrollLeft())
	}, [dispatch])

	const onScrollRight = useCallback(() => {
		dispatch(scrollRight())
	}, [dispatch])



	const onZoomOut = useCallback(() => {
		dispatch(zoomOut())
	}, [dispatch])

	const onZoomIn = useCallback(() => {
		dispatch(zoomIn())
	}, [dispatch])



	const shouldDisableDate = useCallback((date) => {
		if (areAllTimeVitalsSuccess) {
			return vitalsAllTime.every((datum) => !dayjs(datum.ts).startOf('day').isSame(date.startOf('day')))
		} else {
			return true
		}
	}, [vitalsAllTime, areAllTimeVitalsSuccess])

	const shouldDisableWeek = useCallback((date) => {
		if (areAllTimeVitalsSuccess) {
			return vitalsAllTime.every((datum) => !dayjs(datum.ts).startOf('week').isSame(date.startOf('week')))
		} else {
			return true
		}
	}, [vitalsAllTime, areAllTimeVitalsSuccess])

	const shouldDisableMonth = useCallback((date) => {
		if (areAllTimeVitalsSuccess) {
			return vitalsAllTime.every((datum) => !dayjs(datum.ts).startOf('month').isSame(date.startOf('month')))
		} else {
			return true
		}
	}, [vitalsAllTime, areAllTimeVitalsSuccess])



	const onSelectDate = useCallback((date) => {
		dispatch(goToDate(date.valueOf()))
	}, [dispatch])



	const onReset = useCallback(() => {
		refetchSensorsForGraph()
		setShowCustomNav(false)
	}, [refetchSensorsForGraph])

	console.log('Sensors are loading: ', sensorsAreLoading)

	console.log('Vitals areloading: ', vitalsAreLoading)


	return (
		(sensorsAreLoading || vitalsAreLoading) ?
			<Stack
				sx={{
					height: '100%',
					width: '100%',
					justifyContent: 'center',
					alignItems: 'center',
					pb: 12
				}}
				spacing={12}
			>
				<LinearProgress sx={{ width: '100%' }} />

				<Typography
					variant='h2'
					color='text.secondary'
					textAlign='center'
				>
					Loading...
				</Typography>
			</Stack>
		:
			(
				(
					sensorsIsSuccess &&
					Object.keys(sensors ?? {}).filter((sensor) => sensor)?.length === 0
				) ||
				areVitalsUninitialized
			) ?
				<Stack
					sx={{
						height: '100%',
						width: '100%',
						justifyContent: 'center',
						alignItems: 'center'
					}}
					spacing={4}
				>
					<Typography
						variant='h2'
						textAlign='center'
						color='text.secondary'
						fontWeight={'bold'}
					>
						Sorry, this patient has no data.
					</Typography>

					<ShowChartSharp style={{ color: 'red', height: 180, width: 180 }} />
				</Stack>
			:
				<Stack
					sx={{
						width: '100%'
					}}
				>

					<Stack
						direction={{'2xl': 'row', xl: 'column'}}
						sx={{
							width: '100%',
							justifyContent: 'space-between',
							alignItems: 'center',
							px: 4,
							py: 1
						}}
						spacing={2}
					>

						<Stack
							direction={{ xl: 'row', xs: 'column' }}
							sx={{
								width: '100%',
								justifyContent: 'space-between'
							}}
						>
							{
								Object.entries(currentSensors).map(([position, sensor], index) => {
									return (
										<SensorSelect
											key={index}
											currentColor={currentColors[position]}
											colorOptions={mangoFusionPaletteDark.filter((color) => !Object.values(currentColors).includes(color))}
											onSelectColor={(color) => setCurrentColors((prev) => ({ ...prev, [position]: color }))}
											currentSensor={sensor}
											sensorOptions={Object.keys(sensors).filter((sensor) => !SHORT_GRAPH_SENSORS.has(sensor))}
											onSelectSensor={makeOnSelectSensor(position)}
											index={index}
										/>
									)
								})
							}
						</Stack>

						<Stack
							direction='row'
							sx={{
								width: '100%',
								alignItems: 'center',
								justifyContent: 'center',
								// py: 1
							}}
							spacing={2}
						>

							<RangeDatePicker
								loading={areVitalsLoading || sensorsIsLoading}
								minDate={dayjs(patient?.creation_time)}
								maxDate={currentMinute}
								range={range}
								shouldDisableDate={shouldDisableDate}
								shouldDisableWeek={shouldDisableWeek}
								shouldDisableMonth={shouldDisableMonth}
								domain={domain}
								onSelectDate={onSelectDate}
							/>

							<Tooltip
								title='Zoom out'
							>
								<IconButton
									color="primary"
									onClick={onZoomOut}
									disabled={showCustomNav || zoomLevel === null}
								>
									<ZoomOut/>
								</IconButton>
							</Tooltip>

							<Tooltip
								title='Zoom in'
							>
								<IconButton
									color="primary"
									onClick={onZoomIn}
									disabled={showCustomNav || zoomLevel === (ZoomPresets[range].length - 1)}
								>
									<ZoomIn/>
								</IconButton>
							</Tooltip>

						</Stack>

						<Stack
							direction={{xl: 'row', xs: 'column'}}
							sx={{
								width: '100%',
								justifyContent: 'space-between',
								alignItems: 'center'
							}}
						>

							<Stack
								direction='row'
								sx={{
									height: '100%',
									width: '100%',
									// ml: 'auto',
									// mr: 0,
									overflowX: 'auto',
									alignItems: 'center'
								}}
								spacing={2}
							>
								{
									RangePresets.options.map((preset) => {
										return (
											<Chip
												color={(preset === range) ? 'primary' : 'info'}
												label={preset}
												onClick={() => onSelectPreset(preset)}
											/>
										)
									})
								}
							</Stack>

							<Stack
								direction='row'
								sx={{
									width: '100%',
									justifyContent: 'end'
								}}
							>

								<Tooltip
									title='Reset'
								>
									<IconButton
										color="primary"
										onClick={onReset}
									>
										<Replay/>
									</IconButton>
								</Tooltip>

								<Tooltip
									title='Most Recent'
								>
									<IconButton
										color="primary"
										onClick={onGoToMostRecent}
										disabled={showCustomNav}
									>
										<FastForward/>
									</IconButton>
								</Tooltip>

								<Tooltip
									title='Custom Navigation'
								>
									<IconButton
										color="primary"
										onClick={onCustomNavigation}
									>
										<SettingsEthernet/>
									</IconButton>
								</Tooltip>

							</Stack>

						</Stack>

					</Stack>

					{
						showCustomNav &&
						<Stack
							direction='row'
							sx={{
								width: '100%',
								alignItems: 'center',
							}}
						>

							<Tooltip
								title='Scroll left'
							>
								<IconButton
									onClick={onScrollLeft}
									disabled={shouldDisableDate(dayjs(shortDomain.min))}
								>
									<ChevronLeft/>
								</IconButton>
							</Tooltip>

							<VictoryChart
								height={150}
								width={boundingRect.width - 16}
								scale={{x: 'linear', y: 'linear'}}
								domain={{ y: [0,1], x: [ dayjs(domain.min).startOf(String(range).toLowerCase()).valueOf(),  dayjs(domain.max).endOf(String(range).toLowerCase()).valueOf() ] }}
								containerComponent={
									<VictoryBrushContainer 
										responsive={false}
										brushDimension="x"
										brushDomain={{x: [domain.min, domain.max]}}
										allowDrag
										allowDraw
										brushStyle={{stroke: "transparent", fill: theme.palette.secondary.main, fillOpacity: 0.4}}
										onBrushDomainChangeEnd={onBrushDomainChange}
									/>
								}
								style={{
									background: { fill: 'transparent' }
								}}
							>
								
								{
									Object.entries(currentSensors).filter(([position, sensor]) => sensor).map(([position, sensor], index) => {
										return (
											<VictoryLine
												style={{
													data: {stroke: currentColors[position]}
												}}
												
												y={(datum) => {
													if (datum[sensor]) {
														return 0.5 + ((position === SensorPositions.Enum.Left) ? 0 : 0.1)
													} else {
														return null
													}
												}}
												x={'ts'}
												data={vitalsAllTime}
											/>
										)
									})
								}

								{
									shortSensor !== null &&
									<VictoryLine
										style={{
											data: { stroke: mangoFusionPaletteDark[2] }
										}}
										// y={(datum) => { return 0.4 }}
										y={(datum) => {
											if (datum[shortSensor]) {
												return 0.4		
											} else {
												return null
											}
										}}
										x={'ts'}
										data={vitalsAllTime}
									/>
								}

								<VictoryAxis
									style={{
										axis: {
											stroke: 'white'
										},
										tickLabels: {
											fill: 'white'
										}
									}}
									tickFormat={(x) => dayjs(x).format('L\nLTS')}
								/>

								{
									Object.values(currentSensors).filter((sensor) => sensor).map((sensor, index) => {
										return (
											<VictoryAxis 
												key={index}
												dependentAxis 
												orientation={(index === 0) ? 'left' : 'right'}
												style={{
													axis: {
														stroke: 'white'
													},
													tickLabels: {
														display: 'none'
													}
												}}
											/>
										)
									})
								}
							</VictoryChart>

							<Tooltip
								title='Scroll right'
							>
								<IconButton
									onClick={onScrollRight}
								>
									<ChevronRight/>
								</IconButton>
							</Tooltip>

							<Tooltip
								title='Click and Drag the highlighted area on the reference chart to manipulate the data series. You can grow or shrink your viewing area by dragging the edges of the highlighted box. Click and hold the middle to pan.'
								placement="top-start"
							>
								<IconButton
									sx={{
										width: 'fit-content',
										height: 'fit-content'
									}}
								>
									<InfoSharp/>
								</IconButton>
							</Tooltip>
						</Stack>
					}

					{
						areVitalsFetching &&
						<LinearProgress sx={{ width: '100%' }} />
					}

					<Stack
						direction='row'
						sx={{
							width: '100%',
							justifyContent: 'start',
							alignItems: 'center'
						}}
						spacing={0}
					>

						<Tooltip
							title='Scroll left'
						>
							<IconButton
								onClick={onScrollLeft}
								disabled={showCustomNav}
							>
								<ChevronLeft/>
							</IconButton>
						</Tooltip>

						<Box
							sx={{
								height: height,
								width: '100%',
								mx: 0
							}}
							ref={graphRef}
						>
							<ResponsiveChartContainer
								height={height}
								series={(vitals?.length > 0) ? Object.entries(currentSensors)
									.filter(([position, sensor]) => sensor)
									.flatMap(([position, sensor]) => (sensor === 'symptoms') ? Array([...symptoms]).map((symptom) => ([position, symptom])) : [[position, sensor]])
									.map(([position, sensor], index) => {
										const series = {
											dataKey: sensor,
											type: 'line',
											yAxisKey: (symptoms.has(sensor) ? 'symptoms' : sensor),
											label: sensorsToNames[sensor] ?? sensor,
											curve: 'linear',
											color: currentColors[position]
										}
										return series
									}) : []
								}
								xAxis={[{ 
									dataKey: 'ts',
									scaleType: 'linear', 
									valueFormatter: (unixMilliseconds) => dayjs(unixMilliseconds).format('L\nLTS'), 
									...domain 
								}]}
								yAxis={(vitals.length > 0) ? yAxes : []}
								dataset={vitals}
								sx={{
									[`.${axisClasses.left} .${axisClasses.label}`]: {
										transform: 'translate(-25px, 0)'
									},
									[`.${axisClasses.right} .${axisClasses.label}`]: {
										transform: 'translate(30px, 0)'
									}
								}}
								margin={{ left: 70, right: 70 }}
							>
								
								<LinePlot />
								<LineHighlightPlot />
								<ChartsAxisHighlight x="line" />
								{
									vitals?.length > 0 &&
									Object.entries(currentSensors).filter(([position, sensor]) => sensor).map(([position, sensor], index) => {
										return (
											<ChartsYAxis 
												position={position.toLowerCase()} 
												axisId={sensor} 
												label={`${sensorsToNames[sensor] ?? sensor} (${sensors[sensor]?.units})`}
											/>
										)
									})
								}
								<ChartsTooltip trigger="axis" />
								<ChartsXAxis position="bottom" />
								<ChartsLegend/>

								<ChartsReferenceLine 
									x={shortDomainSlider} 
									lineStyle={{ stroke: mangoFusionPaletteDark[2] }} 
									label={sensorsToNames[shortSensor] ?? shortSensor}
									labelAlign="start"
									labelStyle={{ fill: mangoFusionPaletteDark[2], transform: 'translate(0, -20px)' }}

								/>
							</ResponsiveChartContainer>
						</Box>

						<Tooltip
							title='Scroll right'
						>
							<IconButton
								onClick={onScrollRight}
								disabled={showCustomNav}
							>
								<ChevronRight/>
							</IconButton>
						</Tooltip>

					</Stack>

					<Slider
						value={shortDomainSlider}
						onChange={onShortDomainSliderChange}
						onChangeCommitted={onSliderCommit}
						valueLabelDisplay="auto"
						valueLabelFormat={(value) => dayjs(value).format('LTS')}
						min={domain.min}
						max={dayjs(domain.max).subtract(30, 'second')}
						step={1000}
						disabled={shortVitalsAreLoading || areShortVitalsFetching}
						sx={{
							width: '90%',
							alignSelf: 'center',
							color: mangoFusionPaletteDark[2],
							'& .MuiSlider-track': {
								color: 'transparent'
							}
						}}
					/>

					<Stack
						direction='row'
						sx={{
							width: '100%',
							justifyContent: 'start',
							alignItems: 'center'
						}}
					>
						<SensorSelect
							currentColor={mangoFusionPaletteDark[2]}
							colorOptions={mangoFusionPaletteDark.filter((color) => !Object.values(currentColors).includes(color))}
							onSelectColor={() => {}}
							currentSensor={shortSensor}
							sensorOptions={Object.keys(sensors).filter((sensor) => SHORT_GRAPH_SENSORS.has(sensor))}
							onSelectSensor={(sensor) => dispatch(selectShortSensor({sensor, unix_ts: dayjs(sensors[sensor].ts).valueOf()}))}
							index={0}
						/>

						<VictoryChart
							height={150}
							width={boundingRect.width - 16}
							scale={{x: 'linear', y: 'linear'}}
							domain={{ x: [shortDomain.min,  shortDomain.max], y: [-5000, 5000] }}
							containerComponent={
								<VictoryZoomContainer 
									allowPan
									allowZoom
									zoomDimension="x"
								/>
							}
							style={{
								background: { fill: 'transparent' }
							}}
						>
							
							{
								(
									areShortVitalsSuccess &&
									shortSensor !== null
								) &&
								<VictoryLine
									style={{
										data: { stroke: mangoFusionPaletteDark[2] }
									}}
									y={(datum) => {
										return datum[shortSensor]
									}}
									x={'ts'}
									data={shortVitals}
								/>
							}

							<VictoryAxis
								style={{
									axis: {
										stroke: 'transparent'
									},
									tickLabels: {
										fill: 'white'
									}
								}}
								tickFormat={(x) => dayjs(x).format('L\nLTS')}
							/>

							{
								<VictoryAxis 
									dependentAxis 
									orientation={'left'}
									style={{
										axis: {
											stroke: 'white'
										},
										tickLabels: {
											display: 'none'
										}
									}}
								/>
							}

						</VictoryChart>

					</Stack>

				</Stack>
	)
}



const SensorSelect = ({
	currentColor,
	colorOptions,
	onSelectColor,
	sensorOptions,
	currentSensor,
	onSelectSensor,
	index
}) => {
	return (
		<Stack
			direction='row'
			sx={{
				height: '4rem',
				justifyContent: 'start',
				alignItems: 'center',
			}}
		>

			<ToggleButton 
				value={currentColor} 
				sx={{ 
					p: 1,
					height: '100%',
					width: '4rem',
					borderTopRightRadius: 0,
					borderBottomRightRadius: 0 
				}}
			>
				<div
					style={{
						width: 15,
						height: 15,
						backgroundColor: currentColor,
						display: 'inline-block',
					}}
				/>
			</ToggleButton>

			<FormControl>
				<Select
					id={`graph-sensor-${index}-select`}
					value={currentSensor}
					onChange={(e) => {
						onSelectSensor(e.target.value)
					}}
					displayEmpty
					
					sx={{
						height: '4rem',
						width: '10rem',
						borderTopLeftRadius: 0,
						borderBottomLeftRadius:0,
						color: 'white'
					}}
				>
					{
						sensorOptions.map((sensor) => <MenuItem value={sensor}>{sensorsToNames[sensor] ?? sensor}</MenuItem>)
					}
					<MenuItem value={null}>None</MenuItem>
				</Select>
			</FormControl>

		</Stack>
	)
}



const RangeDatePicker = ({
	loading,
	range,
	shouldDisableDate,
	shouldDisableMonth,
	shouldDisableWeek,
	minDate,
	maxDate,
	onSelectDate,
	domain
}) => {
	switch(range) {
		case RangePresets.Enum.Day: {
			return (
				<DayDatePicker
					loading={loading}
					shouldDisableDate={shouldDisableDate}
					minDate={minDate.startOf('day')}
					maxDate={maxDate.endOf('day')}
					onSelectDate={onSelectDate}
					currentDate={dayjs(domain.min)}
				/>
			)
		}
		case RangePresets.Enum.Week: {
			return (
				<WeekDatePicker
					loading={loading}
					shouldDisableWeek={shouldDisableWeek}
					minDate={minDate.startOf('week')}
					maxDate={maxDate.endOf('week')}
					onSelectDate={onSelectDate}
					currentDateRange={Object.values(domain).toSorted((a, b) => a - b).map((unixTS) => dayjs(unixTS))}
				/>
			)
		}
		case RangePresets.Enum.Month: {
			return (
				<MonthDatePicker
					loading={loading}
					shouldDisableMonth={shouldDisableMonth}
					minDate={minDate.startOf('month')}
					maxDate={maxDate.endOf('month')}
					onSelectDate={onSelectDate}
					currentDate={dayjs(domain.min)}
				/>
			)
		}
		case RangePresets.Enum.All: {
			return (
				<AllDatePicker
					loading={loading}
					minDate={minDate}
					maxDate={maxDate}
				/>
			)
		}
		default: return
	}
	

}



const DayDatePicker = ({
	loading,
	shouldDisableDate,
	minDate,
	maxDate,
	onSelectDate,
	currentDate
}) => {
	
	return (
		<DatePicker
			minDate={minDate}
			maxDate={maxDate}
			shouldDisableDate={shouldDisableDate}
			loading={loading}
			renderLoading={CircularProgress}
			value={currentDate}
			onChange={onSelectDate}			
		/>
	)
}



const WeekDatePicker = ({
	loading,
	shouldDisableWeek,
	minDate,
	maxDate,
	onSelectDate,
	currentDateRange
}) => {
	const onChange = useCallback((newDateRange) => {
		console.log(newDateRange)
		if (shouldDisableWeek(newDateRange.at(0))) {
			return
		} else {
			onSelectDate(newDateRange.at(0))
		}
	}, [onSelectDate, shouldDisableWeek])

	console.log('Current Date Range: ', currentDateRange)

	return (
		<DateRangePicker
			loading={loading}
			renderLoading={CircularProgress}
			minDate={minDate}
			maxDate={maxDate}
			slots={{ field: SingleInputDateRangeField }}
			value={currentDateRange}
			onChange={onChange}
			calendars={1}
			sx={{
				minWidth: '16rem'
			}}
		/>
	)
}



const MonthDatePicker = ({
	loading,
	shouldDisableMonth,
	minDate,
	maxDate,
	onSelectDate,
	currentDate
}) => {
	return (
		<DatePicker
			views={['month', 'year']}
			loading={loading}
			renderLoading={CircularProgress}
			shouldDisableMonth={shouldDisableMonth}
			minDate={minDate}
			maxDate={maxDate}
			onChange={onSelectDate}
			value={currentDate}
		/>
	)
}



const AllDatePicker = ({
	loading,
	minDate,
	maxDate
}) => {
	return (
		<DateRangePicker
			value={[minDate, maxDate]}
			onChange={() => {}}
			loading={loading}
			renderLoading={CircularProgress}
			disabled
		/>
	)
}