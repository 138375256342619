import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectUser } from "../../../common/redux/userSlice";
import { Avatar, Box, IconButton, Skeleton, Stack, Switch, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetUserQuery, useUpdateUserInfoMutation } from "../../../common/redux/api/userApi";
import { useIsQueryLoading } from "../../../common/util";
import { useAddPatientToOrgMutation, useAddPharmaToTrialMutation, useGetOrgsForPharmaQuery, useGetOrgsForUserQuery, useGetOrgsQuery, useGetTrialsForPharmaQuery, useGetTrialsQuery } from "../../../common/redux/api/api";
import { formatPhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { parsePhoneNumber } from "react-phone-number-input";
import FormInput from "../../../components/CustomFormInput";
import { SingleLineTextBox } from "../../../common/Theme";
import { AddSharp, Close, CloseSharp, EditSharp, Person, SendSharp } from "@mui/icons-material";
import { FIELD_HEIGHT } from "../doctor/DoctorInfo";
import InfoPageDataField from "../../../components/styledComponents/surfaces/InfoPageDataField";
import { InfoPageChip } from "../../../components/styledComponents/chips/InfoPageChip";
import PhoneNumberInput from "../../../components/PhoneNumberInput";
import InfoPageTextField from "../../../components/styledComponents/surfaces/InfoPageTextField";
import { LoadingButton } from "@mui/lab";
import EntityLinkModal from "../../../components/modals/EntityLinkModal";
import * as z from 'zod';
import { isPossiblePhoneNumber } from "react-phone-number-input";




export const PharmaPage = () => {
    const { id: alacrity_id } = useParams();
    const user = useSelector(selectUser);
    const theme = useTheme()

    const [ isEditing, setIsEditing ] = useState(false);



    const {
        data: pharma,
        currentData: currentPharma,
        isSuccess: isPharmaSuccess,
        isUninitialized: isPharmaUninitialized,
        isLoading: isPharmaLoading,
        isFetching: isPharmaFetching,
        isError: isPharmaError,
        error: pharmaError
    } = useGetUserQuery(alacrity_id);

    const pharmaIsLoading = useIsQueryLoading([isPharmaLoading, isPharmaFetching])



    const {
        data: pharmaOrgs,
        isUninitialized: isOrgsUninitialized,
        isSuccess: isOrgsSuccess,
        isLoading: isOrgsLoading,
        isFetching: isOrgsFetching,
        isError: isOrgsError,
        error: orgsError
    } = useGetOrgsForPharmaQuery(alacrity_id);

    const orgsAreLoading = useIsQueryLoading([isOrgsLoading, isOrgsFetching, isOrgsUninitialized])

    

    const {
        data: trials,
        isUninitialized: isTrialsUninitialized,
        isSuccess: isTrialsSuccess,
        isLoading: isTrialsLoading,
        isFetching: isTrialsFetching,
        isError: isTrialsError,
        error: trialsError
    } = useGetTrialsForPharmaQuery(alacrity_id);

    const trialsAreLoading = useIsQueryLoading([isTrialsLoading, isTrialsFetching, isTrialsUninitialized])



    const formattedPhone = useMemo(() => {
        if (pharma?.phone?.e164NumberString) {
            if (pharma?.phone?.iso2?.toLowerCase() === 'us') {
                return formatPhoneNumber(pharma?.phone?.e164NumberString)
            } else {
                return formatPhoneNumberIntl(pharma?.phone?.e164NumberString)
            }
        } else {
            return 'None'
        }
    }, [pharma]);


    
    const { schema: pharmaInfoSchema, initialValues, editableValues } = usePharmaInfoForm({ user, pharma });

    const methods = useForm({
        resolver: zodResolver(pharmaInfoSchema),
        defaultValues: initialValues
    });

    const { control, reset, watch, handleSubmit, formState: { isSubmitSuccessful, errors, isSubmitting } } = methods;
    
    const [ editedData, setEditedData ] = useState({...editableValues})   // Just make a copy of the pharma's data that can be manipulated without losing the original copy.

    const [ updatePharma, updatePharmaResult ] = useUpdateUserInfoMutation()



    const onSubmit = (values) => {
        let newPharmaInfo = {}
        for (const field in values) {
            if (values[field] !== undefined && values[field] !== '' && field !== 'countryCode') {
                newPharmaInfo = Object.assign(newPharmaInfo, { [field]: values[field] })
            }
        }
        console.log('UpdatePharma: ', newPharmaInfo)

        if (values?.phone) {
            const phone = parsePhoneNumber(values?.phone.replace(' ', ''))

            newPharmaInfo.phone = {
                iso2: values?.countryCode,
                e164NumberString: phone?.number,
                dialCode: phone?.countryCallingCode,
                ext: phone?.ext
            }
        }
        
        updatePharma({
            id: alacrity_id,
            newInfo: newPharmaInfo
        })
    }



    useEffect(() => {
        if (updatePharmaResult.isSuccess) {
            setIsEditing(false)
            reset()
            updatePharmaResult.reset()
        }
    }, [updatePharmaResult, reset, setIsEditing])



    const [ openOrgModal, setOpenOrgModal ] = useState(false)

    const [ openTrialModal, setOpenTrialModal ] = useState(false)



    const onDeleteTrialChip = useCallback(() => {}, [])

    const trialsChips = useMemo(() => {
        if (isPharmaSuccess && isTrialsSuccess) {
            const mainRowChips = trials?.map((trial, index) => {
                if (editableValues?.trials) {
                    if (editableValues?.trials?.includes(trial.trial_id)) {
                        return (
                            <InfoPageChip 
                                onDelete={() => { onDeleteTrialChip(trial) }}
                                label={trial?.name}
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color:'black'
                                }}
                            />
                        )
                    } else {    // Else if not in editable values, don't add a delete button by adding an onDelete function
                        return (
                            <InfoPageChip
                                label={trial?.name}
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color: 'black'
                                }}
                            />
                        )
                    }
                } else {
                    return (
                        <InfoPageChip
                            label={trial?.name}
                            sx={{
                                backgroundColor: theme.palette.secondary.main,
                                color: 'black'
                            }}
                        />
                    )
                }
            })

            return (
                <>
                    <Stack
                        direction='row'
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'center',
                            width: '100%'
                        }}
                        spacing={2}
                    >
                        {mainRowChips}

                        {
                            (
                                user.permissions === 'ALACRITY' ||
                                (
                                    user?.orgs.some((org_id) => pharma?.orgs.includes(org_id)) &&
                                    user.admin === true
                                ) 
                            ) &&
                            <IconButton
                                color="info"
                                onClick={() => { setOpenTrialModal(true) }}
                            >
                                <AddSharp/>
                            </IconButton>
                        }
                    </Stack>
                </>
            )
        } else {
            return (
                <Stack
                    direction='row'
                    sx={{
                        justifyContent: 'start',
                        alignItems: 'center',
                        width: '100%'
                    }}
                ></Stack>
            )
        }
    }, [editableValues, onDeleteTrialChip, pharma, user, isTrialsSuccess, theme, trials, isPharmaSuccess])



    const onDeleteOrgChip = useCallback(() => {}, [])

    const orgsChips = useMemo(() => {
        if (isOrgsSuccess && isPharmaSuccess) {
            const mainRowChips = pharmaOrgs?.map((org, index) => {
                if (editableValues?.orgs) {
                    if (editableValues.orgs.includes(org?.org_id)) {
                        return (
                            <InfoPageChip 
                                onDelete={() => { onDeleteOrgChip(org) }}
                                label={org?.name}
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color:'black'
                                }}
                            />
                        )
                    } else {    // Else if not in editable values, don't add a delete button by adding an onDelete function
                        return (
                            <InfoPageChip
                                label={org?.name}
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color: 'black'
                                }}
                            />
                        )
                    }
                } else {
                    return (
                        <InfoPageChip
                            label={org?.name}
                            sx={{
                                backgroundColor: theme.palette.secondary.main,
                                color: 'black'
                            }}
                        />
                    )
                }
            })

            return (
                <>
                    <Stack
                        direction='row'
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'center',
                            width: '100%'
                        }}
                        spacing={2}
                    >
                        {mainRowChips}

                        {
                            (
                                user.permissions === 'ALACRITY' ||
                                (
                                    user?.orgs.some((org_id) => pharma?.orgs.includes(org_id)) &&
                                    user.admin === true
                                ) 
                            ) &&
                            <IconButton
                                color="info"
                                onClick={() => { setOpenOrgModal(true) }}
                            >
                                <AddSharp/>
                            </IconButton>
                        }
                    </Stack>
                </>
            )
        } else {
            return (
                <Stack
                    direction='row'
                    sx={{
                        justifyContent: 'start',
                        alignItems: 'center',
                        width: '100%'
                    }}
                ></Stack>
            )
        }
    }, [editableValues, onDeleteOrgChip, isOrgsSuccess, theme, pharmaOrgs, pharma, user, isPharmaSuccess])
    


    return (
        <>
        <FormProvider {...methods}>
            <Box
                display='flex'
                flexDirection='column'
                component='form'
                noValidate
                autoComplete='off'
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    width: '100%',
                    height: '100%'
                }}
            >
                <Stack
                    sx={{
                        width: '100%',
                        // height: '100%',
                        p: 8,
                        justifyContent: 'space-between'
                    }}
                    direction='row'
                >
                    <Stack
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'start',
                            height: '100%',
                            width: '80%',
                        }}
                        spacing={8}
                    >
                        <Stack
                            sx={{
                                justifyContent: 'start',
                                alignItems: 'center',
                                width: '100%'
                            }}
                            direction='row'
                        >
                            {                                
                                ( isEditing && editableValues?.first_name ) ?
                                    <>
                                        <FormInput
                                            label='First Name'
                                            type='text'
                                            name='first_name'
                                            sx={{
                                                mr: 5,
                                                maxWidth: '20rem'
                                            }}
                                            focused
                                        />

                                        <FormInput
                                            label='Last Name'
                                            type='text'
                                            name='last_name'
                                            sx={{
                                                maxWidth: '20rem'
                                            }}
                                            focused
                                        />
                                    </>
                                :
                                    <>
                                        <SingleLineTextBox
                                            variant='h2'
                                            tooltip={(pharmaIsLoading) ? null : `${pharma.first_name} ${pharma.last_name}`}
                                        >
                                            {(pharmaIsLoading) ? <Skeleton width={200} /> : `${pharma?.first_name} ${pharma?.last_name}`}
                                        </SingleLineTextBox>
                                    </>
                            }
                            {
                                pharmaIsLoading ?
                                    <Skeleton variant="circular">
                                        <Avatar     // TODO: Provide way to upload profile picture
                                            src={pharma?.avatar}
                                            sx={{
                                                height: 60,
                                                width: 60,
                                                ml: '10%'
                                            }}
                                        >
                                            <Person style={{ height: 40, width: 40 }}/>
                                        </Avatar>
                                    </Skeleton>
                                :
                                    <Avatar     // TODO: Provide way to upload profile picture
                                        src={pharma?.avatar}
                                        sx={{
                                            height: 60,
                                            width: 60,
                                            ml: '10%'
                                        }}
                                    >
                                        <Person style={{ height: 40, width: 40 }}/>
                                    </Avatar>
                            }
                        </Stack>  

                        <Stack
                            sx={{
                                width: '100%',
                                height: FIELD_HEIGHT,
                                alignItems: 'center',
                            }}
                            direction='row'
                            spacing={2}
                        >

                            <InfoPageDataField
                                childSpacing={4}
                                label='Organizations'
                                editing={isEditing}
                                containerProps={{
                                    height: FIELD_HEIGHT,
                                    width: '100%',
                                    justifyContent: 'start',
                                }}
                                loading={pharmaIsLoading || orgsAreLoading}
                                loadingChildren={
                                    <Stack
                                        direction={'row'}
                                        width='100%'
                                        height='100%'
                                        spacing={3}
                                        alignItems='center'
                                    >
                                        {
                                            Array(4).fill(0).map(() => (<Skeleton variant="rounded" sx={{ height: 40, width: 100, borderRadius: 6 }}/>))
                                        }
                                    </Stack>
                                }
                            >
                                {orgsChips}
                            </InfoPageDataField> 

                            <Controller
                                control={control}
                                name='admin'
                                disabled={!isEditing}
                                render={({ field }) => {
                                    const { value, ...fields} = field
                                    return (
                                        <Stack direction={'row'} alignItems='center'>
                                            <Switch 
                                                {...fields} 
                                                checked={(isEditing) ? value : /*pharma?.admin*/ false}
                                                sx={{
                                                    '& .MuiSwitch-switchBase.Mui-disabled': {
                                                        color: (value) ? theme.palette.primary.main : 'rgba(255, 255, 255, 0.75)',
                                                    },
                                                    '& .MuiSwitch-track': {
                                                        backgroundColor: (value) ? `${theme.palette.primary.main} !important` : `${theme.palette.text.secondary} !important` ,
                                                        opacity: '0.5 !important'
                                                    }
                                                }}
                                            />
                                            <Typography>
                                                Admin
                                            </Typography>
                                        </Stack>
                                    )
                                }}
                            />
                        
                        </Stack>

                        <InfoPageDataField
                            label='Trials'
                            editing={isEditing}
                            loading={pharmaIsLoading || trialsAreLoading}
                            loadingChildren={
                                <Stack
                                    direction='row'
                                    width='100%'
                                    height='100%'
                                    spacing={3}
                                    alignItems='center'
                                >
                                    {
                                        Array(3).fill(0).map(() => (<Skeleton variant="rounded" sx={{ height: 40, width: 100, borderRadius: 6 }}/>))
                                    }
                                </Stack>
                            }
                        >
                            {trialsChips}
                        </InfoPageDataField>

                        <Stack
                            sx={{
                                width: '100%',
                                height: FIELD_HEIGHT,
                                alignItems: 'end'
                            }}
                            direction='row'
                            spacing={2}
                        >
                            {
                                isEditing ?
                                    <PhoneNumberInput focused />
                                :
                                    <InfoPageTextField
                                        label='Phone'
                                        type='tel'
                                        name='phone'
                                        loading={pharmaIsLoading}
                                        editing={isEditing}
                                        text={formattedPhone}  
                                        boxProps={{
                                            display: 'flex',
                                            alignItems: 'end',
                                            width: '100%',
                                            height: '100%'
                                        }}
                                        inputProps={{
                                            height: '3.6rem'
                                        }}
                                        focused                        
                                    />
                            }

                            <InfoPageTextField
                                label='Email'
                                type='text'
                                name='email'
                                loading={pharmaIsLoading}
                                editing={isEditing}
                                text={pharma?.email}  
                                boxProps={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    width: '100%',
                                    height: '100%'
                                }}
                                inputProps={{
                                    height: '3.6rem'
                                }}
                                focused                        
                            />

                            <Controller
                                control={control}
                                name='status_active'
                                defaultValue={undefined}
                                disabled={!isEditing}
                                render={({ field }) => {
                                    const { value, ...fields} = field
                                    return (
                                        <Stack direction={'row'} alignItems='center'>
                                            <Typography>
                                                Inactive
                                            </Typography>
                                            <Switch 
                                                {...fields} 
                                                checked={(isEditing) ? value : pharma?.status_active}
                                                sx={{
                                                    '& .MuiSwitch-switchBase.Mui-disabled': {
                                                        color: theme.palette.primary.main,
                                                    },
                                                    '& .MuiSwitch-track': {
                                                        backgroundColor: `${theme.palette.primary.main} !important`,
                                                        opacity: '0.5 !important'
                                                    }
                                                }}
                                            />
                                            <Typography>
                                                Active
                                            </Typography>
                                        </Stack>
                                    )
                                }}
                            />

                        </Stack>

                    </Stack>

                    <Stack
                        sx={{
                            height: '100%',
                            justifyContent: 'space-between'
                        }}
                    >
                        <LoadingButton
                            loading={pharmaIsLoading}
                            startIcon={(isEditing) ? <CloseSharp/> : <EditSharp/>}
                            variant="outlined"
                            color={(isEditing) ? 'error' : 'primary'}
                            sx={{
                                height: 'fit-content',
                                py: 1,
                                px: 1.5,
                                '&:hover': {
                                    backgroundColor: (isEditing) ? theme.palette.error.main : theme.palette.primary.main,
                                    color: 'white' 
                                }
                            }}
                            onClick={() => { reset(); setIsEditing((prev) => !prev) }}
                        >
                            {(isEditing) ? 'Cancel' : 'Edit'}
                        </LoadingButton>  

                        {
                            isEditing &&
                            <LoadingButton
                                loading={isSubmitting}
                                endIcon={<SendSharp/>}
                                variant="outlined"
                                color={'primary'}
                                sx={{
                                    height: 'fit-content',
                                    py: 1,
                                    px: 1.5,
                                    '&:hover': {
                                        backgroundColor: theme.palette.primary.main,
                                        color: 'white' 
                                    }
                                }}
                                type="submit"
                            >
                                Submit
                            </LoadingButton> 
                        }        
                    </Stack>

                </Stack>
            </Box>
        </FormProvider>

        {
            openOrgModal &&
            <AddOrgModal
                open={openOrgModal}
                handleClose={() => setOpenOrgModal(false)}
                pharma_id={alacrity_id}
            />
        }

        {
            openTrialModal &&
            <AddTrialModal
                open={openTrialModal}
                handleClose={() => setOpenTrialModal(false)}
                pharma_id={alacrity_id}
            />
        }

        </>
    )
}



const AddTrialModal = ({ open, handleClose, pharma_id }) => {
    const orderTrials = (a, b) => {
        return a.name.localeCompare(b.name);
    }

    const transformTrials = (entity) => {
        return {
            label: entity.name,
            id: entity.trial_id
        }
    }

    

    return (
        open &&
        <EntityLinkModal
            open={open}
            onClose={handleClose}
            orderEntities={orderTrials}
            transformEntities={transformTrials}
            entityName={'Pharma'}
            entityID={pharma_id}
            entityListQuery={useGetTrialsQuery}
            linkName={'Trial'}
            linkEntityMutation={useAddPharmaToTrialMutation}
            ariaLabelledBy="modal-trial-title"  
            onSuccess={() => {
                handleClose()
            }}      
        />
    )
}



const AddOrgModal = ({ open, handleClose, pharma_id }) => {
    const orderOrgs = (a, b) => {
        return a.name.localeCompare(b.name);
    }

    const transformOrgs = (entity) => {
        return {
            label: `${entity.name}`,
            id: entity.org_id
        }
    }

    

    return (
        open &&
        <EntityLinkModal
            open={open}
            onClose={handleClose}
            orderEntities={orderOrgs}
            transformEntities={transformOrgs}
            entityName={'Pharma'}
            entityID={pharma_id}
            entityListQuery={useGetOrgsQuery}
            linkName={'Org'}
            linkEntityMutation={useAddPatientToOrgMutation}
            ariaLabelledBy="modal-org-title"  
            onSuccess={() => {
                handleClose()
            }}      
        />
    )
}



const usePharmaInfoForm = ({ user, pharma }) => {
    return useMemo(() => {
        var schema = z.object({});
        var initialValues = {};
        var editableValues = {};

        if (
            user.alacrity_id === pharma?.alacrity_id || 
            (
                user?.admin === true && 
                user?.orgs?.some((org_id) => pharma?.orgs?.includes(org_id))
            ) || 
            user?.permissions === 'ALACRITY'
        ) {     // If the user is looking at their own page, they can edit email & phone since they can verify.
            initialValues = {
                ...initialValues,
                first_name: undefined,
                last_name: undefined,
                email: undefined,
                phone: undefined,
                countryCode: 'US'
            }

            editableValues = {
                first_name: pharma?.first_name,
                last_name: pharma?.last_name,
                email: pharma?.email,
                countryCode: pharma?.phone?.iso2,
                phone: pharma?.phone?.e164NumberString,
            }

            schema = schema.extend({
                first_name: z.string().optional(),
                last_name: z.string().optional(),
                email: z.union([z.string().email('Must be a valid email.'), z.literal('')]),
                countryCode: z.string().length(2).optional(),
                phone: z.string().optional(),
            })
        }

        if (
            (
                user?.admin === true && 
                user?.orgs?.some((org_id) => pharma?.orgs?.includes(org_id))
            ) || 
            user?.permissions === 'ALACRITY'
        ) {     // Admins can also edit their team's permissions to access information
            initialValues = {
                ...initialValues,
                orgs: undefined,
                trials: undefined
            }

            schema = schema.extend({
                orgs: z.array(z.string()).optional(),
                trials: z.array(z.string()).optional()
            })

            editableValues = {
                ...editableValues,
                orgs: pharma?.orgs.filter((org_id) => { if (user?.orgs) { return user?.orgs?.includes(org_id) } else { return false }}),
                trials: pharma?.trials?.filter((patient_id) => user?.trials?.includes(patient_id))
            }
        }

        if (user.permissions === 'ALACRITY') {      // Alacrity users can change anything.
            initialValues = {
                ...initialValues,
                status_active: undefined,
                admin: pharma?.admin
            }

            schema = schema.extend({
                status_active: z.boolean().optional(),
                admin: z.boolean().optional()
            })

            editableValues = {
                ...editableValues,
                status_active: pharma?.status_active,
                admin: pharma?.admin
            }
        }


        schema = schema.superRefine((data, ctx) => {
            if (data?.phone) {
                // console.log(data?.phoneNumber.replace(' ', ""))
                if (!isPossiblePhoneNumber(data?.phone.replace(' ', ""))) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: 'Invalid Phone Number',
                        path: ['phone']
                    })
                }
            }
        })


        return { schema: schema, initialValues: initialValues, editableValues: editableValues }
    
    }, [user, pharma])
}