import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBase } from './auth/customAuthQuery';
import { result } from 'lodash';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax';
import duration from 'dayjs/plugin/duration';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(minMax)
dayjs.extend(duration)



export const QuickViewSensors = new Set(['ecg-heartrate', 'temp'])



export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: customFetchBase,
    tagTypes: ['Doctors', 'Patients', 'Trials', 'Orgs', 'Pharmas'],
    endpoints: builder => ({
        getPatientsAdmin: builder.query({
            query: () => '/admin',
            transformResponse: (response) => { 
                console.log(response)
                const transformedData = response.data.map((patient) => {
                    const {patient_id: id, ...data} = patient;
                    return Object.assign({}, data, { id: id });
                });
                response.data = transformedData;
                return response
             }
        }),
        getPatients: builder.query({
            query: () => ({
                url: '/patients',
            }),
            transformResponse: (result) => result?.data,
            providesTags: ['Patients']
        }),
        getPatient: builder.query({
            query: (id) => ({
                url: `/patients/${id}`,
            }),
            transformResponse: (result) => {
                return result?.data?.Item
            },
            providesTags: (result, error, id) => [{ type: 'Patients', id }]
        }),
        getTrialsForPatient: builder.query({
            query: (id) => ({
                url: `/patients/${id}/trials`,
            }),
            transformResponse: (result) => result?.data,
            providesTags: (result, error, id) => [{ type: 'Trials', id }]
        }),
        getDoctorsForPatient: builder.query({
            query: (id) => ({
                url: `/patients/${id}/doctors`,
            }),
            transformResponse: (result) => result?.data,
            providesTags: (result, error, id) => [{ type: 'Doctors', id }]
        }),
        createPatient: builder.mutation({
            query: (patientData) => ({
                url: '/users',
                method: 'POST',
                body: patientData
            }),
            invalidatesTags: ['Patients']
        }),
        updatePatientInfo: builder.mutation({
            query: ({ id, newInfo }) => ({
                url: `/users/${id}`,
                method: 'PUT',
                body: newInfo,
            }),
            invalidatesTags: (result, error, args) => ([{ type: 'Patients', id: args.id }])
        }),
        getTrials: builder.query({
            query: () => ({
                url: '/trials',
            }),
            transformResponse: (result) => {
                return result?.data
            },
            providesTags: ['Trials']
        }),
        getTrial: builder.query({
            query: (id) => ({
                url: `/trials/${id}`,
            }),
            transformResponse: (result) => {
                return result?.data?.Item
            },
            providesTags: (result, error, id) => [{ type: 'Trials', id }]
        }),
        createTrial: builder.mutation({
            query: (trialInfo) => ({
                url: `/trials`,
                method: 'POST',
                body: trialInfo,
            }),
            invalidatesTags: ['Trials']
        }),
        addPatientToTrial: builder.mutation({
            query: ({ patientID, trialID }) => ({
                url: '/links',
                method: "POST",
                body: { patient_id: patientID, trial_id: trialID },
            }),
            invalidatesTags: (result, error, args) => [{ type: 'Patients', id: args.trialID }, { type: 'Trials', id: args.patientID }]
        }),
        addDoctorToTrial: builder.mutation({
            query: ({ doctor_id, trial_id }) => ({
                url: '/links',
                method:'POST',
                body: { doctor_id: doctor_id, trial_id: trial_id },
            }),
            invalidatesTags: (result, error, args) => [{ type: 'Doctors', id: args.trial_id }, { type: 'Trials', id: args.doctor_id }]
        }),
        addPharmaToTrial: builder.mutation({
            query: ({ pharma_id, trial_id }) => ({
                url: '/links',
                method: 'POST',
                body: { pharma_id, trial_id }
            })
        }),
        getDoctorsForTrial: builder.query({
            query: (id) => ({
                url: `/trials/${id}/doctors`,
            }),
            transformResponse: (result) => result?.data,
            providesTags: (result, error, id) => [{ type: 'Doctors', id }]
        }),
        getPatientsForTrial: builder.query({
            query: (id) => ({
                url: `/trials/${id}/patients`,
            }),
            transformResponse: (result) => result?.data,
            providesTags: (result, error, id) => [{ type: 'Patients', id }]
        }),
        getOrgsForTrial: builder.query({
            query: (id) => `/trials/${id}/orgs`,
            transformResponse: (result) => result?.data,
            providesTags: (result, error, id) => ([{ type: 'Orgs', id }])
        }),
        updateTrialInfo: builder.mutation({
            query: ({id, newInfo}) => ({
                url: `/trials/${id}`,
                method: 'PUT',
                body: newInfo,
            }),
            invalidatesTags: (result, error, args) => [{ type: 'Trials', id: args.id }]
        }),
        getDoctors: builder.query({
            query: () => ({
                url: '/doctors',
            }),
            transformResponse: (result) => {
                return result?.data
            },
            providesTags: ['Doctors']
        }),
        addPatientToDoctor: builder.mutation({
            query: ({ patientID, doctorID }) => ({
                url: '/links',
                method: 'POST',
                body: { patient_id: patientID, doctor_id: doctorID },
            }),
            invalidatesTags: (result, error, args) => [{ type: 'Doctors', id: args.patientID }, { type: 'Patients', id: args.doctorID }]
        }),
        getDoctor: builder.query({
            query: (id) => ({
                url: `/doctors/${id}`,
            }),
            transformResponse: (result) => {
                return result?.data?.Item
            },
            providesTags: (result, error, id) => [{ type: 'Doctors', id }]
        }),
        getTrialsForDoctor: builder.query({
            query: (id) => ({
                url: `/doctors/${id}/trials`,
            })
        }),
        getPatientsForDoctor: builder.query({
            query: (id) => ({
                url: `/doctors/${id}/patients`,
            }),
            transformResponse: (result) => result?.data,
            providesTags: (result, error, id) => [{ type: 'Patients', id }] 
        }),
        updateDoctorInfo: builder.mutation({
            query: ({ id, newInfo }) => ({
                url: `/users/${id}`,
                method: 'PUT',
                body: newInfo,
            }),
            invalidatesTags: (result, error, args) => ([{ type: 'Doctors', id: args.id }])
        }),
        testBounceBack: builder.mutation({
            query: (testData) => ({
                url: '/debug/bounce_back',
                method: 'POST',
                body: testData,
            })
        }),
        getPatientsWithData: builder.query({
            queryFn: async (_args, api, _options, baseQuery) => {
                const patients = await baseQuery('/patients')
                if (patients?.error) {
                    console.log(`Error getting patients in patientsWithData: `, patients)
                    return { error: patients.error }
                }

                const patientsWithData = await Promise.all(patients.data.data.map(async (patient) => {     // Map all patients
                    const sensorsResult = await baseQuery(`/sensors/${patient.alacrity_id}`);    // Get sensors list with latest upload timestamps for each patient
                    console.log(`Sensors for ${patient.alacrity_id}: `, sensorsResult)
                    return {...patient, sensors: sensorsResult?.data?.data} // Object.assign({}, { sensors: sensorsResult?.data?.data }, patient);     // Add sensors to patient object.
                })).then(async (patientsWithSensors) => {
                    const patientsWithSensorsAndReadings = await Promise.all(patientsWithSensors.map(async (patient) => {     // Map patients with sensors to readings
                        console.log('Patient with Sensors: ', patient)
                        const sensorsToReadings = await Promise.all(Object.keys(patient.sensors).map(async (sensor) => {    // Filter sensors to only ones we want to display. Map sensors to readings
                            const ts = dayjs(patient.sensors[sensor]?.ts)
                            const latestReadings = await baseQuery(`/vitals/${patient.alacrity_id}/${sensor}/${ts.subtract(1, 'ms').toISOString()}/${ts.add(1, 'ms').toISOString()}`)      // Get readings at the exact timestamp of the latest reading. Should just be one value.
                            console.log(`Latest Readings ${sensor} for ${patient.alacrity_id}: `, latestReadings)
                            if (latestReadings?.data?.data?.values?.length > 0) {
                                return { [sensor]: {...latestReadings?.data?.data?.values?.at(-1), units: latestReadings?.data?.data?.units } }   // Only use most recent reading
                            }
                        })).then((sensorsList) => sensorsList.reduce((acc, readingObject) => ({...acc, ...readingObject}), {}))    // Turn array of sensor to reading mappings into dict with sensor names as keys
                        
                        return {
                            ...patient,
                            sensors: sensorsToReadings
                        }
                    }))
                    return patientsWithSensorsAndReadings
                })
                console.log('Patients With Data: ', patientsWithData)

                return { data: patientsWithData }
            }
        }),
        getUsersList: builder.query({
            queryFn: async (idList, api, options, baseQuery) => {
                const users = await Promise.all(idList.map(async (id) => {
                    return baseQuery(`/users/${id}`).then((result) => {
                        return result?.data?.data
                    })
                }))
                return {data: users}
            },
            providesTags: (result, error, ids) => ids.map((id) => ({ type: 'Patients', id }))
        }),
        getOrgs: builder.query({
            query: () => `/orgs`,
            transformResponse: (result) => {
                return result?.data
            },
            providesTags: ['Orgs']
        }),
        getAllMedicalOrgs: builder.query({
            query: () => `/orgs/DOCTOR`,
            transformResponse: (result) => result?.data,
            providesTags: ['Orgs']
        }),
        getAllPharmaOrgs: builder.query({
            query: () => `/orgs/PHARMA`,
            transformResponse: (result) => result?.data,
            providesTags: ['Orgs']
        }),
        getAllOrgs: builder.query({
            queryFn: async (arg, api, options, baseQuery) => {
                return await Promise.all([
                    baseQuery(`/orgs/DOCTOR`),
                    baseQuery(`/orgs/PHARMA`)
                ]).then((results) => {
                    const newResult = results.flatMap((result) => { return (result?.error) ? result?.error : result?.data?.data })
                    return (newResult?.error) ? { error: newResult } : { data: newResult }
                })
            },
            providesTags: ['Orgs']
        }),
        getOrg: builder.query({
            query: (org_id) => `/orgs/${org_id}`,
            transformResponse: (result) => {
                return result?.data
            },
            providesTags: (result, error, id) => [{ type: 'Orgs', id }]
        }),
        createOrg: builder.mutation({
            query: (info) => ({
                url: '/orgs',
                method: 'POST',
                body: info,
            }),
            invalidatesTags: ['Orgs']
        }),
        updateOrgs: builder.mutation({
            query: ({orgs}) => ({
                url: `/orgs/`,
                method: 'PUT',
                body: orgs
            }),
            invalidatesTags: (result, error, args) => args.map((org) => ({ type: 'Orgs', id: org?.org_id }))
        }),
        updateOrgInfo: builder.mutation({
            query: ({ org_id, newInfo }) => ({
                url: `/orgs/${org_id}`,
                method: 'PUT',
                body: newInfo
            }),
            invalidatesTags: (result, error, args) => ([{ type: 'Orgs', id: args.org_id }])
        }),
        deleteOrg: builder.mutation({
            query: (org_id) => ({
                url: `/orgs/${org_id}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, id) => ([{ type: 'Orgs', id }])
        }),
        getOrgsForUser: builder.query({
            query: (alacrity_id) => `/orgs/${alacrity_id}`,
            transformResponse: (result) => result?.data,
            providesTags: ['Orgs']
        }),
        addPatientToOrg: builder.mutation({
            query: ({ org_id, patient_id }) => ({
                url: `/links`,
                method: 'POST',
                body: {
                    org_id,
                    patient_id
                }
            }),
            invalidatesTags: (result, error, args) => ([{type: 'Patients', id: args.patient_id}, { type: 'Orgs', id: args.org_id }])
        }),
        addProviderToOrg: builder.mutation({
            query: ({ org_id, provider_id }) => ({
                url: `/links`,
                method: 'POST',
                body: {
                    org_id,
                    doctor_id: provider_id
                }
            }),
            invalidatesTags: (result, error, args) => ([{type: 'Doctors', id: args.provider_id}, { type: 'Orgs', id: args.org_id }])
        }),
        addPharmaToOrg: builder.mutation({
            query: ({ org_id, pharma_id }) => ({
                url: `/links`,
                method: 'POST',
                body: {
                    org_id,
                    pharma_id
                }
            }),
            invalidatesTags: (result, error, args) => ([{type: 'Pharmas', id: args.pharma_id}, { type: 'Orgs', id: args.org_id }])
        }),
        addTrialToOrg: builder.mutation({
            query: ({ org_id, trial_id }) => ({
                url: `/links`,
                method: 'POST',
                body: {
                    org_id,
                    trial_id
                }
            })
        }),
        getTrialsForOrg: builder.query({
            query: (org_id) => `/orgs/${org_id}/trials`,
            transformResponse: (result) => result?.data,
            providesTags: (result, error, id) => ([{ type: 'Trials', id }])
        }),
        getPharma: builder.query({
            query: (id) => `/users/${id}`,
            transformResponse: (result) => result?.data,
            providesTags: (result, error, id) => ([{ type: 'Pharmas', id }])
        }),
        updatePharmaInfo: builder.mutation({
            query: ({ id, newInfo }) => ({
                url: `/users/${id}`,
                method: 'PUT',
                body: newInfo,
            }),
            invalidatesTags: (result, error, args) => ([{ type: 'Pharmas', id: args.id }])
        }),
        getTrialsForPharma: builder.query({
            queryFn: async (pharma_id, api, options, baseQuery) => {
                return await baseQuery(`/users/${pharma_id}`).then((pharmaResult) => {
                    if (pharmaResult?.error) {
                        return { error: pharmaResult?.error }
                    }

                    console.log('Pharma Result: ', pharmaResult)

                    return Promise.all((pharmaResult?.data?.data?.trials ?? []).map(async (trial_id) => {
                        const trialResult = await baseQuery(`/trials/${trial_id}`);
                        console.log('Trial Result: ', trialResult)

                        if (trialResult?.error) {
                            return { error: trialResult?.error }
                        } else {
                            return trialResult?.data?.data
                        }
                    }))
                })
            },
            providesTags: (result, error, id) => ([{ type: 'Trials', id }])
        }),
        getOrgsForPharma: builder.query({
            queryFn: async (pharma_id, api, options, baseQuery) => {
                return await baseQuery(`/users/${pharma_id}`).then((pharmaResult) => {
                    if (pharmaResult?.error) {
                        return { error: pharmaResult?.error }
                    }

                    console.log('Pharma Result: ', pharmaResult)

                    return Promise.all((pharmaResult?.data?.data?.orgs ?? []).map(async (org_id) => {
                        const orgResult = await baseQuery(`/orgs/${org_id}`);
                        console.log('Org Result: ', orgResult)

                        if (orgResult?.error) {
                            return { error: orgResult?.error }
                        } else {
                            return orgResult?.data?.data
                        }
                    }))
                })
            },
            providesTags: (result, error, id) => ([{ type: 'Orgs', id }])
        }),
        getProvidersSidebar: builder.query({
            queryFn: async (user, api, options, baseQuery) => {
                switch(user?.permissions) {
                    case 'PATIENT': {
                        return await baseQuery(`/patients/${user?.alacrity_id}/doctors`).then((result) => {
                            console.log('Doctors for patients result: ', result)
                            if (result?.error) {
                                return { error: result?.error }
                            } else {
                                return { data: result?.data }
                            }
                        })
                    }
                    case 'DOCTOR': {
                        const orgs = await baseQuery(`/orgs/${user?.alacrity_id}`).then((result) => result?.data?.data)
                        return await Promise.all(orgs.map(async (org) => {
                            if (org?.doctors) {
                                return Promise.all(org?.doctors.map(async (provider_id) => {
                                    return baseQuery(`/users/${provider_id}`).then((result) => {
                                        return result?.data?.data
                                    })
                                })).then((result) => {  
                                    console.log('Provider orgs result: ', result)
                                    return result
                                })
                            } else {
                                return []
                            }
                        })).then((result) => { 
                            console.log('Provider Result: ', result.flat())
                            return { data: result.flat() } 
                        })
                    }
                    case 'PHARMA': {
                        // TODO: Get all doctors for trials for pharma user
                        if (user?.trials) {
                            return await Promise.all(user?.trials.flatMap(async (trial) => {
                                return baseQuery(`/trials/${trial?.trial_id}/doctors`).then((result) => ({ data: result?.data }))
                            }))
                        } else {
                            return { data: [] }
                        }
                    }
                    case 'ALACRITY': {
                        return await baseQuery('/doctors').then((result) => { 
                            console.log('Alacrity doctors: ', result)
                            return {
                                data: result?.data?.data 
                            }
                        })
                    } 
                    default: return []
                }
            }
        })
    })
})



export const { 
    useGetPatientsQuery,
    useGetPatientQuery, 
    useGetPatientsWithDataQuery,
    useUpdatePatientInfoMutation, 
    useGetPatientsForTrialQuery,
    useAddPatientToDoctorMutation,
    useAddPatientToTrialMutation,
    useGetPatientsForDoctorQuery,
    useAddPatientToOrgMutation,

    useGetTrialsForPatientQuery,
    useGetTrialsQuery, 
    useGetTrialQuery, 
    useCreateTrialMutation, 
    useGetTrialsForDoctorQuery,
    useUpdateTrialInfoMutation,
    useAddTrialToOrgMutation,
    useGetTrialsForOrgQuery,
    useGetTrialsForPharmaQuery,
    useAddPharmaToTrialMutation,
    
    useTestBounceBackMutation,
    
    useAddDoctorToTrialMutation,
    useGetDoctorQuery,
    useGetDoctorsQuery,
    useUpdateDoctorInfoMutation,
    useAddProviderToOrgMutation,
    useGetProvidersSidebarQuery,
    useGetDoctorsForTrialQuery,
    useGetDoctorsForPatientQuery,

    useGetOrgsQuery,
    useGetAllOrgsQuery,
    useGetOrgQuery,
    useGetOrgsForTrialQuery,
    useGetOrgsForPharmaQuery,
    useCreateOrgMutation,
    useUpdateOrgsMutation,
    useDeleteOrgMutation,
    useGetOrgsForUserQuery,
    useUpdateOrgInfoMutation,
    useAddPharmaToOrgMutation,

    useGetPharmaQuery,
    useUpdatePharmaInfoMutation,

    useGetUsersListQuery,
} = apiSlice;