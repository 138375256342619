import { Box, Container, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useAsyncError, useLocation, useNavigate, useRouteError } from 'react-router';
import { toast } from 'react-toastify';

const GenericError = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const error = useRouteError();
	const asyncError = useAsyncError();

	const status = location?.state?.error?.status ?? error?.status ?? asyncError?.status
	const message = location?.state?.error?.message ?? error?.message ?? asyncError?.data?.message

	useEffect(() => {
		if (status === 403 && message === 'forbidden') {
			console.log("Forbidden!")
			toast.error("Your session has expired.", { position: 'top-center', autoClose: 1000, toastId: 'token_expired' })
			navigate('/login')
		}
  	}, [status, message])


	return (
		<Stack
			sx={{
				backgroundColor: '#ece9e9',
				mt: '2rem',
				py: '3rem',
				mx: '5%',
				px: '2rem',
				// height: '15rem',
				// display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
			spacing={3}
		>
			<Typography
				variant='h1'
				sx={{ fontWeight: 500 }}
			>
				{`Error: ${status}`}
			</Typography>

			<Typography
				variant='subtitle1'
				sx={{ 
					color: '#1f1e1e', 
					fontWeight: 500,
					
				}}
			>
				{message}
			</Typography>
		</Stack>
	);
}



export default GenericError;