import * as React from "react";
const SvgMedicalAlertSmall = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <g fill="#fff" fillOpacity={0.7} clipPath="url(#medicalAlert_small_svg__a)">
      <path d="M10.5 30h9V19.5h3V24h3v-4.5H30v-9H19.5V0h-9v10.5H0v9h10.5zm12-16.5h3v6h-3z" />
      <path d="M22.5 27h3v3h-3z" />
    </g>
    <defs>
      <clipPath id="medicalAlert_small_svg__a">
        <path fill="#fff" d="M0 0h30v30H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMedicalAlertSmall;

