import { configureStore, combineReducers } from '@reduxjs/toolkit';
import historyReducer from './common/redux/historySlice';
import windowReducer from './common/redux/windowSlice';
import graphReducer from './common/redux/graphSlice';
import sidebarReducer from './common/redux/sidebarSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1';
import { apiSlice } from './common/redux/api/api';
import userReducer from './common/redux/userSlice';
import authReducer from './common/redux/authSlice';
import alertsReducer from './common/redux/alertsSlice'
import { authApi } from './common/redux/api/auth/authApi';
import { userApi } from './common/redux/api/userApi';



const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['history', 'user', 'sidebar'],
    stateReconciler: autoMergeLevel1
}


  
const rootReducer = combineReducers({
    history: historyReducer,
    window: windowReducer,
    graph: graphReducer,
    sidebar: sidebarReducer,
    alerts: alertsReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    user: userReducer,
    // auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer
})



const persistedReducer = persistReducer(persistConfig, rootReducer)



export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat([apiSlice.middleware, authApi.middleware, userApi.middleware])
    }
})

export const persistor = persistStore(store)