import { createSlice } from "@reduxjs/toolkit";



export const Entities = ['Patients', 'Trials', 'Orgs', 'Providers']

const initialState = {
    entity: 0,
    entity_id: null,
    alertMode: false,
    extended: false
}



export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: initialState,
    reducers: {
        updateEntityType: (state, action) => {
            state.entity = action.payload
            state.entity_id = initialState.entity_id
        },
        updateCurrentEntity: (state, action) => {
            state.entity_id = action.payload
        },
        toggleExtend: (state) => {
            state.extended = !state.extended
        },
        toggleAlertMode: (state) => {
            state.alertMode = !state.alertMode
        },
        setAlertMode: (state, action) => {
            state.alertMode = action.payload
        }
    }
})

export const { updateEntityType, updateCurrentEntity, toggleAlertMode, toggleExtend, setAlertMode } = sidebarSlice.actions

export default sidebarSlice.reducer



export const selectEntityType = (state) => {
    return state.sidebar.entity
}

export const selectCurrentEntity = (state) => {
    return state.sidebar.entity_id
}

export const selectExtended = (state) => {
    return state.sidebar.extended
}

export const selectAlertMode = (state) => {
    return state.sidebar.alertMode
}

export const selectSidebarState = (state) => {
    return state.sidebar
}