import { addLine, appendLineData, updateGraphDomain, updateLineData } from "../graphSlice";
import { apiSlice } from "./api";

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax'

import { debounce } from 'lodash';

dayjs.extend(isBetween);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(minMax)



const INITIAL_READINGS_LIMIT = 1000;



export const readingsApi = apiSlice
.enhanceEndpoints({
    addTagTypes: ['Vitals', 'Symptoms', 'Labs']
})
.injectEndpoints({
    endpoints: builder => ({
        getAllVitals: builder.query({
            query: (id) => ({
                url: `/vitals/${id}`,
                credentials: 'include'
            }),
        }),
        // getVitalsForSensor: builder.query({
        //     queryFn: async ({ id, sensor, start_ts, graphGroupName, graphIndex, lineIndex }, api, _extraOptions, baseQuery) => {
        //         if (
        //             api.getState()
        //             .api
        //             .queries[`getVitalsForSensor({"id":"${id}","sensor":"${sensor}","start_ts":"${start_ts}"})`]
        //             ?.fulfilledTimeStamp === undefined
        //         ) {
        //             const start_plus_buffer = dayjs(start_ts).subtract(10, 'm');
        //             let promises = [];
        //             let end = dayjs();
        //             let start = end.subtract(10, 'm');
        //             while (start >= start_plus_buffer) {
        //                 promises.push(
        //                     baseQuery(`/vitals/${id}/${sensor}/${start.toISOString()}/${end.toISOString()}`).then((resp) => {
        //                         if (resp?.error) {
        //                             return { error: resp.error };
        //                         }

        //                         if (resp.data.values.length > 0) {
        //                             api.dispatch(updateLineData({
        //                                 graphGroupName,
        //                                 graphIndex,
        //                                 lineIndex,
        //                                 data: resp.data.data.values
        //                             }));
        //                         }

        //                         return { data: null };
        //                     })
        //                 );
        //                 end = start;
        //                 start = end.subtract(10, 'm');
        //             }

        //             const results = await Promise.all(promises);
        //             const error = results.filter((res) => res?.error );
        //             if (error.length > 0) {
        //                 return { error: error[0].error };
        //             } else {
        //                 return { data: null };
        //             }
        //         } else {
        //             const end = dayjs().toISOString();
        //             const start = dayjs().subtract(10, 'm').toISOString();
        //             const result = await baseQuery(`/vitals/${id}/${sensor}/${start}/${end}`).then((resp) => {
        //                 if (resp?.error) {
        //                     return { error: resp.error };
        //                 }

        //                 if (resp.data.data.values.length > 0) {
        //                     api.dispatch(updateLineData({
        //                         graphGroupName,
        //                         graphIndex,
        //                         lineIndex,
        //                         data: resp.data.data.values
        //                     }));
        //                 }

        //                 return { data: null };
        //             });
        //             return result;
        //         }
        //     }
        // }),
        // getVitalsForSensorInRange: builder.query({
        //     queryFn: async ({ id, sensor, start_ts, end_ts, graphGroupName, graphIndex, lineIndex }, api, _extraOptions, baseQuery) => {
        //         if (
        //             api.getState()
        //             .api
        //             .queries[`getVitalsForSensorInRange({"id":"${id}","sensor":"${sensor}","start_ts":"${start_ts}","end_ts":"${end_ts}"})`]
        //             ?.fulfilledTimeStamp === undefined
        //         ) {
        //             const start_plus_buffer = dayjs(start_ts).subtract(10, 'm');
        //             let promises = [];
        //             let end = dayjs(end_ts);
        //             let start = end.subtract(10, 'm');
        //             while (start >= start_plus_buffer) {
        //                 promises.push(
        //                     baseQuery(`/vitals/${id}/${sensor}/${start.toISOString()}/${end.toISOString()}`).then((resp) => {
        //                         if (resp?.error) {
        //                             return { error: resp.error };
        //                         }

        //                         if (resp.data.data.values.length > 0) {
        //                             api.dispatch(updateLineData({
        //                                 graphGroupName,
        //                                 graphIndex,
        //                                 lineIndex,
        //                                 data: resp.data.data.values
        //                             }));
        //                         }

        //                         return { data: null };
        //                     })
        //                 );
        //                 end = start;
        //                 start = end.subtract(10, 'm');
        //             }

        //             const results = await Promise.all(promises);
        //             const error = results.filter((res) => res?.error );
        //             if (error.length > 0) {
        //                 return { error: error[0].error };
        //             } else {
        //                 return { data: null };
        //             }
        //         } else {
        //             const end = dayjs(end_ts).toISOString();
        //             const start = api.getState().graph[graphGroupName][graphIndex].lines[lineIndex].data.at(-1);
        //             // const start = dayjs().subtract(10, 'm').toISOString()

        //             const result = await baseQuery(`/vitals/${id}/${sensor}/${start}/${end}`).then((resp) => {
        //                 if (resp?.error) {
        //                     return { error: resp.error };
        //                 }

        //                 if (resp.data.data.values.length > 0) {
        //                     api.dispatch(appendLineData({
        //                         graphGroupName,
        //                         graphIndex,
        //                         lineIndex,
        //                         data: resp.data.data.values
        //                     }));
        //                 }

        //                 return { data: null };
        //             });
        //             return result;
        //         }
        //     }
        // }),
        // getVitalsForGraphInDomain: builder.query({
        //     providesTags: ['Vitals'],
        //     queryFn: debounce(async ({ id, graphGroupName, graphIndex }, api, _extraOptions, baseQuery) => {
        //         const graph = api.getState().graph[graphGroupName][graphIndex];
        //         const { from, to } = graph.domain;
        //         const fromTS = dayjs(from).toISOString();
        //         const toTS = dayjs(to).toISOString();

        //         const promises = [];

        //         for (const line of graph.lines) {
        //             promises.push(
        //                 baseQuery(`/vitals/${id}/${line.vitalSign}/${fromTS}/${toTS}`).then((resp) => {
        //                     if (resp?.error) {
        //                         return { error: resp.error };
        //                     }
        //                     console.log('Vitals: ', resp)
        
        //                     api.dispatch(updateLineData({
        //                         graphGroupName,
        //                         graphIndex,
        //                         data: resp?.data?.data?.values ?? [],
        //                         sensor: line.vitalSign
        //                     }));
        
        //                     return { data: resp?.data?.message };
        //                 })
        //             );
        //         }

        //         const results = await Promise.all(promises);
        //         const error = results.filter((res) => res?.error );
        //         if (error.length > 0) {
        //             return { error: error[0].error };
        //         } else {
        //             return { data: null };
        //         }
        //     }, 5000, { leading: true, trailing: true })
        // }),
        // updateGraphDomain: builder.mutation({
        //     invalidatesTags: ['Vitals'],
        //     queryFn: async ({ graphGroupName, graphIndex, domain }, api, _extraOptions, baseQuery) => {
        //         api.dispatch(updateGraphDomain({
        //             graphGroupName,
        //             graphIndex,
        //             domain
        //         }));

        //         return { data: null };
        //     }
        // }),
        // addLine: builder.mutation({
        //     invalidatesTags: ['Vitals'],
        //     queryFn: async ({ graphGroupName, graphIndex, line }, api, _extraOptions, baseQuery) => {
        //         api.dispatch(addLine({
        //             graphGroupName,
        //             graphIndex,
        //             line
        //         }));

        //         return { data: null };
        //     }
        // }),
        // getCurrentGraphData: builder.query({
        //     queryFn: async ({ id, graphGroupName, graphIndex }, api, _extraOptions, baseQuery) => {
        //         const graph = api.getState().graph[graphGroupName][graphIndex];
        //         const currentEndTS = dayjs(graph.domain.to);
        //         const currentStartTS = dayjs(graph.domain.from);

        //         const newEndTS = currentEndTS.add(10, 'minutes').toISOString();
        //         const newStartTS = currentStartTS.add(10, 'minutes').toISOString();

        //         api.dispatch(updateGraphDomain({
        //             graphGroupName,
        //             graphIndex,
        //             domain: {
        //                 from: newStartTS,
        //                 to: newEndTS
        //             }
        //         }));

        //         const lastDataPointTS = (graph.data.length > 0) ? dayjs(graph.data.at(-1).ts).toISOString() : currentEndTS.toISOString();

        //         const promises = [];

        //         for (const line of graph.lines) {
        //             promises.push(
        //                 baseQuery(`/vitals/${id}/${line.vitalSign}/${lastDataPointTS}/${newEndTS}`).then((resp) => {
        //                     if (resp?.error) {
        //                         return { error: resp.error };
        //                     }

        //                     if (resp?.data?.data?.values?.length > 0) {
        //                         api.dispatch(appendLineData({
        //                             graphGroupName,
        //                             graphIndex,
        //                             data: resp.data.data.values,
        //                             sensor: line.vitalSign
        //                         }));
        //                     }

        //                     return { data: null };
        //                 })
        //             );
        //         }

        //         const results = await Promise.all(promises);
        //         const error = results.filter((res) => res?.error );
        //         if (error.length > 0) {
        //             return { error: error[0].error };
        //         } else {
        //             return { data: null };
        //         }
        //     }
        // }),
        getVitals: builder.query({
            query: ({ patient_id, start_ts, end_ts, sensor }) => `/vitals/${patient_id}/${sensor}/${start_ts}/${end_ts}`,
            providesTags: ['Vitals'],
            transformResponse: (result) => result?.data?.data
        }),
        getPatientVitalsForSensorsInRange: builder.query({
            queryFn: async ({ sensors, patient_id, start_ts, end_ts }, api, _options, baseQuery) => {
                const data = await Promise.all(sensors.map(async (sensor) => {
                    return baseQuery(`/vitals/${patient_id}/${sensor}/${start_ts}/${end_ts}`).then((result) => {
                        if (result?.error) {
                            return { error: result?.error}
                        }
                        return { name: sensor, data: result?.data?.data?.values ?? [] }
                    })
                })).then((sensorsData) => {
                    if (sensorsData.some((result) => result?.error)) {
                        return { error: sensorsData }
                    }

                    const timestampMap = sensorsData.reduce((acc, sensorObject) => {
                        sensorObject.data.forEach((datum) => {
                            const unixTimestamp = dayjs(datum.ts).valueOf()

                            if (Object.hasOwn(acc, unixTimestamp)) {
                                if (Object.hasOwn(acc[unixTimestamp], sensorObject.name)) {
                                    const currentValue = acc[unixTimestamp][sensorObject.name]
                                    if (Array.isArray(currentValue)) {
                                        acc[unixTimestamp][sensorObject.name] = [...currentValue, datum.value]
                                    } else {
                                        acc[unixTimestamp][sensorObject.name] = [currentValue, datum.value]
                                    }
                                } else {
                                    acc[unixTimestamp][sensorObject.name] = datum.value
                                }
                            } else {
                                acc[unixTimestamp] = { [sensorObject.name]: datum.value }
                            }
                        })

                        return acc
                    }, {})

                    return Object.keys(timestampMap).toSorted().map((timestamp) => ({
                        ts: Number(timestamp),
                        ...timestampMap[timestamp]
                    }))
                });

                if (data?.error) {
                    return data
                }

                return { data }
            }
        }),
        getLabsForPatient: builder.query({
            query: (id) =>  `/labs/${id}`
        }),
        getLabForPatient: builder.query({
            query: ({ id, lab }) => ({
                url: `/vitals/${id}/${lab}`,
            })
        }),
        getLabsByID: builder.query({
            query: ({patient_id, lab_id}) => `/labs/${patient_id}/${lab_id}`
        }),
        getLabsForPatientInRange: builder.query({
            query: ({ patient_id, start, end }) => `/vitals/${patient_id}/labs/${start}/${end}`,
            providesTags: (result, error, args) => ([{ type: 'Labs', id: args.patient_id }]),
            transformResponse: (result) => result?.data
        }),
        getAllSymptomsForPatient: builder.query({
            query: (id) => `/symptoms/patient_id=${id}`,
            providesTags: ['Symptoms']
        }),
        getSymptomsForPatientInRange: builder.query({
            query: ({ patient_id, start, end }) => `/symptoms/patient_id=${patient_id}&start_ts=${start}&end_ts=${end}`,
            providesTags: ['Symptoms'],
            transformResponse: (result) => result?.data
            // providesTags: (result, error, arg) => {
            //     return result ?
            //     result.data.data.symptoms.map((symptom) => ({ type: 'Symptoms', id: symptom.id })) :
            //     ['Symptoms'];
            // }
        }),
        postNewSymptom: builder.mutation({
            query: ({ symptom_name, ts, severity, alacrity_id }) => ({
                url: `/symptoms`,
                method: 'POST',
                body: {
                    name: symptom_name,
                    ts,
                    severity,
                    alacrity_id
                }
            }),
            invalidatesTags: ['Symptoms']
        }),
        startPROMISQuestionnaire: builder.mutation({
            query: ({ assessment_id }) => ({
                url: `/symptoms/promis`,
                method: 'POST',
                body: {
                    assessment_id
                }
            }),
            invalidatesTags: ['Symptoms']
        }),
        answerPROMISQuestionnaire: builder.mutation({
            query: ({ assessment_id, response_id, response_value }) => ({
                url: `/symptoms/promis`,
                method: 'POST',
                body: {
                    assessment_id,
                    response_id,
                    response_value
                }
            }),
            invalidatesTags: ['Symptoms']
        }),
        getActivePromiseAssessments: builder.query({
            query: () => `/symptoms/promis`,
            transformResponse: (result) => result?.data,
            providesTags: ['Symptoms']
        }),
        getPromisResult: builder.query({
            query: (assessment_id) => `/symptoms/promis/${assessment_id}`,
            transformResponse: (result) => result?.data
        })
    })
});



export const { 
    useGetAllVitalsQuery, 
    // useGetVitalsForSensorQuery,

    useGetLabsForPatientQuery,
    useGetLabForPatientQuery,
    useGetLabsForPatientInRangeQuery,

    // useUpdateGraphDomainMutation,
    // useGetCurrentGraphDataQuery,
    // useGetVitalsForGraphInDomainQuery,
    useGetPatientVitalsForSensorsInRangeQuery,

    useGetAllSymptomsForPatientQuery,
    useGetSymptomsForPatientInRangeQuery,
    useGetActivePromiseAssessmentsQuery,
    useStartPROMISQuestionnaireMutation,
    usePostNewSymptomMutation,
    useAnswerPROMISQuestionnaireMutation,
    useGetPromisResultQuery,

    // useAddLineMutation,
    useLazyGetLabsByIDQuery
} = readingsApi;