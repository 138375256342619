import { useTheme, Paper, Stack, Typography } from "@mui/material";
import SvgPatientDataPlaceholder from "../../../images/icons/PatientDataPlaceholder";
import { useSelector } from "react-redux";
import { selectUser } from "../../../common/redux/userSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router";



export default function EmptyPatientPage() {
    const theme = useTheme();
    const user = useSelector(selectUser)
    const navigate = useNavigate()



    useEffect(() => {
        if (user.permissions === 'PATIENT') {
            console.log('Patient User: ',user)
            navigate(`/dashboard/patient/${user.alacrity_id}`)
        }
    }, [user, navigate])



    return (
        <Paper
            sx={{
                height: '100%',
                width: '100%',
                borderRadius: 0
            }}
            elevation={8}
        >
            <Stack
                sx={{
                    height: '100%',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                spacing={2} 
            >
                <SvgPatientDataPlaceholder style={{ height: 180, width: 360 }} />
                <Typography
                    variant='h2'
                    color='textSecondary'
                >
                    Select a patient's name to view their data.
                </Typography>
            </Stack>
        </Paper>
    )
}