import { useDispatch } from "react-redux";
import { alertsApi, useBuildNotificationMutation, useGetAllSensorsQuery, useGetNotificationRulesQuery, useUpdateNotificationMutation } from "../../../common/redux/api/alertsApi";
import { useCallback, useEffect, useMemo, useState } from "react";
import { sensorsToNames } from "../../../common/sensors";
import * as z from 'zod';
import { Controller, FormProvider, useFieldArray, useForm, useFormContext, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Checkbox, CircularProgress, Container, Divider, FormControl, FormControlLabel, IconButton, InputLabel, LinearProgress, MenuItem, Paper, Select, Skeleton, Stack, TextField, Typography, useTheme } from "@mui/material";
import { AddSharp, CheckSharp, CloseSharp, DeleteSharp, HeightSharp, NotificationAddSharp, OpenWith, SwapHorizSharp } from "@mui/icons-material";
import FormInput from "../../../components/CustomFormInput";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useRTKResultToasts } from "../../../common/RTKResultHooks";
import { generateMaterialColor } from "../../../common/RandomColor";
import { LoadingButton } from "@mui/lab";
import { useIsQueryLoading, useStrictDroppable } from "../../../common/util";
import { useSelector } from "react-redux";
import { GenericModal } from "../../../components/modals/GenericModal";



const comparatorEnum = z.enum(["GT", "NGT", "LT", "NLT"]);
const combinatorEnum = z.enum(['AND', 'OR']);

const initialRuleState = {
    sensor: 'ecg-heartrate',
    not: false,
    comparator: comparatorEnum.Enum.GT,
    constant: 0,
    id: ''
}

const initialState = {
    outer: {
        inner: [
            {...initialRuleState}
        ],
        id: ''
    },
    combinator: combinatorEnum.Enum.AND
}



export default function RulesEditor({ patient, loading, ruleID, rule, onCancelEditing }) {
    const dispatch = useDispatch();
    const theme = useTheme()

    const [ AND_OR, setAND_OR ] = useState(combinatorEnum.Enum.AND)

    // const [ editRule, setEditRule ] = useState(null)

    useEffect(() => {
        if (rule !== null) {
            const { combinator, schema } = convertJSONToSchema(rule)
            // console.log('Schema: ', schema)
            // setEditRule(schema)
            setAND_OR((combinator === 'AND') ? combinatorEnum.Enum.AND : combinatorEnum.Enum.OR)
        } else {
            // setEditRule(null)
            setAND_OR(combinatorEnum.Enum.AND)
        }
    }, [rule, setAND_OR])


    return (
        <Stack
            sx={{
                height: '50%',
                width: '100%',
                p: 2,
                justifyContent: 'start',
                alignItems: 'start',
                borderRadius: 4,
                border: 'none',
                boxShadow: theme.alacrityShadowBorder //'rgba(255, 255, 255, 0.5) 0px 0px 4px 2px',
            }}
            spacing={2}
        >
            <Stack
                direction='row'
                spacing={2}
                sx={{
                    width: '100%',
                    justifyContent: 'start',
                    alignItems: 'center',
                }}
            >
                <SectionTitle>
                    Set a new alert
                </SectionTitle>

                <NotificationAddSharp style={{ marginRight: 'auto' }} />

                {
                    ruleID &&
                    <LoadingButton
                        color="error"
                        sx={{
                            m: 0,
                            backgroundColor: 'white',
                            backgroundImage: 'linear-gradient(rgba(255, 0, 0, 0.3), rgba(255, 0, 0, 0.3))'
                        }}
                        startIcon={<CloseSharp/>}
                        onClick={onCancelEditing}
                    >
                        Stop Edit
                    </LoadingButton>
                }

                <LoadingButton
                    sx={{
                        backgroundColor: 'white',
                        backgroundImage: 'linear-gradient(rgba(84, 174, 236, 0.5), rgba(84, 174, 236, 0.5))',
                        color: '#041b40',
                        m: 0,
                    }}
                    endIcon={<SwapHorizSharp/>}
                    onClick={() => { setAND_OR((prev) => (prev === combinatorEnum.Enum.AND) ? combinatorEnum.Enum.OR : combinatorEnum.Enum.AND) }}
                >
                    {AND_OR === combinatorEnum.Enum.AND ? 'AND|OR' : 'OR|AND'}
                </LoadingButton>
            </Stack>

            {
                (loading) ?
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                :
                    <Divider width='100%'/>
            }

            {
                loading ?
                    <Stack
                        sx={{
                            width: '100%',
                            height: '100%'
                        }}
                        spacing={4}
                    >
                        {
                            Array(3).fill(0).map(() => {
                                return <Skeleton variant="rounded" width='100%' height='4rem' />
                            })
                        }
                    </Stack>
                :
                    <Box
                        display='flex'
                        width='100%'
                        height='84%'
                        flexDirection='column'
                        alignItems='center'
                        component='form'
                        noValidate
                        autoComplete='off'
                    >
                        <RuleListOuter2 
                            loading={loading} 
                            patient={patient} 
                            combineAND={AND_OR} 
                            ruleID={ruleID}
                            rule={(rule === null) ? null : convertJSONToSchema(rule).schema}
                            onCancelEditing={onCancelEditing}
                        />  
                    </Box>
            }
        </Stack>
    )
}



const checkNotificationSchema = async ({ json, dispatch, alacrity_id }) => {
    const rule_id_array = await dispatch(alertsApi.endpoints.validateNotification.initiate({ 
        id: alacrity_id,
        type: 'PATIENT',
        rules: json
    }));

    const currentRules = await dispatch(alertsApi.endpoints.getNotificationRules.initiate(alacrity_id))

    
    console.log('Current Rules: ', currentRules)
    if (Array.isArray(currentRules?.data) && Array.isArray(rule_id_array)) {
        if (currentRules?.data.some((rule) => rule?.rule_id === rule_id_array.at(0))) {
            return false
        }
    }

    return true
    // if (currentRules.data?.includes(rule_id_array[0])) {
    //     return false
    // } else {
    //     return true
    // }
}



const convertSchemaToJSON = (schema) => {
    const outerCombinator = schema.combinator;
    const innerCombinator = (outerCombinator === 'AND') ? 'OR' : 'AND';

    return {
        [outerCombinator]: schema.outer.map((inner) => {
            return {
                [innerCombinator]: inner.inner.map((rule) => {
                    let jsonRule = {
                        name: rule.sensor,
                        comparator: (rule.comparator === comparatorEnum.Enum.GT) ? '>' : '<',
                        value: rule.constant
                    }

                    if (rule.not) {
                        jsonRule = {
                            NOT: {...jsonRule}
                        }
                    }

                    return jsonRule
                })
            }
        })
    }
}


const convertJSONToSchema = (json) => {
    const combinator = Object.keys(json)[0]

    const schema = {
        outer: json[combinator].map((inner) => {
            const innerCombinator = Object.keys(inner)[0]
            return {
                inner: inner[innerCombinator].map((rule) => {
                    let ruleObject = rule
                    let not = false
                    if (Object.hasOwn('NOT')) {
                        not = true
                        ruleObject = rule.NOT
                    }

                    return {
                        sensor: ruleObject.name,
                        not: not,
                        comparator: (ruleObject.comparator === '>') ? (not) ? comparatorEnum.Enum.NGT : comparatorEnum.Enum.GT : (not) ? comparatorEnum.Enum.NLT : comparatorEnum.Enum.LT,
                        constant: ruleObject.value,
                        id: crypto.randomUUID()
                    }
                }),
                id: crypto.randomUUID()
            }
        })
    }

    return { combinator, schema }
}



export const SectionTitle = ({ children }) => {
    return (
        <Typography
            variant="h4"
            fontWeight={600}
        >
            {children}
        </Typography>
    )
}



const RuleListOuter2 = ({ loading, patient, combineAND, ruleID, rule, onCancelEditing }) => {
    const dispatch = useDispatch();
    const [ enabled ] = useStrictDroppable(loading);

    const [ openWarningModal, setOpenWarningModal ] = useState(false);
    const [ openEditingModal, setOpenEditingModal ] = useState(false);


    const {
        data: currentRules,
        isSuccess: areCurrentRulesSuccess,
        isFetching: areCurrentRulesFetching,
        isLoading: areCurrentRulesLoading,
        isError: areCurrentRulesError,
        error: currentRulesError
    } = useGetNotificationRulesQuery(patient?.alacrity_id, { skip: loading })

    const [ postNotification, result ] = useBuildNotificationMutation();
    const { isLoading, isSuccess, isError, error } = result;

    useEffect(() => {
        if (isSuccess) {
            setOpenWarningModal(false)
        }
    }, [isSuccess])


    const [ updateNotification, updateResult ] = useUpdateNotificationMutation()
    const { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, isError: isUpdateError, error: updateError } = updateResult;

    useRTKResultToasts({
        // isSuccess: isUpdateSuccess,
        // successMessage: 'Update Rule!',
        isError: isUpdateError,
        error: updateError,
        onSuccess: () => { setOpenEditingModal(false); onCancelEditing() }
    })



    const [ rulesState, setRulesState ] = useState(
        {
            outer: [
                {
                    inner: [
                        {
                            ...initialRuleState,
                            id: crypto.randomUUID()
                        }
                    ],
                    id: crypto.randomUUID()
                }
            ],
            combinator: combineAND
        }
    )

    const [ editingRuleID, setEditingRuleID ] = useState(null)

    useEffect(() => {
        if (ruleID !== editingRuleID) {
            console.log('New Edit Rule: ', rule)
            setRulesState(
                (rule === null) ?  
                    {
                        outer: [
                            {
                                inner: [
                                    {
                                        ...initialRuleState,
                                        id: crypto.randomUUID()
                                    }
                                ],
                                id: crypto.randomUUID()
                            }
                        ],
                        combinator: combineAND
                    }
                :
                    rule
            )
            setEditingRuleID(ruleID)
        }
    }, [setRulesState, ruleID, rule, editingRuleID, setEditingRuleID, combineAND])



    const makeAppend = (index) =>
        () => {
            setRulesState((prev) => {
                const newOuter = [...prev.outer]
                newOuter.splice(index + 1, 0, 
                    {
                        inner: [{...initialRuleState, id: crypto.randomUUID()}],
                        id: crypto.randomUUID()
                    }
                )
                return { combinator: prev.combinator, outer: newOuter }
            })
        } 


    const makeUpdate = (index) => 
        (newInner) => {
            if (newInner.inner.length === 0) {
                if (rulesState.outer.length === 1) {
                    return
                } else {
                    setRulesState((prev) => {
                        const newOuter = [...prev.outer]
                        newOuter.splice(index, 1)
                        return {combinator: prev.combinator, outer: newOuter}
                    })
                }
            } else {
                setRulesState((prev) => {
                    const newOuter = [...prev.outer]
                    newOuter.splice(index, 1, newInner)
                    return { combinator: prev.combinator, outer: newOuter }
                })
            }
        }



    const makeRemove = (index) => 
        () => {
            if (
                rulesState.outer.length > 1
            ) {
                setRulesState((prev) => {
                    const newOuter = [...prev.outer]
                    newOuter.splice(index, 1)
                    return {combinator: prev.combinator, outer: newOuter}
                })
            }
        }

    
        
    const onDragEnd = useCallback((result) => {
        if (!result?.destination) {
            return 
        }

        if (result?.source?.droppableId === result?.destination?.droppableId && result?.source?.index === result?.destination?.index) { 
            return 
        }

        if (rulesState.outer.some((fieldArray) => { return fieldArray?.id === result?.draggableId })) {     // Check if draggableId is an outer id or an inner one.
            setRulesState((prev) => {
                const newState = { combinator: prev.combinator, outer: prev.outer }
                const arrayToMove = newState.outer.splice(result?.source?.index, 1)[0]
                newState.outer.splice(result?.destination?.index, 0, arrayToMove)
                return newState
            })

        } else {
            setRulesState((prev) => {
                const newState = { combinator: prev.combinator, outer: [...prev.outer] }
                const sourceArray =  newState.outer.filter((fieldArray) => fieldArray.id === result?.source?.droppableId)[0].inner
                const destinationArray = newState.outer.filter((fieldArray) => fieldArray.id === result?.destination?.droppableId)[0].inner

                const ruleToMove = sourceArray.splice(result?.source?.index, 1)[0]
                destinationArray.splice(result?.destination?.index, 0, ruleToMove)

                return newState
            })
        }
    }, [rulesState])



    const onSubmit = async () => {
        const json = convertSchemaToJSON(rulesState)

        if (editingRuleID !== null) {
            setOpenEditingModal(true)
        } else {
            const isValid = await checkNotificationSchema({ json, dispatch, alacrity_id: patient.alacrity_id })

            console.log('Current Rules: ', currentRules)

            if (isValid) {
                postNotification({
                    id: patient.alacrity_id,
                    type: 'PATIENT',
                    rules: json
                })
            } else {
                setOpenWarningModal(true)
            }
        }
    }



    return (
        <>
            <Stack
                sx={{
                    width: '100%',
                    overflowY: 'auto',
                    overflowX: 'clip',
                    alignItems: 'center',
                    '::-webkit-scrollbar': {
                        '-webkit-appearance': 'none',
                        width: '7px'
                    },  
                    '::-webkit-scrollbar-thumb': {
                        borderRadius: '4px',
                        backgroundColor: 'rgba(0, 0, 0, .5)',
                      }
                }}
                spacing={8}
            >
                <DragDropContext onDragEnd={onDragEnd}>
                    {
                        enabled &&
                        <Droppable droppableId="outer" type="outer">
                            {(provider, snapshot) => (
                                <Stack
                                    sx={{
                                        width: '100%'
                                    }}
                                    ref={provider.innerRef}
                                    {...provider.droppableProps}
                                    spacing={2}
                                >
                                    {
                                        rulesState.outer.map((ruleArray, index) => {
                                            return (
                                                <RuleListInner2
                                                    loading={loading} 
                                                    remove={makeRemove(index)}
                                                    append={makeAppend(index)}
                                                    update={makeUpdate(index)} 
                                                    index={index} 
                                                    state={ruleArray}
                                                    combinator={combineAND}
                                                    key={ruleArray.id}
                                                    showCombinator={index !== rulesState.outer.length - 1}
                                                />
                                            )
                                        })
                                    }
                                    {provider.placeholder}
                                </Stack>
                            )}
                        </Droppable>
                    }
                </DragDropContext>

                <LoadingButton
                    loading={isLoading}
                    onClick={onSubmit}
                    variant='contained'
                    sx={{
                        py: 1,
                        width: 'max-content',
                        fontWeight: 700,
                        py: 1.5
                    }}
                    startIcon={<AddSharp/>}
                >
                    Set Alert
                </LoadingButton>

            </Stack>

            <GenericModal
                open={openWarningModal}
            >
                <Typography
                    variant="h4"
                    color='textSecondary'
                    sx={{
                        maxWidth: '80%',
                        textAlign: 'center'
                    }}
                >
                    This rule set already exists (Most likely with different values). Are you sure you would like to overwrite the previous one?
                </Typography>

                <Divider sx={{ height: '8rem' }} />

                <Stack
                    direction='row'
                    sx={{
                        width:'100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    spacing={4}
                >
                    <LoadingButton
                        variant="contained"
                        color="error"
                        startIcon={<CloseSharp/>}
                        onClick={() => {setOpenWarningModal(false)}}
                    >
                        Cancel
                    </LoadingButton>

                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        color='primary'
                        endIcon={<CheckSharp/>}
                        onClick={() => { postNotification({ id: patient.alacrity_id, type: 'PATIENT', rules: convertSchemaToJSON(rulesState) }) }}
                    >
                        Submit
                    </LoadingButton>
                </Stack>
            </GenericModal>

            <GenericModal
                open={openEditingModal}
            >
                <Typography
                    variant="h4"
                    color='textSecondary'
                    sx={{
                        maxWidth: '80%',
                        textAlign: 'center'
                    }}
                >
                    You are currently editing a rule. Are you sure you want to overwrite it?
                </Typography>

                <Divider sx={{ height: '8rem' }} />

                <Stack
                    direction='row'
                    sx={{
                        width:'100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    spacing={4}
                >
                    <LoadingButton
                        variant="contained"
                        color="error"
                        startIcon={<CloseSharp/>}
                        onClick={() => {setOpenEditingModal(false); }}
                    >
                        Cancel
                    </LoadingButton>

                    <LoadingButton
                        loading={isUpdateLoading}
                        variant="contained"
                        color='primary'
                        endIcon={<CheckSharp/>}
                        onClick={() => { 
                            updateNotification({ 
                                entity_id: patient.alacrity_id, 
                                rule_id: editingRuleID, 
                                rules: convertSchemaToJSON(rulesState) 
                            })
                        }}
                    >
                        Submit
                    </LoadingButton>
                </Stack>
            </GenericModal>
        </>
    )
}



const RuleListInner2 = ({
    loading,
    append: appendOuter,
    remove: removeOuter,
    update: updateOuter,
    state,
    combinator,
    index,
    showCombinator
}) => {
    const theme = useTheme();
    const [ enabled ] = useStrictDroppable(loading)



    const makeAppendInner = (index) => 
        () => {
            const newInner = [...state.inner]
            newInner.splice(index + 1, 0, {...initialRuleState, id: crypto.randomUUID()})

            updateOuter({
                id: state.id,
                inner: newInner
            })
        }
    


    const makeRemoveInner = (index) => 
        () => {
            const newInner = [...state.inner]
            newInner.splice(index, 1)

            updateOuter({
                id: state.id,
                inner: newInner
            })
        }



    const makeUpdateInner = (index) =>
        (ruleField) => {
            const newInner = [...state.inner]
            newInner[index] = {...newInner[index], ...ruleField}

            updateOuter({
                id: state.id,
                inner: newInner
            })
        }



    return (
        <Draggable draggableId={state.id} index={index} key={state.id}>
            {(provided, snapshot) => (
                <Stack
                    sx={{
                        width: '100%',
                    }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >

                    <Stack
                        direction='row'
                        sx={{
                            width: '100%',
                            alignItems: 'center'
                        }}
                        spacing={1}
                    >

                        <Box
                            sx={{
                                height: '100%',
                                width: '4px',
                                borderRadius: 4,
                                backgroundColor: theme.palette.primary.main
                            }}
                        />

                        <Stack      // Top Bar of Inner List Group
                            sx={{
                                height: '100%',
                                width: '100%',
                                alignItems: 'start',
                                justifyContent: 'start'
                            }}
                            spacing={1}
                        >

                            <Paper
                                elevation={20}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    py: 0.5
                                }}
                                {...provided.dragHandleProps}
                            >
                                <OpenWith 
                                    style={{ 
                                        borderRadius: 4,
                                    }} 
                                />
                            </Paper>
                            
                            {
                                enabled &&
                                <Droppable droppableId={state.id} key={state.id} type="inner">
                                    {(provider, snapshot) => (
                                        <Stack
                                            sx={{
                                                width: '100%',
                                                height: '100%'
                                            }}
                                            ref={provider.innerRef}
                                            {...provider.droppableProps}
                                            spacing={1}
                                        >

                                            {
                                                state.inner.map((field, index) => {
                                                    return (
                                                        <RuleListItem2 
                                                            remove={makeRemoveInner(index)} 
                                                            update={makeUpdateInner(index)}
                                                            append={makeAppendInner(index)}  
                                                            state={field}
                                                            combinator={combinator}
                                                            id={field.id} 
                                                            index={index}
                                                            key={field.id}
                                                        />
                                                    )
                                                })
                                            }
                                            {provider.placeholder}
                                        </Stack>
                                    )}
                                </Droppable>
                            }
                        </Stack>

                        <IconButton
                            color='info'
                            size="large"
                            onClick={removeOuter}
                            sx={{ 
                                height: '100%', 
                                borderRadius: 2,
                                '&:hover': {
                                    backgroundColor: theme.palette.error.light,
                                }
                            }}
                        >
                            <CloseSharp 
                                style={{ 
                                    color: theme.palette.text.secondary ,
                                }} 
                                sx={{
                                    '& :hover': {
                                        color: 'red'
                                    }
                                }}
                            />
                        </IconButton>
                    </Stack>

                    {
                        (showCombinator) &&
                        <Stack
                            direction='row'
                            spacing={1}
                            sx={{
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Divider width='100%' />

                            <Typography
                                variant="subtitle1"
                                sx={{
                                    color: theme.palette.primary.light,
                                    textAlign: 'center'
                                }}
                            >
                                {(combinator === combinatorEnum.Enum.AND) ? 'AND' : 'OR'}    
                            </Typography>  

                            <Divider width='100%' /> 
                        </Stack>
                    }

                    <IconButton
                        color="info"
                        sx={{
                            width: '100%',
                            borderRadius: 2,
                            mt: 1,
                            '&:hover svg': {
                                fontSize: 40
                            },
                            p: 0
                        }}
                        onClick={appendOuter}
                    >
                        <AddSharp sx={{ fontSize: 18 }} />
                    </IconButton>
                </Stack>
            )}
        </Draggable>
    )
}



const RuleListItem2 = ({
    remove,
    update,
    append,
    state,
    combinator,
    id,
    index
}) => {
    const theme = useTheme();



    const {
        data: sensors,
        isLoading: areSensorsLoading,
        isFetching: areSensorsFetching,
        isUninitialized: areSensorsUninitialized,
        isError: areSensorsError,
        error: sensorsError
    } = useGetAllSensorsQuery(null);

    const sensorsAreLoading = useIsQueryLoading([ areSensorsLoading, areSensorsFetching, areSensorsUninitialized ])



    const addRuleAfter = () => {
        append()
    }

    const deleteRule = () => {
        remove()
    }



    const updateCombinator = (newCombinator) => {

    }



    return (
        <Draggable draggableId={id} index={index} key={id}>
            {(provider, snapshot) => (
                <Stack
                    ref={provider.innerRef}
                    {...provider.draggableProps}
                    direction='row'
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'end',
                        width: '100%',
                        height: '100%'
                    }}
                    spacing={1}
                >
                    <Stack
                        {...provider.dragHandleProps}
                        direction='row'
                        alignItems='start'
                        justifyContent='start'
                        sx={{
                            height: '100%',
                        }}
                        spacing={1}
                    >

                        <Box
                            sx={{
                                height: '100%',
                                width: '4px',
                                borderRadius: 4,
                                backgroundColor: '#9164B5' // color
                            }}
                        />

                        <Paper
                            elevation={20}
                            sx={{
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <HeightSharp />
                        </Paper>

                    </Stack>

                    {
                        sensorsAreLoading ?
                            <Box
                                sx={{
                                    position: 'relative',
                                }}
                            >
                                <Select  
                                    variant="standard"
                                    label="Loading..."
                                    sx={{
                                        width: '140px'
                                    }} 
                                    color="info"
                                />
                                <CircularProgress 
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        left: -1,
                                    }}
                                />
                            </Box>
                        :
                            <Select
                                id='sensor-select'
                                onChange={(e) => { update({ sensor: e.target.value })}}
                                value={state.sensor}
                                label="Reading"
                                variant='standard'
                                color='info'
                                required
                                focused='true'
                                sx={{ 
                                    fontSize: 16,
                                    height: '100%',
                                    minWidth: '140px'
                                }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                }}
                            >
                                {
                                    !sensorsAreLoading &&
                                    Object.keys(sensors)?.filter((sensorName => Object.hasOwn(sensorsToNames, sensorName)))?.map((sensor) => (
                                        <MenuItem
                                            sx={{ 
                                                width: '200px', 
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.6)'
                                                } 
                                            }} 
                                            key={sensor} 
                                            value={sensor}
                                        >
                                            {sensorsToNames[sensor]}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                    }

                    <Select
                        id='comparator-select'
                        onChange={(e) => { update({ comparator: e.target.value }) }}
                        value={state.comparator}
                        label="Comparator"
                        variant='standard'
                        color='info'
                        required
                        sx={{ 
                            fontSize: 16,
                            height: '100%',
                            color: theme.palette.secondary.main
                        }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                        }}
                    >    
                        <MenuItem
                            sx={{ 
                                width: '200px', 
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.6)'
                                },
                            }} 
                            key={comparatorEnum.Enum.GT} 
                            value={comparatorEnum.Enum.GT}
                        >
                            is above
                        </MenuItem>

                        <MenuItem
                            sx={{ 
                                width: '200px', 
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.6)'
                                } 
                            }} 
                            key={comparatorEnum.Enum.NGT} 
                            value={comparatorEnum.Enum.NGT}
                        >
                            is not above
                        </MenuItem>

                        <MenuItem
                            sx={{ 
                                width: '200px', 
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.6)'
                                } 
                            }} 
                            key={comparatorEnum.Enum.LT} 
                            value={comparatorEnum.Enum.LT}
                        >
                            is below
                        </MenuItem>

                        <MenuItem
                            sx={{ 
                                width: '200px', 
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.6)'
                                } 
                            }} 
                            key={comparatorEnum.Enum.NLT} 
                            value={comparatorEnum.Enum.NLT}
                        >
                            is not below
                        </MenuItem>

                    </Select>

                    <TextField
                        type='number'
                        required
                        variant='standard'
                        placeholder='0.0'
                        defaultValue='0'
                        step={0.1}
                        value={state.constant}
                        onChange={(e) => { update({ constant: e.target.value }) }}
                        color="info"
                        sx={{
                            maxWidth: '60px',
                            
                        }}
                        inputProps={{
                            min: 0.0,
                            step: '0.1',
                            fontSize: 16,
                            pt: 1,
                            style: {
                                color: theme.palette.warning.light
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: 14,
                            }
                        }}
                        FormHelperTextProps={{
                            sx: {
                                fontSize: 14
                            }
                        }}
                    />

                    <Select
                        id='combinator-select'
                        defaultValue={combinatorEnum.Enum.OR}
                        value={(combinator === combinatorEnum.Enum.AND) ? combinatorEnum.Enum.OR : combinatorEnum.Enum.AND}
                        onChange={updateCombinator}
                        label="Combinator"
                        variant='standard'
                        color='info'
                        required
                        sx={{ 
                            fontSize: 16,
                            height: '100%',
                            color: theme.palette.primary.light,
                            minWidth: '60px'
                        }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                        }}
                    >    
                        {
                            combinatorEnum.options.map((combinator) => {
                                return (
                                    <MenuItem
                                        sx={{ 
                                            width: '100px', 
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, 0.6)'
                                            },
                                        }} 
                                        key={combinator} 
                                        value={combinator}
                                    >
                                        {combinator}
                                    </MenuItem>
                                )
                            })
                        }

                    </Select>

                    <IconButton
                        color='info'
                        onClick={addRuleAfter}
                        sx={{
                            borderRadius: 2,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.light,
                                ' svg': {
                                    fill: 'black'
                                }
                            }
                        }}
                    >
                        <AddSharp 
                            style={{ 
                                color: theme.palette.text.secondary ,
                                '&:hover': {
                                    color: 'transparent'
                                }
                            }} 
                        />
                    </IconButton>

                    <IconButton
                        color='info'
                        onClick={deleteRule}
                        sx={{
                            borderRadius: 2,
                            '&:hover': {
                                backgroundColor: theme.palette.error.light,
                            }
                        }}
                    >
                        <CloseSharp 
                            style={{ 
                                color: theme.palette.text.secondary 
                            }}
                        />
                    </IconButton>

                </Stack>
            )}
        </Draggable>
    )
}
