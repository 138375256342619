import { Stack, Box, Typography } from "@mui/material";



const Home = () => {
    return (
        <Stack
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                backgroundColor: 'white'
            }}
        >
            <Box
                component="img"
                alt="Toxhound Logo"
                src={require("./../images/logos/Toxhound_logo_full_gradient.png")}
            />
            <Typography
                variant='h2'
                textAlign='center'
                color='#565656'
            >
                Welcome to man's best healthcare companion. 
            </Typography>
        </Stack>
        // <iframe 
        //     className={"alacrity-iframe"} 
        //     title={"External Squarespace Site"} 
        //     src="https://www.alacrity.care/"  
        //     width='100%'
        //     height='1200vh'
        // />
    )
};

export default Home;
