import * as React from "react";
const SvgClinicalStaff = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={30}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.7}
      d="M14.818 13.667h-4a9.01 9.01 0 0 0-9 9v3a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3v-3a9.01 9.01 0 0 0-9-9Zm5 10h-1v1a1 1 0 0 1-2 0v-1h-1a1 1 0 0 1 0-2h1v-1a1 1 0 0 1 2 0v1h1a1 1 0 1 1 0 2ZM6.818 3.7v-.034a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3l-12 .034Zm12 1.966v1a6 6 0 0 1-12 0V5.7l12-.034Z"
    />
  </svg>
);
export default SvgClinicalStaff;

