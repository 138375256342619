import { Stack, Typography, useTheme, Box, Switch, Avatar, Skeleton, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { EditSharp, Close, Person, ExpandLessSharp, ExpandMoreSharp, AddSharp, SendSharp } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAddPatientToDoctorMutation, useAddProviderToOrgMutation, useGetDoctorQuery, useGetOrgsForUserQuery, useGetOrgsQuery, useGetPatientsForDoctorQuery, useGetPatientsQuery, useUpdateDoctorInfoMutation } from "../../../common/redux/api/api";
import { selectUser } from "../../../common/redux/userSlice";
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { LoadingButton } from "@mui/lab";
import { SingleLineTextBox } from "../../../common/Theme";
import FormInput from "../../../components/CustomFormInput";

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { formatPhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { InfoPageChip } from "../../../components/styledComponents/chips/InfoPageChip";
import InfoPageTextField from "../../../components/styledComponents/surfaces/InfoPageTextField";
import InfoPageDataField from "../../../components/styledComponents/surfaces/InfoPageDataField";
import EntityLinkModal from "../../../components/modals/EntityLinkModal";
import PhoneNumberInput from "../../../components/PhoneNumberInput";
import { parsePhoneNumber } from "react-phone-number-input";
dayjs.extend(localizedFormat);



export const FIELD_HEIGHT = '8rem'



export const ProviderPage = () => {
    const { id: alacrity_id } = useParams();
    const user = useSelector(selectUser);
    const theme = useTheme()

    const [ isEditing, setIsEditing ] = useState(false);



    const {
        data: provider,
        currentData: currentProvider,
        isSuccess: isProviderSuccess,
        isUninitialized: isProviderUninitialized,
        isLoading: isProviderLoading,
        isFetching: isProviderFetching,
        isError: isProviderError,
        error: providerError
    } = useGetDoctorQuery(alacrity_id);

    const providerIsLoading = useMemo(() => isProviderLoading || isProviderFetching || isProviderUninitialized, [isProviderLoading, isProviderFetching, isProviderUninitialized])



    const {
        data: patients,
        isUninitialized: isPatientsUninitialized,
        isSuccess: isPatientsSuccess,
        isLoading: isPatientsLoading,
        isFetching: isPatientsFetching,
        isError: isPatientsError,
        error: patientsError
    } = useGetPatientsForDoctorQuery(alacrity_id);

    const patientsAreLoading = useMemo(() => isPatientsLoading || isPatientsFetching || isPatientsUninitialized, [isPatientsLoading, isPatientsFetching, isPatientsUninitialized])



    const {
        data: providerOrgs,
        isUninitialized: isOrgsUninitialized,
        isSuccess: isOrgsSuccess,
        isLoading: isOrgsLoading,
        isFetching: isOrgsFetching,
        isError: isOrgsError,
        error: orgsError
    } = useGetOrgsForUserQuery(alacrity_id, {
        selectFromResult: (result) => {
            const { data, ...rest } = result;
            const orgsDict = data?.reduce((acc, org) => ({ ...acc, [org.org_id]: org }), {});
            return { ...rest, data: orgsDict }
        }
    });

    const orgsAreLoading = useMemo(() => isOrgsLoading || isOrgsFetching || isOrgsUninitialized, [isOrgsLoading, isOrgsFetching, isOrgsUninitialized])

    console.log('Provider Orgs: ', providerOrgs)



    const formattedPhone = useMemo(() => {
        if (provider?.phone?.e164NumberString) {
            if (provider?.phone?.iso2?.toLowerCase() === 'us') {
                return formatPhoneNumber(provider?.phone?.e164NumberString)
            } else {
                return formatPhoneNumberIntl(provider?.phone?.e164NumberString)
            }
        } else {
            return 'None'
        }
    }, [provider]);


    
    const { schema: providerInfoSchema, initialValues, editableValues } = useProviderInfoForm({ user, provider });

    const methods = useForm({
        resolver: zodResolver(providerInfoSchema),
        defaultValues: initialValues
    });

    const { control, reset, watch, handleSubmit, formState: { isSubmitSuccessful, errors, isSubmitting } } = methods;
    
    const [ editedData, setEditedData ] = useState({...editableValues})   // Just make a copy of the provider's data that can be manipulated without losing the original copy.

    const [ updateProvider, updateProviderResult ] = useUpdateDoctorInfoMutation()


    const onSubmit = (values) => {
        let newDoctorInfo = {}
        for (const field in values) {
            if (values[field] !== undefined && values[field] !== '' && field !== 'countryCode') {
                newDoctorInfo = Object.assign(newDoctorInfo, { [field]: values[field] })
            }
        }
        console.log('UpdateProvider: ', newDoctorInfo)

        if (values?.phone) {
            const phone = parsePhoneNumber(values?.phone.replace(' ', ''))

            newDoctorInfo.phone = {
                iso2: values?.countryCode,
                e164NumberString: phone?.number,
                dialCode: phone?.countryCallingCode,
                ext: phone?.ext
            }
        }
        
        updateProvider({
            id: alacrity_id,
            newInfo: newDoctorInfo
        })
    }



    useEffect(() => {
        if (updateProviderResult.isSuccess) {
            setIsEditing(false)
            reset()
        }
    }, [updateProviderResult, reset, setIsEditing])


    // const [ colorMap, setColorMap ] = useState({});
    // const [ colorSet, setColorSet ] = useState(new Set());

    const [ patientsMap, setPatientsMap ] = useState({});

    const buildPatientsMap = useCallback(() => {
        const newPatientsMap = patients.reduce((acc, patient) => {
            const firstFoundOrg = Object.keys(acc).find((org_id) => (patient?.orgs ?? []).includes(org_id))
            if (firstFoundOrg !== undefined) {
                acc[firstFoundOrg].push(patient)
            } else {
                if (patient?.orgs === null || patient?.orgs === undefined || patient?.orgs.length === 0) {
                    if (Array.isArray(acc['orgless'])) {
                        acc['orgless'].push(patient)
                    } else {
                        acc['orgless'] = [patient]
                    }
                } else {
                    const firstOrg = patient?.orgs[0]
                    if (Array.isArray(acc[firstOrg])) {
                        acc[firstOrg].push(patient)
                    } else {
                        acc[firstOrg] = [patient]
                    }
                }
            }
            // if (Object.hasOwn(acc, patient?.org_id ?? 'test')) {
            //     acc[patient?.org_id ?? 'test'].push(patient)
            // } else {
                // acc[patient?.org_id ?? 'test'] = [patient]
                // var orgColor = generateMaterialColor();
                // while(colorSet.has(orgColor)) {
                //     orgColor = generateMaterialColor(900)
                // }
                // setColorSet((prev) => prev.add(orgColor))
                // setColorMap((prev) => ({...prev, [patient?.org_id ?? 'test'] : orgColor}))
            // }
            return acc
        }, {})

        setPatientsMap(newPatientsMap)
    }, [patients])

    useEffect(() => {
        if (isPatientsSuccess) {
            buildPatientsMap()
        }
    }, [provider, patients, buildPatientsMap, isPatientsSuccess])
 


    const onDeletePatientChip = useCallback(({ patient }) => {
        // if (patientsMap[patient?.org_id ?? 'test'].length === 1) {
        //     const { [patient.org_id]: discard, ...newPatientsMap } = patientsMap;
        //     setPatientsMap(newPatientsMap)

        //     const { [patient?.org_id ?? 'test']: discardedColor, ...newColorMap } = colorMap;
        //     setColorMap(newColorMap)
        //     setColorSet((prev) => {
        //         prev.delete(discardedColor)
        //         return prev
        //     })
        // } else if (patientsMap[patient.org_id].length > 1) {
        //     setPatientsMap((prev) =>  {
        //         prev[patient?.org_id ?? 'test'] = prev[patient?.org_id ?? 'test'].filter((p) => patient.alacrity_id === p.alacrity_id)
        //         return prev
        //     })
        // }
    }, [/*patientsMap, setPatientsMap, colorMap, setColorMap, setColorSet*/])
    


    const [ expandOrgChip, setExpandOrgChip ] = useState('');

    const onClickOrgChip = useCallback((org_id) => {
        if (expandOrgChip === org_id) {
            setExpandOrgChip('')
        } else {
            setExpandOrgChip(org_id)
        }
    }, [setExpandOrgChip, expandOrgChip])



    const patientChips = useMemo(() => {
        if (patientsMap && providerOrgs) {
            const mainRowChips = Object.entries(patientsMap).flatMap(([org_id, patientGroup], index) => {
                if (patientGroup.length === 1) {
                    const patient = patientGroup.at(0);

                    if (editableValues?.patients) {
                        if (editableValues.patients.includes(patient.alacrity_id)) {
                            return (
                                <InfoPageChip 
                                    onDelete={() => { onDeletePatientChip(patient) }}
                                    label={`${patient.first_name} ${patient.last_name}`}
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        color:'black',
                                        transition: theme.transitions.create('all', { duration: 10000 })
                                    }}
                                />
                            )
                        } else {    // Else if not in editable values, don't add a delete button by adding an onDelete function
                            return (
                                <InfoPageChip
                                    label={`${patient.first_name} ${patient.last_name}`}
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: 'black',
                                    }}
                                />
                            )
                        }
                    } else {
                        return (
                            <InfoPageChip
                                label={`${patient.first_name} ${patient.last_name}`}
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: 'black',
                                }}
                            />
                        )
                    }
                } else if (org_id === 'orgless') {
                    const patientsWithoutOrg = []
                    for (const patient of patientGroup) {
                        if (editableValues?.patients) {
                            if (editableValues.patients.includes(patient.alacrity_id)) {
                                patientsWithoutOrg.push(
                                    <InfoPageChip 
                                        onDelete={() => { onDeletePatientChip(patient) }}
                                        label={`${patient.first_name} ${patient.last_name}`}
                                        sx={{
                                            backgroundColor: theme.palette.primary.main,
                                            color:'black',
                                            transition: theme.transitions.create('all', { duration: 10000 })
                                        }}
                                    />
                                )
                            } else {    // Else if not in editable values, don't add a delete button by adding an onDelete function
                                patientsWithoutOrg.push(
                                    <InfoPageChip
                                        label={`${patient.first_name} ${patient.last_name}`}
                                        sx={{
                                            backgroundColor: theme.palette.primary.main,
                                            color: 'black',
                                        }}
                                    />
                                )
                            }
                        } else {
                            patientsWithoutOrg.push(
                                <InfoPageChip
                                    label={`${patient.first_name} ${patient.last_name}`}
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: 'black',
                                    }}
                                />
                            )
                        }
                    }

                    return patientsWithoutOrg
                } else {     
                    return (
                        <InfoPageChip
                            label={providerOrgs[org_id]?.name ?? `Test Org ${index}`}
                            icon={(expandOrgChip === org_id) ? <ExpandLessSharp style={{ color: 'black'}} /> : <ExpandMoreSharp style={{ color: 'black' }} />}
                            onClick={() => { onClickOrgChip(org_id) }}
                            sx={{
                                backgroundColor: theme.palette.primary.dark,
                                color: 'black',
                            }}
                        />
                    )
                }
            })



            var subRowChips = []
            if (expandOrgChip !== '') {
                subRowChips = patientsMap[expandOrgChip].map((patient) => {
                    if (editableValues?.patients) {
                        if (editableValues.patients.includes(patient.alacrity_id)) {
                            return (
                                <InfoPageChip 
                                    onDelete={() => { onDeletePatientChip(patient) }}
                                    label={`${patient.first_name} ${patient.last_name}`}
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,// colorMap[patient?.org_id ?? 'test'],
                                        color: 'white',
                                    }}
                                />
                            )
                        } else {    // Else if not in editable values, don't add a delete button by adding an onDelete function
                            return (
                                <InfoPageChip
                                    label={`${patient.first_name} ${patient.last_name}`}
                                    sx={{
                                        backgroundColor: theme.palette.primary.main, // colorMap[patient?.org_id ?? 'test'],
                                        color: 'white',
                                    }}
                                />
                            )
                        }
                    } else {
                        return (
                            <InfoPageChip
                                label={`${patient.first_name} ${patient.last_name}`}
                                sx={{
                                    backgroundColor: theme.palette.primary.main, // colorMap[patient?.org_id ?? 'test'],
                                    color: 'white',
                                }}
                            />
                        )
                    }
                })
            }



            return (
                <>
                    <Stack
                        direction='row'
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%'
                        }}
                        spacing={2}
                    >
                        {mainRowChips}

                        {
                            (
                                user.permissions === 'ALACRITY' ||
                                (
                                    provider?.orgs.some((org_id) => user?.orgs.includes(org_id)) &&
                                    user.admin === true
                                )   ||
                                user?.alacrity_id === alacrity_id
                            ) &&
                            <IconButton
                                color="info"
                                onClick={() => { setOpenPatientModal(true) }}
                            >
                                <AddSharp/>
                            </IconButton>
                        }
                    </Stack>
                    {
                        expandOrgChip !== '' &&
                        <Stack
                            direction='row'
                            sx={{
                                justifyContent: 'start',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%'
                            }}
                            spacing={2}
                        >
                            {
                                subRowChips
                            }
                        </Stack>
                    }
                </>
            )
        } else {
            return (
                <Stack
                    direction='row'
                    sx={{
                        justifyContent: 'start',
                        alignItems: 'center',
                        width: '100%'
                    }}
                ></Stack>
            )
        }
    }, [patientsMap, editableValues, onDeletePatientChip, expandOrgChip, providerOrgs, onClickOrgChip, provider, user, alacrity_id, theme])



    const onDeleteOrgChip = (org_id) => {
        // setCurrentOrgs((prev) => { 
        //     prev.delete(org_id)
        //     return prev
        //  })
    }



    const [ openPatientModal, setOpenPatientModal ] = useState(false)

    const [ openOrgModal, setOpenOrgModal ] = useState(false)
    


    return (
        <>
        <FormProvider {...methods}>
            <Box
                display='flex'
                flexDirection='column'
                component='form'
                noValidate
                autoComplete='off'
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    width: '100%',
                    height: '100%'
                }}
            >
                <Stack
                    sx={{
                        width: '100%',
                        // height: '100%',
                        p: 8,
                        justifyContent: 'space-between'
                    }}
                    direction='row'
                >
                    <Stack
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'start',
                            height: '100%',
                            width: '80%',
                        }}
                        spacing={8}
                    >
                        <Stack
                            sx={{
                                justifyContent: 'start',
                                alignItems: 'center',
                                width: '100%'
                            }}
                            direction='row'
                        >
                            {                                
                                ( isEditing && editableValues?.first_name ) ?
                                    <>
                                        <FormInput
                                            label='First Name'
                                            type='text'
                                            name='first_name'
                                            sx={{
                                                mr: 5,
                                                maxWidth: '20rem'
                                            }}
                                            focused
                                        />

                                        <FormInput
                                            label='Last Name'
                                            type='text'
                                            name='last_name'
                                            sx={{
                                                maxWidth: '20rem'
                                            }}
                                            focused
                                        />
                                    </>
                                :
                                    <>
                                        <SingleLineTextBox
                                            variant='h2'
                                            tooltip={(providerIsLoading) ? null : `${provider.first_name} ${provider.last_name}`}
                                        >
                                            {(providerIsLoading) ? <Skeleton width={200} /> : `${provider?.first_name} ${provider?.last_name}`}
                                        </SingleLineTextBox>
                                    </>
                            }
                            {
                                providerIsLoading ?
                                    <Skeleton variant="circular">
                                        <Avatar     // TODO: Provide way to upload profile picture
                                            src={provider?.avatar}
                                            sx={{
                                                height: 60,
                                                width: 60,
                                                ml: '10%'
                                            }}
                                        >
                                            <Person style={{ height: 40, width: 40 }}/>
                                        </Avatar>
                                    </Skeleton>
                                :
                                    <Avatar     // TODO: Provide way to upload profile picture
                                        src={provider?.avatar}
                                        sx={{
                                            height: 60,
                                            width: 60,
                                            ml: '10%'
                                        }}
                                    >
                                        <Person style={{ height: 40, width: 40 }}/>
                                    </Avatar>
                            }
                        </Stack>  

                        <Stack
                            sx={{
                                width: '100%',
                                height: FIELD_HEIGHT,
                                alignItems: 'center',
                            }}
                            direction='row'
                            spacing={2}
                        >

                            <InfoPageDataField
                                childSpacing={4}
                                expand={expandOrgChip !== ''}
                                label='Patients'
                                editing={isEditing}
                                containerProps={{
                                    height: (expandOrgChip !== '') ? 2 * FIELD_HEIGHT : FIELD_HEIGHT,
                                    width: '100%',
                                    justifyContent: 'start',
                                }}
                                loading={providerIsLoading || patientsAreLoading || orgsAreLoading}
                                loadingChildren={
                                    <Stack
                                        direction={'row'}
                                        width='100%'
                                        height='100%'
                                        spacing={3}
                                        alignItems='center'
                                    >
                                        {
                                            Array(4).fill(0).map(() => (<Skeleton variant="rounded" sx={{ height: 40, width: 100, borderRadius: 6 }}/>))
                                        }
                                    </Stack>
                                }
                            >
                                {patientChips}
                            </InfoPageDataField> 

                            <Controller
                                control={control}
                                name='admin'
                                disabled={!isEditing}
                                render={({ field }) => {
                                    const { value, ...fields} = field
                                    return (
                                        <Stack direction={'row'} alignItems='center'>
                                            <Switch 
                                                {...fields} 
                                                checked={(isEditing) ? value : /*provider?.admin*/ false}
                                                sx={{
                                                    '& .MuiSwitch-switchBase.Mui-disabled': {
                                                        color: (value) ? theme.palette.primary.main : 'rgba(255, 255, 255, 0.75)',
                                                    },
                                                    '& .MuiSwitch-track': {
                                                        backgroundColor: (value) ? `${theme.palette.primary.main} !important` : `${theme.palette.text.secondary} !important` ,
                                                        opacity: '0.5 !important'
                                                    }
                                                }}
                                            />
                                            <Typography>
                                                Admin
                                            </Typography>
                                        </Stack>
                                    )
                                }}
                            />
                        
                        </Stack>

                        <Stack
                            sx={{
                                width: '100%',
                                height: FIELD_HEIGHT,
                                alignItems: 'end',
                            }}
                            direction='row'
                            spacing={2}
                        >
                            <InfoPageDataField
                                label='Organizations'
                                editing={isEditing}
                                loading={providerIsLoading || patientsAreLoading || orgsAreLoading}
                                loadingChildren={
                                    <Stack
                                        direction='row'
                                        width='100%'
                                        height='100%'
                                        spacing={3}
                                        alignItems='center'
                                    >
                                        {
                                            Array(2).fill(0).map(() => (<Skeleton variant="rounded" sx={{ height: 40, width: 100, borderRadius: 6 }}/>))
                                        }
                                    </Stack>
                                }
                            >
                                <Stack
                                    direction='row'
                                    spacing={2}
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        alignItems: 'center'
                                    }}
                                >
                                    {
                                        isOrgsSuccess &&
                                        Object.values(providerOrgs ?? []).map((org, index) => {
                                            return (
                                                <InfoPageChip
                                                    onDelete={
                                                        ((user?.permissions === 'ALACRITY' || (user?.admin === true && user?.orgs.includes())) && isEditing ) ?
                                                            () => { onDeleteOrgChip(org?.org_id) }
                                                        :
                                                            null
                                                    }
                                                    label={org?.name ?? `Test Org ${index}`}
                                                    sx={{
                                                        backgroundColor: theme.palette.secondary.main, // colorMap[org?.org_id ?? 'test'],
                                                        color: 'black'
                                                    }}
                                                />
                                            )
                                        })                                            
                                    }

                                    {
                                        (
                                            user.permissions === 'ALACRITY' ||
                                            (
                                                provider?.orgs.some((org_id) => user?.orgs.includes(org_id)) &&
                                                user.admin === true
                                            )
                                        ) &&
                                        <IconButton
                                            color="info"
                                            onClick={() => { setOpenOrgModal(true) }}
                                        >
                                            <AddSharp/>
                                        </IconButton>
                                    }
                                </Stack>
                            </InfoPageDataField>

                            <InfoPageTextField
                                label='Role'
                                type='text'
                                name='role'
                                loading={providerIsLoading}
                                editing={isEditing}
                                text={provider?.role}  
                                boxProps={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    width: '20%',
                                    height: '100%',
                                    minWidth: '10rem'
                                }}
                                inputProps={{
                                    height: '3.6rem'
                                }}
                                focused                        
                            />
                        </Stack>

                        <Stack
                            sx={{
                                width: '100%',
                                height: FIELD_HEIGHT,
                                alignItems: 'end'
                            }}
                            direction='row'
                            spacing={2}
                        >
                            {
                                isEditing ?
                                    <PhoneNumberInput focused />
                                :
                                    <InfoPageTextField
                                        label='Phone'
                                        type='tel'
                                        name='phone'
                                        loading={providerIsLoading}
                                        editing={isEditing}
                                        text={formattedPhone}  
                                        boxProps={{
                                            display: 'flex',
                                            alignItems: 'end',
                                            width: '100%',
                                            height: '100%'
                                        }}
                                        inputProps={{
                                            height: '3.6rem'
                                        }}
                                        focused                        
                                    />
                            }

                            <InfoPageTextField
                                label='Email'
                                type='text'
                                name='email'
                                loading={providerIsLoading}
                                editing={isEditing}
                                text={provider?.email}  
                                boxProps={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    width: '100%',
                                    height: '100%'
                                }}
                                inputProps={{
                                    height: '3.6rem'
                                }}
                                focused                        
                            />
                        </Stack>

                    </Stack>

                    <Stack
                        sx={{
                            height: '100%',
                            justifyContent: 'space-between'
                        }}
                    >
                        <LoadingButton
                            loading={providerIsLoading}
                            startIcon={(isEditing) ? <Close/> : <EditSharp/>}
                            variant="outlined"
                            color={(isEditing) ? 'error' : 'primary'}
                            sx={{
                                height: 'fit-content',
                                py: 1,
                                px: 1.5,
                                '&:hover': {
                                    backgroundColor: (isEditing) ? theme.palette.error.main : theme.palette.primary.main,
                                    color: 'white' 
                                }
                            }}
                            onClick={() => { reset(); setIsEditing((prev) => !prev) }}
                        >
                            {(isEditing) ? 'Cancel' : 'Edit'}
                        </LoadingButton>  

                        {
                            isEditing &&
                            <LoadingButton
                                loading={isSubmitting}
                                endIcon={<SendSharp/>}
                                variant="outlined"
                                color={'primary'}
                                sx={{
                                    height: 'fit-content',
                                    py: 1,
                                    px: 1.5,
                                    '&:hover': {
                                        backgroundColor: theme.palette.primary.main,
                                        color: 'white' 
                                    }
                                }}
                                type="submit"
                            >
                                Submit
                            </LoadingButton> 
                        }        
                    </Stack>

                </Stack>
            </Box>
        </FormProvider>

        {
            openPatientModal &&
            <AddPatientModal
                open={openPatientModal}
                handleClose={() => { setOpenPatientModal(false) }}
                provider_id={alacrity_id}
            />
        }

        {
            openOrgModal &&
            <AddOrgModal
                open={openOrgModal}
                handleClose={() => setOpenOrgModal(false)}
                provider_id={alacrity_id}
            />
        }
        </>
    )
}



const AddPatientModal = ({ open, handleClose, provider_id }) => {
    const orderPatients = (a, b) => {
        const order = a.first_name.localeCompare(b.first_name);
        if (order === 0) {
            return a.last_name.localeCompare(b.last_name)
        }
        return order;
    }

    const transformPatients = (entity) => {
        return {
            label: `${entity.first_name} ${entity.last_name}`,
            id: entity.alacrity_id
        }
    }

    

    return (
        open &&
        <EntityLinkModal
            open={open}
            onClose={handleClose}
            orderEntities={orderPatients}
            transformEntities={transformPatients}
            entityName={'Doctor'}
            entityID={provider_id}
            entityListQuery={useGetPatientsQuery}
            linkName={'Patient'}
            linkEntityMutation={useAddPatientToDoctorMutation}
            ariaLabelledBy="modal-doctor-title"  
            onSuccess={() => {
                handleClose()
            }}      
        />
    )
}



const AddOrgModal = ({ open, handleClose, provider_id }) => {
    const orderOrgs = (a, b) => {
        return a.name.localeCompare(b.name);
    }

    const transformOrgs = (entity) => {
        return {
            label: `${entity.name}`,
            id: entity.org_id
        }
    }

    

    return (
        open &&
        <EntityLinkModal
            open={open}
            onClose={handleClose}
            orderEntities={orderOrgs}
            transformEntities={transformOrgs}
            entityName={'Doctor'}
            entityID={provider_id}
            entityListQuery={useGetOrgsQuery}
            linkName={'Org'}
            linkEntityMutation={useAddProviderToOrgMutation}
            ariaLabelledBy="modal-org-title"  
            onSuccess={() => {
                handleClose()
            }}      
        />
    )
}



const useProviderInfoForm = ({ user, provider }) => {
    return useMemo(() => {
        var schema = z.object({});
        var initialValues = {};
        var editableValues = {};

        if (
            user.alacrity_id === provider?.alacrity_id || 
            (
                user?.admin === true && 
                user?.orgs?.some((org_id) => provider?.orgs?.includes(org_id))
            ) || 
            user?.permissions === 'ALACRITY'
        ) {     // If the user is looking at their own page, they can edit email & phone since they can verify.
            initialValues = {
                ...initialValues,
                first_name: undefined,
                last_name: undefined,
                email: undefined,
                email_verified: undefined,
                phone: undefined,
                phone_verified: undefined,
                role: undefined,
                countryCode: 'US'
            }

            editableValues = {
                first_name: provider?.first_name,
                last_name: provider?.last_name,
                email: provider?.email,
                email_verified: provider?.email_verified,
                countryCode: provider?.phone?.iso2,
                phone: provider?.phone?.e164NumberString,
                phone_verified: provider?.phone_verified,
                role:provider?.role
            }

            schema = schema.extend({
                first_name: z.string().optional(),
                last_name: z.string().optional(),
                email: z.union([z.string().email('Must be a valid email.'), z.literal('')]),
                email_verified: z.boolean().optional(),
                countryCode: z.string().length(2).optional(),
                phone: z.string().optional(),
                phone_verified: z.boolean().optional(),
                role: z.string().optional()
            })
        }

        if (
            (
                user?.admin === true && 
                user?.orgs?.some((org_id) => provider?.orgs?.includes(org_id))
            ) || 
            user?.permissions === 'ALACRITY'
        ) {     // Admins can also edit their team's permissions to access information
            initialValues = {
                ...initialValues,
                orgs: undefined,
                patients: undefined
            }

            schema = schema.extend({
                orgs: z.array(z.string()).optional(),
                patients: z.array(z.string()).optional()
            })

            editableValues = {
                ...editableValues,
                orgs: provider?.orgs.filter((org_id) => { if (user?.orgs) { return user?.orgs?.includes(org_id) } else { return false }}),
                patients: provider?.patients?.filter((patient_id) => user?.patients?.includes(patient_id))
            }
        }

        if (
            (
                user?.admin === true && 
                user?.orgs?.some((org_id) => provider?.orgs?.includes(org_id))
            ) || 
            user?.permissions === 'ALACRITY'
        ) {    // Pharma admins can take providers off their trials or add them.
            initialValues = {
                ...initialValues,
                trials: undefined,
                pharmas: undefined
            }

            schema = schema.extend({
                trials: z.array(z.string()).optional(),
                pharmas: z.array(z.string()).optional()
            })

            editableValues = {
                ...editableValues,
                trials: provider?.trials?.filter((trial_id) => user?.trials?.includes(trial_id)),
                pharmas: provider?.pharmas?.filter((pharma_id) => user.pharmas?.includes(pharma_id))
            }
        }

        if (user.permissions === 'ALACRITY') {      // Alacrity users can change anything.
            initialValues = {
                ...initialValues,
                status_active: undefined,
                admin: provider?.admin
            }

            schema = schema.extend({
                status_active: z.boolean().optional(),
                admin: z.boolean().optional()
            })

            editableValues = {
                ...editableValues,
                status_active: provider?.status_active,
                admin: provider?.admin
            }
        }


        schema = schema.superRefine((data, ctx) => {
            if (data?.phone) {
                // console.log(data?.phoneNumber.replace(' ', ""))
                if (!isPossiblePhoneNumber(data?.phone.replace(' ', ""))) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: 'Invalid Phone Number',
                        path: ['phone']
                    })
                }
            }
        })


        return { schema: schema, initialValues: initialValues, editableValues: editableValues }
    
    }, [user, provider])
}



// const PatientStack = forwardRef((props, ref) => {
//     const { children, ...otherProps } = props;

//     return (
//         <Stack
//             ref={ref}
//             {...otherProps}
//             sx={{
//                 width: '100%'
//             }}
//         >
//             {props.children}
//         </Stack>
//     )
// })