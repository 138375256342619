import { createSlice } from "@reduxjs/toolkit";



export const historySlice = createSlice({
    name: 'history',
    initialState: [],
    reducers: {
        push: (state, action) => {
            const index = state.findIndex((breadcrumb) => { return action.payload.path === breadcrumb.path })
            if (index > -1) {
                return state.slice(0, index + 1)
            } else {
                return [...state, action.payload]
            }
        },
        popX: (state, action) => {
            return state.slice(0, state.length - action.payload)
        },
        pop: state => {
            return state.slice(0, -1)
        },
        rewrite: (state, action) => {
            return action.payload
        },
        erase: _ => {
            return []
        }
    }
})

export const { push, popX, pop, rewrite, erase } = historySlice.actions

export const selectHistory = state => {
    return state.history
}

export default historySlice.reducer