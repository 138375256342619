import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLoginUserMutation } from '../../common/redux/api/auth/authApi';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Container, Divider, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FormInput from '../../components/CustomFormInput';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { rewrite } from '../../common/redux/historySlice';
// import { InternationalPhoneNumberField } from './CreateUser';
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/material.css'
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
// import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import PhoneNumberInput from '../../components/PhoneNumberInput';
import { getCountryCallingCode } from 'react-phone-number-input';
import { parsePhoneNumber } from 'react-phone-number-input';



function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [ phoneNumberData, setPhoneNumberData ] = useState('');

    const [ login, result ] = useLoginUserMutation();
    const { isLoading, isError, error, isSuccess } = result;



    useEffect(() => {
        dispatch(rewrite([{ name: 'Login', path: '/login' }]))
    })



    const loginSchema = z.object({
        email: z.union([z.string().email('Email is invalid'), z.string().length(0)]),
        phone: z.union([z.string(), z.string().length(0)]),
        countryCode: z.string().length(2),
        password: z.string()
          .min(1, 'Password is required')
          .min(10, 'Password must be at least 12 characters')
    }).superRefine((data, ctx) => {
        if (!data?.email && !data?.phone) {
            console.log("No email or phone")
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Please enter your phone number or email address.',
                path: ["email"],
                fatal: true
            })
            return z.NEVER
        }

        if (data?.phone) {
            if (!isPossiblePhoneNumber(data?.phone.replace(' ', ""))) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Invalid Phone Number',
                    path: ['phone']
                })
            }
        }
    })

    const defaultValues = {
        email: '',
        phone: '',
        countryCode: 'US',
        password: ''
    }

    const methods = useForm({
        resolver: zodResolver(loginSchema),
        defaultValues,
        reValidateMode: 'onChange',
        mode: 'onSubmit'
    });

    const { reset, handleSubmit, formState: { isSubmitSuccessful }, control } = methods;



    const onSubmitHandler = (values) => {
        const username = (values?.phone) ? parsePhoneNumber(values?.phone?.replace(' ', "")).number : values?.email
        login({
            username: username, 
            password: values.password
        });
    };



    useEffect(() => {
        if (isSuccess) {
            toast.success('Success', {
                position: 'top-center', 
                autoClose: 1000,
                toastId: 'login-success',
                theme: 'dark'
            });
            reset();
            navigate('/dashboard');
        }
        if (isError) {
            console.log(error);
            toast.error(error?.data?.message, {
                position: 'top-center', 
                autoClose: 1000,
                toastId: 'login-error',
                theme: 'dark'
            });
        }
      }, [isSuccess, isError, error, navigate, reset]);



    return(
        <Container
            sx={{
                width: { xl: '35%', md: '50%', xs: '90%' },
                justifyContent: 'center',
                alignItems: 'center',
                my: '2rem'
            }}
        >
            <FormProvider {...methods}>
                <Box
                    display='flex'
                    flexDirection='column'
                    component='form'
                    noValidate
                    autoComplete='off'
                    onSubmit={handleSubmit(onSubmitHandler)}
                >
                    <Stack
                        height='100%'
                        width='100%'
                        justifyContent='center'
                        alignItems='center'
                        spacing={4}
                    >
                        <Typography
                            variant='h1'
                            sx={{ textAlign: 'center'}}
                        >
                            Login
                        </Typography>

                        <FormInput
                            label='Enter your email'
                            type='email'
                            name='email'
                            focused
                            required
                        />

                        <Typography
                            variant='h4'
                            textAlign={'center'}
                        >
                            – OR –
                        </Typography>

                        <PhoneNumberInput 
                            focused
                            required
                        />

                        <Divider sx={{ height: '2rem' }}/>

                        <FormInput
                            type='password'
                            label='Password'
                            name='password'
                            required
                            focused
                        />

                        <LoadingButton
                            loading={isLoading}
                            type='submit'
                            variant='xxl'
                            sx={{
                                py: '0.8rem',
                                width: 'max-content'
                                // marginInline: 'auto',
                            }}
                        >
                            Login
                        </LoadingButton>
                    </Stack>
                </Box>
            </FormProvider>
        </Container>
    )
}

export default Login;
