
// import "typeface-montserrat";
// import "@fontsource/atkinson-hyperlegible"

import { Avatar, Box, createTheme, useTheme, Stack } from "@mui/material";
import AlacrityTheme from "../../common/Theme";

const BaseTheme = createTheme(
    AlacrityTheme,
    {}
);



export const InfoPageTheme = createTheme(
    BaseTheme,
    {
        typography: {
            metricsLabel: {
                fontFamily: 'Atkinson Hyperlegible',
                color: BaseTheme.palette.text.secondary,
                fontSize: '1.5rem',
            },
            metricsValue: {
                fontWeight: 'bold',
                fontSize: '2.125rem',
                lineHeight: 1.235,
                letterSpacing: "0.00735em"
            },
            trend: {
                fontFamily: 'Atkinson Hyperlegible',
                fontSize: '1.2rem',
                flexGrow: 0,
                lineHeight: 1.75,
                fontWeight: 600
            },
            name: {
                fontFamily: 'Montserrat',
                fontWeight: 500,
                fontSize: '4.25rem',
                lineHeight: 1.2,
                letterSpacing: '-0.00833em'
            },
            info: {
                fontFamily: 'Montserrat',
                fontWeight: 500,
                fontSize: '1.25rem',
                lineHeight: 1.6,
                letterSpacing: '0.0075em' 
            },
            infoTitle: {
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                fontSize: '1.25rem',
                lineHeight: 1.6,
                letterSpacing: '0.0075em',
                color: '#606060',
                whiteSpace: 'nowrap',
                overflow: 'visible' 
            }
        },
        components: {
            MuiCard: {
                variants: [
                    {
                        props: { variant: 'metrics' },
                        style: {
                            minWidth: 250, 
                            minHeight: 150, 
                            border: BaseTheme.alacrityBorder,
                             display: 'flex'
                        }
                    }
                ]
            },
            MuiCardContent: {
                variants: [
                    {
                        props: { variant: 'metrics' },
                        style: {
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'space-between', 
                            alignContent: 'center'
                        }
                    }
                ]
            },
            MuiCardMedia: {
                variants: [
                    {
                        props: { variant: 'metrics' },
                        style: {
                            color: BaseTheme.palette.secondary.main, 
                            width: 150, 
                            height: 150
                        }
                    }
                ]
            },
            MuiTypography: {
                defaultProps: {
                    variantMapping: {
                        metricsLabel: 'h5',
                        metricsValue: 'h4',
                        name: 'h2',
                    },
                },
            },
            MuiSvgIcon: {
                variants: [
                    {
                        props: { variant: 'bio' },
                        style: {
                            height: 150, 
                            width: 150
                        }
                    }
                ]
            },
        }
    }
);



export const EntityListTheme = createTheme(
    BaseTheme,
    {}
)



export const TrendBox = (props) => (
    <Box
        sx={{
            display: 'flex', 
            maxWidth: '70px', 
            justifyContent: 'center', 
            backgroundColor: 'secondary.main', 
            alignContent: 'center',
            alignItems: 'center'
        }}
    >
        {props.children}
    </Box>
)



export const BioBox = (props) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                mx: 0, 
                mt:'10px',
                px: theme.alacrityMarginsX,
                py: 2,
                borderBottom: `${theme.alacrityBorder}`,
                width: '100vw',
                maxWidth: '100%'
            }}
        >
            {props.children}
        </Box>
    )
}



// Don't know how to make a custom variant that's square, so doing it here.
export const BioAvatar = (props) => {
    return (
        <Avatar
            sx={{
                backgroundColor: "primary.main", 
                width: 320, 
                height: 240
            }}
            alt={'Profile Picture'}
            variant='square'
            src={props.src}
        >
            {props.children}
        </Avatar>
    )
}



export const MetricsStack = (props) => {
    const theme = useTheme();

    return (
        <Stack
            direction='row'
            spacing={2}
            sx={{
                mx: theme.alacrityMarginsX, 
                my: '1em',
                // width: '100vw',
                maxWidth: '100%',
                overflowX: 'scroll'
            }}
        >
            {props.children}
        </Stack>
    )
}



export const InfoPageEntitiesStack = (props) => {
    const theme = useTheme();

    return (
        <Stack
            spacing={1}
            py='1em' 
            px={theme.alacrityMarginsX} 
            mb='4em' 
            borderTop={theme.alacrityBorder}
            {...props}
        >
            {props.children}
        </Stack>
    )
}



export const InfoPiece = (props) => {
    return (
        <Stack
            direction={{sm: 'row', xs: 'column' }}
            spacing={1}
            sx={{
                width: { md: '33%', sm: '75%', xs: '85%' }
            }}
            alignItems='center'
        >
            {props.children}
        </Stack>
    )
}

export const InfoRow = (props) => {
    return (
        <Stack
            direction={{ md: 'row', sm: 'column' }}
            spacing={1}
        >
            {props.children}
        </Stack>
    )
}