import { CloseSharp } from "@mui/icons-material"
import { IconButton, Modal, Paper, Stack } from "@mui/material"




export const GenericModal = ({ open, children }) => {

    return (
        <Modal
            open={open}
            onClose={()=>{}/* Do Nothing. Must use Close Button */}
        >
            <Stack 
                sx={{
                    width: {xl: '50%', md: '60%', xs: '90%'},
                    alignItems: 'center',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    boxShadow: 24,
                    p: 4,
                    backgroundColor: '#212121'
                }}
                spacing={2}
            >
                {children}            
            </Stack>
        </Modal>
    )
}