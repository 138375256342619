import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { store } from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor } from './store';
import "typeface-montserrat";
import "@fontsource/atkinson-hyperlegible";
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {enableMapSet} from "immer";
import { CookiesProvider } from 'react-cookie';
import { LicenseInfo } from '@mui/x-license-pro'
import { CssBaseline, ThemeProvider } from '@mui/material';
import AlacrityTheme from './common/Theme';

enableMapSet();


const root = ReactDOM.createRoot(document.getElementById('root'));

LicenseInfo.setLicenseKey('cc508494df6cc7758a7e44711faaf7bcTz03Nzg2MyxFPTE3MzA0OTI1MTgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CookiesProvider>
              <ThemeProvider theme={AlacrityTheme}>
                <CssBaseline/>
                <App />
              </ThemeProvider>
            </CookiesProvider>
          </LocalizationProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
