import React, { Suspense, useEffect, useMemo } from 'react';
import { Typography, Box, Container, CircularProgress, Stack, Paper, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Await, defer, useLoaderData } from 'react-router';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { useCreateTrialMutation, useGetAllOrgsQuery, useGetOrgsForUserQuery } from '../../../common/redux/api/api';
import FormInput from '../../../components/CustomFormInput';
import { userApi } from '../../../common/redux/api/userApi';
import GenericError from '../../error/GenericError';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../common/redux/userSlice';
import { BANNER_HEIGHT } from '../../../components/Banner';
import { ArrowDropDown } from '@mui/icons-material';



export default function CreateTrial() {
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const role = user.permissions;



    const [ createTrial, result ] = useCreateTrialMutation();
    const { data, isLoading, isError, error, isSuccess } = result;



    const {
        data: orgs,
        isLoading: areOrgsLoading,
        isFetching: areOrgsFetching,
        isSuccess: areOrgsSuccess,
        isError: areOrgsError,
        error: orgsError
    } = useGetOrgsForUserQuery(user?.alacrity_id, { skip: user?.permissions === 'ALACRITY' })

    const {
        data: allOrgs,
        isLoading: areAllOrgsLoading,
        isFetching: areAllOrgsFetching,
        isSuccess: areAllOrgsSuccess,
        isError: areAllOrgsError,
        error: orgsAllError
    } = useGetAllOrgsQuery(undefined, { skip: user?.permissions !== 'ALACRITY' })



    const createTrialSchema = z.object({
        name: z.string().min(1, 'A name is required.'),
        id: z.string().min(1, "Please give your trial an ID."),
        orgs: z.string().array()
        // treatment: z.string().min(1, 'Please provide a valid treatment name.').or(z.literal(''))
    });

    const defaultValues = {
        id: '',
        name: "",
        orgs: []
    }

    const methods = useForm({
        resolver: zodResolver(createTrialSchema),
        defaultValues,
    });

    const { control, reset, handleSubmit, formState: { errors, isSubmitting } } = methods;



    const onSubmitHandler = (values) => {
        createTrial({ custom_id: values.id, name: values.name, orgs: values?.orgs })
    };



    useEffect(() => {
        if (isSuccess) {
            toast.success('Trial successfully created.', {
                position: 'top-center', autoClose: 1000
            });
            reset()
            console.log(data);
            navigate(`/createTrial/${data.trial_id}`, {
                state: {
                    role: 'TRIAL'
                }
            });
        }
        if (isError) {
            toast.error(error?.data?.message, {
                position: 'top-center', autoClose: 1000,
            });
        }
    }, [isLoading, isSuccess, isError, error, data, navigate, reset]);



    const orgOptions = useMemo(() => {
        if (user.permissions === 'ALACRITY') {
            if (areAllOrgsSuccess) {
                return allOrgs.filter((org) => org?.org_type === 'PHARMA')
            } else {
                return []
            }
        } else {
            if (areOrgsSuccess) {
                return orgs.filter((org) => org?.org_type === 'PHARMA')
            } else {
                return []
            }
        }
    }, [orgs, allOrgs, user, areOrgsSuccess, areAllOrgsSuccess])



    return (
        <Container
            sx={{
                width: { xl: '35%', md: '50%', xs: '90%' },
                justifyContent: 'center',
                alignItems: 'center',
                py: '2rem',
                height: `calc(100vh - ${BANNER_HEIGHT}px - 16px)`
            }}
        >
            <FormProvider {...methods}>
                <Box
                    display='flex'
                    flexDirection='column'
                    component='form'
                    noValidate
                    autoComplete='off'
                    sx={{ 
                        width: '100%',
                        height: '100%'
                    }}
                    onSubmit={handleSubmit(onSubmitHandler)}
                >
                    <Stack
                        height='100%'
                        width='100%'
                        justifyContent='start'
                        alignItems='center'
                        spacing={6}
                    >
                        <Typography
                            variant='h1'
                            sx={{ textAlign: 'center', mb: '1.5rem' }}
                        >
                            Create Trial
                        </Typography>

                        <Paper
                            elevation={8}
                            sx={{
                                m: 0,
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <Stack
                                sx={{
                                    width: '100%',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    px:2,
                                    pt: 2,
                                    pb: 8,
                                    height:'62vh',
                                    overflowY: 'scroll',
                                    '::-webkit-scrollbar': {
                                        '-webkit-appearance': 'none',
                                        width: '7px'
                                    },  
                                    '::-webkit-scrollbar-thumb': {
                                        borderRadius: '4px',
                                        backgroundColor: 'rgba(255, 255, 255, .5)',
                                    }
                                }}
                                spacing={8}
                            >
                                <FormInput
                                    label='ID'
                                    type='text'
                                    name='id'
                                    required
                                    focused
                                    disabled={isLoading || isSubmitting}
                                />

                                <FormInput
                                    label='Name'
                                    type='text'
                                    name='name'
                                    required
                                    focused
                                    disabled={isLoading || isSubmitting}
                                />

                                <FormControl fullWidth required focused disabled={isLoading || isSubmitting || areOrgsLoading}>

                                    <InputLabel 
                                        id='orgs-select-label'
                                        sx={{ 
                                            fontSize: 24, 
                                            '&.Mui-focused': {
                                                color: 'white'
                                            } 
                                        }} 
                                    >
                                        Add new trial to your organization
                                    </InputLabel>

                                    <Controller
                                        control={control}
                                        name={'orgs'}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                multiple
                                                labelId='orgs-select-label'
                                                label={'Add new user to your organizations'}
                                                color="info"
                                                sx={{
                                                    fontSize: 32,
                                                    px: 2,
                                                    height: '6rem',
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        fontSize: 24,
                                                    }
                                                }}
                                                IconComponent={(areOrgsLoading || areAllOrgsLoading) ? CircularProgress : ArrowDropDown }
                                            >
                                                {
                                                    orgOptions.map((org) => {
                                                        return <MenuItem sx={{ fontSize: 32 }} key={org?.org_id} value={org?.org_id}>{org?.name}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        )}
                                    />

                                </FormControl>

                                <LoadingButton
                                    loading={isLoading || isSubmitting}
                                    type='submit'
                                    variant='xxl'
                                    sx={{
                                        py: 1,
                                    }}
                                >
                                    Submit
                                </LoadingButton>
                            </Stack>
                        </Paper>
                    </Stack>
                </Box>
            </FormProvider>
        </Container>
    )
}



export const createTrialLoader = ({ dispatch }) =>
    async ({ request }) => {
        const promise = dispatch(userApi.endpoints.getCurrentUser.initiate()).then(resp => {
            if (resp?.error) {
                throw (resp?.error)
            }
            return resp
        });
        return defer({ promise })
    }