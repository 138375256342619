import { initializeGraph } from "../graphSlice";
import { apiSlice } from "./api";




export const alertsApi = apiSlice.enhanceEndpoints({
    addTagTypes: ['Sensors', 'Notifications', 'Triage', 'Alerts']
}).injectEndpoints({
    endpoints: builder => ({
        /************************ RANGES START ************************ */

        getNotificationRanges: builder.query({
            query: (id) => `/notifications/ranges/${id}`,
            providesTags: (result, error, arg) => ([{type: 'Sensors', id: arg}])
        }),
        updateNotificationRanges: builder.mutation({
            query: ({ id, refs }) => ({
                url: `/notifications/ranges/${id}`,
                method: "POST",
                body: {
                    ranges: refs
                }
            }),
            invalidatesTags: (result, error, args) => ([{ type: 'Sensors', id: args.id }])
        }),

        /************************ RANGES END ************************ */

        /************************ RULES START ************************ */

        getNotificationRules: builder.query({
            query: (id) => `/notifications/rules/${id}`,
            providesTags: ['Notifications'],
            transformResponse: (result) => result?.rules
        }),
        getNotificationRule: builder.query({
            query: (id) => `/rules/${id}`,
            providesTags: (result, error, arg) => ([{type: 'Notifications', id: arg}])
        }),
        deleteNotificationRule: builder.mutation({
            query: (rule_id) => ({
                url: `/notifications/rules/${rule_id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Notifications']
        }),
        buildNotification: builder.mutation({
            query: ({ id, type, rules }) => ({
                url: `/notifications/rules`,
                method: 'POST',
                body: {
                    entity_id: id,
                    entity_type: type,
                    rules: [rules]
                }
            }),
            invalidatesTags: ['Notifications']
        }),
        updateNotification: builder.mutation({
            query: ({ rule_id, entity_id, rules }) => ({
                url: `/notifications/rules/${entity_id}`,
                method: 'PUT',
                body: [
                    {
                        old_rule_id: rule_id,
                        logic_tree: rules
                    }
                ]
            }),
            invalidatesTags: (result, error, args) => ([{type: 'Notifications', id: args.entity_id}])
        }),
        updateNotifications: builder.mutation({
            query: ({ entity_id, rules }) => ({
                url: `/notifications/rules/${entity_id}`,
                method: 'PUT',
                body: rules
            }),
            invalidatesTags: (result, error, args) => ([{type: 'Notifications', id: args.entity_id}])
        }),
        validateNotification: builder.mutation({
            query: ({ id, type, rules }) => ({
                url: `/notifications/rules/validate`,
                method: 'POST',
                body: {
                    entity_id: id,
                    entity_type: type,
                    rules: [rules]
                }
            }),
            transformResponse: (result) => result?.data?.rule_ids
        }),

        /************************ RULES END ************************ */

        /************************ TRIAGE START ************************ */

        subscribeToEntityNotifications: builder.mutation({
            query: ({entity_id, triage_order}) => ({
                url: `/notifications/triage`,
                method: 'POST',
                body: {
                    entity_id,
                    triage_order
                }
            }),
            invalidatesTags: (result, error, args) => {
                return [{type: 'Triage', id: args.entity_id}]
            }
        }),
        unsubscribeToEntityNotifications: builder.mutation({
            query: ({ entity_id, triage_order }) => ({
                url: `/notifications/triage`,
                method: 'POST',
                body: {
                    entity_id,
                    triage_order
                }
            }),
            invalidatesTags: (result, error, args) => {
                return [{type: 'Triage', id: args.entity_id}]
            }
        }),
        getTriageList: builder.query({
            query: (id) => `/notifications/triage/${id}`,
            transformResponse: (result) => result?.data,
            providesTags: (result, error, arg) => ([{ type: 'Triage', id: arg }])
        }),

        /************************ TRIAGE END ************************ */

        /************************ SENSORS START ************************ */

        getSensorsForEntity: builder.query({
            query: (entity_id) => `/sensors/${entity_id}`,
            transformResponse: (result) => {
                console.log('GetSensors: ', result)
                return result?.data
            }
        }),

        getSensorsForEntityForGraph: builder.query({
            query: ({ entity_id, creation_time }) => `/sensors/${entity_id}`,
            transformResponse: (result) => result?.data,
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled
                    console.log('GetSensors Data: ', data)

                    dispatch(initializeGraph({
                        sensors: data,
                        creationDateUnixTS: args?.creation_time
                    }))
                } catch (error) {
                    console.log('Sensors Error: ', error)
                }
            }
        }),

        getAllSensors: builder.query({
            query: () => `/sensors`,
            transformResponse: (result) => result?.data
        }),

        /************************ SENSORS END ************************ */

        /************************ ALERTS START ************************ */

        getActiveAlertsForEntity: builder.query({
            query: (entity_id) => `/alerts/active/${entity_id}`,
            transformResponse: (result) => result?.data,
            providesTags: ['Alerts']
        }),
        getAlertHistoryForEntity: builder.query({
            query: (entity_id) => `/alerts/history/${entity_id}`,
            transformResponse: (result) => result?.data,
            providesTags: ['Alerts']
        }),
        getAlert: builder.query({
            query: (alert_id) => `/alerts/${alert_id}`,
            providesTags: (result, error, id) => ([{ type: 'Alerts', id }]),
            transformResponse: (result) => result?.data
        }),
        resolveAlert: builder.mutation({
            query: ({ alert_id, resolution, notes }) => {
                const body = { resolution }
                if (notes) {
                    body.notes = notes
                }

                return {
                    url: `/alerts/${alert_id}`,
                    method: 'POST',
                    body: body
                }
            },
            invalidatesTags: ['Alerts']
        })

        /************************ ALERTS END ************************ */
    })
});



export const {
    useGetNotificationRangesQuery,
    useUpdateNotificationRangesMutation,

    useGetNotificationRulesQuery,
    useGetNotificationRuleQuery,
    useDeleteNotificationRuleMutation,
    useBuildNotificationMutation,
    useUpdateNotificationMutation,
    useValidateNotificationMutation,

    useSubscribeToEntityNotificationsMutation,
    useUnsubscribeToEntityNotificationsMutation,
    useGetTriageListQuery,

    useGetSensorsForEntityQuery,
    useGetAllSensorsQuery,
    useGetSensorsForEntityForGraphQuery,

    useGetActiveAlertsForEntityQuery,
    useGetAlertHistoryForEntityQuery,
    useGetAlertQuery,
    useResolveAlertMutation
} = alertsApi;