import { Box, ClickAwayListener, Divider, FormControl, IconButton, InputLabel, LinearProgress, Menu, MenuItem, Select, Skeleton, Stack, Tooltip, Typography, useTheme } from "@mui/material"
import { SectionTitle } from "./RulesEditor"
import { LoadingButton, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator, timelineContentClasses, timelineOppositeContentClasses } from "@mui/lab"
import { AddSharp, ArrowDropDownSharp, CancelSharp, CheckCircleOutlined, CloseSharp } from "@mui/icons-material"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { selectUser } from "../../../common/redux/userSlice"
import { useGetTriageListQuery, useSubscribeToEntityNotificationsMutation, useUnsubscribeToEntityNotificationsMutation } from "../../../common/redux/api/alertsApi"
import { useIsQueryLoading } from "../../../common/util"
import { useRTKResultToasts } from "../../../common/RTKResultHooks"
import { useGetDoctorsForPatientQuery, useGetUsersListQuery } from "../../../common/redux/api/api"
import { useNavigate } from "react-router"




export default function TriageDisplay({ patient, loading }) {
    const theme = useTheme()
    const user = useSelector(selectUser)
    const navigate = useNavigate();



    const { 
        data: triageData, 
        isSuccess: isTriageSuccess, 
        isLoading: isTriageLoading, 
        isFetching: isTriageFetching, 
        isError: isTriageError, 
        error: triageError, 
        isUninitialized: isTriageUninitialized 
    } = useGetTriageListQuery(patient?.alacrity_id, { skip: loading })



    const {
        data: providers,
        isSuccess: areProvidersSuccess,
        isLoading: areProvidersLoading,
        isFetching: areProvidersFetching,
        isError: areProvidersError,
        error: providersError,
        isUninitialized: areProvidersUninitialized
    } = useGetDoctorsForPatientQuery(patient?.alacrity_id, { skip: loading })

    const providersAreLoading = useIsQueryLoading([ areProvidersLoading, areProvidersFetching, areProvidersUninitialized ])



    const [ subscribe, subscribeResult ] = useSubscribeToEntityNotificationsMutation();
    const { 
        isLoading: isSubscribeLoading, 
        isSuccess: isSubscribeSuccess, 
        isError: isSubscribeError, 
        error: subscribeError 
    } = subscribeResult;



    const [ unsubscribe, unsubscribeResult ] = useUnsubscribeToEntityNotificationsMutation();
    const { 
        isLoading: isUnsubscribeLoading, 
        isSuccess: isUnsubscribeSuccess, 
        isError: isUnsubscribeError, 
        error: unsubscribeError 
    } = unsubscribeResult;



    const triageIsLoading = useIsQueryLoading([isTriageFetching, isTriageLoading, isTriageUninitialized, isSubscribeLoading, isUnsubscribeLoading, providersAreLoading])



    const providerOptions = useMemo(() => {
        if (
            user.permissions === 'ALACRITY' || 
            (
                user.permissions === 'DOCTOR' &&
                user?.patients.includes(patient.alacrity_id)
            )
        ) {
            if (areProvidersSuccess) {
                return providers.filter((provider) => {
                    return provider?.orgs?.some((org) => user?.permissions === 'ALACRITY' || user?.orgs.includes(org))
                })
            } else {
                return []
            }
        } else {
            return []
        }
    }, [providers, areProvidersSuccess, user, patient])



    const onSelectProvider = (index, provider_id) => {
        const newTriageOrder = Object
            .entries(triageData?.triage_order)
            .toSorted(([indexA], [indexB]) => Number(indexA) - Number(indexB))
            .map(([index, triageLevel]) => triageLevel[0])

        newTriageOrder[index] = provider_id

        subscribe({
            entity_id: patient?.alacrity_id,
            triage_order: newTriageOrder.reduce((acc, subscriber, index) => {
                return {
                    ...acc,
                    [index]: subscriber
                }
            }, {})
        })
    }



    const onDeleteTriageLevel = (index) => {
        const {
            [index]: discard,
            ...newTriageOrder
        } = triageData?.triage_order;

        unsubscribe({
            entity_id: patient?.alacrity_id,
            triage_order: Object
                .entries(newTriageOrder)
                .toSorted(([indexA], [indexB]) => Number(indexA) - Number(indexB))
                .reduce((acc, [oldIndex, triageLevel], index) => ({
                    ...acc,
                    [index]: triageLevel
                }), {})
        })
    }



    const [ newLevelAnchorEl, setNewLevelAnchorEl ] = useState(null)
    
    const onAddNewTriageLevel = (e) => {
        setNewLevelAnchorEl(e.currentTarget)
    }

    const onCloseNewTriageLevelMenu = () => {
        setNewLevelAnchorEl(null)
    }

    const onSelectNewTriageLevel = (provider_id) => {
        const newIndex = Object.keys(triageData?.triage_order ?? []).length
        
        subscribe({
            entity_id: patient?.alacrity_id,
            triage_order: {
                ...triageData?.triage_order,
                [newIndex]: provider_id
            }
        })

        onCloseNewTriageLevelMenu()
    }



    return (
        <Stack
            sx={{
                height: '100%',
                width: '100%',
                borderRadius: 4,
                border: 'none',
                boxShadow: theme.alacrityShadowBorder, // 'rgba(255, 255, 255, 0.5) 0px 0px 4px 2px',
                p: 2,
                alignItems: 'start'
            }}
            spacing={2}
        >
            <SectionTitle
                variant="h4"
            >
                Notifications
            </SectionTitle>

            {
                loading || triageIsLoading ?
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                :
                    <Divider width='100%'/>
            }

            {
                (loading || triageIsLoading) ?
                    <Skeleton variant="rounded" />
                :
                    <Box
                        sx={{
                            display: 'flex',
                            overflowY: 'auto',
                            '::-webkit-scrollbar': {
                                '-webkit-appearance': 'none',
                                width: '7px'
                            },  
                            '::-webkit-scrollbar-thumb': {
                                borderRadius: '4px',
                                backgroundColor: 'rgba(0, 0, 0, .5)',
                            },
                            width: '100%',
                            justifyContent: 'start',
                        }}
                    >
                        <Timeline
                            sx={{
                                [`& .${timelineOppositeContentClasses.root}`]: {
                                    flex: 0.4
                                },
                            }}
                        >
                                {
                                    Object.entries(triageData?.triage_order ?? {}).map(([index, triageLevel], _index, triageArray) => {
                                        console.log(`Triage Level ${index}: `, triageLevel)
                                        return (
                                            <TimelineItem>

                                                {
                                                    ( index === '0' || index === `${triageArray.length - 1}` ) &&
                                                    <TimelineOppositeContent
                                                        sx={{
                                                            mt: 0.5
                                                        }}
                                                    >

                                                        <Typography
                                                            variant="h6" 
                                                            color='text.secondary'
                                                            fontWeight={700}
                                                        > 
                                                            {
                                                                (index === '0') ? 
                                                                    'Sent first'
                                                                : 
                                                                    'Sent last'
                                                            }
                                                        </Typography>
                                                        
                                                    </TimelineOppositeContent>
                                                }

                                                <TimelineSeparator sx={{ height: '10rem', mt: 1 }}>
                                                    <TimelineDot />
                                                    <TimelineConnector sx={{ my: 2 }}/>
                                                </TimelineSeparator>

                                                <TimelineContent>

                                                    <Stack
                                                        direction='row'
                                                        sx={{
                                                            alignItems: 'center',
                                                            justifyContent: 'start'
                                                        }}
                                                        spacing={2}
                                                    >
                                                    
                                                        <FormControl 
                                                            sx={{ 
                                                                minWidth: '20rem',
                                                            }}
                                                        >
                                                            <InputLabel id={`select-triage-${index}-user-label`}>Recipient</InputLabel>
                                                            
                                                            <Select
                                                                label='Recipient'
                                                                labelId={`select-triage-${index}-user-label`}
                                                                id={`select-triage-${index}-user`}
                                                                value={triageLevel}
                                                                sx={{
                                                                    '& .MuiInputBase-input': {
                                                                        fontSize: '2rem',
                                                                        pt: '1rem',
                                                                        pb: 0,
                                                                        color: theme.palette.text.secondary
                                                                    }
                                                                }}
                                                            >
                                                                {
                                                                    providerOptions
                                                                    .filter((provider) => {
                                                                        // Filter anyone already selected in another triage level. Don't filter the selected person in this level though or else they won't show up in the selected box.
                                                                        return (
                                                                            provider?.alacrity_id === triageLevel ||
                                                                            !Object.values(triageData?.triage_order).includes(provider?.alacrity_id)
                                                                        )
                                                                    })
                                                                    .map((provider) => {
                                                                        return (
                                                                            <MenuItem 
                                                                                key={provider?.alacrity_id}
                                                                                sx={{
                                                                                    color: theme.palette.text.secondary
                                                                                }}
                                                                                value={provider.alacrity_id}
                                                                                onClick={() => { onSelectProvider(index, provider.alacrity_id) }}
                                                                            >
                                                                                {`${provider?.first_name} ${provider?.last_name}`}
                                                                            </MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </FormControl>

                                                        <IconButton
                                                            color="error"
                                                            sx={{
                                                                borderRadius: 2,
                                                                height: '100%'
                                                            }}
                                                            onClick={() => onDeleteTriageLevel(index)}
                                                        >
                                                            <CloseSharp style={{ color: theme.palette.error.main }} />
                                                        </IconButton>
                                                    
                                                    </Stack>

                                                </TimelineContent>

                                            </TimelineItem>
                                        )
                                    })
                                }

                                <TimelineItem>

                                    <TimelineOppositeContent/>

                                    <TimelineContent>

                                        <LoadingButton
                                            loading={isSubscribeLoading || isUnsubscribeLoading}
                                            onClick={onAddNewTriageLevel}
                                            startIcon={<AddSharp />}
                                            variant="contained"
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: 700,
                                                py: 2,
                                                px: 3
                                            }}
                                        >
                                            Recipient
                                        </LoadingButton>

                                        <Menu
                                            anchorEl={newLevelAnchorEl}
                                            open={newLevelAnchorEl}
                                            onClose={onCloseNewTriageLevelMenu}
                                        >
                                            {
                                                providerOptions.map((provider) => {
                                                    return (
                                                        <MenuItem 
                                                            key={provider?.alacrity_id}
                                                            sx={{
                                                                color: theme.palette.text.secondary
                                                            }}
                                                            value={provider.alacrity_id}
                                                            onClick={() => { onSelectNewTriageLevel(provider.alacrity_id) }}
                                                        >
                                                            {`${provider?.first_name} ${provider?.last_name}`}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Menu>
                                                   
                                    </TimelineContent>

                                </TimelineItem>
                            
                        </Timeline>
                    </Box>
            }
        </Stack>
    )
}