import { useSelector } from "react-redux";
import { selectUser } from "../../../common/redux/userSlice";
import { Box, Button, Divider, IconButton, Modal, Paper, Skeleton, Stack, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { useGetActiveAlertsForEntityQuery, useGetAlertHistoryForEntityQuery, useGetAlertQuery, useGetTriageListQuery, useResolveAlertMutation } from "../../../common/redux/api/alertsApi";
import { useIsQueryLoading } from "../../../common/util";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { AlarmOffSharp, CheckSharp, ChevronLeftSharp, ChevronRightSharp, CloseSharp, PriorityHigh } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax';
import LoadingSplashPage from "../../LoadingSplashPage";
import { EmptyList } from "../../../components/EntityList";
import { useAlertsHistoryColumns } from "./AlertsColumns";
import { useNavigate } from "react-router";
import { useGetDoctorQuery, useGetDoctorsForPatientQuery, useGetPatientQuery, useGetUsersListQuery } from "../../../common/redux/api/api";
import { GenericModal } from "../../../components/modals/GenericModal";
import { displayRulesChild, formatJSONRules } from "./PatientAlertsPage";
import { SectionTitle } from "./RulesEditor";
import { LoadingButton, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator, timelineOppositeContentClasses } from "@mui/lab";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";
import { toast } from "react-toastify";
import UseAnimations from "react-useanimations";
import alertCircle from 'react-useanimations/lib/alertCircle'

dayjs.extend(localizedFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(minMax)




export default function AlertCenter() {
    const user = useSelector(selectUser);
    const theme = useTheme();
    const navigate = useNavigate()

    const [ currentDay, setCurrentDay ] = useState(dayjs().startOf('day'))

    console.log('Got to Alerts Center')

    const {
        data: activeAlerts,
        isLoading: areAlertsLoading,
        isFetching: areAlertsFetching,
        isSuccess: areAlertsSuccess,
        isError: areAlertsError,
        error: alertsError,
        isUninitialized: areAlertsUninitialized
    } = useGetActiveAlertsForEntityQuery(
        user?.alacrity_id,
        {
            pollingInterval: 1_000 * 60     // Every minute check for new alerts.
        }
    )

    const activeAlertsAreLoading = useIsQueryLoading([areAlertsLoading, areAlertsUninitialized])



    const {
        data: alertsHistory,
        isLoading: isHistoryLoading,
        isFetching: isHistoryFetching,
        isSuccess: isHistorySuccess,
        isError: isHistoryError,
        error: historyError,
        isUninitialized: isHistoryUninitialized
    } = useGetAlertHistoryForEntityQuery(user?.alacrity_id)

    const historyIsLoading = useIsQueryLoading([isHistoryLoading, isHistoryUninitialized])

    const minDate = useMemo(() => {
        if (isHistorySuccess) {
            return alertsHistory.reduce((acc, alert) => {
                return dayjs.min(acc, dayjs(alert?.ts))
            }, dayjs()).startOf('day')
        } else {
            return dayjs().startOf('day')
        }
    }, [isHistorySuccess, alertsHistory])

    // const currentAlerts = useMemo(() => {
    //     if (isHistorySuccess) {
    //         return alertsHistory.filter((alert) => dayjs(alert?.ts).isSame(currentDay, 'day'))
    //     } else {
    //         return []
    //     }
    // }, [alertsHistory, isHistorySuccess, currentDay])



    const {
        data: responders,
        isLoading: areRespondersLoading,
        isFetching: areRespondersFetching,
        isSuccess: areRespondersSuccess,
        isError: areRespondersError,
        error: respondersError
    } = useGetUsersListQuery(
        (alertsHistory ?? [])
            .filter((alert) => (activeAlerts ?? [])?.every((activeAlert) => activeAlert?.alert_id !== alert?.alert_id))
            .map((alert) => alert?.handler_id), 
        { 
            skip: !isHistorySuccess || !areAlertsSuccess, 
            selectFromResult: (result) => {
                if (result?.isSuccess) {
                    const { data, ...rest } = result;

                    // Convert list of providers who have responded to one of the current list into a map of alert_id to its responder.
                    const newData = data.reduce((accProvider, provider) => {
                        return {
                            ...accProvider,
                            ...alertsHistory
                                .filter((alert) => alert?.handler_id === provider?.alacrity_id)   // For each provider, get the alerts they responded to and make a map of the alert ids to them.
                                .reduce((accAlert, alert) => {
                                    return {
                                        ...accAlert,
                                        [alert?.alert_id]: provider
                                    }
                                }, {})
                        }
                    }, {})

                    return { ...rest, data: newData }
                } else {
                    return result
                }
            }
        }
    )



    const handleNavigate = useCallback((newDay) => {
        setCurrentDay(newDay)
    }, [])



    const [ openViewAlertModal, setOpenViewAlertModal ] = useState(false)

    const [ openResolveAlertModal, setOpenResolveAlertModal ] = useState(false)

    const [ selectedAlert, setSelectedAlert ] = useState(null)
    
    const viewAlert = (alertID) => {
        setSelectedAlert(alertID)
        setOpenViewAlertModal(true)
        setOpenResolveAlertModal(false)
    }



    const resolveAlert = (alertID) => {
        setSelectedAlert(alertID)
        setOpenResolveAlertModal(true)
        setOpenViewAlertModal(false)
    }



    const handleCloseModal = () => {
        setOpenResolveAlertModal(false)
        setOpenViewAlertModal(false)
        setSelectedAlert(null)
    }



    const columns = useAlertsHistoryColumns({ role: user?.permissions, viewAlert, resolveAlert, navigate })



    const rows = useMemo(() => {
        if (isHistorySuccess && areAlertsSuccess && areRespondersSuccess) {
            return alertsHistory?.filter((alert) => {
                return activeAlerts?.every((activeAlert) => activeAlert.alert_id !== alert.alert_id)
            })?.map((alert) => ({
                id: alert?.alert_id,
                alert_ts: alert?.alert_ts,
                handled_ts: alert?.handled_ts,
                active: alert?.active,
                responderID: alert?.handler_id,
                responder: `${responders[alert?.alert_id]?.first_name} ${responders[alert?.alert_id]?.last_name}`
            }))
        } else {
            return []
        } 
    }, [isHistorySuccess, areAlertsSuccess, activeAlerts, responders, areRespondersSuccess, alertsHistory])

    console.log('Rows: ', rows)



    const pinnedRows = useMemo(() => {
        if (areAlertsSuccess) {
            return {
                top: activeAlerts.map((alert) => ({
                    id: alert?.alert_id,
                    alert_ts: alert?.alert_ts,
                    responderID: '',
                    responder: 'None',
                    handled_ts: null,
                    active: true
                })),
                bottom: []
            }
        } else {
            return []
        }
    }, [areAlertsSuccess, activeAlerts])
    
    
    
    return (
        <>
        <Stack
            justifyContent='start'
            alignItems='center'
            spacing={2}
            sx={{
                px: 4,
                pt: 2,
                pb: 12,
                height: '100%',
                width: '100%'
            }}
        >

            <Typography
                variant="h3"
                fontWeight={650}
            >
                Alert Center
            </Typography>

            <Divider width='100%'/>

            <Paper
                elevation={2} // Background color for rows
                sx={{
                    height: '100%',
                    width: '100%',
                }}
            >
                <DataGridPremium
                    loading={activeAlertsAreLoading || historyIsLoading || areRespondersLoading}
                    // pagination
                    columns={columns}
                    rows={rows}
                    pinnedRows={pinnedRows}
                    rowHeight={64}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                id: user?.permissions === 'ALACRITY',
                                responderID: user?.permissions === 'ALACRITY'
                            }   
                        }
                    }}
                    slots={{
                        toolbar: GridToolbar,
                        // pagination: CustomPagination,
                        loadingOverlay: LoadingSplashPage,
                        noRowsOverlay: EmptyNoRowsOverlay // EmptyList
                    }}
                    slotProps={{
                        columnsPanel: {
                            getTogglableColumns: () => columns.filter((column) => column.field !== 'id' && column.field !== 'responderID').map((column) => column.field)
                        },
                        // pagination: {
                        //     handleNavigate,
                        //     currentDate: currentDay,
                        //     minDate
                        // },
                    }}
                    sx={{
                        // width: '100%',
                        '& .MuiDataGrid-cell': {
                            // pl: 2,
                            fontSize: 24,
                            // textAlign: 'center',
                            '&:focus': {
                                outline: 'none'
                            }
                        },
                        '& .MuiDataGrid-row.Mui-selected': {
                            backgroundColor: '#121212',
                            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))',
                            '&:hover': {
                                backgroundColor: '#121212',
                                backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))',
                            }
                        },
                        '& .MuiDataGrid-toolbarContainer': {
                            backgroundColor: '#212121'
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#212121'
                        },
                        '& .MuiDataGrid-footerContainer': {
                            backgroundColor: '#212121'
                        },
                        '& .MuiDataGrid-virtualScroller': {
                            '::-webkit-scrollbar': {
                                '-webkit-appearance': 'none',
                                width: '7px' 
                            },  
                            '::-webkit-scrollbar-thumb': {
                                borderRadius: '4px',
                                backgroundColor: 'rgba(255, 255, 255, .8)',
                            }
                        }
                    }}
                    autosizeOnMount
                    autosizeOptions={{
                        columns: ['responder', 'active', 'alert_ts', 'handled_ts'],
                        includeOutliers: true,
                        includeHeaders: true,
                        expand: true
                    }}
                />
            </Paper>
        </Stack>

        {
            openViewAlertModal &&
            <ViewAlertModal
                open={openViewAlertModal}
                alertID={selectedAlert}
                handleClose={handleCloseModal}
            />
        }

        {
            openResolveAlertModal &&
            <ResolveAlertModal
                open={openResolveAlertModal}
                alertID={selectedAlert}
                handleClose={handleCloseModal}
            />
        }

        </>
    )
}



const CustomPagination = ({handleNavigate, currentDate, minDate }) => {
    const theme = useTheme()
    const isToday = useMemo(() => {
        return currentDate.isSame(dayjs(), 'day')
    }, [currentDate])



    return (
        <Stack
            direction='row'
            spacing={1}
            sx={{
                justifyContent: 'end',
                alignItems: 'center',
                width: '100%',
                pr: '2rem',
                py: '1rem',
            }}
        >
            <Tooltip
                title={'Previous Day'}
            >
                <IconButton
                    disabled={currentDate.isSameOrBefore(minDate)}
                    onClick={() => handleNavigate(currentDate.subtract(1, 'day').startOf('day'))}
                >
                    <ChevronLeftSharp style={{ color: 'white' }} />
                </IconButton>
            </Tooltip>

            <DatePicker
                value={currentDate}
                onChange={(newDate) => { handleNavigate(newDate.startOf('day')) }}
                disableFuture
                minDate={minDate}
            />

            <Tooltip
                title={'Next Day'}
            >
                <IconButton
                    disabled={isToday}
                    onClick={() => handleNavigate(currentDate.add(1, 'day').startOf('day'))}
                >
                    <ChevronRightSharp style={{ color: (isToday) ? theme.palette.text.secondary: 'white' }} />
                </IconButton>
            </Tooltip>

        </Stack>
    )
}



const ViewAlertModal = ({ open, alertID, handleClose }) => {
    const MODAL_HEIGHT = '75%'
    const MODAL_WIDTH = '75%'

    const theme = useTheme()



    const {
        data: alert,
        isLoading: isAlertLoading,
        isFetching: isAlertFetching,
        isSuccess: isAlertSuccess,
        isError: isAlertError,
        error: alertError,
        isUninitialized: isAlertUninitialized
    } = useGetAlertQuery(alertID)

    const formattedRule = useMemo(() => {
        if (isAlertSuccess) {
            formatJSONRules(alert?.logic_tree)
        } else {
            return ''
        }
    }, [alert, isAlertSuccess])



    const {
        data: patient,
        isLoading: isPatientLoading,
        isFetching: isPatientFetching,
        isSuccess: isPatientSuccess,
        isError: isPatientError,
        error: patientError,
        isUninitialized: isPatientUninitialized
    } = useGetPatientQuery(
        alert?.entity_id,
        {
            skip: !isAlertSuccess
        }
    )



    const {
        data: responder,
        isLoading: isResponderLoading,
        isFetching: isResponderFetching,
        isSuccess: isResponderSuccess,
        isError: isResponderError,
        error: responderError,
        isUninitialized: isResponderUninitialized
    } = useGetDoctorQuery(
        alert?.responder_id,
        {
            skip: !isAlertSuccess
        }
    )



    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Stack
                sx={{
                    width: MODAL_WIDTH,
                    height: MODAL_HEIGHT,
                    justifyContent: 'start',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    boxShadow: 24,
                    p: 4,
                    backgroundColor: '#212121'
                }}
                spacing={1}
            >
                <Stack
                    direction='row'
                    spacing={2}
                    sx={{
                        width: '100%',
                        height: '100%'
                    }}
                >
                    <Stack
                        sx={{
                            width: '50%',
                            height: '100%',
                            justifyContent: 'start',
                            alignItems: 'center'
                        }}
                        spacing={2}
                    >
                        <Stack
                            sx={{
                                width: '100%',
                                height: '50%',
                                justifyContent: 'start',
                                alignItems: 'center',
                                borderRadius: 4,
                                border: 'none',
                                boxShadow: theme.alacrityShadowBorder,
                                p: 2,
                            }}
                            spacing={2}
                        >
                            <ViewAlertInfoText
                                title='Patient:'
                                text={`${patient?.first_name} ${patient?.last_name}`}
                                loading={isPatientLoading}
                            />

                            <ViewAlertInfoText
                                title='Date Activated:'
                                text={dayjs(alert?.alert_ts).format('L LT')}
                                loading={isAlertLoading}
                            />

                            <ViewAlertInfoText
                                title='Date Handled:'
                                text={dayjs(alert?.handled_ts).format('L LT')}
                                loading={isAlertLoading}
                            />

                            <ViewAlertInfoText
                                title='Handled By:'
                                text={`${responder?.first_name} ${responder?.last_name}`}
                                loading={isResponderLoading}
                            />

                            <ViewAlertInfoText
                                title='Last Ping:'
                                text={dayjs(alert?.last_ping).format('L LT')}
                                loading={isAlertLoading}
                            />

                            <ViewAlertInfoText
                                title='Times Pinged:'
                                text={alert?.current_triage}
                                loading={isAlertLoading}
                            />
                        </Stack>
                        
                        <Box
                            sx={{
                                borderRadius: 4,
                                border: 'none',
                                boxShadow: theme.alacrityShadowBorder,
                                p: 2,
                                height: '50%',
                                width: '100%'
                            }}
                        >
                            {
                                (isAlertLoading) ?
                                    <LoadingSplashPage />
                                :
                                    displayRulesChild(formattedRule)                                    
                            }
                        </Box>

                    </Stack>

                    <Stack
                        sx={{
                            width: '50%',
                            height: '100%',
                            justifyContent: 'start',
                            alignItems: 'center'
                        }}
                        spacing={2}
                    >

                        <TriageTree
                            loading={isAlertLoading}
                            patient_id={alert?.entity_id}
                            currentTriageLevel={Math.min(Object.keys(alert?.triage_order ?? {}).length - 1, Number(alert?.current_triage))}
                            active={false}
                        />

                        <Stack
                            sx={{
                                width: '100%',
                                height: '50%',
                                justifyContent: 'start',
                                alignItems: 'start',
                                borderRadius: 4,
                                border: 'none',
                                boxShadow: theme.alacrityShadowBorder,
                                p: 2
                            }}
                            spacing={1}
                        >
                            <SectionTitle>
                                Notes
                            </SectionTitle>

                            <Divider width='100%' />

                            <Typography>
                                {
                                    (isAlertLoading) ?
                                        <Skeleton width='100%' height='100%' variant="rounded" />
                                    :
                                        alert?.notes
                                }
                            </Typography>

                        </Stack>
                    </Stack>

                </Stack>

                <Button
                    variant="contained"
                    color="error"
                    startIcon={<CloseSharp/>}
                    sx={{
                        p: 2
                    }}
                    onClick={handleClose}
                >
                    Close
                </Button>
            </Stack>
        </Modal>
    )
}



const ViewAlertInfoText = ({ text, title, loading }) => {
    return (
        <Stack
            direction='row'
            sx={{
                width: '100%',
                justifyContent: 'start',
                alignItems: 'center'
            }}
            spacing={1}
        >
            <Typography
                variant="h6"
                fontWeight={650}
            >
                {title}
            </Typography>

            <Typography
                variant="h6"
                fontWeight={650}
            >
                {(loading) ? <Skeleton width='10rem' /> : text}
            </Typography>
        </Stack>
    )
}



const TriageTree = ({ patient_id, loading, currentTriageLevel, active }) => {


    const { 
        data: triageData, 
        isSuccess: isTriageSuccess, 
        isLoading: isTriageLoading, 
        isFetching: isTriageFetching, 
        isError: isTriageError, 
        error: triageError, 
        isUninitialized: isTriageUninitialized 
    } = useGetTriageListQuery(patient_id, { skip: loading })



    const {
        data: providers,
        isSuccess: areProvidersSuccess,
        isLoading: areProvidersLoading,
        isFetching: areProvidersFetching,
        isError: areProvidersError,
        error: providersError,
        isUninitialized: areProvidersUninitialized
    } = useGetDoctorsForPatientQuery(patient_id, { skip: loading })

    const providersAreLoading = useIsQueryLoading([ areProvidersLoading, areProvidersFetching, areProvidersUninitialized ])



    return (
        <Box
            sx={{
                // display: 'flex',
                overflowY: 'auto',
                '::-webkit-scrollbar': {
                    '-webkit-appearance': 'none',
                    width: '7px'
                },  
                '::-webkit-scrollbar-thumb': {
                    borderRadius: '4px',
                    backgroundColor: 'rgba(0, 0, 0, .5)',
                },
                width: '100%',
                height: '50%',
                justifyContent: 'start',
            }}
        >
            {
                (isTriageLoading || providersAreLoading || loading) ?
                    <LoadingSplashPage/>
                :
                    <Timeline
                        sx={{
                            [`& .${timelineOppositeContentClasses.root}`]: {
                                flex: 0.4
                            },
                        }}
                    >
                        {
                            Object.entries(triageData?.triage_order ?? {}).map(([index, triageLevel], _index, triageArray) => {
                                console.log(`Triage Level: `, currentTriageLevel === Number(index), currentTriageLevel, index)
                                return (
                                    <TimelineItem>

                                        {
                                            ( index === '0' || Number(index) === currentTriageLevel ) &&
                                            <TimelineOppositeContent>

                                                <Typography
                                                    variant="h6" 
                                                    color='text.secondary'
                                                    fontWeight={700}
                                                > 
                                                    {
                                                        (index === '0') ? 
                                                            'Sent first'
                                                        : 
                                                            'Sent last'
                                                    }
                                                </Typography>
                                                
                                            </TimelineOppositeContent>
                                        }

                                        <TimelineSeparator sx={{ height: '10rem' }}>
                                            {
                                                (currentTriageLevel > Number(index)) ?
                                                    <TimelineDot
                                                        color="info"
                                                        variant="outlined"
                                                    >
                                                        <CloseSharp style={{ color: 'red' }} />
                                                    </TimelineDot>
                                                :
                                                    (currentTriageLevel === Number(index)) ?
                                                        (active) ?
                                                            <TimelineDot color="primary" sx={{ p: 0 }}>
                                                                <UseAnimations animation={alertCircle} size={30} />
                                                            </TimelineDot>
                                                        :
                                                            <Timeline color="success" />
                                                    :
                                                        <TimelineDot color="warning" />
                                            }
                                            <TimelineConnector sx={{ my: 2 }}/>
                                        </TimelineSeparator>

                                        <TimelineContent>

                                            <Typography
                                                variant="h6"
                                                fontWeight={650}
                                                color='info'
                                            >
                                                { 
                                                    (areProvidersLoading) ?
                                                        <Skeleton width='4rem' />
                                                    :
                                                        providers
                                                            .filter((provider) => triageLevel === provider?.alacrity_id)
                                                            .map((provider) => `${provider?.first_name} ${provider?.last_name}`)
                                                            .at(0) ?? 'Provider Not Found'
                                                }
                                            </Typography>

                                        </TimelineContent>

                                    </TimelineItem>
                                )
                            })
                        }
                    </Timeline>
            }
        </Box>
    )
}



const ResolveAlertModal = ({ alertID, handleClose, open }) => {
    const MODAL_HEIGHT = '75%'
    const MODAL_WIDTH = '75%'

    const theme = useTheme()



    const [ notes, setNotes ] = useState('')

    const [ openConfirmationModal, setOpenConfirmationModal ] = useState(false)



    const {
        data: alert,
        isLoading: isAlertLoading,
        isFetching: isAlertFetching,
        isSuccess: isAlertSuccess,
        isError: isAlertError,
        error: alertError,
        isUninitialized: isAlertUninitialized
    } = useGetAlertQuery(alertID)

    const formattedRule = useMemo(() => {
        if (isAlertSuccess) {
            return formatJSONRules(alert?.logic_tree)
        } else {
            return ''
        }
    }, [alert, isAlertSuccess])



    const {
        data: patient,
        isLoading: isPatientLoading,
        isFetching: isPatientFetching,
        isSuccess: isPatientSuccess,
        isError: isPatientError,
        error: patientError,
        isUninitialized: isPatientUninitialized
    } = useGetPatientQuery(
        alert?.patient_id,
        {
            skip: !isAlertSuccess
        }
    )



    const [ resolveAlert, resolveResult ] = useResolveAlertMutation()
    const { isLoading: isResolutionLoading, isSuccess: isResolutionSuccess, isError: isResolutionError, error: resolutionError } = resolveResult;



    const handleResolve = useCallback((resolution) => {
        if (resolution === 'resolved' && notes === '') {
            setOpenConfirmationModal(true)
        } else {
            resolveAlert({
                alert_id: alertID,
                resolution,
                notes
            })
        }
    }, [notes, alertID, resolveAlert])


    const handleConfirmResolve = useCallback(() => {
        resolveAlert({
            alert_id: alertID,
            resolution: 'resolved',
            notes
        })
    }, [alertID, resolveAlert, notes])

    const handleConfirmCancel = useCallback(() => {
        setOpenConfirmationModal(false)
        // handleClose()
    }, [])



    useEffect(() => {
        if (isResolutionSuccess) {
            handleClose()
        } else if (isResolutionError) {
            resolveResult.reset()
            toast.error(resolutionError?.error, {
                autoClose: 2_000,
                position: 'top-center',
                toastId: 'resolve-error'
            })
        }
    }, [isResolutionError, isResolutionSuccess, resolutionError, resolveResult, handleClose])


    return (
        <>
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Stack
                sx={{
                    width: MODAL_WIDTH,
                    height: MODAL_HEIGHT,
                    justifyContent: 'start',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    boxShadow: 24,
                    p: 4,
                    backgroundColor: '#212121'
                }}
                spacing={1}
            >
                <Stack
                    direction='row'
                    spacing={2}
                    sx={{
                        width: '100%',
                        height: '100%'
                    }}
                >
                    <Stack
                        sx={{
                            width: '50%',
                            height: '100%',
                            justifyContent: 'start',
                            alignItems: 'center'
                        }}
                        spacing={2}
                    >
                        <Stack
                            sx={{
                                width: '100%',
                                height: '50%',
                                justifyContent: 'start',
                                alignItems: 'center',
                                borderRadius: 4,
                                border: 'none',
                                boxShadow: theme.alacrityShadowBorder,
                                p: 2,
                            }}
                            spacing={2}
                        >
                            <ViewAlertInfoText
                                title='Patient:'
                                text={`${patient?.first_name} ${patient?.last_name}`}
                                loading={isPatientLoading}
                            />

                            <ViewAlertInfoText
                                title='Date Activated:'
                                text={dayjs(alert?.alert_ts).format('L LT')}
                                loading={isAlertLoading}
                            />

                            <ViewAlertInfoText
                                title='Last Ping:'
                                text={dayjs(alert?.last_ping).format('L LT')}
                                loading={isAlertLoading}
                            />

                            <ViewAlertInfoText
                                title='Times Pinged:'
                                text={alert?.current_triage}
                                loading={isAlertLoading}
                            />
                        </Stack>
                        
                        <Box
                            sx={{
                                borderRadius: 4,
                                border: 'none',
                                boxShadow: theme.alacrityShadowBorder,
                                p: 2,
                                height: '50%',
                                width: '100%'
                            }}
                        >
                            {
                                (isAlertLoading) ?
                                    <LoadingSplashPage />
                                :
                                    displayRulesChild({child: formattedRule, color: theme.palette.secondary.main})                                    
                            }
                        </Box>

                    </Stack>

                    <Stack
                        sx={{
                            width: '50%',
                            height: '100%',
                            justifyContent: 'start',
                            alignItems: 'center'
                        }}
                        spacing={2}
                    >

                        <TriageTree
                            loading={isAlertLoading}
                            patient_id={alert?.patient_id}
                            currentTriageLevel={Math.min(alert?.current_triage, Object.keys(alert?.triage_order ?? {}).length - 1)}
                            active={true}
                        />

                        <Stack
                            sx={{
                                width: '100%',
                                height: '50%',
                                justifyContent: 'start',
                                alignItems: 'start',
                                borderRadius: 4,
                                border: 'none',
                                boxShadow: theme.alacrityShadowBorder,
                                p: 2
                            }}
                            spacing={2}
                        >
                            <SectionTitle>
                                Notes
                            </SectionTitle>

                            <TextField
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                                label='Notes'
                                placeholder="Enter notes here"
                                multiline
                                rows={12}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            />

                        </Stack>
                    </Stack>

                </Stack>

                <Stack
                    direction='row'
                    spacing={2}
                    sx={{
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 1
                    }}
                >

                    <Button
                        variant="contained"
                        color="error"
                        startIcon={<CloseSharp/>}
                        onClick={handleClose}
                        sx={{
                            p: 2
                        }}
                    >
                        Close
                    </Button>

                    <LoadingButton
                        loading={ isPatientLoading || isResolutionLoading || isAlertLoading }
                        variant="contained"
                        color="success"
                        startIcon={<CheckSharp/>}
                        sx={{
                            p: 2
                        }}
                        onClick={() => handleResolve('resolved')}
                    >
                        Resolve
                    </LoadingButton>

                    <LoadingButton
                        loading={ isPatientLoading || isResolutionLoading || isAlertLoading  }
                        variant="contained"
                        color="warning"
                        startIcon={<AlarmOffSharp/>}
                        sx={{
                            p: 2
                        }}
                        onClick={() => handleResolve('false_alarm')}
                    >
                        False Alarm
                    </LoadingButton>

                </Stack>
            </Stack>
        </Modal>
        
        {
            openConfirmationModal &&
            <GenericModal
                open={openConfirmationModal}
            >
                <PriorityHigh style={{ color: theme.palette.warning.main }} />

                <Typography
                    variant="subtitle1"
                    color='info'
                    textAlign='center'
                >
                    Your notes are empty. Would you like to add any before resolving?
                </Typography>

                <Stack
                    direction='row'
                    spacing={2}
                    sx={{
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 2
                    }}
                >
                    <Button
                        variant="contained"
                        color="error"
                        startIcon={<CloseSharp />}
                        onClick={handleConfirmCancel}
                        sx={{
                            p: 2
                        }}
                    >
                        Cancel
                    </Button>

                    <LoadingButton
                        loading={isResolutionLoading}
                        color="success"
                        variant="contained"
                        startIcon={<CheckSharp/>}
                        onClick={handleConfirmResolve}
                        sx={{
                            p: 2
                        }}
                    >
                        Resolve Anyways
                    </LoadingButton>
                </Stack>
            </GenericModal>
        }
        </>
    )
}



const EmptyNoRowsOverlay = () => (<></>)