import { Typography, Container, Box, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";
import { redirect, useNavigate } from "react-router-dom";
import { apiSlice } from "../../common/redux/api/api";
import { prefillUser } from "../../common/redux/userSlice";
import Cookies from "js-cookie";
import { useLoginWithCodeMutation } from "../../common/redux/api/auth/authApi";
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';
import FormInput from "../../components/CustomFormInput";
import { toast } from 'react-toastify';



export default function SignUp() {
    const [ submitCode, result ] = useLoginWithCodeMutation();
    const { data, isLoading, isError, error, isSuccess } = result;
    const navigate = useNavigate();



    const signUpSchema = z.object({
        code: z.string().min(1, 'Please enter your code'),
    });

    const defaultValues = {
        code: '',
    }

    const methods = useForm({
        resolver: zodResolver(signUpSchema),
        defaultValues,
    });

    const { reset, handleSubmit, formState: { isSubmitSuccessful } } = methods;


    const onSubmitHandler = (values) => {
        submitCode(values.code);
    };



    useEffect(() => {
        if (isSuccess) {
            toast.info('Please confirm your account info.', {
                autoClose: 1000,
                toastId: 'signup-success',
                theme: 'dark'
            });
            navigate('/verifyUser');
        }
        if (isError) {
            console.log(error);
            if (Array.isArray((error))) {
                (error).data.error.forEach((el) =>
                    toast.error(el.message, {
                        position: 'top-center', 
                        autoClose: 1000,
                        theme: 'dark'
                    })
                );
            } else {
                toast.error((error).error, {
                    position: 'top-center', 
                    autoClose: 1000,
                    theme: 'dark'
                });
            }
        }
        }, [isSuccess, isError, error]);
    
    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
        }
    }, [isSubmitSuccessful]);



    return(
        <Container
            sx={{
                width: { xl: '35%', md: '50%', xs: '90%' },
                justifyContent: 'center',
                alignItems: 'center',
                my: '2rem'
            }}
        >
            <FormProvider {...methods}>
                <Box
                    display='flex'
                    flexDirection='column'
                    component='form'
                    noValidate
                    autoComplete='off'
                    sx={{ 
                        paddingRight: { sm: '3rem' },
                    }}
                    onSubmit={handleSubmit(onSubmitHandler)}
                >
                     <Typography 
                        variant="h1" 
                        textAlign={'center'}
                        sx={{
                            my: '1rem'
                        }}
                    >
                        Sign Up
                    </Typography>

                    <Typography 
                        variant="h5" 
                        textAlign={'center'}
                        sx={{
                            my: '1rem',
                            color: '#555555'
                        }}
                    >
                        Enter the code given to you by your provider.
                    </Typography>

                    <Divider sx={{ height: '3rem', width: 0 }} />

                    <FormInput
                        type='text'
                        label='Code'
                        name='code'
                        required
                    />

                    <Divider sx={{ height: '3rem', width: '0' }} />

                    <LoadingButton
                        loading={isLoading}
                        type='submit'
                        variant='xxl'
                        sx={{
                        py: '0.8rem',
                        mt: 2,
                        marginInline: 'auto',
                        }}
                    >
                        Submit
                    </LoadingButton>
                </Box>
            </FormProvider>
        </Container>
    )
    // return (
    //     <ThemeProvider theme={AlacrityTheme}>
    //         <Banner />
    //         <AppBar 
    //             position="static" 
    //             sx={{
    //                 height: '3rem',
    //                 mt: '1rem'
    //             }}
    //         >

    //         </AppBar>
    //          <Stack
    //             sx={{
    //                 alignItems: 'center'
    //             }}
    //             spacing={2}
    //          >
                // <Typography 
                //     variant="h1" 
                //     textAlign={'center'}
                //     sx={{
                //         my: '1rem'
                //     }}
                // >
                //     Sign Up
                // </Typography>
                // <Typography 
                //     variant="h5" 
                //     textAlign={'center'}
                //     sx={{
                //         width: '35%'
                //     }}
                // >
                //     Enter the code given to you by your provider.
                // </Typography>
    //             <TextField
    //                 sx={{
    //                     width: '35%'
    //                 }}
    //                 value={value}
    //                 error={error}
    //                 onChange={(event) => { setValue(event.target.value) }}
    //                 helperText={helperText}
    //                 variant='filled'
    //             />
    //             <Button 
    //                 variant="contained" 
    //                 size="large"
    //                 onClick={onSubmit}
    //             >
    //                 Submit
    //             </Button>
    //         </Stack>
    //     </ThemeProvider>
    // )
}



export const signUpActionRouter = ({ dispatch }) =>
    async ({ params, request }) => {
        let formData = await request.formData();
        const intent = formData.get("intent");

        switch(intent) {
            case 'loginWithCode':
                const code = formData.get("code")
                const res = await LoginWithCodeAction({ dispatch, code })
                if (!res.ok) throw res
                else return { ok: true };
            default:
                throw Error("Unreachable! The default case should never activate.")
        }
    }



export const LoginWithCodeAction = async ({ dispatch, code }) => {
    const error = await dispatch(apiSlice.endpoints.loginWithCode.initiate(code)).then(resp => {
        const loginInfo = resp.data.data;
        const newToken = loginInfo.token;
        const error = resp.error.message;

        if (error.length > 0) {
            console.log(error);
            return error;
        } else {
            Cookies.set('alacrity-user-jwt', newToken, { secure: true });
            dispatch(prefillUser(loginInfo))
            return redirect(`/signUp/verify`);
        }
    });

    return error
}