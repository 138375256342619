import config from "../../../../config";
import { Mutex } from 'async-mutex';
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { logout } from "../../userSlice";
import { redirect, useNavigate } from "react-router";
import { toast } from 'react-toastify';
import { userApi } from "../userApi";
import { authApi } from "./authApi";



const baseUrl = `${config.staging.db.host}/`;



const mutex = new Mutex();



const baseQuery = fetchBaseQuery({
    baseUrl,
    mode: 'cors',
	credentials: 'include'
});



export const customFetchBase = async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if (result?.error?.status === 401 && result?.error?.data?.message === 'access token expired') {
		if (!mutex.isLocked()) {
			const release = await mutex.acquire();
	
			try {
				const refreshResult = await baseQuery(
					{ url: 'auth/refresh' },
					api,
					extraOptions
				);
		
				if (refreshResult?.error) {
					api.dispatch(authApi.endpoints.logoutUser());
					// window.location.href = '/login';
				} else {
					// Retry the initial query
					result = await baseQuery(args, api, extraOptions);
				}
			} finally {
				// release must be called once the mutex should be released again.
				release();
			}
		} else {
			// wait until the mutex is available without locking it
			await mutex.waitForUnlock();
			result = await baseQuery(args, api, extraOptions);
		}
    } else if (result?.error?.status === 403 && result?.error?.data?.message === 'forbidden') {
		// redirect('/login')
		// toast.error("Your session has expired.", { position: 'top-center', autoClose: 1000, toastId: 'token_expired' })
		api.dispatch(authApi.endpoints.logoutUser.initiate());
	}
  
	return result;
};