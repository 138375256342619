import * as React from "react";

const NotFound = () => {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <p>There's nothing here: 404!</p>
        </div>
    )
};

export default NotFound;
