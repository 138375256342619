import { useMemo } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DoNotDisturbOn from "@mui/icons-material/DoNotDisturbOn";
import { CreateSharp, Edit, EditSharp, InsertLinkSharp, PreviewSharp } from "@mui/icons-material";

import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Button } from "@mui/material";
dayjs.extend(localizedFormat);



export function usePatientColumns(role, deactivatePatient, linkPatientToDoctor, linkPatientToTrial) {
    return useMemo( () => {
        return [
            {
                field: "id",
                headerName: "ID",
                type: "string",
                disableExport: (role !== "ALACRITY"),
                filterable: (role === "ALACRITY"),
                hideable: (role === "ALACRITY"),
            },
            {
                field: "pharma_id",
                headerName: "Trial ID",
                type: "string",
                flex: 0.5,
                align: "left",
                headerAlign: "left",
            },
            {
                field: "first_name",
                type: "string",
                hideable: false,
            },
            {
                field: "last_name",
                type: "string",
                hideable: false
            },
            {
                field: "full_name",
                type: "string",
                headerName: "Name",
                flex: 0.5,
                align: "left",
                headerAlign: "left",
                disableExport: true,
                filterable: false,
                valueGetter: (params) => {
                    return `${params.row.first_name || ''} ${params.row.last_name || ''}`;
                }
            },
            {
                field: "events", 
                headerName: "Events", 
                description: "Total # of patients treated by since joining Alacrity.",
                type: "number",
                flex: 0.2,
                align: "left",
                headerAlign: "left"
            },
            {
                field: "contact_num",
                headerName: "Phone Number",
                type: "string",
                flex: 0.5,
                align: "left",
                headerAlign: "left"
            },
            {
                field: "status",
                headerName: "Status",
                type: "string",
                flex: 0.5,
                align: "left",
                headerAlign: "left",
                valueFormatter: (params) => {
                    return (params.value) ? "Active" : "Inactive"
                }
            },
            {
                field: "enroll_date",
                headerName: "Date Enrolled",
                type: "date",
                flex: 0.5,
                align: "left",
                headerAlign: "left"
            },
            {
                field: "actions",
                type: "actions",
                flex: 0.1,
                getActions: (params) => {
                    let actions = [];

                    if (role === 'ALACRITY' || role === 'ADMIN' || role === 'DOCTOR') {
                        actions.push(
                            <GridActionsCellItem
                                icon={<DoNotDisturbOn />}
                                label="Deactivate"
                                onClick={deactivatePatient(params.id)}
                                showInMenu
                            />
                        )
                    }

                    if (role === 'ALACRITY' || role === 'DOCTOR' || role === 'ADMIN') {
                        actions.push(
                            <GridActionsCellItem
                                icon={<InsertLinkSharp />}
                                label="Assign a Doctor"
                                onClick={() => linkPatientToDoctor(params)}
                                showInMenu
                            />
                        )

                        actions.push(
                            <GridActionsCellItem
                                icon={<InsertLinkSharp />}
                                label="Add to Trial"
                                onClick={() => linkPatientToTrial(params)}
                                showInMenu
                            />
                        )
                    }

                    return actions
                }
            }
        ]
    }, [role, deactivatePatient, linkPatientToDoctor, linkPatientToTrial]) 
}



export function useLabsColumns(role) {
    return useMemo(() => {
        return [
            {
                field: "id",
                type: "string",
                disableExport: (role !== "ALACRITY"),
                filterable: (role === "ALACRITY"),
                hideable: (role === "ALACRITY"),
                align: "left",
                headerAlign: "left",
                
            },
            {
                field: "name",
                minWidth: 200,
                headerName: "Name",
                type: "string",
                align: "left",
                headerAlign: "left",
            },
            {
                field: "ref_min",
                headerName: "Min. Ref.",
                type: "number",
                align: "left",
                headerAlign: "left",
            },
            {
                field: "ref_max",
                headerName: "Max. Ref.",
                type: "number",
                align: "left",
                headerAlign: "left",
            },
            {
                field: "unit",
                headerName: 'Unit',
                type: "string",
                align: "left",
                headerAlign: "left",
            },
            {
                field: "ts",
                minWidth: 300,
                headerName: "Time",
                align: "left",
                headerAlign: "left",
                valueFormatter: (params) => {
                    return dayjs(params.value).format('LTS')
                },
            },
            {
                field: "value",
                headerName: "Value",
                type: "number",
                align: "left",
                headerAlign: "left",
            },
        ]
    }, [role])
}



export function useSymptomsColumns(role, beginForm, continueForm, viewForm) {
    return useMemo(() => {
        return [
            {
                field: "id",
                type: "string",
                disableExport: (role !== "ALACRITY"),
                filterable: (role === "ALACRITY"),
                hideable: (role === "ALACRITY"),
                align: "left",
                headerAlign: "left",
                
            },
            {
                field: "symptom",
                headerName: "Symptom",
                type: "string",
                align: "left",
                headerAlign: "left",
            },
            {
                field: "severity",
                headerName: 'Severity',
                type: "string",
                align: "left",
                headerAlign: "left",
            },
            {
                field: "ts",
                headerName: "Time",
                type: "string",
                align: "left",
                headerAlign: "left",
                valueFormatter: (params) => {
                    return dayjs(params.value).format('HH:mm')
                },
            },
            {
                field: "actions",
                headerName: "Form Completed?",
                type: "actions",
                align: 'center',
                headerAlign: "center",
                getActions: (params) => {
                    switch(params.row.completed) {
                        case 'incomplete':
                            return ([
                                <GridActionsCellItem 
                                    icon={<CreateSharp style={{ color: "green" }} />}
                                    label="Begin"
                                    onClick={() => beginForm(params.row.id)}
                                />
                            ])
                        case 'completed':
                            return ([
                                <GridActionsCellItem
                                    icon={<PreviewSharp/>}
                                    label="View"
                                    onClick={() => viewForm(params.row.id)}
                                />
                            ])
                        default: return []
                    }
                },
                renderCell: (params) => {
                    return (
                        <Button
                            color="info"
                            endIcon={(params.row.completed === 'completed') ? <PreviewSharp style={{ color: 'green' }} /> : <EditSharp style={{ color: 'red' }} />}
                            onClick={() => { 
                                if (params.row.completed === 'completed') {
                                    viewForm(params.row.id)
                                } else {
                                    beginForm(params.row.id)
                                }
                             }}
                        >
                            {params.row.completed}
                        </Button>
                    )
                }
            }
        ]
    }, [role, beginForm, viewForm])
}