import { Box, Container, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { countries } from 'country-flag-icons'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { isSupportedCountry } from 'react-phone-number-input'
import { getCountryCallingCode } from 'react-phone-number-input';
import InputMask from 'react-input-mask';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';



export default function PhoneNumberInput({focused, required, disabled=false}) {
    const inputName = 'phone'
    const selectName = 'countryCode'
    const inputRef = useRef(null)

    const [ flagList, setFlagList ] = useState([]);
    const [ selectedCountry, setSelectedCountry ] = useState('US');
    const [ selectedCountryCode, setSelectedCountryCode ] = useState(1)

    useEffect(() => {
        setSelectedCountryCode(getCountryCallingCode(selectedCountry))
    }, [selectedCountry, setSelectedCountryCode])

    useEffect(() => {
        setFlagList(countries.filter((country) => { return isSupportedCountry(country) }))
    }, [setFlagList])



    const {
		control,
        resetField,
		formState: { errors },
    } = useFormContext();



    const phoneNumberMask = useMemo(() => {
        if (selectedCountry === 'US') {
            return `+1 (999)999-9999`
        } else {
            return `+${selectedCountryCode} ${'9'.repeat(10)}`
        }
    }, [selectedCountry, selectedCountryCode])

    useEffect(() => {
        resetField(inputName)
    }, [resetField, selectedCountry])



    const onSelectCountry = useCallback(({event, onChange}) => {
        setSelectedCountry(event.target.value); 
        onChange(event)
    }, [setSelectedCountry])
    


    return (
        <Stack
            direction='row'
            alignItems='start'
            justifyContent='start'
            sx={{
                width: '100%',
                // height: '7rem'
            }}
        >
             <Controller
                control={control}
                name={selectName}
                defaultValue={1}
                render={({ field: { onChange, ...fields } }) => (
                    <FormControl 
                        sx={{ 
                            minWidth: '160px',
                            height: '100%',
                            width: 'fit-content',
                            // maxWidth: '200px'
                        }}
                        focused={focused}
                        error={!!errors[selectName]}
                    >
                        <InputLabel
                            id="country-select-label"
                            sx={{
                                color: 'white',
                                fontSize: 24,
                                '&.Mui-focused': {
                                    color: 'white'
                                }
                            }}
                            required={required}
                        >
                            Country Code
                        </InputLabel>

                        <Select
                            id='country-select'
                            {...fields}
                            onChange={(event) => { onSelectCountry({ event, onChange }) }}
                            label="Country Code"
                            labelId='country-select-label'
                            variant='outlined'
                            color='info'
                            required={required}
                            // focused={(focused) ? 'true' : 'false'}
                            sx={{ 
                                // height: '100%',
                                borderBottomLeftRadius: 4,
                                borderBottomRightRadius: 0,
                                borderTopRightRadius: 0,
                                pt: 2,
                                '& .MuiOutlinedInput-notchedOutline': {
                                    fontSize: 24,
                                    '&:focus': {
                                        border: '2px solid white'
                                    },
                                    // width: '100%'
                                },
                                '& .Mui-focused': {
                                    borderRightWidth: 0
                                },
                                fontSize: 32,
                            }}
                            MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left"
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left"
                                },
                            }}
                            renderValue={(countryCode) => { 
                                return (
                                    <Container>
                                        <Box
                                            component="img"
                                            sx={{
                                                height: '100%',
                                                width: 40,
                                                backgroundColor: 'transparent',
                                            }}
                                            alt={`${countryCode} Flag Icon`}
                                            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
                                        />
                                    </Container>
                                )
                             }}
                        >
                            {
                                flagList.map((countryCode) => {
                                    return (
                                        <MenuItem 
                                            sx={{ 
                                                width: '200px', 
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.6)'
                                                } 
                                            }} 
                                            key={countryCode} 
                                            value={countryCode}
                                        >
                                            <Grid2 sx={{ width: '100%', pl: 1 }} container spacing={2}>
                                                <Grid2 xs={3} display='flex' justifyContent='end' alignItems='center'> 
                                                    <Typography variant='body1' sx={{ color: 'white' }}>
                                                        {`+${getCountryCallingCode(countryCode)}`}
                                                    </Typography>
                                                </Grid2>
                                                <Grid2 xs={6} display='flex' justifyContent='center' alignItems='center'>
                                                    <Box
                                                        component="img"
                                                        sx={{
                                                            height: 20,
                                                            width: 30,
                                                            backgroundColor: 'transparent',
                                                        }}
                                                        alt={`${countryCode} Flag Icon`}
                                                        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
                                                    />
                                                </Grid2>
                                                <Grid2 xs={3} display='flex' justifyContent='center' alignItems='center'>
                                                    <Typography variant='body1' sx={{ color: 'white' }}>
                                                        {countryCode}
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                        <FormHelperText>{errors[selectName] ? errors[selectName]?.message : ''}</FormHelperText>
                    </FormControl>
                )}
            />  

            <Controller
                control={control}
                name={inputName}
                defaultValue=''
                render={({ field: { onChange, value } }) => (
                    <InputMask 
                        mask={phoneNumberMask}
                        alwaysShowMask={false}
                        maskChar={ selectedCountry === 'US' ? '_' : ''}
                        onChange={onChange}
                        value={value}
                        disabled={disabled}
                    >
                        {(inputProps) => 
                            <TextField
                                {...inputProps}
                                label="Mobile Number"
                                variant='outlined'
                                color='info'
                                type='tel'
                                focused={focused}
                                required={required}
                                ref={inputRef}
                                sx={{ 
                                    width: '100%',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        fontSize: 24,
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                          borderLeftWidth: 0,
                                          borderTopLeftRadius: 0,
                                          borderBottomLeftRadius: 0,
                                        },
                                        pt: 2,
                                        borderLeftWidth: 0,
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                    }
                                }}
                                inputProps={{
                                    sx: {
                                        fontSize: 32,
                                        '&:-webkit-autofill': {
                                            WebkitBoxShadow: 'none'
                                        },
                                        borderTopRightRadius: 4,
                                        borderLeft: 'none',
                                    },
                                }}
                                InputLabelProps={{
                                    sx: {
                                        fontSize: 24,
                                    }
                                }}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: 16
                                    }
                                }}
                                error={!!errors[inputName]}
                                helperText={
                                    errors[inputName] ? errors[inputName]?.message : ''
                                }
                            />
                        }
                    </InputMask>
                )}
            />  
        </Stack>
    );
}