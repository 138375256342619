import { Box, CircularProgress, Container, Fade, Paper } from "@mui/material";
import SvgFreiDog from "../images/icons/FreiDog";
import Toxhound_icon_white80_large from '../images/logos/Toxhound_icon_white80_large.png'



export default function LoadingSplashPage() {
    return (
        <Paper
            elevation={20}
            sx={{
                width: '100%',
                height: '100%'
            }}
        >
            <Container
                sx={{
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}
            >
                <Fade in={true}>
                    <Box
                        sx={{
                            minHeight:'200px',
                            // width: '100%',
                            position: 'relative'
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                height: 120,
                                width: 148,
                                backgroundColor: 'transparent',
                                borderRadius: 4,
                                p: 1
                            }}
                            alt="Alacrity Logo"
                            src={Toxhound_icon_white80_large}
                            // src={require("./../images/logos/Toxhound_icon_white80_large.png")}

                        />
                        <CircularProgress
                            size={196}
                            sx={{
                                position: 'absolute',
                                top: -32,
                                left: -24,
                                zIndex: 1, 
                                color: 'rgba(255, 255, 255, 0.8)'

                            }}
                        />
                    </Box>
                </Fade>
            </Container>
        </Paper>
    )
}