import { Stack, Typography, FormControlLabel, Radio, RadioGroup, useTheme, Paper, Box, FormControl, InputLabel, Select, Button, Divider, MenuItem, IconButton, Tooltip, FormHelperText, Skeleton, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router"
import { selectUser } from "../../../common/redux/userSlice";
import { useSelector } from "react-redux";
import { AddSharp, ArrowBackIosSharp, ArrowForwardIosSharp, CheckSharp, ChevronLeftSharp, ChevronRightSharp, CloseSharp, ExpandMoreSharp, KeyboardDoubleArrowLeftSharp, KeyboardDoubleArrowRightSharp, LastPageSharp, SaveSharp, SendSharp } from "@mui/icons-material";
import * as z from 'zod'

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useSymptomsColumns } from "./PatientTableColumns";
import { EmptyList, LoadingList } from "../../../components/EntityList";
import { GenericModal } from "../../../components/modals/GenericModal";
import { LoadingButton } from "@mui/lab";
import AlacrityTheme from "../../../common/Theme";
import { toast } from "react-toastify";
import { useAnswerPROMISQuestionnaireMutation, useGetActivePromiseAssessmentsQuery, useGetPromisResultQuery, useGetSymptomsForPatientInRangeQuery, usePostNewSymptomMutation, useStartPROMISQuestionnaireMutation } from "../../../common/redux/api/readingsApi";
import { useIsQueryLoading } from "../../../common/util";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormLabel } from "react-bootstrap";
import { element } from "prop-types";
import { DatePicker } from "@mui/x-date-pickers";
import { useGetPatientQuery } from "../../../common/redux/api/api";
import LoadingSplashPage from "../../LoadingSplashPage";
dayjs.extend(localizedFormat);



export default function SymptomsTable() {
    const { id: alacrity_id } = useParams()
    const theme = useTheme()
    const user = useSelector(selectUser);
    const role = user.permissions



    const [ startTS, setStartTS ] = useState(dayjs().startOf('day'))



    const {
        data: patient,
        isSuccess: isPatientSuccess,
        isLoading: isPatientLoading,
        isFetching: isPatientFetching,
        isError: isPatientError,
        error: patientError,
        isUninitialized: isPatientUninitialized
    } = useGetPatientQuery(alacrity_id)

    const patientIsLoading = useIsQueryLoading([isPatientLoading, isPatientFetching, isPatientUninitialized]);



    const {
        data: symptoms,
        isSuccess: areSymptomsSuccess,
        isLoading: areSymptomsLoading,
        isFetching: areSymptomsFetching,
        isError: areSymptomsError,
        error: symptomsError,
        isUninitialized: areSymptomsUninitialized
    } = useGetSymptomsForPatientInRangeQuery({ patient_id: alacrity_id, start: startTS.toISOString(), end: startTS.endOf('day').toISOString() })



    const {
        data: activeSymptoms,
        isLoading: areActiveSymptomsLoading,
        isFetching: areActiveSymptomsFetching,
        isSuccess: areActiveSymptomsSuccess,
        isError: areActiveSymptomsError,
        error: activeSymptomsError,
        isUninitialized: areActiveSymptomsUninitialized
    } = useGetActivePromiseAssessmentsQuery(null, {
        skip: alacrity_id !== user.alacrity_id
    })

    const symptomsAreLoading = useIsQueryLoading([areSymptomsLoading, areSymptomsUninitialized, areActiveSymptomsLoading])




    const rows = useMemo(() => {
        if (areSymptomsSuccess && areActiveSymptomsSuccess) {
            return symptoms?.filter((symptom) => {
                if (alacrity_id === user.alacrity_id) {
                    return activeSymptoms?.every((activeSymptom) => activeSymptom.assessment_id !== symptom.assessment_id)
                } else {
                    return true
                }
            })?.map((symptom) => ({
                id: symptom?.assessment_id,
                symptom: symptomLabels[symptom?.symptom],
                severity: symptom?.severity ?? 1,
                ts: symptom.ts,
                completed: 'completed'
            }))
        } else {
            return []
        } 
    }, [symptoms, activeSymptoms, alacrity_id, user, areSymptomsSuccess])



    const pinnedRows = useMemo(() => {
        if (areActiveSymptomsSuccess && areSymptomsSuccess) {
            return {
                top: symptoms?.filter((symptom) => {
                    return activeSymptoms?.some((activeSymptom) => activeSymptom.assessment_id === symptom.assessment_id)
                })?.map((symptom) => ({
                    id: symptom?.assessment_id,
                    symptom: symptomLabels[symptom?.symptom],
                    severity: symptom?.severity ?? 1,
                    ts: symptom?.ts,
                    completed: 'incomplete'
                })),
                bottom: []
            }
        } else {
            return []
        }
    }, [activeSymptoms, symptoms, areActiveSymptomsSuccess, areSymptomsSuccess])



    const [ openBeginAssessment, setOpenBeginAssessment ] = useState(false)

    const [ currentAssessment, setCurrentAssessment ] = useState(null)

    const [ openCompleteAssessment, setOpenCompleteAssessment ] = useState(false)

    const [ currentResponse, setCurrentResponse ] = useState({})

    const [ openViewResults, setOpenViewResults ] = useState(false)



    const beginForm = useCallback((assessment_id) => {
        console.log('Begin Form: ', assessment_id)
        setOpenBeginAssessment(true)
        setCurrentAssessment(assessment_id)
    }, [setOpenBeginAssessment, setCurrentAssessment])



    const handleCloseBeginModal = useCallback(() => {
        setOpenBeginAssessment(false)
        setCurrentAssessment(null)
    }, [setOpenBeginAssessment, setCurrentAssessment])



    const handleCompleteBeginAssessment = useCallback((data) => {
        console.log('Begin Assessment Result: ', data)
        setOpenCompleteAssessment(true)
        setOpenBeginAssessment(false)
        setCurrentResponse(data)
    }, [setOpenCompleteAssessment, setOpenBeginAssessment, setCurrentResponse])



    const handleCloseAssessmentModal = useCallback(() => {
        setOpenCompleteAssessment(false)
        setCurrentAssessment(null)
        setCurrentResponse(null)
    }, [setOpenCompleteAssessment, setCurrentAssessment, setCurrentResponse])



    const handleNextQuestion = useCallback((data) => {
        console.log('Next Question: ', data)
        setCurrentResponse(data)
    }, [setCurrentResponse])



    const handleCompleteQuestionnaire = useCallback((data) => {
        console.log('Completed Data: ', data)
        setCurrentAssessment(null)
        setOpenCompleteAssessment(false)
        setCurrentResponse(null)
        // setOpenViewResults(true)
    }, [])



    const viewForm = useCallback((assessment_id) => {
        setCurrentAssessment(assessment_id)
        setOpenViewResults(true)
    }, [])



    const handleCloseViewResultsModal = useCallback(() => {
        setCurrentAssessment(null)
        setOpenViewResults(false)
    }, [])



    const columns = useSymptomsColumns(role, beginForm, () => {}, viewForm)



    const [createOpen, setCreateOpen] = useState(false)

    const handleCreateNewSymptom = useCallback(() => {
        setCreateOpen(true)
    }, [setCreateOpen])



    const onPageBack = useCallback(() => {
        setStartTS((prev) => prev.subtract(1, 'day'))
    }, [setStartTS])



    const onPageForward = useCallback(() => {
        setStartTS((prev) => prev.add(1, 'day'))
    }, [setStartTS])



    const onSkipForward = useCallback(() => {
        setStartTS(dayjs().startOf('day'))
    }, [setStartTS])



     return (
         <>

        {
            createOpen &&
            <CreateSymptomModal
                open={createOpen}
                handleClose={() => { setCreateOpen(false) }}
                alacrity_id={alacrity_id}
            />
        }

        {
            openBeginAssessment &&
            <BeginAssessmentModal 
                open={openBeginAssessment} 
                assessment_id={currentAssessment}
                handleClose={handleCloseBeginModal}
                onComplete={handleCompleteBeginAssessment}
            />
        }

        {
            openCompleteAssessment &&
            <AssessmentModal
                open={openCompleteAssessment}
                handleClose={handleCloseAssessmentModal}
                handleNext={handleNextQuestion}
                onComplete={handleCompleteQuestionnaire}
                response={currentResponse}
                assessmentID={currentAssessment}
            />
        }

        {
            openViewResults &&
            <ViewResultsModal
                open={openViewResults}
                handleClose={handleCloseViewResultsModal}
                assessmentID={currentAssessment}
            />
        }

        <Stack
            sx={{
                width: '100%',
                height: '100%',
                justifyContent: 'start',
                alignItems: 'start'
            }}
            spacing={1}
        >

            {
                (
                    user.alacrity_id === alacrity_id
                ) &&
                <LoadingButton
                    loading={symptomsAreLoading || patientIsLoading}
                    variant="standard"
                    endIcon={<AddSharp style={{ color: theme.palette.primary.main }} />}
                    onClick={handleCreateNewSymptom}
                >
                    <Typography
                        color='primary'
                        fontWeight={800}
                    >
                        Add Symptom
                    </Typography>
                </LoadingButton>
            }



            {
                // symptomsAreLoading || patientIsLoading ? 
                //     <LoadingList />
                // :
                    <Paper
                        elevation={2} // Background color for rows
                        sx={{
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <DataGridPremium
                            loading={symptomsAreLoading || patientIsLoading}
                            pagination
                            columns={columns}
                            rows={rows}
                            pinnedRows={pinnedRows}
                            rowHeight={64}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false
                                    }   
                                }
                            }}
                            slots={{
                                toolbar: GridToolbar,
                                pagination: CustomPagination,
                                loadingOverlay: LoadingSplashPage,
                                noRowsOverlay: EmptyList
                            }}
                            slotProps={{
                                columnsPanel: {
                                    getTogglableColumns: () => columns.filter((column) => column.field !== 'id').map((column) => column.field)
                                },
                                pagination: {
                                    onBack: onPageBack,
                                    onForward: onPageForward,
                                    onSkipForward: onSkipForward,
                                    currentDate: startTS,
                                    creationDate: dayjs(patient?.creation_time)
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-cell': {
                                    pl: 2,
                                    fontSize: 24,
                                    textAlign: 'center',
                                    '&:focus': {
                                        outline: 'none'
                                    }
                                },
                                '& .MuiDataGrid-row.Mui-selected': {
                                    backgroundColor: '#121212',
                                    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))',
                                    '&:hover': {
                                        backgroundColor: '#121212',
                                        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))',
                                    }
                                },
                                '& .MuiDataGrid-toolbarContainer': {
                                    backgroundColor: '#212121'
                                },
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: '#212121'
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    backgroundColor: '#212121'
                                },
                                '& .MuiDataGrid-virtualScroller': {
                                    '::-webkit-scrollbar': {
                                        '-webkit-appearance': 'none',
                                        width: '7px' 
                                    },  
                                    '::-webkit-scrollbar-thumb': {
                                        borderRadius: '4px',
                                        backgroundColor: 'rgba(255, 255, 255, .8)',
                                    }
                                }
                            }}
                            autosizeOnMount
                            autosizeOptions={{
                                columns: ['symptom', 'severity', 'ts', 'actions'],
                                includeOutliers: true,
                                includeHeaders: true,
                                expand: true
                            }}
                        />
                    </Paper>
            }
        </Stack>
        </>
     )
}



const symptomEnum = z.enum(['perservative-speech', 'expressive-aphasia', 'inattention', 'somnolence', 'difficulty-writing', 'shortness-of-breath', 'diarrhea', 'constipation', 'nausea', 'vomiting', 'abdominal-pain', 'itch', 'tired', 'pain'])
const symptomLabels = { 
    'perservative-speech': 'Stuttering',
    'perservative speech': 'Stuttering',
    'expressive-aphasia': 'Difficulty Speaking', 
    'expressive aphasia': 'Difficulty Speaking', 
    'inattention': 'Inattention', 
    'somnolence': 'Drowsiness', 
    'difficulty-writing': 'Difficulty Writing', 
    'shortness-of-breath': 'Shortness of Breath', 
    'difficulty writing': 'Difficulty Writing', 
    'shortness of breath': 'Shortness of Breath', 
    'diarrhea': 'Diarrhea', 
    'constipation': 'Constipation', 
    'nausea': 'Nausea', 
    'vomiting': 'Vomiting', 
    'abdominal-pain': 'Abdominal Pain', 
    'abdominal pain': 'Abdominal Pain', 
    'itch': 'Itchiness', 
    'tired': 'Exhaustion', 
    'pain': 'Pain' 
}

const CreateSymptomModal = ({
    open,
    onSubmit,
    handleClose,
    alacrity_id
}) => {
    const symptomSchema = z.object({
        name: symptomEnum,
        severity: z.number().int().gt(0).lte(5)
    })

    const defaultValues = {
        name: symptomEnum.Enum.pain,
        severity: 1
    }



    const methods = useForm({
        resolver: zodResolver(symptomSchema),
        defaultValues
    })

    const { watch, control, handleSubmit, formState: { isSubmitSuccessful, errors, isSubmitting } } = methods;



    const [ submitSymptom, symptomResult ] = usePostNewSymptomMutation()
    const { data: symptomReply, isLoading: isSymptomLoading, isSuccess: isSymptomSuccess, isError: isSymptomError, error: symptomError } = symptomResult;
    console.log('New Symptom Reply: ', symptomReply)


    useEffect(() => {
        if (isSymptomSuccess) {
            handleClose()
        }

        if (isSymptomError) {
            toast.error('There was an error while submitting. Please try again.', {
                position: 'top-center',
                autoClose: '2000',
                toastId: 'symptom-post-error'
            })
        }
    }, [isSymptomSuccess, handleClose, isSymptomError])



    const onSubmitHandler = (values) => {
        console.log('Submit: ', values)
        submitSymptom({
            symptom_name: values?.name,
            severity: values?.severity,
            ts: dayjs().toISOString(),
            alacrity_id 
        })
    }



    return (
        <GenericModal
            open={open}
            handleClose={handleClose}
        >
            <Typography
                variant="h2"
                textAlign='center'
            >
                Submit Symptom
            </Typography>

            <Divider height='4rem' />

            <FormProvider {...methods}>
                <Box
                    component='form'
                    noValidate
                    autoComplete='off'
                    sx={{ 
                        width: '100%',
                        height: '100%'
                    }}
                    onSubmit={handleSubmit(onSubmitHandler)}
                >

                    <Stack
                        sx={{
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        spacing={8}
                    >

                        <FormControl 
                            fullWidth
                            required 
                            focused 
                            disabled={isSymptomLoading || isSubmitting}
                            sx={{
                                width: '40%'
                            }}
                        >
                            <InputLabel 
                                id='symptom-select-label'
                                sx={{ 
                                    fontSize: 24, 
                                    '&.Mui-focused': {
                                        color: 'white'
                                    } 
                                }} 
                            >
                                Symptom Type
                            </InputLabel>

                            <Controller
                                control={control}
                                name={'name'}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        labelId='symptom-select-label'
                                        label={'Select a Symptom'}
                                        color="info"
                                        sx={{
                                            fontSize: 32,
                                            px: 2,
                                            height: '6rem',
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                fontSize: 24,
                                            }
                                        }}
                                    >
                                        {
                                            symptomEnum.options.map((option) => {
                                                return <MenuItem value={option} >{symptomLabels[option]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                )}
                            />

                            <FormHelperText>{errors['name'] ? errors['name']?.message : ''}</FormHelperText>

                        </FormControl>

                        <Stack
                            direction='row'
                            spacing={2}
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'end',

                            }}
                        >
                            <Typography
                                variant="body1"
                                color='text.secondary'
                            >
                                Least Severe
                            </Typography>
                        
                            <FormControl>
                                <FormLabel id="severity-label">
                                    Severity
                                </FormLabel>

                                <Controller
                                    control={control}
                                    name={'severity'}
                                    render={({ field: { onChange, ...fields } }) => {
                                        return (
                                            <RadioGroup
                                                {...fields}
                                                onChange={(event) => { onChange(Number(event.target.value)) }}
                                                row
                                                aria-labelledby="severity-label"
                                                name="severity-buttons-group"
                                            >
                                                {
                                                    Array(5).fill(0).map((value, index) => {
                                                        return <FormControlLabel value={index + 1} control={<Radio />} label={index + 1} />
                                                    })
                                                }
                                                
                                            </RadioGroup>
                                        )
                                    }}

                                />

                                <FormHelperText>{errors['severity'] ? errors['severity']?.message : ''}</FormHelperText>
                            </FormControl>

                            <Typography
                                variant="body1"
                                color='text.secondary'
                            >
                                Most Severe
                            </Typography>

                        </Stack>

                        <Stack
                            direction='row'
                            sx={{
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                            spacing={10}
                        >
                            <Button
                                color="error"
                                variant="contained"
                                endIcon={<CloseSharp />}
                                onClick={handleClose}
                                sx={{
                                    fontSize: 24
                                }}
                            >
                                Cancel
                            </Button>

                            <LoadingButton
                                variant="contained"
                                color="primary"
                                loading={isSubmitting || isSymptomLoading}
                                type="submit"
                                sx={{
                                    fontSize: 24,
                                    color: 'white'
                                }}
                                endIcon={<SendSharp/>}
                            >
                                Submit
                            </LoadingButton>
                        </Stack>

                    </Stack>

                </Box>
            </FormProvider>

        </GenericModal>
    )
}



const BeginAssessmentModal = ({
    open, 
    handleClose,
    assessment_id,
    onComplete
}) => {
    const [ beginAssessment, beginAssessmentResult ] = useStartPROMISQuestionnaireMutation()
    console.log('Begin Assessment: ', beginAssessmentResult.data)

    useEffect(() => {
        if (beginAssessmentResult.isSuccess) {
            onComplete(beginAssessmentResult.data)
        }
    }, [beginAssessmentResult, onComplete])



    return (
        <GenericModal
            open={open}
            handleClose={handleClose}
        >
            <Stack
                sx={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                spacing={4}
            >
                <Typography variant="h2" textAlign='center'>
                    Begin Questionnaire
                </Typography>

                <Typography variant="subtitle1" textAlign={'center'}>
                    Please answer a few questions to help us better understand your symptom.
                </Typography>
                <AssessmentModalToolbar 
                    handleNext={() => beginAssessment({assessment_id})} 
                    handleNextIsLoading={beginAssessmentResult.isLoading} 
                    handleClose={handleClose}
                    handleCloseIsLoading={beginAssessmentResult.isLoading}
                />
            </Stack>
        </GenericModal>
    )
}



const AssessmentModal = ({
    open,
    handleClose,
    handleNext,
    onComplete,
    assessmentID,
    response
}) => {
    const [ responseValue, setResponseValue ] = useState(undefined)

    const [ answerQuestion, questionResult ] = useAnswerPROMISQuestionnaireMutation()



    const [ currentTitle, setCurrentTitle ] = useState('Question')
    const [ currentQuestion, setCurrentQuestion ] = useState('Please rate on a scale of 1 to 5')
    const [ currentResponseOptions, setCurrentResponseOptions ] = useState(Array(5).fill(0).map((x, index) => ({value: { value: index + 1, oid: '' }, label: index + 1})))

    useEffect(() => {
        const sortedResult = response?.question?.Elements?.toSorted((elementA, elementB) => Number(elementA.ElementOrder) - Number(elementB.ElementOrder) );
        setCurrentTitle(sortedResult?.at(0)?.Description)
        setCurrentQuestion(sortedResult?.at(1)?.Description)

        setCurrentResponseOptions(
            sortedResult?.at(2)?.Map
                ?.toSorted((elementA, elementB) => Number(elementA.Position) - Number(elementB.Position))
                ?.map((element) => {
                    return {
                        label: element.Description,
                        value: { value: element.Value, oid: element.ItemResponseOID }
                    }
                })
        )

        setResponseValue(undefined)
    }, [response, setCurrentTitle, setCurrentQuestion, setCurrentResponseOptions])



    const handleSubmit = () => {
        console.log(`Answer: assessment_id: ${assessmentID}, response_id: ${responseValue.oid}, response_value: ${responseValue.value}`)
        answerQuestion({
            assessment_id: assessmentID,
            response_id: responseValue.oid,
            response_value: responseValue.value
        })
    }



    useEffect(() => {
        console.log('Question Result: ', questionResult)
        if (questionResult.isSuccess) {
            if (questionResult?.data?.complete) {
                onComplete(questionResult?.data)
            } else {
                handleNext(questionResult.data)
            }
        }
    }, [questionResult, handleNext, onComplete])



    return (
        <GenericModal
            open={open}
        >
            <Stack
                spacing={4}
                sx={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >

                <Typography variant="h5" textAlign={'center'}>
                    {currentTitle}
                </Typography>

                <Typography variant="h5" textAlign={'center'}>
                   {currentQuestion}
                </Typography>

                <RadioGroup
                    row
                    name="radio-buttons-group"
                    value={responseValue?.value ?? ''}
                >
                    {
                        currentResponseOptions?.map((option) => {
                            return <FormControlLabel onClick={() => setResponseValue(option.value)} value={option.value.value} control={<Radio/>} label={option.label}/>
                        })
                    }
                </RadioGroup>

                <AssessmentModalToolbar
                    handleClose={handleClose}
                    handleCloseIsLoading={questionResult.isLoading}
                    handleNext={handleSubmit}
                    handleNextIsLoading={questionResult.isLoading}
                    handleNextDisabled={responseValue === undefined}
                />

            </Stack>
        </GenericModal>
    )
}



const ViewResultsModal = ({ 
    assessmentID ,
    open,
    handleClose
}) => {
    const theme = useTheme();



    const {
        data: promisForm,
        isLoading: isFormLoading,
        isFetching: isFormFetching,
        isSuccess: isFormSuccess,
        isError: isFormError,
        error: formError,
        isUninitialized: isFormUninitialized
    } = useGetPromisResultQuery(assessmentID, {
        skip: assessmentID === null
    });

    const formIsLoading = useIsQueryLoading([isFormLoading, isFormFetching, isFormUninitialized]);

    if (isFormSuccess) {
        console.log('Form: ', promisForm)
    }


    return (
        <GenericModal
            open={open}
        >
            
            <Stack
                spacing={4}
                sx={{
                    width: '100%',
                    justifyContent: 'start',
                    alignItems: 'center',
                    maxHeight: '80vh',
                    overflowY: 'auto'
                }}
            >

                <Typography
                    variant="h4"
                    fontWeight={700}
                    color='text.secondary'
                >
                    Assessment Results
                </Typography>

                <Divider sx={{ width: '100%' }} />

                {
                    formIsLoading ?
                        Array(4).fill(0).map(() => {
                            return (
                                <Stack
                                    sx={{
                                        width: '100%',
                                        justifyContent: 'start',
                                        alignItems: 'start'
                                    }}
                                    spacing={1}
                                >
                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '40%' }} />
                                    <Skeleton variant="rounded" sx={{ height: '2rem', width: '60%' }} />
                                </Stack>
                            )
                        })
                    :
                        <>
                            {
                                promisForm.assessment_response.Items.map((question) => {
                                    return (
                                        <Stack
                                            sx={{
                                                width: '100%',
                                                justifyContent: 'start',
                                                alignItems: 'start'
                                            }}
                                            spacing={1}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                color='text.secondary'
                                            >
                                                {`${question.Elements.at(0)?.Description ?? ''} ${question.Elements.at(1)?.Description ?? ''}`}
                                            </Typography>

                                            <RadioGroup
                                                row
                                                name={`results-radio-buttons-group-${question?.ID}`}
                                                value={question?.Response}
                                                onChange={() => {}}
                                            >
                                                {
                                                    question.Elements.at(2)?.Map?.map((option) => {
                                                        return <FormControlLabel value={option.Value} control={<Radio/>} label={option.Description}/>
                                                    })
                                                }
                                            </RadioGroup>

                                            <Accordion
                                                sx={{
                                                    backgroundColor: 'transparent',
                                                    backgroundImage: 'none',
                                                    boxShadow: 'none',
                                                    border: 'none',
                                                }}
                                            >

                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreSharp style={{ color: theme.palette.text.secondary }} />} 
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        color='text.secondary'
                                                    >
                                                        Metadata:
                                                    </Typography>
                                                </AccordionSummary>

                                                <AccordionDetails>

                                                    <Stack
                                                        spacing={1}
                                                        sx={{
                                                            width: '100%',
                                                            justifyContent: 'start',
                                                            alignItems: 'center'
                                                        }}
                                                    >

                                                        <Typography
                                                            color='text.secondary'
                                                        >
                                                            {`StdError: ${question?.StdError ?? 'N/A'}`}
                                                        </Typography>

                                                        <Typography
                                                            color='text.secondary'
                                                        >
                                                            {`Theta: ${question?.Theta ?? 'N/A'}`}
                                                        </Typography>

                                                    </Stack>

                                                </AccordionDetails>

                                            </Accordion>

                                        </Stack>
                                    )
                                })
                            }

                            <Typography
                                variant="h6"
                                fontWeight={700}
                            >
                                {`T-Score: ${promisForm?.assessment_response['T-Score'] ?? 'N/A'}`}
                            </Typography>

                            <Accordion
                                sx={{
                                    backgroundColor: 'transparent',
                                    backgroundImage: 'none',
                                    boxShadow: 'none',
                                    border: 'none',
                                }}
                            >

                                <AccordionSummary
                                    expandIcon={<ExpandMoreSharp style={{ color: theme.palette.text.secondary }} />} 
                                >
                                    <Typography
                                        variant="body1"
                                        color='text.secondary'
                                    >
                                        Metadata
                                    </Typography>
                                </AccordionSummary>

                                <AccordionDetails>

                                    <Stack
                                        spacing={1}
                                        sx={{
                                            width: '100%',
                                            justifyContent: 'start',
                                            alignItems: 'start'
                                        }}
                                    >

                                        <Typography
                                            color='text.secondary'
                                        >
                                            {`PROMIS Assessment: ${promisForm?.assessment_response?.Name ?? 'N/A'}`}
                                        </Typography>

                                        <Typography
                                            color='text.secondary'
                                        >
                                            {`SError: ${promisForm?.assessment_response?.SError ?? 'N/A'}`}
                                        </Typography>

                                        <Typography
                                            color='text.secondary'
                                        >
                                            {`StdError: ${promisForm?.assessment_response?.StdError ?? 'N/A'}`}
                                        </Typography>

                                        <Typography
                                            color='text.secondary'
                                        >
                                            {`Theta: ${promisForm?.assessment_response?.Theta ?? 'N/A'}`}
                                        </Typography>

                                    </Stack>

                                </AccordionDetails>

                            </Accordion>
                        </>
                }

                <AssessmentModalToolbar
                    handleClose={handleClose}
                />

            </Stack>

        </GenericModal>
    )
}



const ModalToolbar = ({ children }) => {
    return (
        <Stack
            width={'100%'}
            height={'100%'}
            direction={{md: 'row', xs: 'column'}}
            sx={{
                justifyContent: 'space-evenly',
                p: 2 
            }}
        >
            {children}
        </Stack>
    )
}



const AssessmentModalToolbar = ({
    handleNext = null,
    handleNextDisabled = false,
    handleNextIsLoading = null,
    handlePrevious = null,
    handlePreviousIsLoading = null,
    handleClose = null,
    handleCloseIsLoading = null,
    handleSave = null,
    handleSaveIsLoading = null
}) => {
    return (
        <ModalToolbar>
            {
                handlePrevious &&
                <ModalControlButton 
                    name="Previous"
                    color={"orange"}
                    loading={handlePreviousIsLoading}
                    icon={<KeyboardDoubleArrowLeftSharp/>}
                    onClick={handlePrevious}
                />
            }

            {
                handleClose &&
                <ModalControlButton
                    name="Close"
                    color={"red"}
                    loading={handleCloseIsLoading}
                    icon={<CloseSharp/>}
                    onClick={handleClose}
                />
            }

            {
                handleSave &&
                <ModalControlButton 
                    name="Save & Exit"
                    color={AlacrityTheme.palette.secondary.main}
                    loading={handleSaveIsLoading}
                    icon={<SaveSharp/>}
                    onClick={handleSave}
                />
            }

            {
                handleNext &&
                <ModalControlButton 
                    name="Next"
                    color={AlacrityTheme.palette.primary.main}
                    loading={handleNextIsLoading}
                    icon={<KeyboardDoubleArrowRightSharp/>}
                    onClick={handleNext}
                    disabled={handleNextDisabled}
                />
            }
        </ModalToolbar>
    )
}



const ConfirmationToolbar = ({ 
    onCancel, 
    onCancelIsLoading = false, 
    onConfirm, 
    onConfirmIsLoading = false,
    onGoBack,
    onGoBackIsLoading = false
}) => {
    return (
        <ModalToolbar>
            <ModalControlButton
                name="Go Back"
                color="orange"
                loading={onGoBackIsLoading}
                icon={<KeyboardDoubleArrowLeftSharp/>}
                onClick={onGoBack}
            />

            <ModalControlButton
                name="Cancel"
                color="red"
                loading={onCancelIsLoading}
                icon={<CloseSharp/>}
                onClick={onCancel}
            />

            <ModalControlButton
                name="Confirm"
                color="green"
                loading={onConfirmIsLoading}
                icon={<CheckSharp/>}
                onClick={onConfirm}
            />
        </ModalToolbar>
    )
}



const ConfirmationModal = ({ title, text, open, loading, onConfirm, onCancel, onGoBack }) => {
    return (
        <GenericModal
            open={open}
            // handleClose={onCancel}
        >
            <Typography variant="h2" textAlign={'center'}>
                {title}
            </Typography>

            <Typography variant="subtitle1" textAlign={'center'}>
                {text}
            </Typography>

            <ConfirmationToolbar 
                onCancel={onCancel} 
                onConfirm={onConfirm} 
                onConfirmIsLoading={loading} 
                onGoBack={onGoBack}
            />
        </GenericModal>
    )
}



const ModalControlButton = ({ name, onClick, color, icon, loading, disabled }) => {
    return (
        <LoadingButton
            loading={loading}
            disabled={disabled}
            loadingPosition='start'
            variant='contained'
            sx={{
                backgroundColor: color
            }}
            onClick={onClick}
            startIcon={icon}
        >
            {name}
        </LoadingButton>
    )
}



const CustomPagination = ({ onBack, onForward, /*onSkipForward,*/ currentDate, creationDate }) => {
    const theme = useTheme()
    const isToday = useMemo(() => {
        return currentDate.isSame(dayjs(), 'day')
    }, [currentDate])

    const [ date, setDate ] = useState(dayjs().startOf('day'))



    return (
        <Stack
            direction='row'
            spacing={1}
            sx={{
                justifyContent: 'end',
                alignItems: 'center',
                width: '100%',
                pr: '2rem',
                py: '1rem',
            }}
        >
            <Tooltip
                title={'Previous Day'}
            >
                <IconButton
                    onClick={onBack}
                >
                    <ChevronLeftSharp style={{ color: 'white' }} />
                </IconButton>
            </Tooltip>

            {/* <Typography
                variant="body1"
                textAlign='center'
            >
                {currentDate.format('L')}
            </Typography> */}

            <DatePicker
                value={date}
                onChange={(newDate) => { setDate(newDate) }}
                disableFuture
                minDate={creationDate}
            />

            <Tooltip
                title={'Next Day'}
            >
                <IconButton
                    onClick={(isToday) ? () => {} : onForward}
                >
                    <ChevronRightSharp style={{ color: (isToday) ? theme.palette.text.secondary: 'white' }} />
                </IconButton>
            </Tooltip>

            {/* <Tooltip
                title={'Skip to Today'}
            >
                <IconButton
                    onClick={(isToday) ? () => {} : onSkipForward}
                >
                    <LastPageSharp style={{ color: (isToday) ? theme.palette.text.secondary : 'white' }} />
                </IconButton>
            </Tooltip> */}
        </Stack>
    )
}
 