import { useEffect, useState } from "react";
import { Modal, Typography, Container, CircularProgress, Autocomplete, TextField, Stack, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { selectUser } from "../../common/redux/userSlice";
import LoadingSplashPage from "../../pages/LoadingSplashPage";



const vowels = "aeiouAEIOU"; 



export default function EntityLinkModal({ 
    open,
    entityListQuery,
    linkEntityMutation,
    entityName,
    linkName,
    entityID,
    onSuccess,
    orderEntities,
    transformEntities,
    onClose,
    ariaLabelledBy
 }) {
    const theme = useTheme()
    const user = useSelector(selectUser)



    const handleModalClose = () => {
        onClose();
    }



    const [ selectedEntity, setSelectedEntity ] = useState();

    const onSelectEntity = (event, newValue) => {
        console.log(newValue);
        setSelectedEntity(newValue);
    }



    const { data, isSuccess, isLoading, isFetching, isError, error } = entityListQuery(user.alacrity_id);
    console.log(data)
    
    const [ linkEntities, result ] = linkEntityMutation();
    const { isSuccess: linkIsSuccess, isLoading: linkIsLoading, isError: linkIsError, error: linkError } = result;



    const onLinkEntities = () => {
        if (!selectedEntity) {
            toast.error(`Please select a${(vowels.indexOf(linkName[0]) === -1) ? '' : 'n'} ${linkName}.`, {
                position: 'top-center', autoClose: 1000
            })
        } else {
            linkEntities({
                [`${linkName.toLowerCase()}ID`]: selectedEntity.id,
                [`${entityName.toLowerCase()}ID`]: entityID
            })
        }
    }



    useEffect(() => {
        if (linkIsSuccess) {
            setSelectedEntity(null);
            onSuccess();
        }
    }, [ linkIsSuccess, linkIsError, linkError, entityName, linkName, onSuccess ])



    return (
        <Modal
            open={open}
            onClose={handleModalClose}
            aria-labelledby={ariaLabelledBy}        
        >
            <Stack 
                sx={{
                    width: {xl: '40%', md: '60%', xs: '90%'},
                    minHeight: '400px',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: '#212121',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    justifyContent: 'space-between'
                }}
                spacing={2}
            >
                <Typography 
                    id="modal-title" 
                    variant="h6" 
                    component="h2"
                >
                    {`Add a${(vowels.indexOf(linkName[0]) === -1) ? '' : 'n'} ${linkName}`}
                </Typography>

                {
                    (
                        isLoading || isFetching
                    ) ?
                        <LoadingSplashPage/>
                    :
                        <Stack
                            spacing={2}
                            sx={{
                                width: '100%',
                                alignItems: 'center'
                            }}
                        >
                            <Autocomplete
                                value={selectedEntity}
                                onChange={onSelectEntity}
                                disablePortal
                                id="autocomplete"
                                options={data.toSorted(orderEntities).map(transformEntities)}
                                sx={{ 
                                    width: '60%', 
                                    color: theme.palette.primary.main,
                                    // mt: 'auto',
                                }}
                                renderInput={(params) => <TextField {...params} variant="outlined" label={`Add a${(vowels.indexOf(linkName[0]) === -1) ? '' : 'n'} ${linkName}`} />}
                            />
                            <LoadingButton
                                size='large'
                                variant="contained"
                                loading={linkIsLoading}
                                onClick={onLinkEntities}
                            >
                                Add
                            </LoadingButton>
                        </Stack>
                }
            </Stack>
        </Modal>
    )
}