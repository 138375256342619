import { ChevronRight, DomainSharp, ExpandLessSharp, ExpandMoreSharp, KeyboardDoubleArrowLeftSharp, KeyboardDoubleArrowRightSharp, LibraryBooksSharp, Support } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Stack, Typography, useMediaQuery, useTheme, Menu, MenuItem, IconButton, Button, Paper, Collapse, ClickAwayListener } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiSlice } from "../../common/redux/api/api";
import { SidebarOrgsList, SidebarPatientsList, SidebarProvidersList, SidebarTrialsList } from "./SidebarEntities";
import Patients from '../../images/icons/Patients'
import { Entities, selectEntityType, selectSidebarState, setAlertMode, toggleExtend, updateCurrentEntity, updateEntityType } from "../../common/redux/sidebarSlice";
import { useNavigate } from "react-router";
import SvgClinicalStaff from '../../images/icons/ClinicalStaff'
import { useGetSize, useWidth } from "../../common/util";
import { selectUser } from "../../common/redux/userSlice";
import SvgMedicalAlertSmall from "../../images/icons/MedicalAlertSmall";
import { useGetActiveAlertsForEntityQuery } from "../../common/redux/api/alertsApi";



export const DRAWER_CLOSED_WIDTH = { '2xl': 400, xl: 300 };
const DRAWER_OPEN_WIDTH = {'2xl': 800, xl: 600 };



export default function ClinicalSidebar({ open, toggleOpen }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const width = useWidth()
    // const matches = useMediaQuery(theme.breakpoints.down('sm'));

    // const entity = useSelector(selectEntityType);
    const sidebarState = useSelector(selectSidebarState)

    // const [ openDrawer, setOpenDrawer ] = useState(false);

    // const toggleDrawer = () => {
    //     dispatch(toggleExtend())
    //     // setOpenDrawer((prev) => { return !prev })
    // }



    // const onSelectEntity = (newEntity) => {
    //     dispatch(updateCurrentEntity(null))
    //     navigate(`/dashboard/${Entities[newEntity].toLowerCase().slice(0, -1)}`)
    // }



    const drawerOpenWidth = useGetSize(DRAWER_OPEN_WIDTH, width)

    const drawerCloseWidth = useGetSize(DRAWER_CLOSED_WIDTH, width)



    return (
        <Stack
            sx={{
                backgroundColor: theme.palette.background.default,
                zIndex: 1,
                height: '100%',
                width: (sidebarState.extended) ? drawerOpenWidth : drawerCloseWidth,
                transition: (sidebarState.extended) ? 
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    })
                :
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                overflowX: 'hidden',
            }}
        >
            <SidebarHeader />
            {
                ( Entities[sidebarState.entity] === 'Patients' ) &&
                <SidebarPatientsList />
            }
            {
                ( Entities[sidebarState.entity] === 'Trials' ) &&
                <SidebarTrialsList />
            }
            {
                ( Entities[sidebarState.entity] === 'Orgs' ) &&
                <SidebarOrgsList />
            }
            {
                ( Entities[sidebarState.entity] === 'Providers' ) &&
                <SidebarProvidersList />
            }
            <SidebarFooter/>
        </Stack>
    )
}



const SidebarHeader = () => {
    const theme = useTheme();
    const user = useSelector(selectUser)
    const dispatch = useDispatch()
    const navigate = useNavigate()


    
    const entity = useSelector(selectEntityType);
    const [ menuOpen, setMenuOpen ] = useState(false);
    const [ startIcon, setStartIcon ] = useState()



    const { refetch: refetchPatients } = apiSlice.endpoints.getPatientsWithData.useQuerySubscription(null, { skip: Entities[entity] !== 'Patients' });
    const { refetch: refetchTrials } = apiSlice.endpoints.getTrials.useQuerySubscription(null, { skip: Entities[entity] !== 'Trials' });
    const { refetch: refetchProviders } = apiSlice.endpoints.getProvidersSidebar.useQuerySubscription(user, { skip: Entities[entity] !== 'Providers' });
    const { refetch: refetchOrgs } = apiSlice.endpoints.getAllOrgs.useQuerySubscription(null, { skip: Entities[entity] !== 'Orgs' });



    const handleOpenMenu = () => {
        setMenuOpen(true)
    };

    const handleCloseMenu = () => {
           setMenuOpen(false)
    };



    const onSelectEntity = useCallback((newEntity) => {
        handleCloseMenu()
        dispatch(updateCurrentEntity(null))
        navigate(`/dashboard/${Entities[newEntity].toLowerCase().slice(0, -1)}`)
    }, [dispatch, navigate])



    useEffect(() => {
        if (Entities[entity] === 'Patients') {
            refetchPatients();
            setStartIcon(<Patients/>)
        } else if (Entities[entity] === 'Trials') {
            refetchTrials()
            setStartIcon(<LibraryBooksSharp style={{ color: theme.palette.text.secondary, height: 30, width: 30 }} />)
        } else if (Entities[entity] === 'Orgs') {
            refetchOrgs()
            setStartIcon(<DomainSharp style={{ color: theme.palette.text.secondary, height: 30, width: 30 }} />)
        } else if (Entities[entity] === 'Providers') {
            refetchProviders()
            setStartIcon(<SvgClinicalStaff style={{ height: 30, width: 30 }} />)
        }
    }, [entity, refetchPatients, refetchTrials, refetchProviders, refetchOrgs, theme.palette.text.secondary])



    const menuItems = useMemo(() => {
        return (
            <Stack
                sx={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 2
                }}
            >
                {
                    Entities.map((entityName, index) => {
                        return (
                            <Button
                                key={entityName}
                                sx={{
                                    width: '100%'
                                }}
                                onClick={() => { onSelectEntity(index) }}
                            >
                                <Typography
                                    textAlign='center'
                                    variant="h5"
                                    color='textSecondary'
                                >
                                    {entityName}
                                    {/* {(entityName === 'Orgs') ? 'Organizations' : entityName} */}
                                </Typography>
                            </Button>
                        )
                    })
                }
            </Stack>
        )
    }, [onSelectEntity])



    return (
        <ClickAwayListener 
            onClickAway={() => { if (menuOpen) { handleCloseMenu() } }}
        >
            <Paper 
                elevation={4} 
                sx={{ 
                    borderRadius: 0 
                }}
            >
                <Stack
                    sx={{
                        height: (menuOpen) ? 'fit-content' : 80,
                        width: '100%',
                        px: 1,
                        py: '0.5rem',
                    }}
                >

                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='start'
                    >

                        <LoadingButton
                            fullWidth={false}
                            startIcon={startIcon}
                            endIcon={(menuOpen) ? <ExpandLessSharp style={{ color: theme.palette.text.secondary, height: 30, width: 30 }} /> : <ExpandMoreSharp style={{ color: theme.palette.text.secondary, height: 30, width: 30 }} />}
                            onClick={(menuOpen) ? handleCloseMenu : handleOpenMenu}
                            sx={{ 
                                height: '100%',
                                // flex: 1,
                                textTransform: 'none',
                                width: DRAWER_CLOSED_WIDTH
                            }}
                        >
                            <Typography
                                textAlign='center'
                                variant='h3'
                                color='textSecondary'
                            >
                                {Entities[entity]}
                                {/* {(Entities[entity] === 'Orgs') ? 'Organizations' : Entities[entity]} */}
                            </Typography>
                        </LoadingButton>

                        {/* <IconButton
                            onClick={toggleDrawer}
                            sx={{
                                height: '100%',
                                borderRadius: 1,
                            }}
                        >
                            {
                                openDrawer ?
                                    <KeyboardDoubleArrowLeftSharp style={{ color: theme.palette.text.secondary, height: 30, width: 30 }} />
                                :
                                    <KeyboardDoubleArrowRightSharp style={{ color: theme.palette.text.secondary, height: 30, width: 30 }} />
                            }
                        </IconButton> */}

                    </Stack>

                    <Collapse
                        in={menuOpen}
                    >
                        {menuItems}
                    </Collapse>

                </Stack>
            </Paper>
        </ClickAwayListener>
    )
}



const FooterButton = ({label, icon, onClick}) => {
    const theme = useTheme()



    return (
        <Stack 
            spacing={1}
            direction='row'
            justifyContent='start'
            alignItems='center'
            sx={{
                width: '100%',
                px: '1rem',
            }}
        >
            <ChevronRight style={{ color: theme.palette.text.secondary }} />

            <Button
                startIcon={icon}
                variant='standard'
                sx={{
                    border: 'none'
                }}
                onClick={onClick}
            >
                <Typography
                    variant='h5'
                    color='textSecondary'
                >
                    {label}
                </Typography>
            </Button>
        </Stack>
    )
}



const SidebarFooter = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme()
    const user = useSelector(selectUser)



    const {
        data: activeAlerts,
        isLoading: areAlertsLoading,
        isFetching: areAlertsFetching,
        isSuccess: areAlertsSuccess,
        isError: areAlertsError,
        error: alertsError,
        isUninitialized: areAlertsUninitialized
    } = useGetActiveAlertsForEntityQuery(
        user?.alacrity_id,
        {
            pollingInterval: 1_000 * 60     // Every minute check for new alerts.
        }
    )

    console.log('Active Alerts: ', activeAlerts)

    const onClickAlertCenter = useCallback(() => {
        console.log('On Click Alert Center')
        dispatch(updateCurrentEntity(null))
        dispatch(setAlertMode(true))
        navigate('/dashboard/alerts')
    }, [navigate, dispatch])



    return (
        <Stack
            sx={{
                width: '100%',
                py: '2rem',
                px: '1rem'
            }}
            spacing={1}
        >
            <Typography
                variant="h5"
                color='text.secondary'
            >
                {`${activeAlerts?.length ?? 0} Active Alerts`}
            </Typography>

            <FooterButton
                label='Alert Center'
                icon={<SvgMedicalAlertSmall />}
                onClick={onClickAlertCenter}
            />

            <FooterButton
                label='Care Team'
                icon={<SvgClinicalStaff/>}
                onClick={() => {}}
            />

            <FooterButton
                label='Help'
                icon={<Support style={{ color: theme.palette.text.secondary, height: 30, width: 30 }} />}
                onClick={() => {}}
            />
        </Stack>
    )
}