/* eslint-disable no-fallthrough */
import { useMediaQuery, useTheme } from "@mui/material"
import { useEffect, useMemo, useState } from "react"



export function canCreateTrial(user) {
    if (Object.hasOwn(user, 'permissions')) {
        if (user.permissions === 'ALACRITY' || user.permissions === 'PHARMA') {
            return true
        }
    } else {
        return false
    }
}



export function canCreatePatient(user) {
    if (Object.hasOwn(user, 'permissions')) {
        if (user.permissions === 'ALACRITY' || user.permissions === 'ADMIN') {
            return true
        }
    } else {
        return false
    }
}



export function canCreateOrg(user) {
    if (Object.hasOwn(user, 'permissions')) {
        if (user.permissions === 'ALACRITY') {
            return true
        }
    } else {
        return false
    }
}



export function useIsQueryLoading(booleansArray) {
    return useMemo(() => {
        return booleansArray.some((x) => x)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, booleansArray)
}



export const useStrictDroppable = (loading) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    let animation

    if (!loading) {
      animation = requestAnimationFrame(() => setEnabled(true));
    }

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, [loading]);

  return [enabled];
};



export function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || 'xs'
    );
}



export const useGetSize = (breakpoints, breakpoint) => {
    return useMemo(() => {
        let size = 0

        switch(breakpoint) { 
            case '5xl':
                if (Object.hasOwn(breakpoints, '5xl')) {
                    size = breakpoints['5xl']
                    break;
                }
            case '4xl':
                if (Object.hasOwn(breakpoints, '4xl')) {
                    size = breakpoints['4xl']
                    break;
                }
            case '3xl':
                if (Object.hasOwn(breakpoints, '3xl')) {
                    size = breakpoints['3xl']
                    break;
                }
            case '2xl':
                if (Object.hasOwn(breakpoints, '2xl')) {
                    size = breakpoints['2xl']
                    break;
                }
            case 'xl':
                if (Object.hasOwn(breakpoints, 'xl')) {
                    size = breakpoints['xl']
                    break;
                }
            case 'lg':
                if (Object.hasOwn(breakpoints, 'lg')) {
                    size = breakpoints['lg']
                    break;
                }
            case 'md':
                if (Object.hasOwn(breakpoints, 'md')) {
                    size = breakpoints['md']
                    break;
                }
            case 'sm':
                if (Object.hasOwn(breakpoints, 'sm')) {
                    size = breakpoints['sm']
                    break;
                }
            case 'xs':
                if (Object.hasOwn(breakpoints, 'xs')) {
                    size = breakpoints['xs']
                    break;
                }
            default: 
                size = 0
        }

        return size
    }, [breakpoints, breakpoint])
}