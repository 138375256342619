import { useTheme, Typography, Button, Stack, Switch, Box, ToggleButton, ToggleButtonGroup, Skeleton, IconButton, Avatar, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { SingleLineTextBox } from '../../../common/Theme.js';
import { useNavigate, useParams, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FIELD_HEIGHT } from '../doctor/DoctorInfo.js';
import { FormProvider, Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { AddSharp, CloseSharp, EditSharp, KeyboardDoubleArrowDownSharp, KeyboardDoubleArrowUpSharp, Person, SendSharp } from '@mui/icons-material';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useAddPatientToDoctorMutation, useAddPatientToOrgMutation, useAddPatientToTrialMutation, useGetDoctorsForPatientQuery, useGetOrgsForUserQuery, useGetOrgsQuery, useGetPatientQuery, useGetProvidersSidebarQuery, useGetTrialsForPatientQuery, useGetTrialsQuery, useUpdatePatientInfoMutation } from '../../../common/redux/api/api.js';
import FormInput from '../../../components/CustomFormInput.js';
import { LoadingButton } from '@mui/lab';
import { useRTKResultToasts } from '../../../common/RTKResultHooks.js';
import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { useIsQueryLoading } from '../../../common/util.js';
import PhoneNumberInput from '../../../components/PhoneNumberInput.js';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { selectUser } from '../../../common/redux/userSlice.js';
import { InfoPageChip } from '../../../components/styledComponents/chips/InfoPageChip.js';
import InfoPageTextField from '../../../components/styledComponents/surfaces/InfoPageTextField.js';
import InfoPageDataField from '../../../components/styledComponents/surfaces/InfoPageDataField.js';
import EntityLinkModal from '../../../components/modals/EntityLinkModal.js';
import { BANNER_HEIGHT } from '../../../components/Banner.js';
import { parsePhoneNumber } from 'react-phone-number-input';
import { updateCurrentEntity } from '../../../common/redux/sidebarSlice.js';

dayjs.extend(localizedFormat);



const patientDataType = z.enum(['Graphs', 'Labs', 'Symptoms'])

export const PatientPage = () => {
    const { id: alacrity_id } = useParams();
    const navigate = useNavigate()
    const location = useLocation()



    const [ dataType, setDataType ] = useState(patientDataType.Enum.Graphs)

    useEffect(() => {
        const dataType = location.pathname.split('/').at(-1)

        switch(dataType) {
            case 'labs':
                setDataType(patientDataType.Enum.Labs)
                break;
            case 'graphs':
                setDataType(patientDataType.Enum.Graphs)
                break;
            default:
                setDataType(patientDataType.Enum.Symptoms)
        }
    }, [location])

    const handleToggle = (event, newDataType) => {
        if (newDataType) {
            setDataType(newDataType)
            navigate(`/dashboard/patient/${alacrity_id}/${newDataType.toLowerCase()}`)
        }
    }



    const { 
        data: patientInfo, 
        isSuccess: isPatientSuccess, 
        isError: isPatientError, 
        error: patientError,
        isLoading: isPatientLoading, 
        isFetching: isPatientFetching, 
        isUninitialized: isPatientUninitialized 
    } = useGetPatientQuery(alacrity_id);

    const patientIsLoading = useIsQueryLoading([isPatientLoading, isPatientFetching, isPatientUninitialized])



    useRTKResultToasts({
        isSuccess: null,
        isError: isPatientError,
        error: patientError
    })

    

    return (
        <Stack
            sx={{
                height: `calc(100vh - ${BANNER_HEIGHT}px - 16px )`,
                width: '100%'
            }}
            spacing={2}
        >

            <PatientBio />

            <Stack
                sx={{
                    px: 8,
                    height: '100%',
                    overflowY: 'clip',
                    pb: 2
                }}
                spacing={2}
            >

                <ToggleButtonGroup
                    exclusive
                    value={dataType}
                    onChange={handleToggle}
                    color='primary'
                >
                    {
                        patientDataType.options.map((type) => {
                            return (
                                <ToggleButton value={type}>
                                    {type}
                                </ToggleButton>
                            )
                        })
                    }
                </ToggleButtonGroup>

                <Box
                    sx={{
                        height: '95%',
                        maxHeight: '95%',
                        width: '100%',
                        pb: 6
                    }}
                >
                    <Outlet/>
                </Box>
                
            </Stack>
        </Stack>
    )
}



export const PatientBio = () => {
    const { id: alacrity_id } = useParams();
    const user = useSelector(selectUser);
    const theme = useTheme()

    const [ isEditing, setIsEditing ] = useState(false);



    const {
        data: patient,
        isSuccess: isPatientSuccess,
        isUninitialized: isPatientUninitialized,
        isLoading: isPatientLoading,
        isFetching: isPatientFetching,
        isError: isPatientError,
        error: patientError,
        // refetch: refetchPatient
    } = useGetPatientQuery(alacrity_id);

    const patientIsLoading = useIsQueryLoading([isPatientLoading, isPatientFetching, isPatientUninitialized])



    const {
        data: doctors,
        isUninitialized: isDoctorsUninitialized,
        isSuccess: isDoctorsSuccess,
        isLoading: isDoctorsLoading,
        isFetching: isDoctorsFetching,
        isError: isDoctorsError,
        error: doctorsError
    } = useGetDoctorsForPatientQuery(alacrity_id);

    const doctorsAreLoading = useIsQueryLoading([isDoctorsLoading, isDoctorsFetching, isDoctorsUninitialized])



    const {
        data: orgs,
        isUninitialized: isOrgsUninitialized,
        isSuccess: isOrgsSuccess,
        isLoading: isOrgsLoading,
        isFetching: isOrgsFetching,
        isError: isOrgsError,
        error: orgsError
    } = useGetOrgsForUserQuery(alacrity_id);

    const orgsAreLoading = useIsQueryLoading([isOrgsLoading, isOrgsFetching, isOrgsUninitialized])



    const {
        data: trials,
        isUninitialized: isTrialsUninitialized,
        isSuccess: isTrialsSuccess,
        isLoading: isTrialsLoading,
        isFetching: isTrialsFetching,
        isError: isTrialsError,
        error: trialsError
    } = useGetTrialsForPatientQuery(alacrity_id);

    const trialsAreLoading = useIsQueryLoading([isTrialsLoading, isTrialsFetching, isTrialsUninitialized])



    const formattedPhone = useMemo(() => {
        if (patient?.phone?.e164NumberString) {
            if (patient?.phone?.iso2?.toLowerCase() === 'us') {
                return formatPhoneNumber(patient?.phone?.e164NumberString)
            } else {
                return formatPhoneNumberIntl(patient?.phone?.e164NumberString)
            }
        } else {
            return 'None'
        }
    }, [patient]);


    
    const { schema: patientInfoSchema, initialValues, editableValues } = usePatientInfoForm({ user, patient });

    const methods = useForm({
        resolver: zodResolver(patientInfoSchema),
        defaultValues: initialValues
    });

    const { control, reset, watch, handleSubmit, formState: { isSubmitSuccessful, errors, isSubmitting } } = methods;
    
    const [ editedData, setEditedData ] = useState({...editableValues})   // Just make a copy of the provider's data that can be manipulated without losing the original copy.

    const [ updatePatient, updatePatientResult ] = useUpdatePatientInfoMutation()


    const onSubmit = (values) => {
        console.log('Edit Patient: ', values)
        let newPatientInfo = {}
        for (const field in values) {
            if (values[field] !== undefined && values[field] !== '' && field !== 'countryCode') {
                newPatientInfo = Object.assign(newPatientInfo, { [field]: values[field] })
            }
        }

        if (values?.phone) {
            const phone = parsePhoneNumber(values?.phone.replace(' ', ''))

            newPatientInfo.phone = {
                iso2: values?.countryCode,
                e164NumberString: phone?.number,
                dialCode: phone?.countryCallingCode,
                ext: phone?.ext
            }
        }

        console.log('New Patient Info: ', newPatientInfo)
        
        updatePatient({
            id: alacrity_id,
            newInfo: newPatientInfo
        })
    }



    useEffect(() => {
        if (updatePatientResult.isSuccess) {
            setIsEditing(false)
            reset()
            updatePatientResult.reset()
            // refetchPatient()
        }
    }, [updatePatientResult, reset, setIsEditing])
 


    const onDeleteTrialChip = useCallback(({ trial }) => {}, [])

    const onDeleteDoctorChip = useCallback(({ doctor }) => {}, [])

    const onDeleteOrgChip = useCallback(({ org }) => {}, [])



    const providerChips = useMemo(() => {
        if (isPatientSuccess && isDoctorsSuccess) {
            const mainRowChips = doctors?.map((doctor, index) => {
                if (editableValues?.doctors) {
                    if (editableValues?.doctors?.includes(doctor.alacrity_id)) {
                        return (
                            <InfoPageChip 
                                onDelete={() => { onDeleteDoctorChip(doctor) }}
                                label={`${doctor.first_name} ${doctor.last_name}`}
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color:'black'
                                }}
                            />
                        )
                    } else {    // Else if not in editable values, don't add a delete button by adding an onDelete function
                        return (
                            <InfoPageChip
                                label={`${doctor.first_name} ${doctor.last_name}`}
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color: 'black'
                                }}
                            />
                        )
                    }
                } else {
                    return (
                        <InfoPageChip
                            label={`${doctor.first_name} ${doctor.last_name}`}
                            sx={{
                                backgroundColor: theme.palette.secondary.main,
                                color: 'black'
                            }}
                        />
                    )
                }
            })

            return (
                <>
                    <Stack
                        direction='row'
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'center',
                            width: '100%'
                        }}
                        spacing={2}
                    >
                        {mainRowChips}

                        {
                            (
                                user.permissions === 'ALACRITY' ||
                                (
                                    user?.orgs.some((org_id) => patient?.orgs.includes(org_id)) &&
                                    user.admin === true
                                ) 
                            ) &&
                            <IconButton
                                color="info"
                                onClick={() => { setOpenProviderModal(true) }}
                            >
                                <AddSharp/>
                            </IconButton>
                        }
                    </Stack>
                </>
            )
        } else {
            return (
                <Stack
                    direction='row'
                    sx={{
                        justifyContent: 'start',
                        alignItems: 'center',
                        width: '100%'
                    }}
                ></Stack>
            )
        }
    }, [editableValues, onDeleteDoctorChip, patient, user, isDoctorsSuccess, theme, doctors, isPatientSuccess])



    const trialsChips = useMemo(() => {
        if (isPatientSuccess && isTrialsSuccess) {
            const mainRowChips = trials?.map((trial, index) => {
                if (editableValues?.trials) {
                    if (editableValues?.trials?.includes(trial.trial_id)) {
                        return (
                            <InfoPageChip 
                                onDelete={() => { onDeleteTrialChip(trial) }}
                                label={trial?.name}
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color:'black'
                                }}
                            />
                        )
                    } else {    // Else if not in editable values, don't add a delete button by adding an onDelete function
                        return (
                            <InfoPageChip
                                label={trial?.name}
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color: 'black'
                                }}
                            />
                        )
                    }
                } else {
                    return (
                        <InfoPageChip
                            label={trial?.name}
                            sx={{
                                backgroundColor: theme.palette.secondary.main,
                                color: 'black'
                            }}
                        />
                    )
                }
            })

            return (
                <>
                    <Stack
                        direction='row'
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'center',
                            width: '100%'
                        }}
                        spacing={2}
                    >
                        {mainRowChips}

                        {
                            (
                                user.permissions === 'ALACRITY' ||
                                (
                                    user?.orgs.some((org_id) => patient?.orgs.includes(org_id)) &&
                                    user.admin === true
                                ) 
                            ) &&
                            <IconButton
                                color="info"
                                onClick={() => { setOpenTrialModal(true) }}
                            >
                                <AddSharp/>
                            </IconButton>
                        }
                    </Stack>
                </>
            )
        } else {
            return (
                <Stack
                    direction='row'
                    sx={{
                        justifyContent: 'start',
                        alignItems: 'center',
                        width: '100%'
                    }}
                ></Stack>
            )
        }
    }, [editableValues, onDeleteTrialChip, patient, user, isTrialsSuccess, theme, trials, isPatientSuccess])



    const orgsChips = useMemo(() => {
        if (isOrgsSuccess) {
            console.log('Editable orgs: ', editableValues.orgs)
            const mainRowChips = orgs?.map((org, index) => {
                console.log('Org: ', org)
                if (editableValues?.orgs) {
                    if (editableValues.orgs.includes(org?.org_id)) {
                        console.log('Deletable org: ', org)
                        return (
                            <InfoPageChip 
                                onDelete={() => { onDeleteOrgChip(org) }}
                                label={org?.name}
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color:'black'
                                }}
                            />
                        )
                    } else {    // Else if not in editable values, don't add a delete button by adding an onDelete function
                        return (
                            <InfoPageChip
                                label={org?.name}
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color: 'black'
                                }}
                            />
                        )
                    }
                } else {
                    return (
                        <InfoPageChip
                            label={org?.name}
                            sx={{
                                backgroundColor: theme.palette.secondary.main,
                                color: 'black'
                            }}
                        />
                    )
                }
            })

            return (
                <>
                    <Stack
                        direction='row'
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'center',
                            width: '100%'
                        }}
                        spacing={2}
                    >
                        {mainRowChips}

                        {
                            (
                                user.permissions === 'ALACRITY' ||
                                (
                                    user?.orgs.some((org_id) => patient?.orgs.includes(org_id)) &&
                                    user.admin === true
                                ) 
                            ) &&
                            <IconButton
                                color="info"
                                onClick={() => { setOpenOrgModal(true) }}
                            >
                                <AddSharp/>
                            </IconButton>
                        }
                    </Stack>
                </>
            )
        } else {
            return (
                <Stack
                    direction='row'
                    sx={{
                        justifyContent: 'start',
                        alignItems: 'center',
                        width: '100%'
                    }}
                ></Stack>
            )
        }
    }, [editableValues, onDeleteOrgChip, isOrgsSuccess, theme, orgs, patient, user])



    const [ openProviderModal, setOpenProviderModal ] = useState(false)

    const [ openTrialModal, setOpenTrialModal ] = useState(false)

    const [ openOrgModal, setOpenOrgModal ] = useState(false)



    const [ expand, setExpand ] = useState(false)
    


    return (
        <>
        <FormProvider {...methods}>
            <Box
                display='flex'
                flexDirection='column'
                component='form'
                noValidate
                autoComplete='off'
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    width: '100%',
                    borderBottom: '2px solid white',
                    justifyContent: 'start',
                    alignItems: 'center'
                }}
            >
                    
                <Stack
                    sx={{
                        justifyContent: 'start',
                        alignItems: 'start',
                        height: '100%',
                        width: '100%',
                        overflowY: 'auto',
                        px: 8,
                        // py: 1
                    }}
                    spacing={8}
                >

                    <Accordion 
                        expanded={expand} 
                        TransitionProps={{
                            unmountOnExit: true
                        }}
                        sx={{
                            width: '100%',
                            backgroundColor: 'transparent',
                            backgroundImage: 'none',
                        }}
                    >

                        <AccordionSummary
                            id='patient-bio-header'
                            sx={{
                                width: '100%'
                            }}
                        >

                            <Stack
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    width: '100%'
                                }}
                                direction='row'
                                // spacing={8}
                            >
                                {                                
                                    ( isEditing && editableValues?.first_name ) ?
                                        <>
                                            <FormInput
                                                label='First Name'
                                                type='text'
                                                name='first_name'
                                                sx={{
                                                    mr: 5,
                                                    maxWidth: '20%'
                                                }}
                                                focused
                                            />

                                            <FormInput
                                                label='Last Name'
                                                type='text'
                                                name='last_name'
                                                sx={{
                                                    maxWidth: '20%'
                                                }}
                                                focused
                                            />
                                        </>
                                    :
                                        <>
                                            <SingleLineTextBox
                                                variant='h2'
                                                tooltip={(patientIsLoading) ? null : `${patient.first_name} ${patient.last_name}`}
                                            >
                                                {(patientIsLoading) ? <Skeleton width={200} /> : `${patient?.first_name} ${patient?.last_name}`}
                                            </SingleLineTextBox>
                                        </>
                                }
                                {
                                    patientIsLoading ?
                                        <Skeleton variant="circular">
                                            <Avatar     // TODO: Provide way to upload profile picture
                                                src={patient?.avatar}
                                                sx={{
                                                    height: 60,
                                                    width: 60,
                                                    ml: '10%'
                                                }}
                                            >
                                                <Person style={{ height: 40, width: 40 }}/>
                                            </Avatar>
                                        </Skeleton>
                                    :
                                        <Avatar     // TODO: Provide way to upload profile picture
                                            src={patient?.avatar}
                                            sx={{
                                                height: 60,
                                                width: 60,
                                                ml: '10%',
                                                mr: 'auto'
                                            }}
                                        >
                                            <Person style={{ height: 40, width: 40 }}/>
                                        </Avatar>
                                }

                            </Stack>

                        </AccordionSummary>

                        <AccordionDetails>

                            <Stack
                                sx={{
                                    width: '100%',
                                    justifyContent: 'space-between',
                                }}
                                direction='row'
                                spacing={12}
                            >

                                <Stack
                                    sx={{
                                        width: '100%'
                                    }}
                                    spacing={8}
                                >

                                    <Stack
                                        sx={{
                                            width: '100%',
                                            height: FIELD_HEIGHT,
                                            alignItems: 'center',
                                        }}
                                        direction='row'
                                        spacing={8}
                                    >

                                        <InfoPageDataField
                                            childSpacing={4}
                                            label='Providers'
                                            editing={isEditing}
                                            containerProps={{
                                                height: FIELD_HEIGHT,
                                                width: '100%',
                                            }}
                                            loading={patientIsLoading || doctorsAreLoading}
                                            loadingChildren={
                                                <Stack
                                                    direction={'row'}
                                                    width='100%'
                                                    height='100%'
                                                    spacing={3}
                                                    alignItems='center'
                                                >
                                                    {
                                                        Array(4).fill(0).map(() => (<Skeleton variant="rounded" sx={{ height: 40, width: 100, borderRadius: 6 }}/>))
                                                    }
                                                </Stack>
                                            }
                                        >
                                            {providerChips}
                                        </InfoPageDataField>  

                                        <Controller
                                            control={control}
                                            name='status_active'
                                            defaultValue={undefined}
                                            disabled={!isEditing}
                                            render={({ field }) => {
                                                const { value, ...fields} = field
                                                return (
                                                    <Stack direction={'row'} alignItems='center'>
                                                        <Typography>
                                                            Inactive
                                                        </Typography>
                                                        <Switch 
                                                            {...fields} 
                                                            checked={(isEditing) ? value : patient?.status_active}
                                                            sx={{
                                                                '& .MuiSwitch-switchBase.Mui-disabled': {
                                                                    color: theme.palette.primary.main,
                                                                },
                                                                '& .MuiSwitch-track': {
                                                                    backgroundColor: `${theme.palette.primary.main} !important`,
                                                                    opacity: '0.5 !important'
                                                                }
                                                            }}
                                                        />
                                                        <Typography>
                                                            Active
                                                        </Typography>
                                                    </Stack>
                                                )
                                            }}
                                        />

                                    </Stack>

                                    <Stack
                                        sx={{
                                            width: '100%',
                                            height: FIELD_HEIGHT,
                                            alignItems: 'end',
                                        }}
                                        direction='row'
                                        spacing={8}
                                    >
                                        
                                        <InfoPageDataField
                                            childSpacing={4}
                                            label='Organizations'
                                            editing={isEditing}
                                            containerProps={{
                                                height: FIELD_HEIGHT,
                                                width: '100%',
                                            }}
                                            loading={patientIsLoading || orgsAreLoading}
                                            loadingChildren={
                                                <Stack
                                                    direction={'row'}
                                                    width='100%'
                                                    height='100%'
                                                    spacing={3}
                                                    alignItems='center'
                                                >
                                                    {
                                                        Array(4).fill(0).map(() => (<Skeleton variant="rounded" sx={{ height: 40, width: 100, borderRadius: 6 }}/>))
                                                    }
                                                </Stack>
                                            }
                                        >
                                            {orgsChips}
                                        </InfoPageDataField> 

                                        <InfoPageDataField
                                            childSpacing={4}
                                            label='Trials'
                                            editing={isEditing}
                                            containerProps={{
                                                height: FIELD_HEIGHT,
                                                width: '100%',
                                            }}
                                            loading={patientIsLoading || trialsAreLoading}
                                            loadingChildren={
                                                <Stack
                                                    direction={'row'}
                                                    width='100%'
                                                    height='100%'
                                                    spacing={3}
                                                    alignItems='center'
                                                >
                                                    {
                                                        Array(4).fill(0).map(() => (<Skeleton variant="rounded" sx={{ height: 40, width: 100, borderRadius: 6 }}/>))
                                                    }
                                                </Stack>
                                            }
                                        >
                                            {trialsChips}
                                        </InfoPageDataField> 

                                    </Stack>

                                    <Stack
                                        sx={{
                                            width: '100%',
                                            height: FIELD_HEIGHT,
                                            alignItems: 'end'
                                        }}
                                        direction='row'
                                        spacing={8}
                                    >
                                        {
                                            isEditing ?
                                                <PhoneNumberInput focused />
                                            :
                                                <InfoPageTextField
                                                    label='Phone'
                                                    type='tel'
                                                    name='phone'
                                                    loading={patientIsLoading}
                                                    editing={isEditing}
                                                    text={formattedPhone}  
                                                    boxProps={{
                                                        display: 'flex',
                                                        alignItems: 'end',
                                                        width: '100%',
                                                        height: '100%'
                                                    }}
                                                    inputProps={{
                                                        height: '3.6rem'
                                                    }}
                                                    focused                        
                                                />
                                        }

                                        <InfoPageTextField
                                            label='Email'
                                            type='text'
                                            name='email'
                                            loading={patientIsLoading}
                                            editing={isEditing}
                                            text={(patient?.email !== '') ? patient?.email : undefined}  
                                            boxProps={{
                                                display: 'flex',
                                                alignItems: 'end',
                                                width: '100%',
                                                height: '100%'
                                            }}
                                            inputProps={{
                                                height: '3.6rem'
                                            }}
                                            focused                        
                                        />

                                    </Stack>

                                </Stack>

                                <Stack
                                    sx={{
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <LoadingButton
                                        loading={patientIsLoading}
                                        startIcon={(isEditing) ? <CloseSharp/> : <EditSharp/>}
                                        variant="outlined"
                                        color={(isEditing) ? 'error' : 'primary'}
                                        sx={{
                                            height: 'fit-content',
                                            py: 1,
                                            px: 1.5,
                                            '&:hover': {
                                                backgroundColor: (isEditing) ? theme.palette.error.main : theme.palette.primary.main,
                                                color: 'white' 
                                            }
                                        }}
                                        onClick={() => { reset(); setExpand(!isEditing); setIsEditing((prev) => !prev) }}
                                    >
                                        {(isEditing) ? 'Cancel' : 'Edit'}
                                    </LoadingButton>  

                                    {
                                        isEditing &&
                                        <LoadingButton
                                            loading={isSubmitting}
                                            endIcon={<SendSharp/>}
                                            variant="outlined"
                                            color={'primary'}
                                            sx={{
                                                height: 'fit-content',
                                                py: 1,
                                                px: 1.5,
                                                '&:hover': {
                                                    backgroundColor: theme.palette.primary.main,
                                                    color: 'white' 
                                                }
                                            }}
                                            type="submit"
                                        >
                                            Submit
                                        </LoadingButton> 
                                    }     
                                    
                                </Stack>

                            </Stack>

                        </AccordionDetails>

                    </Accordion>

                </Stack>



                <Button
                    disabled={isEditing}
                    variant='standard'
                    endIcon={(expand) ? <KeyboardDoubleArrowUpSharp/> : <KeyboardDoubleArrowDownSharp/>}
                    onClick={(expand) ? () => setExpand(false) : () => setExpand(true)}
                    sx={{
                        fontSize: '1rem'
                    }}
                >
                    {`See ${(expand) ? 'Less' : 'More'}`}
                </Button> 
            </Box>
        </FormProvider>

        {
            openProviderModal &&
            <AddProviderModal
                open={openProviderModal}
                handleClose={() => { setOpenProviderModal(false) }}
                patient_id={alacrity_id}
            />
        }

        {
            openTrialModal &&
            <AddTrialModal
                open={openTrialModal}
                handleClose={() => { setOpenTrialModal(false) }}
                patient_id={alacrity_id}
            />
        }

        {
            openOrgModal &&
            <AddOrgModal
                open={openOrgModal}
                handleClose={() => setOpenOrgModal(false)}
                patient_id={alacrity_id}
            />
        }

        </>
    )
}



const AddProviderModal = ({ open, handleClose, patient_id }) => {
    const orderProviders = (a, b) => {
        const order = a.first_name.localeCompare(b.first_name);
        if (order === 0) {
            return a.last_name.localeCompare(b.last_name)
        }
        return order;
    }

    const transformProviders = (entity) => {
        return {
            label: `${entity.first_name} ${entity.last_name}`,
            id: entity.alacrity_id
        }
    }

    

    return (
        open &&
        <EntityLinkModal
            open={open}
            onClose={handleClose}
            orderEntities={orderProviders}
            transformEntities={transformProviders}
            entityName={'Patient'}
            entityID={patient_id}
            entityListQuery={useGetProvidersSidebarQuery}
            linkName={'Doctor'}
            linkEntityMutation={useAddPatientToDoctorMutation}
            ariaLabelledBy="modal-doctor-title"  
            onSuccess={() => {
                handleClose()
            }}      
        />
    )
}



const AddOrgModal = ({ open, handleClose, patient_id }) => {
    const orderOrgs = (a, b) => {
        return a.name.localeCompare(b.name);
    }

    const transformOrgs = (entity) => {
        return {
            label: `${entity.name}`,
            id: entity.org_id
        }
    }

    

    return (
        open &&
        <EntityLinkModal
            open={open}
            onClose={handleClose}
            orderEntities={orderOrgs}
            transformEntities={transformOrgs}
            entityName={'Patient'}
            entityID={patient_id}
            entityListQuery={useGetOrgsQuery}
            linkName={'Org'}
            linkEntityMutation={useAddPatientToOrgMutation}
            ariaLabelledBy="modal-org-title"  
            onSuccess={() => {
                handleClose()
            }}      
        />
    )
}



const AddTrialModal = ({ open, handleClose, patient_id }) => {
    const orderTrials = (a, b) => {
        return a.name.localeCompare(b.name);
    }

    const transformTrials = (entity) => {
        return {
            label: entity.name,
            id: entity.trial_id
        }
    }

    

    return (
        open &&
        <EntityLinkModal
            open={open}
            onClose={handleClose}
            orderEntities={orderTrials}
            transformEntities={transformTrials}
            entityName={'Patient'}
            entityID={patient_id}
            entityListQuery={useGetTrialsQuery}
            linkName={'Trial'}
            linkEntityMutation={useAddPatientToTrialMutation}
            ariaLabelledBy="modal-trial-title"  
            onSuccess={() => {
                handleClose()
            }}      
        />
    )
}



const usePatientInfoForm = ({ user, patient }) => {
    return useMemo(() => {
        var schema = z.object({});
        var initialValues = {};
        var editableValues = {};

        if (
            user.alacrity_id === patient?.alacrity_id || 
            (
                user?.admin === true && 
                user?.permissions === 'DOCTOR' && 
                user?.orgs?.some((org_id) => patient?.orgs?.includes(org_id))
            ) || 
            user?.permissions === 'ALACRITY'
        ) {     // If the user is looking at their own page, they can edit email & phone since they can verify.
            initialValues = {
                ...initialValues,
                first_name: undefined,
                last_name: undefined,
                email: undefined,
                email_verified: undefined,
                phone: undefined,
                phone_verified: undefined,
                countryCode: 'US'
            }

            editableValues = {
                first_name: patient?.first_name,
                last_name: patient?.last_name,
                email: patient?.email,
                email_verified: patient?.email_verified,
                countryCode: patient?.phone?.iso2,
                phone: patient?.phone?.e164NumberString,
                phone_verified: patient?.phone_verified,
            }

            schema = schema.extend({
                first_name: z.string().optional(),
                last_name: z.string().optional(),
                email: z.union([z.string().email('Must be a valid email.'), z.literal('')]),
                email_verified: z.boolean().optional(),
                countryCode: z.string().length(2).optional(),
                phone: z.string().optional(),
                phone_verified: z.boolean().optional(),
            })
        }

        if (
            (
                user?.admin === true && 
                user?.permissions === 'DOCTOR' &&
                user?.orgs?.some((org_id) => patient?.orgs?.includes(org_id))
            ) || 
            user?.permissions === 'ALACRITY'
        ) {
            initialValues = {
                ...initialValues,
                orgs: undefined,
                doctors: undefined,
                trials: undefined,
                status_active: patient?.status_active
            }

            schema = schema.extend({
                orgs: z.array(z.string()).optional(),
                doctors: z.array(z.string()).optional(),
                trials: z.array(z.string()).optional(),
                status_active: z.boolean().optional()
            })

            editableValues = {
                ...editableValues,
                orgs: patient?.orgs?.filter((org_id) => { return ( user?.permissions === 'ALACRITY' || user?.orgs?.includes(org_id) ) }),
                doctors: patient?.doctors?.filter((alacrity_id) => user?.permissions === 'ALACRITY' || user?.doctors?.includes(alacrity_id)),
                trials: patient?.trials?.filter((trial_id) => user?.permissions === 'ALACRITY' || user?.trials?.includes(trial_id)) ?? [],
                status_active: patient?.status_active
            }
        }

        

        if (user.permissions === 'ALACRITY') {      // Alacrity users can change anything.
            initialValues = {
                ...initialValues,
                pharmas: undefined,
            }

            schema = schema.extend({
                pharmas: z.array(z.string()).optional()
            })

            editableValues = {
                ...editableValues,
                pharmas: patient?.pharmas,
            }
        }


        schema = schema.superRefine((data, ctx) => {
            if (data?.phone) {
                if (!isPossiblePhoneNumber(data?.phone.replace(' ', ""))) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: 'Invalid Phone Number',
                        path: ['phone']
                    })
                }
            }
        })


        return { schema, initialValues, editableValues }
    
    }, [user, patient])
}



export const PatientInfoLoader = ({dispatch, id}) =>
    async () => {
        console.log('Patient Info Updated')
        dispatch(updateCurrentEntity(id))
        return Promise.resolve(null)
    }