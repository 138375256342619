import { Box, LinearProgress, Stack, Typography, IconButton, Tooltip, Paper, CircularProgress, useTheme } from "@mui/material";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Await, useLoaderData, useParams } from "react-router";
import GenericError from "../../error/GenericError";
import { selectUser } from "../../../common/redux/userSlice";
import { useSelector } from "react-redux";
import { GridToolbar } from "@mui/x-data-grid";
import { DataGridPremium } from "@mui/x-data-grid-premium"
import { FolderOffSharp, Login } from "@mui/icons-material";
import { EmptyList, LoadingList } from "../../../components/EntityList";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { toast } from "react-toastify";

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { useLabsColumns } from "./PatientTableColumns";
import { useGetLabsForPatientInRangeQuery, useGetLabsForPatientQuery, useLazyGetLabsByIDQuery } from "../../../common/redux/api/readingsApi";
import { useIsQueryLoading } from "../../../common/util";
import { useGetPatientQuery } from "../../../common/redux/api/api";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import LoadingSplashPage from "../../LoadingSplashPage";

dayjs.extend(localizedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);



const TABLE_HEIGHT = '75vh'



export default function LabsTable() {
    const { id: alacrity_id } = useParams()
    const user = useSelector(selectUser);
    const role = user.permissions;
    const theme = useTheme()



    const {
        data: patient,
        isLoading: isPatientLoading,
        isFetching: isPatientFetching,
        isSuccess: isPatientSuccess,
        isError: isPatientError,
        error: patientError,
        isUninitialized: isPatientUninitialized
    } = useGetPatientQuery(alacrity_id)

    const patientIsLoading = useIsQueryLoading([isPatientLoading, isPatientFetching, isPatientUninitialized])



    const [ currentDay, setCurrentDay ] = useState(dayjs().startOf('day'))

    const {
        data: labs,
        isSuccess: areLabsSuccess,
        isLoading: areLabsLoading,
        isFetching: areLabsFetching,
        isError: areLabsError,
        error: labsError,
        isUninitialized: areLabsUninitialized,
    } = useGetLabsForPatientInRangeQuery({ 
        patient_id: alacrity_id,
        start: currentDay.toISOString(),
        end: currentDay.endOf('day').toISOString()
    })

    const labsAreLoading = useIsQueryLoading([areLabsLoading, areLabsUninitialized])



    const handleNavigate = useCallback((newDay) => {
        setCurrentDay(newDay)
    }, [])



    const CustomPagination = () => {
        return (
            <Stack
                width={"100%"}
                direction='row'
                justifyContent={'end'}
                spacing={2}
                alignItems='center'
                sx={{
                    px: '2rem',
                    py: '1rem'
                }}
            >
                <Tooltip
                    title={'Previous Day'}
                >
                    <LoadingButton
                        loading={patientIsLoading || labsAreLoading}
                        startIcon={<KeyboardArrowLeftIcon/>}
                        disabled={currentDay.isSameOrBefore(dayjs(patient?.creation_time ?? dayjs().toISOString()).startOf('day'))}    // If patient is null, stay on current day and prevent navigation. Don't go past creation date.
                        onClick={() => {
                            handleNavigate(currentDay.subtract(1, 'day'))
                        }}
                    >
                    </LoadingButton>
                </Tooltip>

                {/* <Typography
                    variant="subtitle1"
                    textAlign={'center'}
                >
                    {dayjs(date).format("L")}
                </Typography> */}

            <DatePicker
                minDate={dayjs(patient?.creation_time ?? dayjs().toISOString())}
                maxDate={dayjs()}
                // shouldDisableDate={shouldDisableDate}
                loading={patientIsLoading || labsAreLoading}
                renderLoading={CircularProgress}
                value={currentDay}
                onChange={(newDay) => { console.log(`Selected Date: ${newDay.toISOString()}`); handleNavigate(newDay.startOf('day')) }}			
            />

                <Tooltip
                    title={'Next Day'}
                >
                    <LoadingButton
                        loading={patientIsLoading || labsAreLoading}
                        disabled={currentDay.isSameOrAfter(dayjs().startOf('day'))}
                        onClick={() => {
                            handleNavigate(currentDay.add(1, 'day'))
                        }}
                        startIcon={<KeyboardArrowRightIcon/>}
                    >
                    </LoadingButton>
                </Tooltip>
            </Stack>
        )
    }



    const rows = useMemo(() => {
        if (areLabsSuccess) {
            return labs?.map((lab, index) => ({
                id: `${lab.lab_id}-${lab.sensor_type}-${lab.value}`,
                name: lab.sensor_type,
                ref_min: lab.ref_min,
                ref_max: lab.ref_max,
                unit: lab.units, // (lab?.units !== "unknown" && labType?.unit) ? labType?.unit : "None",
                value: lab.value,
                ts: lab.ts
            }))
        } else {
            return []
        }
    }, [labs, areLabsSuccess])



    const columns = useLabsColumns(role)



    return(
        <Paper 
            elevation={2}
            sx={{ 
                height: TABLE_HEIGHT, 
                width: '100%',
            }}
        >
                <DataGridPremium
                    autoHeight={false}
                    columns={columns}
                    rows={rows}
                    loading={patientIsLoading || labsAreLoading}
                    pagination
                    // autoPageSize
                    // paginationModel={{ pageSize: 100 }}
                    // pageSizeOptions={[100]}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                id: false
                            }
                        },
                        // pagination: { paginationModel: { pageSize: 100 } },
                    }}
                    slots={{
                        "toolbar": GridToolbar,
                        pagination: CustomPagination,
                        loadingOverlay: LoadingSplashPage,
                        noRowsOverlay: EmptyList
                    }}
                    autosizeOnMount
                    autosizeOptions={{
                        // columns: ['name', 'ref_min', 'ref_max', 'unit', 'value'],
                        includeOutliers: true,
                        includeHeaders: true,
                        expand: true
                    }}
                    sx={{
                        height: TABLE_HEIGHT,
                        '& .MuiDataGrid-toolbarContainer': {
                            backgroundColor: '#212121'
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#212121',
                        },
                        '& .MuiDataGrid-footerContainer': {
                            backgroundColor: '#212121'
                        },
                        '& .MuiDataGrid-virtualScroller': {
                            '::-webkit-scrollbar': {
                                '-webkit-appearance': 'none',
                                width: '7px' 
                            },  
                            '::-webkit-scrollbar-thumb': {
                                borderRadius: '4px',
                                backgroundColor: 'rgba(255, 255, 255, .8)',
                            }
                        }
                    }}
                />

        </Paper>
    )
}