import { createSelector, createSlice } from "@reduxjs/toolkit";



const initialState = {}




export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        prefillUser: (state, action) => {
            const loginInfo = action.payload;
            state.email = loginInfo.email;
            state.phoneNumber = loginInfo.phoneNumber
        },
        logout: () => {
            return initialState
        },
        setUser: (state, action) => {
            return action.payload
        }
    }
})



export const { prefillUser, logout, setUser } = userSlice.actions;



export const selectUser = (state) => {
    return state.user
}



export const makeSelectPrefilledUserInfo = () =>
    createSelector(
        selectUser,
        (user) => { return { email: user.email, phoneNumber: user.phoneNumber } }
    )



export default userSlice.reducer