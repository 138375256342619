import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { DataGridPremium as DataGrid, DataGridPremium } from "@mui/x-data-grid-premium";
import { GridToolbar } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { selectUser } from "../common/redux/userSlice";
import FolderOffSharpIcon from '@mui/icons-material/FolderOffSharp';
import { LoadingButton } from "@mui/lab";
import AlacrityTheme from "../common/Theme";



export default function EntityList({
    loading,
    columns,
    rows,
    initColumnVisibilityModel,
    actions = [],
    // handleAddRow,
    entityName,
    onRowSelectionModelChange
}) {
    const user = useSelector(selectUser);
    const role = user.permissions

   // TODO: Show buttons for altering rows based on permissions.

   if (loading) {
       return <LoadingList/>
   }

   if (rows.length === 0) {
       return (
            <Stack
                height={'100%'}
                width={'100%'}
            >
                <Stack
                    width={'100%'}
                    alignItems={'center'}
                    sx={{
                        border: '1px solid #dddddd',
                        my: '2rem',
                        py: '2rem'
                    }}
                    spacing={3}
                >
                    <FolderOffSharpIcon 
                        sx={{
                            height: '4rem',
                            width: '4rem'
                        }}
                    />
                    <Typography 
                        variant='h4'
                        sx={{
                            height: '4rem',
                            width: '100%',
                            color: '#dddddd',
                            textAlign: 'center'
                        }}
                    >
                        There's nothing here...
                    </Typography>
                </Stack>
                <Stack
                    direction={'row'}
                    spacing={1}
                    sx={{
                        px: '1rem'
                    }}
                >
                    {   
                        actions.map((action) => {
                            if (action.permissions.has(role)) {
                                return (
                                    <LoadingButton
                                        key={action.label}
                                        size="large" 
                                        onClick={action.onClick}
                                    >
                                        {action.label}
                                    </LoadingButton>
                                )
                            }
                        })
                    }
                </Stack>
            </Stack>
        )
   }

    return(
        <Box sx={{ flexGrow: 1}}>
            <Box sx={{ height: 'auto', width: 'auto' }}>
                <DataGrid 
                    loading={loading}
                    columns={columns}
                    rows={rows}
                    initialState={{
                        columns: {
                            columnVisibilityModel: initColumnVisibilityModel
                        },
                        pagination: { paginationModel: { pageSize: 25 } },
                    }}
                    onRowSelectionModelChange={onRowSelectionModelChange}
                    slots={{
                        "toolbar": GridToolbar,
                        loadingOverlay: LinearProgress,
                    }}
                    pageSizeOptions={[25, 50, 100]}
                />
            </Box>
            <Stack direction="row" spacing={1}>
                {   
                    actions.map((action) => {
                        if (action.permissions.has(role)) {
                            return (
                                <LoadingButton
                                    key={action.label}
                                    size="large" 
                                    onClick={action.onClick}
                                >
                                    {action.label}
                                </LoadingButton>
                            )
                        }
                    })
                }
            </Stack>
        </Box>
    );
}



export const LoadingList = () => {
    return (
        <Box
            sx={{
                m: '2rem',
                p: '2rem',
                border: AlacrityTheme.alacrityBorder,
                borderRadius: 0,
                width: "100%",
                height: "100%"
            }}
        >
            <DataGridPremium
                columns={[
                    {
                        field: 'id',
                        headerName: "Loading...",
                        headerAlign: 'center'
                    }
                ]}
                rows={[{id: 1},{id: 2},{id: 3}]}
                loading={true}
                slots={{
                    loadingOverlay: LinearProgress
                }}
                sx={{
                    '&.MuiDataGrid-root': {
                        borderRadius: 0,
                        borderTopWidth: 0,
                        borderLeftWidth: 0,
                        borderRightWidth: 0
                    },
                }}
            />
        </Box>
    )
}



export const EmptyList = ({ actions=[] }) => {
    const user = useSelector(selectUser)
    const role = user.permissions



    return (
        <Stack
            height={'100%'}
            width={'100%'}
        >

            <Stack
                direction={'row'}
                spacing={2}
                sx={{
                    px: '1rem'
                }}
            >
                {   
                    actions?.map((action) => {
                        if (action.permissions.has(role)) {
                            return (
                                <LoadingButton
                                    key={action.label}
                                    size="large" 
                                    onClick={action.onClick}
                                >
                                    {action.label}
                                </LoadingButton>
                            )
                        }
                    })
                }
            </Stack>

            <Stack
                width={'100%'}
                alignItems={'center'}
                sx={{
                    // border: '1px solid #dddddd',
                    my: '2rem',
                    py: '2rem'
                }}
                spacing={3}
            >
                <FolderOffSharpIcon 
                    sx={{
                        height: '4rem',
                        width: '4rem'
                    }}
                />
                <Typography 
                    variant='h4'
                    sx={{
                        height: '4rem',
                        width: '100%',
                        color: '#dddddd',
                        textAlign: 'center'
                    }}
                >
                    There's nothing here...
                </Typography>
            </Stack>

        </Stack>
    )
}