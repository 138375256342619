import { AddSharp, ArrowBackRounded, CloseSharp, EditSharp, HeightSharp, NotificationAddSharp } from "@mui/icons-material";
import { Box, Checkbox, Divider, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Paper, Select, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { json, useNavigate, useParams } from "react-router";
import { useGetPatientQuery } from "../../../common/redux/api/api";
import { useRTKResultToasts } from "../../../common/RTKResultHooks";
import { SingleLineTextBox } from "../../../common/Theme";
import { useCallback, useEffect, useMemo, useState } from "react";
import { alertsApi, useDeleteNotificationRuleMutation, useGetAllSensorsQuery, useGetNotificationRangesQuery, useGetNotificationRulesQuery } from "../../../common/redux/api/alertsApi";
import * as z from "zod";
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { sensorsToNames } from "../../../common/sensors";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FormInput from "../../../components/CustomFormInput";
import { generateMaterialColor } from "../../../common/RandomColor";
import { LoadingButton } from "@mui/lab";
import { useIsQueryLoading } from "../../../common/util";
import { useDispatch } from "react-redux";
import RulesEditor, { SectionTitle } from "./RulesEditor";
import TriageDisplay from "./TriageDisplay";
import { updateCurrentEntity } from "../../../common/redux/sidebarSlice";



export default function PatientAlertsPage() {
    const { id: patient_id } = useParams();
    const navigate = useNavigate();
    const theme = useTheme()
    const dispatch = useDispatch()



    const { 
        data: patient, 
        isLoading: isPatientLoading,
        isFetching: isPatientFetching,
        isSuccess: isPatientSuccess,
        isUninitialized: isPatientUninitialized,
        isError: isPatientError,
        error: patientError 
    } = useGetPatientQuery(patient_id)

    const patientIsLoading = useMemo(() => { return isPatientLoading || isPatientFetching || isPatientUninitialized }, [isPatientLoading, isPatientFetching, isPatientUninitialized])

    useRTKResultToasts({
        isError: isPatientError,
        error: patientError
    })



    const navigateBack = () => {
        dispatch(updateCurrentEntity(null))
        navigate(`/dashboard/alerts`)
    }


    const [ editingRuleID, setEditingRuleID ] = useState(null)
    const [ editingRule, setEditingRule ] = useState(null)
    const [ stopEditing, setStopEditing ] = useState(false)
    
    const onEditRule = (ruleID, rule) => {
        console.log(rule)
        setEditingRuleID(ruleID)
        setEditingRule(rule)
        setStopEditing(false)
    }

    const onCancelEditing = () => {
        setEditingRuleID(null)
        setEditingRule(null)
        setStopEditing(true)
    }



    return (
        <Stack 
            sx={{
                width: '100%',
                height: '100%',
                px: 4,
                pt: 2,
                pb: 12,
                justifyContent: 'start',
                alignItems: 'start'
            }}
            spacing={2}
        >
{/**************************************************************************** */}

            <Stack       // Title bar
                sx={{
                    justifyContent: 'start',
                    alignItems: 'center',
                    width: '100%'
                }}
                direction='row'
            >
                <IconButton
                    onClick={navigateBack}
                >
                    <ArrowBackRounded fontSize="large" style={{ color: theme.palette.text.secondary }} />
                </IconButton>
            
                <SingleLineTextBox
                    variant='h4'
                    tooltip={`${patient?.first_name} ${patient?.last_name}`}
                    color={'textSecondary'}
                >
                    {(patientIsLoading) ? <Skeleton width='40%' /> : `${patient?.first_name} ${patient?.last_name}`}
                </SingleLineTextBox>
            </Stack>

{/*****************************************************************************/}

            <Stack      // Main Body Columns
                direction='row'
                sx={{
                    height: '100%',
                    width: '100%',
                }}
                spacing={'22px'}
            >

{/*****************************************************************************/}

                <Stack       // Left Half of Page 
                    sx={{
                        height: '100%',
                        width: '100%',
                    }}
                    spacing={'22px'}
                >
                    <RulesList 
                        patient={patient} 
                        loading={patientIsLoading}
                        onEditRule={onEditRule}
                        stopEditing={stopEditing} 
                    />

                    <RulesEditor 
                        patient={patient} 
                        loading={patientIsLoading} 
                        ruleID={editingRuleID}
                        rule={editingRule}
                        onCancelEditing={onCancelEditing}
                    />
                </Stack>

{/*****************************************************************************/}

                <Box
                    sx={{
                        width: '80%',
                        height: '100%',
                    }}
                >
                    <TriageDisplay patient={patient} loading={patientIsLoading} />      {/*Right Half of Page*/}
                </Box>

{/*****************************************************************************/}

            </Stack>
        </Stack>
    )
}



const RulesList = ({ patient, loading, onEditRule, stopEditing }) => {
    const theme = useTheme()



    const {
        data: rules,
        isLoading: areRulesLoading,
        isFetching: areRulesFetching,
        isUninitialized: areRulesUninitialized,
        isError: areRulesError,
        error: RulesError
    } = useGetNotificationRulesQuery(patient?.alacrity_id, { skip: loading});

    const rulesAreLoading = useIsQueryLoading([areRulesLoading, areRulesFetching, areRulesUninitialized]);

    useRTKResultToasts({
        isError: areRulesError,
        error: RulesError
    })



    return (
        <Stack
            sx={{
                height: '50%',
                width: '100%',
                p: 2,
                justifyContent: 'start',
                alignItems: 'start',
                borderRadius: 4,
                border: 'none',
                boxShadow: theme.alacrityShadowBorder // 'rgba(255, 255, 255, 0.5) 0px 0px 4px 2px',
            }}
            spacing={2}
        >
            <SectionTitle>
                Rules
            </SectionTitle>

            <Divider width='100%'/>

            <Stack
                sx={{
                    // height: '100%',
                    // maxHeight: '50%',
                    width: '100%',
                    justifyContent: 'start',
                    overflowY: 'auto',
                    '::-webkit-scrollbar': {
                        '-webkit-appearance': 'none',
                        width: '7px'
                    },  
                    '::-webkit-scrollbar-thumb': {
                        borderRadius: '4px',
                        backgroundColor: 'rgba(255, 255, 255, .5)',
                    }
                }}
                spacing={2}
            >

                {
                    (rulesAreLoading) ?
                        Array(3).fill(0).map((_, index) => <RuleDisplayItem key={index} loading={true} />)
                    :
                        rules?.map((rule) => (
                            <RuleDisplayItem 
                                key={rule?.rule_id} 
                                rules={formatJSONRules(rule?.logic_tree ?? {})} 
                                loading={loading} 
                                ruleID={rule?.rule_id} 
                                color={theme.palette.primary.main} 
                                onClickRule={() => onEditRule(rule?.rule_id, rule?.logic_tree)}
                                stopEditing={stopEditing}
                            />
                        ))
                }

            </Stack>

        </Stack>
    )
}



const RuleDisplayItem = ({
    rules,
    loading,
    onClickRule,
    ruleID,
    color,
    stopEditing
}) => {
    const theme = useTheme();



    const [ deleteNotification, deletionResult ] = useDeleteNotificationRuleMutation();
    const { isLoading: isDeletionLoading, isSuccess: isDeletionSuccess, isError: isDeletionError, error: deletionError } = deletionResult;

    useRTKResultToasts({
        isSuccess: isDeletionSuccess,
        successMessage: 'Deleted rule successfully',
        isError: isDeletionError,
        error: deletionError,
        toastId: 'notifications-rule-delete'
    });



    useEffect(() => {
        if (stopEditing) {}
    }, [stopEditing])



    return (
        <Stack
            direction='row'
            sx={{
                width: '100%',
                justifyContent: 'start',
                alignItems: 'center',
            }}  
            spacing={1}  
        >
            <Box
                sx={{
                    height: '100%',
                    width: '4px',
                    borderRadius: 4,
                    backgroundColor: color
                }}
            />

            <Stack
                sx={{
                    width: '100%',
                    height: '100%',
                    overFlowY: 'auto',
                    justifyContent: 'start',
                    alignItems: 'start',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.3)'
                    }
                }}
            >
                { 
                    loading ?
                        <Skeleton sx={{ width: '100%' }} />
                    :
                        displayFormattedRules({ rules, color: theme.palette.secondary.main }) 
                }
            </Stack>

            <IconButton
                sx={{
                    borderRadius: 2
                }}
                onClick={onClickRule}
            >
                <EditSharp style={{ color: theme.palette.text.secondary }} />
            </IconButton>

            <LoadingButton
                loading={isDeletionLoading}
                onClick={() => { deleteNotification(ruleID) }}
            >
                <CloseSharp style={{ color: theme.palette.text.secondary }} />
            </LoadingButton>
        </Stack>
    )
}



const initialValues = {
    openParen: '',
    sensorName: '',
    is: '',
    not: false,
    comparator: '',
    constant: '',
    closeParen: '' ,
    combinator: ''
};

export const formatJSONRules = (rule, negate=false) => {
    if (Object.hasOwn(rule, 'name')) {     // Base Case. All leaves are rules of type { name, comparator, value }
        return {
            ...initialValues,
            sensorName: sensorsToNames[rule.name],
            is: 'is',
            not: negate,
            comparator: (rule.comparator === '>') ? 'above' : 'below',
            constant: rule.value,
        }
    }

    if (Object.hasOwn(rule, 'NOT')) {       // Rules wrapped in NOT are negated and need their not value turned to true.
        return rule.NOT.map((child) => formatJSONRules(child, true))
    }

    if (Object.hasOwn(rule, 'AND') || Object.hasOwn(rule, 'OR')) {      // Rules wrapped in AND or OR are all separated by these operators and combined in a subgroup.
        const combinator = (Object.hasOwn(rule, 'AND')) ? 'AND' : 'OR';
        const children = rule[combinator].map((child) => formatJSONRules(child, negate));

        if (children.length === 1) {    //  Since an AND or OR of 1 is just that rule, get rid of the array for less confusion up the stack.
            return children.at(0)
        } else {
            return children.map((child) => {    // Wrap children who are arrays in parentheses.
                if (Array.isArray(child)) {
                    child.unshift({ ...initialValues, openParen: '(' })  // Put parentheses at beginning and end.
                    child.push({ ...initialValues, closeParen: ')' })
                  }
                  return child
            }).flatMap((wrappedChild, index, wrappedChildren) => {  // Put combinator operators after all but the last child.
                if (index === wrappedChildren.length - 1) {
                    return wrappedChild
                } else {
                    // Easy way to 'insert' values (actually creating new array) into an array while iterating over it by adding them as arrays then flattening it.
                    return [wrappedChild, { ...initialValues, combinator: combinator }]
                }
            })//.flat()   // Children should never be more than one level deep, so just call flat with no depth.
        }
    }

    return rule
}



const displayFormattedRules = ({rules, color}) => {
    console.log('Formatted JSON Rules: ', rules)
    return (
        <Stack
            sx={{
                width: '100%',
                justifyContent: 'start',
                alignItems: 'start'
            }}
        >
            {
                displayRulesChild({ child: rules, color })
            }
        </Stack>
    )
}


// TODO: Redo this piece of shit
export const displayRulesChild = ({ child, color, depth=0 }) => {
    console.log('Child: ', child)
    if (Array.isArray(child)) {
        return child.map((grandchild) => displayRulesChild({ child: grandchild, color, depth: depth + 1 }))
    } else {
        if (Object.values(child).length === 0) { // Base case is empty object
            return
        }

        const [[key, value], ...rest] = Object.entries(child);  // Pop off first key-value pair
        const objectTail = rest.reduce((acc, [k, v]) => Object.assign(acc, { [k]: v }), {});    // Reconstruct rest of entries into object

        if (value) {
            switch(key) {
                // case 'openParen':
                //     return displayRulesChild({ child: objectTail, color, depth })
                case 'sensorName':
                    return (
                        <Stack
                            key={`${value}-${objectTail?.comparator}`}
                            direction='row'
                            spacing={1}
                            sx={{
                                width: '100%',
                                alignItems: 'center'
                            }}
                        >
                            <Typography
                                variant="h4"
                                color={color}
                            >
                                {'•  '.repeat(depth)}
                            </Typography>

                            <Typography
                                variant="h4"
                                color='white'
                            >
                                {value}
                            </Typography>

                            {
                                displayRulesChild({ child: objectTail, color, depth })
                            }
                        </Stack>
                    )
                case 'is':
                    return (
                        <>
                            <Typography
                                variant="h4"
                                color={'#CE93D8'}
                            >
                                {value}
                            </Typography>
                            {
                                displayRulesChild({ child: objectTail, color, depth })
                            }
                        </>
                    )
                case 'not':
                    return (
                        <>
                            {
                                value &&
                                <Typography
                                    variant="h4"
                                    color={'#d32f2f'}
                                >
                                    NOT
                                </Typography>
                            }
                            {
                                displayRulesChild({ child: objectTail, color, depth })
                            }
                        </>
                    )
                case 'comparator':
                    return (
                        <>
                            <Typography
                                variant="h4"
                                color={'#CE93D8'}
                            >
                                {value}
                            </Typography>
                            {
                                displayRulesChild({ child: objectTail, color, depth })
                            }
                        </>
                    )
                case 'constant':
                    return (
                        <>
                            <Typography
                                variant="h4"
                                color={'#FFB74D'}
                            >
                                {value}
                            </Typography>
                            {
                                displayRulesChild({ child: objectTail, color, depth })
                            }
                        </>
                    )
                // case 'closeParen':
                //     return displayRulesChild({ child: objectTail, color, depth: depth - 1 })
                case 'combinator':
                    return (
                        <>
                            <Typography
                                variant="h4"
                                color={'#78CCDD'}
                            >
                                {`${'•  '.repeat(depth)}${value}`}
                            </Typography>
                            {
                                displayRulesChild({ child: objectTail, color, depth })
                            }
                        </>
                    )
                default:
            }
        } else {
            return displayRulesChild({child: objectTail, color, depth})
        }
    }
}