import { useNavigate, Outlet } from "react-router-dom";
import { Box, Stack, Button, Avatar, IconButton, Menu, useTheme, Paper, CircularProgress, Tooltip } from "@mui/material";
import { useLogoutUserMutation } from "../common/redux/api/auth/authApi";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../common/redux/userSlice";
import Cookies from "js-cookie";
import { apiSlice } from "../common/redux/api/api";
import { LoginSharp, PermIdentitySharp, PersonAddSharp, LogoutSharp } from "@mui/icons-material";
import SvgDiagnosisStethoscope from '../images/icons/DiagnosisStethoscope'
import SvgToxhoundDmIconBlueSmall from '../images/icons/Toxhound_dm_icon_blue_small'



export const BANNER_HEIGHT = 48;



export default function Banner() {
    const [ logoutUser, logoutResult ] = useLogoutUserMutation();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const user = useSelector(selectUser);
    const cookies = Cookies.get();
    const dispatch = useDispatch();
    const theme = useTheme();



    const [anchorEl, setAnchorEl] = useState(null);
    const openUserMenu = Boolean(anchorEl);

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };



    const navgiateToAccount = () => {
        navigate('/account')
    }



    useEffect(() => {
        setIsLoggedIn(Cookies.get("logged_out") === 'false')
    }, [setIsLoggedIn, cookies])



    const onLogout = () => {
        logoutUser()
        navigate('/login')
    }

    

    useEffect(() => {
        if (logoutResult.isSuccess) {
            toast.success('Logged out successfully.', { autoClose: 1000, toastId: 'logout', theme: 'dark' })
            logoutResult.reset()
            navigate('/login')
            dispatch(apiSlice.util.resetApiState())
        } else {
            if (logoutResult.isError) {
                toast.error(logoutResult?.error?.data?.message, {
                    position: 'top-center', 
                    autoClose: 1000,
                    theme: 'dark',
                    toastId: 'logout-error'
                });
                logoutResult.reset()
            }
        }
    }, [logoutResult, dispatch, navigate])



    const [ accountButtonContent, setAccountButtonContent ] = useState("")

    useEffect(() => {
        if (isLoggedIn) {
            if (user?.first_name) {
                setAccountButtonContent(`${user?.first_name?.at(0) ?? ''}${user?.last_name?.at(0) ?? ''}`)
            } else {
                setAccountButtonContent(
                    <Box sx={{ position: 'relative' }}>
                        <PermIdentitySharp style={{ color: 'white' }} />
                        <CircularProgress
                            size={32}
                            sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-16px',
                            marginLeft: '-16px',
                            }}
                        />
                    </Box>
                )
            }
        } else {
            setAccountButtonContent(<PermIdentitySharp style={{ color: 'white' }} />)
        }
    }, [setAccountButtonContent, user, isLoggedIn])

    

    return (
        <Paper 
            elevation={1}
            sx={{
                height: '100vh',
                width: '100vw',
                borderRadius: 0
            }}
        >
            <Stack
                sx={{
                    pt: 2,
                    height: '100%',
                    width: '100%'
                }}
            >
                    <Stack 
                        direction={'row'} 
                        justifyContent='space-between' 
                        alignItems='center'
                        sx={{
                            px: '1rem',
                            pb: '1rem',
                            height: BANNER_HEIGHT,
                        }}
                    >
                        <IconButton
                            sx={{
                                p: 1,
                                height: 60,
                                width: 76,
                            }} 
                            onClick={() => {isLoggedIn ? navigate('/dashboard') : navigate('/home') }}
                        >
                                <SvgToxhoundDmIconBlueSmall stroke={theme.palette.primary.main} />  
                        </IconButton>

                        <Stack 
                            direction={'row'} 
                            spacing={1}
                            justifyContent='center'
                            alignItems='center'
                            sx={{ 
                                height: 'fit-content', 
                                alignContent: 'center', 
                            }}
                        >
                            <BannerIconButton
                                onClick={isLoggedIn ? navgiateToAccount : openMenu }
                                // onClick={() => { navigate('/createUser') }}
                                label={isLoggedIn ? 'Account' : 'Sign In Options' }
                            >
                                {
                                    accountButtonContent
                                }
                            </BannerIconButton>
                           
                            {
                                isLoggedIn &&
                                <BannerIconButton
                                    onClick={onLogout}
                                    label='Logout'
                                    // sx={{
                                    //     backgroundColor: 'transparent'
                                    // }}
                                >
                                    <LogoutSharp style={{ color: 'white' }} />
                                </BannerIconButton>
                            }
                            <Menu
                                anchorEl={anchorEl}
                                open={openUserMenu}
                                onClose={closeMenu}
                                sx={{
                                    top: 6,
                                    left: 0,
                                }}
                            >
                                <MenuListItem startIcon={<LoginSharp/>} label='Login' onClick={() => { navigate('/login') }} />
                                <MenuListItem startIcon={<PersonAddSharp/>} label='Sign Up' onClick={() => { navigate('/signup') }} />
                            </Menu>
                        </Stack>
                    </Stack>

                <Paper 
                    elevation={0}
                    sx={{
                        height: `100%`,
                        width: '100%',
                    }}
                >

                    <Outlet/>

                </Paper>

            </Stack>
        </Paper>
    )
};



const MenuListItem = ({startIcon, label, onClick}) => {
    return (
        <Button
            variant='info'
            startIcon={startIcon}
            onClick={onClick}
            sx={{
                width: '100%',
                borderRadius: 0,
                justifyContent: 'start'
            }}
        >
            {label}
        </Button>
    )
}



const BannerIconButton = (props) => {
    const { onClick, label, ...avatarProps } = props;
    const theme = useTheme()

    return (
        <Tooltip title={label}>
            <IconButton
                onClick={onClick}
            >
                <Avatar
                    sx={{
                        height: BANNER_HEIGHT - 12,
                        width: BANNER_HEIGHT - 12,
                        // boxShadow: theme.alacrityShadowBorder, //'rgba(255, 255, 255, 0.5) 0px 0px 4px 2px',
                        // backgroundColor: 'text.secondary.darker',
                        // color: 'paper.elevation.1',
                        fontSize: 16,
                        backgroundColor: 'transparent',
                        color: 'white'
                    }}
                    {...avatarProps}
                />
            </IconButton>
        </Tooltip>
    )
}