import * as React from "react";
const SvgPatientDataPlaceholder = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={340}
    height={351}
    fill="none"
    {...props}
  >
    <g
      fill="#fff"
      fillOpacity={0.7}
      clipPath="url(#patientDataPlaceholder_svg__a)"
      filter="url(#patientDataPlaceholder_svg__b)"
    >
      <path d="M228.945 81.704a8.438 8.438 0 1 1-16.876-.001 8.438 8.438 0 0 1 16.876 0Z" />
      <path d="M158.631 62.016h-22.5V180.14h118.125V39.516h-95.625v22.5Zm25.312 106.875h-33.75v-5.625h33.75v5.625Zm50.625-39.375v5.625h-78.75v-5.625h78.75Zm-67.5-5.625v-5.625h67.5v5.625h-67.5Zm67.5 16.875v5.625h-78.75v-5.625h78.75Zm5.625 28.125h-33.75v-5.625h33.75v5.625Zm-2.812-120.938h5.625v5.625h-5.625v-5.625Zm-11.25 0h5.625v5.625h-5.625v-5.625Zm-11.25 0h5.625v5.625h-5.625v-5.625Zm-14.063 14.063h39.375v45h-.225c-1.35-9.563-9.534-16.875-19.462-16.875-9.928 0-18.113 7.312-19.463 16.875h-.225v-45Zm-39.375 5.625h14.063v11.25h11.25v14.062h-11.25v11.25h-14.063v-11.25h-11.25V78.891h11.25V67.64Z" />
      <path d="m153.006 42.328-14.063 14.063h14.063V42.328Z" />
      <path d="m105.192 36.702 22.5-22.5h78.75V.14h-98.437v22.5h-22.5v118.125h19.687V36.702Z" />
      <path d="M102.38 17.015V2.952L88.318 17.015h14.062ZM231.755 33.89V19.828h-98.437v22.5h-22.5v118.125h19.687V56.39l22.5-22.5h78.75Z" />
      <path d="M113.631 36.703h14.062V22.64l-14.062 14.063Z" />
    </g>
    <defs>
      <clipPath id="patientDataPlaceholder_svg__a">
        <path fill="#fff" d="M79.88.14h180v180h-180z" />
      </clipPath>
      <filter
        id="patientDataPlaceholder_svg__b"
        width={350.04}
        height={350.04}
        x={-5.14}
        y={0.14}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={85.02} />
        <feGaussianBlur stdDeviation={42.51} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_8889_454"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_8889_454"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default SvgPatientDataPlaceholder;
