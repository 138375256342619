import * as React from 'react';
import {
    Route,
    Navigate,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
} from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Home from "./pages/Home";
import Login from "./pages/account/Login";
import NotFound from "./pages/error/NotFound";

import SignUp , { signUpActionRouter } from './pages/account/SignUp';
import CreateUser from "./pages/account/CreateUser"
import { ProviderPage } from './pages/dashboard/doctor/DoctorInfo';
import { TrialPage } from './pages/dashboard/trial/TrialInfo';
import { PatientInfoLoader, PatientPage } from './pages/dashboard/patient/PatientInfo';
import EmptyPatientPage from './pages/dashboard/patient/EmptyPatientPage';
import { useDispatch } from 'react-redux';
import CodeDisplay from './pages/account/CodeDisplay';
import VerifyUser from './pages/account/VerifyUser';
import Unauthorized from './pages/error/Unauthorized';
import GenericError from './pages/error/GenericError'
import Account from './pages/account/Account';
import LabsTable from './pages/dashboard/patient/LabsTable';
import CreateOrg from './pages/dashboard/org/CreateOrg';
import CreateTrial, { createTrialLoader } from './pages/dashboard/trial/CreateTrial';
import RequireUser from './components/RequireUser';
import SymptomsTable from './pages/dashboard/patient/SymptomsList';
import { Box } from '@mui/material';
import Banner from './components/Banner';
import DashboardLayout from './pages/dashboard/DashboardLayout';
import EmptyTrialPage from './pages/dashboard/trial/EmptyTrialPage';
import EmptyDoctorPage from './pages/dashboard/doctor/EmptyDoctorPage';
import EmptyOrgPage from './pages/dashboard/org/EmptyOrgPage';
import PatientLoader from './pages/dashboard/patient/PatientLoader';
import TrialLoader from './pages/dashboard/trial/TrialLoader';
import DoctorLoader from './pages/dashboard/doctor/DoctorLoader';
import OrgLoader from './pages/dashboard/org/OrgLoader';
import PatientAlertsPage from './pages/dashboard/alerts/PatientAlertsPage';
import EmptyAlertsPage from './pages/dashboard/alerts/EmptyAlertsPage';
import { Graphs } from './components/Graph';
import OrgChart from './pages/dashboard/org/OrgChart';
import AlertCenter from './pages/dashboard/alerts/AlertCenter';
import { PharmaPage } from './pages/dashboard/pharma/PharmaInfo';



const PERMISSIONS_ANY = ["ALACRITY", "DOCTOR", "PHARMA", "PATIENT"];



const App = () => {
    const dispatch = useDispatch();
    // const user = useSelector(selectUser);

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route 
                path='/' 
                element={<Banner/>}
                errorElement={<GenericError />}
            >
                <Route 
                    path='home'
                    element={<Home />} 
                />

                <Route
                    index
                    element={<Navigate to={'login'}/>}
                />

                <Route element={<RequireUser allowedRoles={PERMISSIONS_ANY} />}>
                    <Route 
                        path="dashboard" 
                        element={<DashboardLayout />}
                    >
                        <Route 
                            index 
                            element={<Navigate to="patient"/>}
                        />

                        <Route 
                            path="patient" 
                            loader={PatientLoader(dispatch)}
                        >
                            <Route
                                index
                                element={<EmptyPatientPage/>}
                            />

                            <Route 
                                path=':id' 
                                element={<PatientPage />}
                                loader={({ params, request}) => PatientInfoLoader({ dispatch, id: params.id })()}
                            >

                                <Route
                                    index
                                    element={<Navigate to='graphs' />}
                                />

                                <Route
                                    path='symptoms'
                                    element={<SymptomsTable />}
                                />

                                <Route 
                                    path='graphs' 
                                    element={<Graphs/>} 
                                />

                                <Route 
                                    path='labs' 
                                    element={<LabsTable />} 
                                />
                            </Route>
                        </Route>
                        <Route 
                            path='trial' 
                            loader={TrialLoader(dispatch)}
                        >

                            <Route
                                index
                                element={<EmptyTrialPage/>}
                            />

                            <Route 
                                path=":id" 
                                element={<TrialPage/>}
                            />

                        </Route>
                        <Route 
                            path='provider' 
                            loader={DoctorLoader(dispatch)}
                        >

                            <Route
                                index
                                element={<EmptyDoctorPage/>}
                            />

                            <Route 
                                path=':id' 
                                element={<ProviderPage/>}
                            />
                                
                        </Route>
                        <Route
                            path='org'
                            loader={OrgLoader(dispatch)}
                        >
                            
                            <Route
                                index
                                element={<EmptyOrgPage/>}
                            />

                            <Route
                                path=':id'
                                element={<OrgChart/>}
                            />
                        </Route>

                        <Route
                            path='alerts'
                        >
                            <Route
                                index
                                element={<AlertCenter/>}
                            />

                            {/* <Route
                                path='center'
                                element={<AlertsCenter/>}
                            /> */}

                            <Route
                                path=':id'
                                element={<PatientAlertsPage/>}
                            />

                        </Route>

                        <Route
                            path='pharma'
                        >
                            <Route
                                path=':id'
                                element={<PharmaPage/>}
                            />
                        </Route>
                    </Route>
                </Route>
                <Route
                    path="signup"
                    element={
                        <SignUp />
                    }
                    action={signUpActionRouter({ dispatch })}
                >
                    <Route element={<RequireUser allowedRoles={PERMISSIONS_ANY}/>}>
                        <Route
                            path='verify'
                            element={ <VerifyUser/> }
                        />
                    </Route>
                </Route>

                <Route 
                    path="login" 
                    element={<Login />} 
                />
                
                <Route element={<RequireUser allowedRoles={["ALACRITY", "DOCTOR", "PHARMA"]}/>}>
                    <Route
                        path="createUser"
                    >
                        <Route
                            index
                            element={<CreateUser />}
                        />
                        <Route 
                            path=':code'
                            element={<CodeDisplay />}
                        />
                    </Route>
                </Route>

                <Route element={<RequireUser allowedRoles={["ALACRITY"]}/>}>
                    <Route
                        path="createOrg"
                    >
                        <Route
                            index
                            element={<CreateOrg />}
                        />
                        <Route
                            path=':code'
                            element={<CodeDisplay/>}
                        />
                    </Route>
                </Route>

                <Route element={<RequireUser allowedRoles={["ALACRITY", "PHARMA"]}/>}>
                    <Route
                        path="createTrial"
                    >
                        <Route
                            index
                            element={<CreateTrial/>}
                            loader={createTrialLoader({ dispatch })}
                        />
                        <Route
                            path=':code'
                            element={<CodeDisplay/>}
                        />
                    </Route>
                </Route>

                <Route
                    path='verifyUser'
                    element={<VerifyUser />}
                />
                <Route element={<RequireUser allowedRoles={PERMISSIONS_ANY}/>}>
                    <Route
                        path='account'
                        element={<Account/>}
                    />
                </Route>

                <Route
                    path='error'
                    element={<GenericError />}
                >
                    <Route
                        path='unauthorized'
                        element={<Unauthorized />}
                    />
                </Route>

                <Route path="test">
                    <Route
                        index
                        element={
                            <Box
                                sx={{
                                    backgroundColor: 'black',
                                    height: '100%',
                                    width: '100%'
                                }}
                            >
                            </Box>
                        }
                        // loader={({ params, request }) => { return (symptomsLoader({ dispatch, id: params.id }))(request) }}
                    />
                </Route>

                <Route 
                    path="*" 
                    element={<NotFound />} 
                />
            </Route>
        )
    );

    return (
        <>
            <CssBaseline enableColorScheme />
            <ToastContainer />
            <RouterProvider router={router} />
        </>
    )
};

export default App;
