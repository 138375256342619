import { createTheme } from "@mui/material";
import AlacrityTheme from "../../common/Theme";




export const AccountTheme = createTheme(
    AlacrityTheme,
    {
        components: {
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        '.Mui-disabled': {
                            color: 'red',
                            borderColor: 'red',
                        }
                    }
                }
            }
        },
    }
)