import { Box, Collapse, Stack, Typography, useTheme } from "@mui/material";
import { FIELD_HEIGHT } from "../../../pages/dashboard/doctor/DoctorInfo";




export default function InfoPageDataField({
    editing,
    loading,
    children,
    loadingChildren,
    containerProps={ height: '100%', width: '100%'},
    label,
    expand=false,
    childSpacing=2,
    height='100%'
}) {
    const theme = useTheme()

    return (
        <Stack
            sx={{
                ...containerProps
            }}
        >
            <Typography
                variant="subtitle2"
                color={(editing) ? 'white' : 'textSecondary'}
                ml={2}
            >
                {label}
            </Typography>

            <Stack
                sx={{
                    pl: 2,
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: (editing) ? '2px solid white' : '1px solid rgba(255, 255, 255, 0.23)',
                    borderRadius: 1,
                    py: 4,
                }}
                spacing={childSpacing}
            >
                { 
                    (loading) ?
                        loadingChildren
                    :
                        children 
                }
            </Stack>
        </Stack>
    )
}