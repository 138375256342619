import { createSlice, current } from "@reduxjs/toolkit";



const initialState = {}

export const alertsSlice = createSlice({
    name: 'alerts',
    initialState: {...initialState},
    reducers: {
        initializeOuterFields: (state, action) => {
            const { outer_field_ids } = action.payload
            console.log(outer_field_ids)
            const newState = outer_field_ids.reduce((acc, id, index) => {
                return {...acc, [id]: {index: index, inner: {}}}
            }, {})
            console.log(newState)
            return newState
        },
        appendOuterField: (state, action) => {
            const { field_id } = action.payload
            state = {...state, [field_id]: { index: Object.keys(state).length, inner: {} } }
        },
        removeOuterField: (state, action) => {
            const { field_id } = action.payload
            const { [field_id]: discard, ...rest } = state      // Remove field from state  

            state = Object.keys(rest).reduce((acc, id) => {     // Shift all indices past {discard} down one and make {rest} new state
                if (rest[id].index > discard.index) {
                    rest[id].index = rest[id].index - 1
                }

                return {...acc, [id]: rest[id]}
            }, {}) 
        },
        moveOuterField: (state, action) => {
            const { field_id, destinationIndex } = action.payload
            const sourceIndex = state[field_id].index;

            if (destinationIndex > sourceIndex) {
                for (let id in state) {
                    const currentIndex = state[id].index
                    if (sourceIndex < currentIndex && currentIndex <= destinationIndex) {
                        state[id].index--       // Shift down all affected elements by one.
                    }
                }
            } else if (destinationIndex < sourceIndex) {
                for (let id in state) {
                    const currentIndex = state[id].index
                    if (destinationIndex <= currentIndex && currentIndex < sourceIndex) {
                        state[id].index++       // Shift up all affected elements by one.
                    }
                }
            }
        },
        initializeInnerFields: (state, action) => {
            const { outer_field_id, inner_field_ids } = action.payload
            console.log(current(state))
            console.log(action.payload)
            state[outer_field_id].inner = inner_field_ids.reduce((acc, id, index) => {
                return {...acc, [id]: index}
            }, {})
        },
        appendInnerField: (state, action) => {
            const { outerField_id, innerField_id } = action.payload
            const outerField = state[outerField_id]
            const nextIndex = Object.values(outerField.inner).length

            outerField.inner[innerField_id] = nextIndex
        },
        removeInnerField: (state, action) => {
            const { outerField_id, innerField_id } = action.payload
            const { [innerField_id]: discard, ...rest } = state[outerField_id].inner

            state[outerField_id].inner = Object.keys(rest).reduce((acc, id) => {     // Shift all indices past {discard} down one and make {rest} new state
                if (rest[id].index > discard.index) {
                    rest[id].index = rest[id].index - 1
                }

                return {...acc, [id]: rest[id]}
            }, {}) 
        },
        moveInnerField: (state, action) => {
            const { source_outer_field_id, source_inner_field_id, destination_outer_field_id, destinationIndex } = action.payload
            const sourceInnerMap = state[source_outer_field_id].inner
            const { [source_inner_field_id]: sourceIndex, ...restOfSourceInnerMap } = sourceInnerMap     // Remove mapping from source.

            // Shift all proceeding elements down in source
            for (let id in restOfSourceInnerMap) {
                const currentIndex = restOfSourceInnerMap[id]
                if (currentIndex > sourceIndex) {
                    restOfSourceInnerMap[id]--
                }
            }
            state[source_outer_field_id].inner = restOfSourceInnerMap   // Set new source map

            // Shift all elements at or after the new destination index up by 1.
            const destinationInnerMap = state[destination_outer_field_id].inner
            for (let id in destinationInnerMap) {
                const currentIndex = destinationInnerMap[id]
                if (currentIndex >= destinationIndex) {
                    destinationInnerMap[id]++
                }
            }
            destinationInnerMap[source_inner_field_id] = destinationIndex
        },
        reset: (state) => {
            state = {...initialState}
        }
    }
})



export const { initializeOuterFields, appendOuterField, removeOuterField, moveOuterField, initializeInnerFields, appendInnerField, removeInnerField, moveInnerField, reset } = alertsSlice.actions



export const selectAlerts = (state) => {
    return state.alerts
}



export default alertsSlice.reducer