import { CircularProgress, Fade } from '@mui/material';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useGetCurrentUserQuery } from '../common/redux/api/userApi';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import LoadingSplashPage from '../pages/LoadingSplashPage';



const RequireUser = ({ allowedRoles }) => {
    const location = useLocation();
    const navigate = useNavigate();



    const { isLoading, isFetching, isUninitialized, data: user } = useGetCurrentUserQuery(null, {
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
        refetchOnFocus: true,
        selectFromResult: ({ data, ...rest }) => {
            return {
                data: data?.data,
                ...rest
            }
        }
    });

    useEffect(() => {
        const loading = isLoading || isFetching || isUninitialized;
        if ( !loading && ( Cookies.get('logged_out') === 'true' && user )) {
            toast.error("You are not logged in.", { position: 'top-center', autoClose: 1000, toastId: 'not_logged_in', theme: 'dark' })
            navigate('/login')
        }
    }, [user, isLoading, isFetching, isUninitialized, navigate])


    if (isLoading || isFetching || isUninitialized) { 
        return  (
            <LoadingSplashPage />
        )
    }


    return (Cookies.get('logged_in') === 'true' || user) && allowedRoles.includes(user?.permissions) ? 
    (
        <Outlet />
    ) : Cookies.get('logged_in') === 'true' && user ? (
        <Navigate to='/error/unauthorized' state={{ from: location }} replace />
    ) : (
        <Navigate to='/login' state={{ from: location }} replace />
    );
};



export default RequireUser;

