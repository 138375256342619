import { Box, Stack, Typography, Switch, useTheme, IconButton, Skeleton, Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { FIELD_HEIGHT, MetricsCard } from "../doctor/DoctorInfo";
import { apiSlice, useAddDoctorToTrialMutation, useAddPatientToTrialMutation, useAddTrialToOrgMutation, useGetDoctorsForTrialQuery, useGetDoctorsQuery, useGetOrgsForTrialQuery, useGetOrgsQuery, useGetPatientsForTrialQuery, useGetPatientsQuery, useGetTrialQuery, useUpdateTrialInfoMutation } from "../../../common/redux/api/api";
import { GenericLoader } from "../../../common/GenericLoader";
import GenericError from "../../error/GenericError";
import AlacrityTheme from "../../../common/Theme";
import { selectUser } from "../../../common/redux/userSlice";
import { BioBox } from "../Themes";
import * as z from 'zod';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { LoadingButton } from "@mui/lab";
import { zodResolver } from '@hookform/resolvers/zod';
import { SingleLineTextBox } from "../../../common/Theme";
import FormInput from "../../../components/CustomFormInput";

import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { AddSharp, CloseSharp, EditSharp, PeopleSharp, Person, SendSharp } from "@mui/icons-material";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useIsQueryLoading } from "../../../common/util";
import EntityLinkModal from "../../../components/modals/EntityLinkModal";
import InfoPageTextField from "../../../components/styledComponents/surfaces/InfoPageTextField";
import { InfoPageChip } from "../../../components/styledComponents/chips/InfoPageChip";
import InfoPageDataField from "../../../components/styledComponents/surfaces/InfoPageDataField";
dayjs.extend(localizedFormat);



export const TrialPage = () => {
    const { id: trial_id } = useParams();
    const user = useSelector(selectUser);
    const theme = useTheme()

    const [ isEditing, setIsEditing ] = useState(false);



    const {
        data: trial,
        isSuccess: isTrialSuccess,
        isUninitialized: isTrialUninitialized,
        isLoading: isTrialLoading,
        isFetching: isTrialFetching,
        isError: isTrialError,
        error: trialError
    } = useGetTrialQuery(trial_id);
    console.log('Trial: ', trial)

    const trialIsLoading = useIsQueryLoading([isTrialLoading, isTrialFetching, isTrialUninitialized])


    const {
        data: patients,
        isUninitialized: isPatientsUninitialized,
        isSuccess: isPatientsSuccess,
        isLoading: isPatientsLoading,
        isFetching: isPatientsFetching,
        isError: isPatientsError,
        error: patientsError
    } = useGetPatientsForTrialQuery(trial_id);

    const patientsAreLoading = useIsQueryLoading([isPatientsUninitialized, isPatientsLoading, isPatientsFetching])



    const {
        data: doctors,
        isUninitialized: isDoctorsUninitialized,
        isSuccess: isDoctorsSuccess,
        isLoading: isDoctorsLoading,
        isFetching: isDoctorsFetching,
        isError: isDoctorsError,
        error: DoctorsError
    } = useGetDoctorsForTrialQuery(trial_id);

    const doctorsAreLoading = useIsQueryLoading([isDoctorsLoading, isDoctorsFetching, isDoctorsUninitialized])


    
    const { schema: providerInfoSchema, initialValues, editableValues } = useTrialInfoForm({ user, trial });

    const methods = useForm({
        resolver: zodResolver(providerInfoSchema),
        defaultValues: initialValues
    });

    const { control, reset, watch, handleSubmit, formState: { isSubmitSuccessful, errors, isSubmitting } } = methods;
    
    const [ editedData, setEditedData ] = useState({...editableValues})   // Just make a copy of the provider's data that can be manipulated without losing the original copy.

    const [ updateTrial, updateTrialResult ] = useUpdateTrialInfoMutation()


    const onSubmit = (values) => {
        console.log('Edit Trial: ', values)
        let newTrialInfo = {}
        for (const field in values) {
            if (values[field] !== undefined && values[field] !== '') {
                newTrialInfo = Object.assign(newTrialInfo, { [field]: values[field] })
            }
        }
        console.log('UpdateTrial: ', newTrialInfo)
        
        updateTrial({
            id: trial_id,
            newInfo: newTrialInfo
        })
    }



    useEffect(() => {
        if (updateTrialResult.isSuccess) {
            setIsEditing(false)
            reset()
        }
    }, [updateTrialResult, reset, setIsEditing])



    const onDeletePatientChip = useCallback(({ patient }) => {}, [])

    const onDeleteOrgChip = useCallback(({ org }) => {}, [])



    const patientChips = useMemo(() => {
        if (isPatientsSuccess) {
            const mainRowChips = patients.map((patient, index) => {
                // if (patientGroup.length === 1) {
                    // const patient = patientGroup.at(0);
                    // const orgColor = colorMap[org_id]
                if (editableValues?.patients) {

                    if (editableValues.patients.includes(patient.alacrity_id)) {
                        return (
                            <InfoPageChip 
                                onDelete={() => { onDeletePatientChip(patient) }}
                                label={`${patient.first_name} ${patient.last_name}`}
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color:'black'
                                }}
                            />
                        )
                    } else {    // Else if not in editable values, don't add a delete button by adding an onDelete function
                        return (
                            <InfoPageChip
                                label={`${patient.first_name} ${patient.last_name}`}
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: 'black'
                                }}
                            />
                        )
                    }
                } else {
                    return (
                        <InfoPageChip
                            label={`${patient.first_name} ${patient.last_name}`}
                            sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: 'black'
                            }}
                        />
                    )
                }
            })

            return (
                <>
                    <Stack
                        direction='row'
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'center',
                            width: '100%'
                        }}
                        spacing={2}
                    >
                        {mainRowChips}

                        {
                            (
                                user.permissions === 'ALACRITY' ||
                                (
                                    user?.orgs.includes(trial?.org_id) &&
                                    user.admin === true
                                ) 
                            ) &&
                            <IconButton
                                color="info"
                                onClick={() => { setOpenPatientModal(true) }}
                            >
                                <AddSharp/>
                            </IconButton>
                        }
                    </Stack>
                </>
            )
        } else {
            return (
                <Stack
                    direction='row'
                    sx={{
                        justifyContent: 'start',
                        alignItems: 'center',
                        width: '100%'
                    }}
                ></Stack>
            )
        }
    }, [editableValues, onDeletePatientChip, trial, user, isPatientsSuccess, theme, patients])



    const doctorChips = useMemo(() => {
        if (isDoctorsSuccess) {
            const mainRowChips = doctors?.map((doctor, index) => {
                if (editableValues?.doctors) {
                    if (editableValues.doctors.includes(doctor.alacrity_id)) {
                        return (
                            <InfoPageChip 
                                onDelete={() => { onDeletePatientChip(doctor) }}
                                label={`${doctor.first_name} ${doctor.last_name}`}
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color:'black'
                                }}
                            />
                        )
                    } else {    // Else if not in editable values, don't add a delete button by adding an onDelete function
                        return (
                            <InfoPageChip
                                label={`${doctor.first_name} ${doctor.last_name}`}
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color: 'black'
                                }}
                            />
                        )
                    }
                } else {
                    return (
                        <InfoPageChip
                            label={`${doctor.first_name} ${doctor.last_name}`}
                            sx={{
                                backgroundColor: theme.palette.secondary.main,
                                color: 'black'
                            }}
                        />
                    )
                }
            })

            return (
                <>
                    <Stack
                        direction='row'
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'center',
                            width: '100%'
                        }}
                        spacing={2}
                    >
                        {mainRowChips}

                        {
                            (
                                user.permissions === 'ALACRITY' ||
                                (
                                    user?.org_id === trial?.org_id &&
                                    user.admin === true
                                ) 
                            ) &&
                            <IconButton
                                color="info"
                                onClick={() => { setOpenPatientModal(true) }}
                            >
                                <AddSharp/>
                            </IconButton>
                        }
                    </Stack>
                </>
            )
        } else {
            return (
                <Stack
                    direction='row'
                    sx={{
                        justifyContent: 'start',
                        alignItems: 'center',
                        width: '100%'
                    }}
                ></Stack>
            )
        }
    }, [editableValues, onDeletePatientChip, trial, user, isDoctorsSuccess, theme, doctors])



    const {
        data: orgs,
        isUninitialized: isOrgsUninitialized,
        isSuccess: isOrgsSuccess,
        isLoading: isOrgsLoading,
        isFetching: isOrgsFetching,
        isError: isOrgsError,
        error: orgsError
    } = useGetOrgsForTrialQuery(trial_id);

    const orgsAreLoading = useIsQueryLoading([isOrgsLoading, isOrgsFetching, isOrgsUninitialized])



    const orgsChips = useMemo(() => {
        if (isOrgsSuccess) {
            const mainRowChips = orgs?.map((org, index) => {
                if (editableValues?.orgs) {
                    if (editableValues.orgs.includes(org?.org_id)) {
                        return (
                            <InfoPageChip 
                                onDelete={() => { onDeleteOrgChip(org) }}
                                label={org?.name}
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color:'black'
                                }}
                            />
                        )
                    } else {    // Else if not in editable values, don't add a delete button by adding an onDelete function
                        return (
                            <InfoPageChip
                                label={org?.name}
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color: 'black'
                                }}
                            />
                        )
                    }
                } else {
                    return (
                        <InfoPageChip
                            label={org?.name}
                            sx={{
                                backgroundColor: theme.palette.secondary.main,
                                color: 'black'
                            }}
                        />
                    )
                }
            })

            return (
                <Stack
                    direction='row'
                    sx={{
                        justifyContent: 'start',
                        alignItems: 'center',
                        width: '100%'
                    }}
                    spacing={2}
                >
                    {mainRowChips}

                    {
                        (
                            user.permissions === 'ALACRITY'
                        ) &&
                        <IconButton
                            color="info"
                            onClick={() => { setOpenOrgModal(true) }}
                        >
                            <AddSharp/>
                        </IconButton>
                    }
                </Stack>
            )
        } else {
            return (
                <Stack
                    direction='row'
                    sx={{
                        justifyContent: 'start',
                        alignItems: 'center',
                        width: '100%'
                    }}
                ></Stack>
            )
        }
    }, [editableValues, onDeleteOrgChip, isOrgsSuccess, theme, orgs, user])



    const [ openPatientModal, setOpenPatientModal ] = useState(false)

    const [ openDoctorModal, setOpenDoctorModal ] = useState(false)

    const [ openOrgModal, setOpenOrgModal ] = useState(false)
    


    return (
        <>
        <FormProvider {...methods}>
            <Box
                display='flex'
                flexDirection='column'
                component='form'
                noValidate
                autoComplete='off'
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    width: '100%',
                    height: '100%'
                }}
            >
                <Stack
                    sx={{
                        width: '100%',
                        // height: '100%',
                        p: 8,
                        justifyContent: 'space-between'
                    }}
                    direction='row'
                >
                    <Stack
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'start',
                            height: '100%',
                            width: '80%',
                        }}
                        spacing={8}
                    >
                        <Stack
                            sx={{
                                justifyContent: 'start',
                                alignItems: 'center',
                                width: '100%'
                            }}
                            direction='row'
                        >
                            {                                
                                ( isEditing && editableValues?.name ) ?
                                    <>
                                        <FormInput
                                            label='Name'
                                            type='text'
                                            name='name'
                                            sx={{
                                                mr: 5,
                                                maxWidth: '40%'
                                            }}
                                            focused
                                        />
                                    </>
                                :
                                    <>
                                        <SingleLineTextBox
                                            variant='h2'
                                            tooltip={(trialIsLoading) ? null : trial?.name}
                                        >
                                            {(trialIsLoading) ? <Skeleton width={200} /> : trial?.name}
                                        </SingleLineTextBox>
                                    </>
                            }
                            {
                                trialIsLoading ?
                                    <Skeleton variant="circular">
                                        <Avatar     // TODO: Provide way to upload profile picture
                                            src={trial?.avatar}
                                            sx={{
                                                height: 60,
                                                width: 60,
                                                ml: '10%'
                                            }}
                                        >
                                            <Person style={{ height: 40, width: 40 }}/>
                                        </Avatar>
                                    </Skeleton>
                                :
                                    <Avatar     // TODO: Provide way to upload profile picture
                                        src={trial?.avatar}
                                        sx={{
                                            height: 60,
                                            width: 60,
                                            ml: '10%'
                                        }}
                                    >
                                        <Person style={{ height: 40, width: 40 }}/>
                                    </Avatar>
                            }
                        </Stack>  

                        <InfoPageDataField
                            childSpacing={4}
                            label='Patients'
                            editing={isEditing}
                            containerProps={{
                                height: FIELD_HEIGHT,
                                width: '100%',
                            }}
                            loading={trialIsLoading || patientsAreLoading}
                            loadingChildren={
                                <Stack
                                    direction={'row'}
                                    width='100%'
                                    height='100%'
                                    spacing={3}
                                    alignItems='center'
                                >
                                    {
                                        Array(4).fill(0).map(() => (<Skeleton variant="rounded" sx={{ height: 40, width: 100, borderRadius: 6 }}/>))
                                    }
                                </Stack>
                            }
                        >
                            {patientChips}
                        </InfoPageDataField> 

                        <InfoPageDataField
                            childSpacing={4}
                            label='Doctors'
                            editing={isEditing}
                            containerProps={{
                                height: FIELD_HEIGHT,
                                width: '100%',
                            }}
                            loading={trialIsLoading || doctorsAreLoading}
                            loadingChildren={
                                <Stack
                                    direction={'row'}
                                    width='100%'
                                    height='100%'
                                    spacing={3}
                                    alignItems='center'
                                >
                                    {
                                        Array(4).fill(0).map(() => (<Skeleton variant="rounded" sx={{ height: 40, width: 100, borderRadius: 6 }}/>))
                                    }
                                </Stack>
                            }
                        >
                            {doctorChips}
                        </InfoPageDataField> 

                        <InfoPageDataField
                            childSpacing={4}
                            label='Organizations'
                            editing={isEditing}
                            containerProps={{
                                height: FIELD_HEIGHT,
                                width: '100%',
                            }}
                            loading={trialIsLoading || orgsAreLoading}
                            loadingChildren={
                                <Stack
                                    direction={'row'}
                                    width='100%'
                                    height='100%'
                                    spacing={3}
                                    alignItems='center'
                                >
                                    {
                                        Array(4).fill(0).map(() => (<Skeleton variant="rounded" sx={{ height: 40, width: 100, borderRadius: 6 }}/>))
                                    }
                                </Stack>
                            }
                        >
                            {orgsChips}
                        </InfoPageDataField> 

                        <Stack
                            sx={{
                                width: '100%',
                                height: FIELD_HEIGHT
                            }}
                            direction='row'
                            spacing={4}
                        >
                            <InfoPageTextField
                                label='Treatment'
                                type='text'
                                name='treatment'
                                placeholder="None"
                                loading={trialIsLoading}
                                editing={isEditing}
                                text={trial?.treatment}  
                                boxProps={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    width: '100%',
                                    height: '100%'
                                }}
                                inputProps={{
                                    height: '3.6rem'
                                }}
                                focused                        
                            />

                            <InfoPageTextField
                                label='Custom ID'
                                type='text'
                                name='custom_id'
                                placeholder="None"
                                loading={trialIsLoading}
                                editing={isEditing}
                                text={trial?.custom_id}  
                                boxProps={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    width: '100%',
                                    height: '100%'
                                }}
                                inputProps={{
                                    height: '3.6rem'
                                }}
                                focused                        
                            />

                            <Controller
                                control={control}
                                name='status_active'
                                defaultValue={undefined}
                                disabled={!isEditing}
                                render={({ field }) => {
                                    const { value, ...fields} = field
                                    return (
                                        <Stack direction={'row'} alignItems='center'>
                                            <Typography>
                                                Inactive
                                            </Typography>
                                            <Switch 
                                                {...fields} 
                                                checked={(isEditing) ? value : trial?.status_active}
                                                sx={{
                                                    '& .MuiSwitch-switchBase.Mui-disabled': {
                                                        color: theme.palette.primary.main,
                                                    },
                                                    '& .MuiSwitch-track': {
                                                        backgroundColor: `${theme.palette.primary.main} !important`,
                                                        opacity: '0.5 !important'
                                                    }
                                                }}
                                            />
                                            <Typography>
                                                Active
                                            </Typography>
                                        </Stack>
                                    )
                                }}
                            />
                        </Stack>

                        <Stack
                            sx={{
                                width: '100%',
                                height: FIELD_HEIGHT
                            }}
                            direction='row'
                            spacing={4}
                        >

                            <InfoPageTextField
                                label='Total Events'
                                type='text'
                                name='total_events'
                                loading={trialIsLoading}
                                editing={false}
                                text={trial?.total_events}  
                                boxProps={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    width: '100%',
                                    height: '100%',
                                    color: theme.palette.warning.light,
                                }}
                                inputProps={{
                                    height: '3.6rem',
                                }}
                                focused                        
                            />

                            <InfoPageTextField
                                label='Total Subjects'
                                type='text'
                                name='total_subjects'
                                loading={trialIsLoading}
                                editing={false}
                                text={(patients ?? []).length}  
                                boxProps={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    width: '100%',
                                    height: '100%',
                                    color: theme.palette.warning.light,
                                }}
                                inputProps={{
                                    height: '3.6rem',
                                }}
                                focused                        
                            />

                            <InfoPageTextField
                                label='Active Subjects'
                                type='text'
                                name='active_subjects'
                                loading={trialIsLoading}
                                editing={false}
                                text={(patients ?? []).filter((patient) => patient?.status_active).length}  
                                boxProps={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    width: '100%',
                                    height: '100%',
                                    color: theme.palette.warning.light,
                                }}
                                inputProps={{
                                    height: '3.6rem',
                                }}
                                focused                        
                            />

                        </Stack>
                    </Stack>

                    <Stack
                        sx={{
                            height: '100%',
                            justifyContent: 'space-between'
                        }}
                    >
                        <LoadingButton
                            loading={trialIsLoading}
                            startIcon={(isEditing) ? <CloseSharp/> : <EditSharp/>}
                            variant="outlined"
                            color={(isEditing) ? 'error' : 'primary'}
                            sx={{
                                height: 'fit-content',
                                py: 1,
                                px: 1.5,
                                '&:hover': {
                                    backgroundColor: (isEditing) ? theme.palette.error.main : theme.palette.primary.main,
                                    color: 'white' 
                                }
                            }}
                            onClick={() => { reset(); setIsEditing((prev) => !prev) }}
                        >
                            {(isEditing) ? 'Cancel' : 'Edit'}
                        </LoadingButton>  

                        {
                            isEditing &&
                            <LoadingButton
                                loading={isSubmitting}
                                endIcon={<SendSharp />}
                                variant="outlined"
                                color={'primary'}
                                sx={{
                                    height: 'fit-content',
                                    py: 1,
                                    px: 1.5,
                                    '&:hover': {
                                        backgroundColor: theme.palette.primary.main,
                                        color: 'white' 
                                    }
                                }}
                                type="submit"
                            >
                                Submit
                            </LoadingButton> 
                        }        
                    </Stack>
                </Stack>
            </Box>
        </FormProvider>

        <AddPatientModal
            open={openPatientModal}
            handleClose={() => { setOpenPatientModal(false) }}
            trial_id={trial_id}
        />

        <AddDoctorModal
            open={openDoctorModal}
            handleClose={() => { setOpenDoctorModal(false) }}
            trial_id={trial_id}
        />

        <AddOrgModal
            open={openOrgModal}
            handleClose={() => setOpenOrgModal(false)}
            trial_id={trial_id}
        />

        </>
    )
}



const AddPatientModal = ({ open, handleClose, trial_id }) => {
    const orderPatients = (a, b) => {
        const order = a.first_name.localeCompare(b.first_name);
        if (order === 0) {
            return a.last_name.localeCompare(b.last_name)
        }
        return order;
    }

    const transformPatients = (entity) => {
        return {
            label: `${entity.first_name} ${entity.last_name}`,
            id: entity.alacrity_id
        }
    }

    

    return (
        open &&
        <EntityLinkModal
            open={open}
            onClose={handleClose}
            orderEntities={orderPatients}
            transformEntities={transformPatients}
            entityName={'Trial'}
            entityID={trial_id}
            entityListQuery={useGetPatientsQuery}
            linkName={'Patient'}
            linkEntityMutation={useAddPatientToTrialMutation}
            ariaLabelledBy="modal-patient-title"  
            onSuccess={() => {
                handleClose()
            }}      
        />
    )
}



const AddDoctorModal = ({ open, handleClose, trial_id }) => {
    const orderDoctors = (a, b) => {
        const order = a.first_name.localeCompare(b.first_name);
        if (order === 0) {
            return a.last_name.localeCompare(b.last_name)
        }
        return order;
    }

    const transformDoctors = (entity) => {
        return {
            label: `${entity.first_name} ${entity.last_name}`,
            id: entity.alacrity_id
        }
    }

    

    return (
        open &&
        <EntityLinkModal
            open={open}
            onClose={handleClose}
            orderEntities={orderDoctors}
            transformEntities={transformDoctors}
            entityName={'Trial'}
            entityID={trial_id}
            entityListQuery={useGetDoctorsQuery}
            linkName={'Patient'}
            linkEntityMutation={useAddDoctorToTrialMutation}
            ariaLabelledBy="modal-patient-title"  
            onSuccess={() => {
                handleClose()
            }}      
        />
    )
}



const AddOrgModal = ({ open, handleClose, trial_id }) => {
    const orderOrgs = (a, b) => {
        return a.name.localeCompare(b.name);
    }

    const transformOrgs = (entity) => {
        return {
            label: `${entity.name}`,
            id: entity.org_id
        }
    }

    

    return (
        open &&
        <EntityLinkModal
            open={open}
            onClose={handleClose}
            orderEntities={orderOrgs}
            transformEntities={transformOrgs}
            entityName={'Trial'}
            entityID={trial_id}
            entityListQuery={useGetOrgsQuery}
            linkName={'Org'}
            linkEntityMutation={useAddTrialToOrgMutation}
            ariaLabelledBy="modal-org-title"  
            onSuccess={() => {
                handleClose()
            }}      
        />
    )
}



const useTrialInfoForm = ({ user, trial }) => {
    return useMemo(() => {
        var schema = z.object({});
        var initialValues = {};
        var editableValues = {};

        if (
            (
                user?.admin === true && 
                user?.orgs.some((org) => trial?.orgs.includes(org))
            ) || 
            user?.permissions === 'ALACRITY'
        ) {     // If the user is looking at their own page, they can edit email & phone since they can verify.
            initialValues = {
                ...initialValues,
                name: undefined,
                treatment: undefined,
                status_active: undefined,
                patients: undefined,
                doctors: undefined,
                custom_id: undefined,
            }

            editableValues = {
                name: trial?.name,
                treatment: trial?.treatment,
                status_active: trial?.status_active,
                patients: trial?.patients,
                doctors: trial?.doctors,
                custom_id: trial?.custom_id
            }

            schema = schema.extend({
                name: z.string().optional(),
                treatment: z.string().optional(),
                status_active: z.boolean().optional(),
                patients: z.array(z.string()).optional(),
                doctors: z.array(z.string()).optional(),
                custom_id: z.string().min(6, 'Must be at least 6 characters.').optional()
            })
        }

        if (user?.permissions === 'ALACRITY') {
            initialValues = {
                ...initialValues,
                orgs: undefined
            }

            editableValues = {
                ...editableValues,
                orgs: trial?.orgs
            }

            schema = schema.extend({
                orgs: z.array(z.string()).optional()
            })
        }


        return { schema: schema, initialValues: initialValues, editableValues: editableValues }
    
    }, [user, trial])
}