import { DataGridPremium, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, useGridApiRef, GRID_STRING_COL_DEF, GridActionsCellItem, GridToolbarQuickFilter, DEFAULT_GRID_AUTOSIZE_OPTIONS } from "@mui/x-data-grid-premium";
import { cloneElement, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { QuickViewSensors, apiSlice } from "../../common/redux/api/api";
import { selectCurrentEntity, selectExtended, selectSidebarState, toggleAlertMode, toggleExtend, updateCurrentEntity } from "../../common/redux/sidebarSlice";
import { useRTKResultToasts } from "../../common/RTKResultHooks";
import { Box, Stack, Typography, Paper, useTheme, LinearProgress, Skeleton, Toolbar, Button, Container, IconButton, Badge } from '@mui/material'
import { AddSharp, DomainDisabledSharp, FilterAltSharp, Logout, MonitorHeartSharp, NotificationAddSharp, NotificationsSharp, PersonOffSharp, SaveAltSharp, ThermostatSharp } from "@mui/icons-material";
import FolderOffSharp from "@mui/icons-material/FolderOffSharp";
// import { GRID_STRING_COL_DEF, GridActionsCellItem, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import { useNavigate, useParams } from "react-router";

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import minMax from 'dayjs/plugin/minMax';
import updateLocale from 'dayjs/plugin/updateLocale';
import { useSelector } from "react-redux";
import { selectUser } from "../../common/redux/userSlice";

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(minMax);
dayjs.extend(updateLocale)

const thresholds = [
    { l: 's', r: 10, d: 'minute' },
    { l: 'mm', r: 59, d: 'minute' },
    { l: 'h', r: 1 },
    { l: 'hh', r: 23, d: 'hour' },
    { l: 'd', r: 1 },
    { l: 'dd', r: 29, d: 'day' },
    { l: 'M', r: 1 },
    { l: 'MM', r: 11, d: 'month' },
    { l: 'y', r: 1 },
    { l: 'yy', d: 'year' }
]

dayjs.extend(relativeTime, { thresholds })

dayjs.updateLocale('en', {
    relativeTime: {
        past: '%s',
        s: 'Just now',
        mm: '%d m.',
        h: "1 hr.",
        hh: "%d hr.",
        d: "1 d.",
        dd: "%d d.",
        M: "1 mo.",
        MM: "%d mo.",
        y: "1 yr",
        yy: "%d yr."
    }
})



/*
*   entities: an array of objects of one type that will be displayed on the sidebar.
*   (e.g. Patients, Trials)
*
*   getEntityName: the sidebar will only display the names of the entities, so to keep this element
*   generic, we need a function that will map entities to whatever their display name should be.
*/
function SidebarEntitiesList({
    loading,
    columns,
    rows,
    emptyMessage,
    emptyIcon,
    onRowSelect,
    columnVisibilityModel,
    autosizeOptions,
    footer=null,
    footerProps={},
    treeData=false,
    getTreeDataPath=null,
    customToolbar=GenericCustomToolbar,
    customToolbarProps={}
}) {
    // const {id: entity_id } = useParams()
    const theme = useTheme()
    const apiRef = useGridApiRef()

    const sidebarState = useSelector(selectSidebarState)



    const onRowSelectionModelChange = (newModel) => {
        if (newModel.length > 0) {
            if (rows.some((row) => newModel.includes(row.id))) {
                const entity_id = newModel[0]
                onRowSelect(entity_id)
            }
        }
    }



    const getTogglableColumns = (columns) => {
        // hide the column with field `id` from list of togglable columns
        return columns
          .filter((column) => column.field !== 'id')
          .map((column) => column.field);
    };



    return (
        loading ?
            <LoadingEntities/>
        :
            <Paper
                elevation={2} // Background color for rows
                sx={{
                    height: '100%',
                    width: '100%',
                    flex: 1,
                    '& .lastUpdated': {
                        color: theme.palette.text.secondary,
                        fontSize: 16
                    },
                }}
            >
                <DataGridPremium
                    apiRef={apiRef}
                    autosizeOnMount
                    hideFooter={footer === null}
                    columnHeaderHeight={0}  // Can't just remove header because Filter & Column Toolbar buttons rely on it for some reason. Instead, set height to zero.
                    columns={columns}
                    rows={rows}
                    rowHeight={64}
                    rowSelectionModel={sidebarState.entity_id ? [sidebarState.entity_id] : []}
                    onRowSelectionModelChange={onRowSelectionModelChange}
                    getCellClassName={(params) => {
                        return params.field
                    }}
                    localeText={{
                        toolbarFilters: '',
                        toolbarExport: ''
                    }}
                    autosizeOptions={autosizeOptions}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            }   
                        },
                        autosizeOptions: autosizeOptions
                    }}
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                        toolbar: customToolbar,
                        footer: footer
                    }}
                    slotProps={{
                        noRowsOverlay: {
                            message: emptyMessage,
                            icon: emptyIcon
                        },
                        columnsPanel: {
                            getTogglableColumns
                        },
                        footer: footerProps,
                        toolbar: {
                            ...customToolbarProps
                        }
                    }}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            pl: 2,
                            textAlign: 'start',
                            '&:focus': {
                                outline: 'none'
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            border: 'none'
                        },
                        '&.MuiDataGrid-root': {
                            borderRadius: 0,
                            borderTopWidth: 0,
                            borderLeftWidth: 0,
                            borderRightWidth: 0,
                            // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px 1px'
                        },
                        '& .MuiDataGrid-row.Mui-selected': {
                            backgroundColor: '#343434',
                            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))',
                            '&:hover': {
                                backgroundColor: '#121212',
                                backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5))',
                            }
                        },
                        '& .MuiDataGrid-row': {
                            '&:hover': {
                                backgroundColor: '#121212',
                                backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))',
                            }
                        },
                        '&. MuiPaper-root': {
                            boxShadow: 'none'
                        }
                    }}
                    columnVisibilityModel={columnVisibilityModel}
                    treeData={treeData}
                    getTreeDataPath={getTreeDataPath}
                    groupingColDef={{
                        flex: 1,
                    }}
                />
            </Paper>
    )
}



const CustomNoRowsOverlay = ({ message, icon }) => {
    const theme = useTheme()

    return (
        <Stack
            justifyContent='center'
            alignItems='center'
            sx={{
                width: '100%',
                height: '100%'
            }}
            spacing={2}
        >
            {
                cloneElement(icon, { style: { height: 60, width: 60, color: theme.palette.text.secondary } })
            }
            <Typography
                variant='h6'
                color='textSecondary'
            >
                {message}
            </Typography>
        </Stack>
    )
}



const GenericCustomToolbar = (props) => {
    const theme = useTheme();


    return (
        <Paper 
            sx={{ 
                width: '100%', 
                borderRadius: 0,
                boxShadow: 'none'
            }} 
            elevation={4}
        >
            <Stack
                sx={{
                    width: '100%',
                    px: '1rem'
                }}
            >
                <GridToolbarQuickFilter 
                    sx={{
                        backgroundColor: '#212121', 
                        borderRadius: 4, 
                        px: 2,
                        '& .MuiInputBase-root': {
                            '&:before': {
                                border: 'none',
                            },
                            '&:after': {
                                border: 'none'
                            },
                            '&:hover:before': {
                                border: 'none'
                            },
                        },
                        '& .MuiInput-root': {
                            '&:hover': {
                                border: 'none'
                            },
                        },
                        width: '100%',
                        minWidth: '50px',
                        maxWidth: '24rem',
                    }}
                    placeholder="Search"
                />

                <GridToolbarContainer 
                    sx={{ 
                        width: '100%', 
                        py: 1 
                    }}
                >
                    <GridToolbarFilterButton
                        sx={{ 
                            color: grey.A100,
                        }}
                        componentsProps={{
                            button: {
                                startIcon: (<FilterAltSharp style={{ color: theme.palette.text.secondary }} />),
                                size: 'small',
                                sx: {
                                    minWidth: '20px'
                                }
                            }
                        }}
                    />

                    <GridToolbarExport 
                        sx={{ 
                            color: grey.A100,
                            minWidth: '20px'
                        }} 
                        componentsProps={{
                            button: {
                                startIcon: (<SaveAltSharp style={{ color: theme.palette.text.secondary }} />),
                                size: 'small'
                            }
                        }}
                    />
                </GridToolbarContainer>
            </Stack>
        </Paper>
    )
}



const PatientsToolbar = (props) => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const sidebarState = useSelector(selectSidebarState)

    const onToggleExtend = useCallback(() => {
        dispatch(toggleExtend())
    }, [dispatch])

    const onToggleAlertMode = useCallback(() => {
        if (sidebarState.alertMode) {
            if (sidebarState.entity_id !== null) {
                navigate(`/dashboard/patient/${sidebarState.entity_id}`)
            }
        } else {
            if (sidebarState.entity_id !== null) {
                navigate(`/dashboard/alerts/${sidebarState.entity_id}`)
            }
        }

        dispatch(toggleAlertMode())
    }, [dispatch, sidebarState, navigate])



    return (
        <Paper 
            sx={{ 
                width: '100%', 
                borderRadius: 0,
                boxShadow: 'none'
            }} 
            elevation={4}
        >
            <Stack
                sx={{
                    width: '100%',
                    px: '1rem'
                }}
            >
                <GridToolbarQuickFilter 
                    sx={{
                        backgroundColor: '#212121', 
                        borderRadius: 4, 
                        px: 2,
                        '& .MuiInputBase-root': {
                            '&:before': {
                                border: 'none',
                            },
                            '&:after': {
                                border: 'none'
                            },
                            '&:hover:before': {
                                border: 'none'
                            },
                        },
                        '& .MuiInput-root': {
                            '&:hover': {
                                border: 'none'
                            },
                        },
                        width: '100%',
                        minWidth: '50px',
                        maxWidth: '24rem',
                    }}
                    placeholder="Search"
                />

                <GridToolbarContainer 
                    sx={{ 
                        width: '100%', 
                        py: 1 
                    }}
                >
                    <GridToolbarFilterButton
                        sx={{ 
                            color: grey.A100,
                        }}
                        componentsProps={{
                            button: {
                                startIcon: (
                                    <FilterAltSharp 
                                        style={{ 
                                            color: 'white'
                                        }} 
                                        sx={{
                                            width: '30px',
                                            height: '30px'
                                        }}
                                    />
                                ),
                                size: 'large',
                                sx: {
                                    minWidth: '40px',
                                    height: '40px',
                                }
                            }
                        }}
                    />

                    <GridToolbarExport 
                        sx={{ 
                            color: grey.A100,
                            minWidth: '40px',
                            height: '40px',
                            '& .MuiSvgIcon-root': {
                                width: '30px',
                                height: '30px'
                            }
                        }} 
                        componentsProps={{
                            button: {
                                startIcon: (
                                    <SaveAltSharp />
                                ),
                            },
                        }}
                    />

                    <Badge 
                        variant="dot"
                        color="primary"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        invisible={!sidebarState.extended}
                        sx={{
                            '& .MuiBadge-badge': {
                                left: 16,
                            }
                        }}
                    >
                        <Button
                            onClick={onToggleExtend}
                            color="info"
                            sx={{
                                borderRadius: 1,
                                height: '40px',
                                minWidth: '40px',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.4)'
                                },
                            }}
                            startIcon={
                                <Logout 
                                    style={{ 
                                        color: (sidebarState.extended) ? theme.palette.primary.main : 'white',
                                        height: '30px',
                                        width: '30px'
                                    }} 
                                />
                            }
                        >
                            {""}
                        </Button>
                    </Badge>

                    <Badge 
                        variant="dot"
                        color="primary"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        invisible={!sidebarState.alertMode}
                        sx={{
                            '& .MuiBadge-badge': {
                                left: 19,
                            }
                        }}
                    >
                        <Button
                            onClick={onToggleAlertMode}
                            color="info"
                            sx={{
                                borderRadius: 1,
                                height: '40px',
                                minWidth: '40px',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.4)'
                                },
                            }}
                            startIcon={
                                <NotificationsSharp 
                                    style={{ 
                                        color: (sidebarState.alertMode) ? theme.palette.primary.main : 'white',
                                        height: '30px',
                                        width: '30px'
                                    }} 
                                />
                            }
                        >
                            {""}
                        </Button>
                    </Badge>

                    {
                        sidebarState.extended &&
                        <>
                            <IconButton
                                sx={{
                                    ml: 'auto',
                                    mr: '4rem'
                                }}
                            >
                                <MonitorHeartSharp />
                            </IconButton>

                            <IconButton>
                                <ThermostatSharp />
                            </IconButton>
                        </>
                    }
                </GridToolbarContainer>
            </Stack>
        </Paper>
    )
}



export function SidebarPatientsList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(selectUser)
    const sidebarState = useSelector(selectSidebarState)



    const columns = [
        {
            field: 'id',
            type: 'string',
            headerName: 'ID',
            filterable: false,
            sortable: false,
            editable: false,
            groupable: false,
        },
        {
            field: 'name',
            type: 'string',
            align: 'left',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'lastUpdated',
            type: 'string',
            align: 'left',
            flex: 0.4,
            headerName: 'Last Updated',
            valueFormatter: (params) => {
                if (params.value) {
                    const date = dayjs(params.value)
                    if (date.isBefore(dayjs().subtract(1, 'year'))) {
                        return '...'
                    } else {
                        return dayjs(params.value).fromNow()
                    }
                } else {
                    return '...'
                }
            }
        },
        // {
        //     field: 'actions',
        //     type: 'actions',
        //     getActions: (params) => ([
        //         // Link Doctor,
        //         // Link Trial,
        //         // Toggle Activation,
        //         // GOTO: Graphs, Care Team, etc.
        //     ])
        // },
        ...Array.from(QuickViewSensors).map((sensor) => {
            return {
                field: sensor,
                type: 'string',
                align: 'right',
                width: 100,
                headerName: sensor,
                valueFormatter: (params) => {
                    if (params?.value?.value) {
                        return `${Math.round(params.value.value)} ${(params.value.units ?? '').toLowerCase()}`
                    } else {
                        return 'N/A'
                    }
                }
            }
        })
    ]

    const { 
        data: patients, 
        isSucces: isPatientsSuccess, 
        isError: isPatientsError, 
        isLoading: isPatientsLoading, 
        isFetching: isPatientsFetching, 
        error: patientsError, 
    } = apiSlice.endpoints.getPatientsWithData.useQueryState(null);
    // Show a toast on error but not success.
    useRTKResultToasts({
        isSuccess: null,
        isError: isPatientsError,
        error: patientsError
    })



    const [ rows, setRows ] = useState([]);

    useEffect(() => {
        setRows(patients?.map((patient) => {
            return ({
                id: patient.alacrity_id,
                name: `${patient.first_name} ${patient.last_name}`,
                lastUpdated: Object.values(patient.sensors)?.reduce((acc, { ts }) => dayjs.max(acc, dayjs(ts)), dayjs(patient?.creation_time ?? dayjs().subtract(5, 'year').toISOString())),
                ...Array.from(QuickViewSensors).reduce((acc, sensor) => {
                    if (Object.hasOwn(patient.sensors, sensor)) {
                        return {
                            ...acc,
                            [sensor]: patient?.sensors[sensor]
                        }
                    } else {
                        return acc
                    }
                }, {})
            })
        }) ?? [])
    }, [patients, setRows])



    const onRowSelect = (alacrity_id) => {
        console.log('On Row Select')
        dispatch(updateCurrentEntity(alacrity_id))
        navigate(`/dashboard/patient/${alacrity_id}`)
    }

    const onRowSelectAlertMode = (alacrity_id) => {
        console.log('On Row Select Alert Mode')
        dispatch(updateCurrentEntity(alacrity_id))
        navigate(`/dashboard/alerts/${alacrity_id}`)
    }



    const columnVisibilityModel = useMemo(() => {
        const model = {
            ...Array.from(QuickViewSensors).reduce((acc, sensor) => ({...acc, [sensor]: sidebarState.extended}), {}),
            id: false
        }
        return model
    }, [sidebarState])



    return (
        <SidebarEntitiesList 
            columns={columns}
            rows={rows}
            loading={isPatientsLoading || isPatientsFetching}
            emptyMessage='No Patients'
            emptyIcon={<PersonOffSharp/>}
            onRowSelect={(sidebarState.alertMode) ? onRowSelectAlertMode : onRowSelect}
            columnVisibilityModel={columnVisibilityModel}
            autosizeOptions={{
                includeOutliers: true,
                includeHeaders: false,
                expand: true,
                outliersFactor: DEFAULT_GRID_AUTOSIZE_OPTIONS.outliersFactor
            }}
            footer={(user.permissions === 'ALACRITY' || ( user.admin && user.permissions === 'DOCTOR' )) ? AddEntityFooter : null}
            footerProps={{
                entity: 'Patient',
                link: '/createUser',
                navProps: { state: { role: 'PATIENT' } }
            }}
            customToolbar={PatientsToolbar}
        />
    )
}



export function SidebarTrialsList({ extended }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(selectUser)



    const columns = [
        {
            field: 'id', // Have to have an ID field, but this is just the main field too.
            type: 'string',
            headerName: 'ID',
            filterable: false,
            sortable: false,
            editable: false,
            groupable: false,
        },
        {
            field: 'name',
            type: 'string',
            headerName: 'Name',
            align: 'left',
            flex: 1.0
        },
        {
            field: 'actions',
            type: 'actions',
            getActions: (params) => ([
                // Link Doctor,
                // Link Patient,
                // Toggle Activation,
                // GOTO: Graphs, Care Team, etc.
            ])
        }
    ]

    const { 
        data: trials, 
        isSuccess: isTrialsSuccess, 
        isError: isTrialsError, 
        isLoading: isTrialsLoading, 
        isFetching: isTrialsFetching, 
        error: trialsError, 
    } = apiSlice.endpoints.getTrials.useQueryState(null);

    // useRTKResultToasts({
    //     isSuccess: null,
    //     isError: isTrialsError,
    //     error: trialsError
    // })



    const [ rows, setRows ] = useState([]);

    useEffect(() => {
        setRows(trials?.map((trial) => {
            return ({
                id: trial.trial_id,
                name: `${trial.name}`
            })
        }) ?? [])
    }, [trials, setRows])



    const onRowSelect = (trial_id) => {
        dispatch(updateCurrentEntity(trial_id))
        navigate(`/dashboard/trial/${trial_id}`)
    }



    return (
        <SidebarEntitiesList 
            columns={columns}
            rows={rows}
            loading={isTrialsLoading || isTrialsFetching}
            emptyMessage='No Trials'
            emptyIcon={<FolderOffSharp/>}
            onRowSelect={onRowSelect}
            extended={extended}
            columnVisibilityModel={{ id: false }}
            autosizeOptions={{
                columns: ['name'],
                includeOutliers: true,
                includeHeaders: false,
                expand: true
            }}
            footer={(user.permissions === 'ALACRITY' || ( user.admin && user.permissions === 'PHARMA' )) ? AddEntityFooter : null}
            footerProps={{
                entity: 'Trial',
                link: '/createTrial',
            }}
        />
    )
}



export function SidebarOrgsList({ extended }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(selectUser)



    const columns = [
        {
            field: 'id', // Have to have an ID field, but this is just the main field too.
            type: 'string',
            headerName: 'ID',
            filterable: false,
            sortable: false,
            editable: false,
            groupable: false,
        },
        // {
        //     field: 'name',
        //     type: 'string',
        //     headerName: 'Name',
        //     align: 'left',
        //     flex: 1
        // },
        {
            field: 'actions',
            type: 'actions',
            getActions: (params) => ([
                // Link Doctor,
                // Link Patient,
                // Toggle Activation,
                // GOTO: Graphs, Care Team, etc.
            ])
        }
    ]

    const { 
        data: orgs, 
        isSuccess: isOrgsSuccess, 
        isError: isOrgsError, 
        isLoading: isOrgsLoading, 
        isFetching: isOrgsFetching, 
        error: orgsError, 
    } = apiSlice.endpoints.getAllOrgs.useQueryState(null);



    const rows = useMemo(() => {
        if (isOrgsSuccess) {
            return orgs?.map((org) => ({
                id: org?.org_id,
                path: ((user?.orgs ?? []).includes(org?.org_id) ? [org?.name] : ['External Orgs', org?.name]),
            }))
        } else {
            return []
        }
        
    }, [orgs, isOrgsSuccess, user])



    const onRowSelect = (org_id) => {
        dispatch(updateCurrentEntity(org_id))
        navigate(`/dashboard/org/${org_id}`)

    }



    return (
        <SidebarEntitiesList 
            columns={columns}
            rows={rows}
            loading={isOrgsLoading || isOrgsFetching}
            emptyMessage='No Organizations'
            emptyIcon={<DomainDisabledSharp/>}
            onRowSelect={onRowSelect}
            extended={extended}
            columnVisibilityModel={{ id: false }}
            autosizeOptions={{
                // columns: ['name'],
                includeOutliers: true,
                includeHeaders: false,
                expand: true
            }}
            footer={(user.permissions === 'ALACRITY') ? AddEntityFooter : null}
            footerProps={{
                entity: 'Organization',
                link: '/createOrg'
            }}
            treeData={true}
            getTreeDataPath={(row) => row.path}
        />
    )
}



export function SidebarProvidersList({ extended }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(selectUser)



    const columns = [
        {
            field: 'id', // Have to have an ID field, but this is just the main field too.
            type: 'string',
            headerName: 'ID',
            filterable: false,
            sortable: false,
            editable: false,
            groupable: false,
        },
        {
            field: 'name',
            type: 'string',
            headerName: 'Name',
            align: 'left',
            flex: 1
        },
        {
            field: 'actions',
            type: 'actions',
            getActions: (params) => ([
                // Link Doctor,
                // Link Patient,
                // Toggle Activation,
                // GOTO: Graphs, Care Team, etc.
            ])
        }
    ]

    const { 
        data: providers, 
        isSuccess: isProvidersSuccess, 
        isError: isProvidersError, 
        isLoading: isProvidersLoading, 
        isFetching: isProvidersFetching, 
        error: providersError,
    } = apiSlice.endpoints.getProvidersSidebar.useQueryState(user);

    useRTKResultToasts({
        isSuccess: null,
        isError: isProvidersError,
        error: providersError
    })



    const rows = useMemo(() => {
        if (isProvidersSuccess) {
            console.log('Providers: ', providers)
            return providers?.map((provider) => {
                return {
                    id: provider.alacrity_id,
                    name: `${provider.first_name} ${provider.last_name}`
                }
            })
        } else {
            return []
        }
    }, [isProvidersSuccess, providers])



    const onRowSelect = (provider_id) => {
        dispatch(updateCurrentEntity(provider_id))
        navigate(`/dashboard/provider/${provider_id}`)
    }



    return (
        <SidebarEntitiesList 
            columns={columns}
            rows={rows}
            loading={isProvidersLoading || isProvidersFetching}
            emptyMessage='No Providers'
            emptyIcon={<PersonOffSharp/>}
            onRowSelect={onRowSelect}
            extended={extended}
            columnVisibilityModel={{ id: false }}
            autosizeOptions={{
                columns: ['name'],
                includeOutliers: true,
                includeHeaders: false,
                expand: true
            }}
            footer={(user?.permissions === 'ALACRITY' || ( user?.admin && user?.permissions === 'DOCTOR' )) ? AddEntityFooter : null}
            footerProps={{
                entity: 'Provider',
                link: '/createUser',
                navProps: { state: { role: 'DOCTOR' } }
            }}
        />
    )
}



const EntitiesSkeleton = () => {
    return (
        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '60%'}} />
    )
}



const LoadingEntities = () => {
    const loadingColumnType = {
        ...GRID_STRING_COL_DEF,
        resizable: false,
        filterable: false,
        sortable: false,
        editable: false,
        groupable: false,
        renderCell: () => <EntitiesSkeleton />,
    };



    const columns = [
        {
            field: 'id', // Have to have an ID field, but this is just the main field too.
            ...loadingColumnType,
            width: '100%',
        }
    ]

    const rows = [
        {
            id: 'Patient 1'
        },
        {
            id: 'Patient 2'
        },
        {
            id: 'Patient 3'
        },
        {
            id: 'Patient 4'
        }
    ]



    return (
        <Paper
            sx={{
                height: '100%',
                width: '100%',
                flex: 1,
            }}
        >
            <DataGridPremium
                loading
                hideFooter
                columns={columns}
                rows={rows}
                slots={{
                    loadingOverlay: LinearProgress,
                    columnHeaders: () => null,
                }}
                sx={{
                    '& .MuiDataGrid-cell': {
                        pl: 2,
                        fontSize: 24
                    },
                    '& .MuiDataGrid-filterForm': {
                        zIndex: 100
                    }
                }}
                autosizeOnMount
                autosizeOptions={{
                    columns: ['id'],
                    includeOutliers: true,
                    includeHeaders: false,
                    expand: true
                }}
            />
        </Paper>   
    )
}



const AddEntityFooter = ({
    entity,
    link,
    navProps
}) => {
    // const theme = useTheme()
    const navigate = useNavigate()



    return (
        <Container
            sx={{
                width: '100%',
                py: 2
            }}
        >
            <Button
                color="info"
                variant="contained"
                startIcon={<AddSharp/>}
                sx={{
                    width: '100%',
                    py: 2,
                    backgroundColor: '#333333',
                    color: 'white',
                    fontSize: 20,
                    fontWeight: 600,
                    textTransform: 'none'
                }}
                onClick={() => navigate(link, navProps)}
            >
                {`Add ${entity}`}
            </Button>
        </Container>
    )
}