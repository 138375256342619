import { Box, Skeleton, Stack, Typography } from "@mui/material"
import FormInput from "../../CustomFormInput"




export default function InfoPageTextField({
    name,
    label,
    type,
    required = false,
    focused = false,
    inputProps={},
    boxProps={ width: '100%', height: '100%' },
    loading,
    editing,
    text,
    textProps={},
    placeholder=''
}) {
    return (
        <Box
            sx={{
                ...boxProps
            }}
        >
        {
            editing ?
                <FormInput
                    label={label}
                    type={type}
                    name={name}
                    inputProps={inputProps}
                    focused={focused}
                    required={required}
                />
            :
                <Stack
                    width='100%'
                    height='100%'
                >
                    <Typography
                        variant="subtitle2"
                        color='textSecondary'
                        ml={2}
                    >
                        {label}
                    </Typography>

                    <Stack
                        sx={{
                            border: '1px solid rgba(255, 255, 255, 0.23)',
                            borderRadius: 1,
                            px: 2,
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center'
                        }}
                        spacing={2}
                    >
                        {
                            text ?
                                <Typography
                                    variant="h4"
                                    {...textProps}
                                >
                                    {(loading) ? <Skeleton/> : text }
                                </Typography>
                            :
                                <Typography
                                    variant="h4"
                                    {...textProps}
                                    color={'text.secondary'}
                                >
                                    {(loading) ? <Skeleton/> : placeholder }
                                </Typography>
                        }
                    </Stack>
                </Stack>
        }
        </Box>
    )
}