import { createSlice } from "@reduxjs/toolkit";



export const windowSlice = createSlice({
    name: 'window',
    initialState: {
        scrollYPosition: 0.0
    },
    reducers: {
        set: (_state, action) => {
            return action.payload
        }
    }
})

export const { set } = windowSlice.actions

export const selectWindow = state => {
    return state.window
}

export default windowSlice.reducer