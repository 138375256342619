import { CircularProgress, Container, MenuItem, Box, Typography, InputLabel, Select, FormControl, Stack, Divider, Paper } from "@mui/material";
import { Suspense, useEffect } from "react";
import { Await, useLoaderData, useNavigate, defer } from "react-router";
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { userApi } from "../../../common/redux/api/userApi";
import GenericError from "../../error/GenericError";
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from "@mui/lab";
import { useCreateOrgMutation } from "../../../common/redux/api/api";
import { toast } from 'react-toastify';
import FormInput from "../../../components/CustomFormInput";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneNumberInput from "../../../components/PhoneNumberInput";
import { parsePhoneNumber } from "react-phone-number-input";
import { BANNER_HEIGHT } from "../../../components/Banner";



export default function CreateOrg() {
    const navigate = useNavigate();

    const [ createOrg, result ] = useCreateOrgMutation();
    const { data, isSuccess, isError, isLoading, error } = result;



    const typeEnum = z.enum(["PHARMA", "DOCTOR"]);
    const formSchema = z.object({
        type: typeEnum,
        name: z.string().min(2),
        email: z.string().email('Please enter a valid email.').or(z.literal('')),
        phone: z.string().or(z.literal('')),
        countryCode: z.string().length(2)
    }).refine((data) => {
        if (data?.phone) {
            return isPossiblePhoneNumber(data?.phone?.replace(' ', ""))
        }
        return true
    }, {
        message: 'Phone number is invalid.',
        path: ['phone']
    })

    const methods = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            type: typeEnum.Enum.DOCTOR,
            name: '',
            email: '',
            phone: '',
            countryCode: 'US'
        }
    });

    const { control, reset, handleSubmit, formState: { isSubmitSuccessful, errors, isSubmitting } } = methods;

    const onSubmitHandler = (values) => {
        const orgInfo = {
            type: values.type,
            name: values.name
        }

        if (values?.email) {
            orgInfo.email = values?.email
        }

        if (values?.phone) {
            const phoneObject = parsePhoneNumber(values?.phone?.replace(' ', ''))

            orgInfo.phone = {
                iso2: phoneObject.country,
                dialCode: phoneObject.countryCallingCode,
                e164numberstring: phoneObject.number,
                ext: phoneObject?.ext
            }
        }

        createOrg(orgInfo);
    }



    useEffect(() => {
        if (isSuccess) {
            toast.success("New org successfully created.", {
                position: 'top-center', autoClose: 1000,
                toastId: 'CreateOrg.org.put.success'
            });
            navigate(`/createOrg/${data.org_id}`, {
                state: {
                    role: 'ORG'
                }
            });
        }
        if (isError) {
            toast.error(error?.data?.message, {
                position: 'top-center', autoClose: 1000,
                toastId: 'Create.org.put.error'
            })
        }
    }, [isSuccess, isError, error, data, navigate]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
        }
    }, [isSubmitSuccessful, reset])




    return (
        <Container
            sx={{
                width: { xl: '40%', lg: '50%', xs: '90%' },
                justifyContent: 'center',
                alignItems: 'center',
                pt: '2rem',
                height: `calc(100vh - ${BANNER_HEIGHT}px - 16px)`
            }}
        >
            <FormProvider {...methods}>
                <Box
                    component='form'
                    noValidate
                    autoComplete='off'
                    sx={{ 
                        // paddingRight: { sm: '3rem' },
                        width: '100%',
                        height: '100%'
                    }}
                    onSubmit={handleSubmit(onSubmitHandler)}
                >
                    <Stack
                        height='100%'
                        width='100%'
                        justifyContent='start'
                        alignItems='center'
                        spacing={6}
                        sx={{
                            pb: '2rem'
                        }}
                    >
                        <Typography
                            variant='h1'
                            sx={{ textAlign: 'center'}}
                        >
                            Create Organization
                        </Typography>


                        <Paper
                            elevation={8}
                            sx={{
                                m: 0,
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <Stack
                                sx={{
                                    width: '100%',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    px:2,
                                    pt: 2,
                                    pb: 8,
                                    height:'62vh',
                                    overflowY: 'scroll',
                                }}
                                spacing={8}
                            >
                                <FormControl fullWidth required focused>
                                    <InputLabel 
                                        sx={{ 
                                            fontSize: 24, 
                                            '&.Mui-focused': {
                                                color: 'white'
                                            } 
                                        }} 
                                        id='type-select-label'
                                    >
                                        Select Org Type
                                    </InputLabel>

                                    <Controller
                                        control={control}
                                        name={'type'}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                labelId='type-select-label'
                                                label={'Select Org Type'}
                                                color="info"
                                                sx={{
                                                    fontSize: 32,
                                                    px: 2,
                                                    height: '6rem',
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        fontSize: 24,
                                                    }
                                                }}
                                                disabled={isLoading || isSubmitting}
                                            >
                                                <MenuItem sx={{ fontSize: 32 }} value={typeEnum.Enum.PHARMA}>Pharmaceutical</MenuItem>
                                                <MenuItem sx={{ fontSize: 32 }} value={typeEnum.Enum.DOCTOR}>Medical</MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>

                                <FormInput
                                    type='text'
                                    label='Name'
                                    name='name'
                                    required
                                    focused
                                    disabled={isLoading || isSubmitting}
                                />

                                <FormInput
                                    type='email'
                                    label='Email'
                                    name='email'
                                    focused
                                    disabled={isLoading || isSubmitting}
                                />

                                <PhoneNumberInput 
                                    focused
                                    disabled={isLoading || isSubmitting}
                                />

                                <LoadingButton
                                    type='submit'
                                    loading={isLoading || isSubmitting}
                                    variant='xxl'
                                    sx={{
                                        py: 1,
                                    }}
                                >
                                    Submit
                                </LoadingButton>
                            </Stack>
                        </Paper>
                    </Stack>
                </Box>
            </FormProvider>
        </Container>
    )
}