import { AddSharp } from "@mui/icons-material";
import { Stack, Typography, Button, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { selectUser } from "../../common/redux/userSlice";



const USER_TEXT = "Please provide this code to the person whose account you created, so they can enter it when they sign up. Please print out this page or write down the code so neither you nor they lose it.";

const TRIAL_TEXT = "Please provide this code to doctors who are participating in this trial, so they can enter it when they sign up. Please print out this page or write down the code so neither you nor they lose it.";

const ORG_TEXT = "Here is your unique Organization ID."



export default function CodeDisplay() {
    const theme = useTheme();
    const user = useSelector(selectUser);
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const fixedRole = location.state?.role;
    console.log(location?.state)

    const [ navigateTo, setNavigateTo ] = useState('/');

    const [ subtext, setSubtext ] = useState('');

    const [ navigateBack, setNavigateBack ] = useState({ path: '/', state: {} });

    

    useEffect(() => {
        if (fixedRole) {
            switch (fixedRole) {
                case "PATIENT":
                    setNavigateTo('/dashboard/patient');
                    setSubtext(USER_TEXT);
                    setNavigateBack({ path: '/createUser', state: { role: 'PATIENT' } })
                    break;
                case "DOCTOR":
                    setNavigateTo('/dashboard/provider');
                    setSubtext(USER_TEXT);
                    setNavigateBack({ path: '/createUser', state: { role: 'DOCTOR' } })
                    break;
                case "ORG":
                    setNavigateTo('/dashboard')
                    setSubtext(ORG_TEXT)
                    setNavigateBack({ path: '/createOrg', state: {} })
                    break;
                case "TRIAL":
                    setNavigateTo('/dashboard/trial');
                    setSubtext(TRIAL_TEXT)
                    setNavigateBack({ path: '/createTrial', state: {} })
                    break;
                case "PHARMA":
                    setNavigateTo('/dashboard');
                    setSubtext(USER_TEXT);
                    setNavigateBack({ path: '/createUser', state: { role: 'PHARMA' } });
                    break;
                default:
                    //Do nothing
                    break;
            }
        }
    }, [fixedRole, setSubtext, setNavigateBack, setNavigateTo])

    return (
        <Stack 
            alignItems={'center'} 
            justifyContent={'center'}
            spacing={4}
            width={'100%'}
            sx={{
                py: '3rem',
                px: '20%'
            }}
        >
            <Typography variant="h1" textAlign={'center'}>
                Code
            </Typography>

            <Typography 
                variant="h5" 
                textAlign={'center'}
                color='textSecondary'
                sx={{
                    my: '1rem',
                }}
            >
                {subtext}
            </Typography>

            <Typography 
                variant="h3" 
                textAlign={'center'}
                
            >
                {params.code}
            </Typography>

            <Stack
                direction='row'
                spacing={4}
                sx={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Button 
                    variant='xxl'
                    onClick={() => { navigate(navigateTo) }}
                    size='large'
                    sx={{
                        py: 1
                    }}
                >
                    Ok
                </Button>

                <Button
                    variant="xxl"
                    onClick={() => { navigate(navigateBack.path, { state: (user.permissions === 'ALACRITY') ? {} : navigateBack.state }) }}
                    startIcon={<AddSharp style={{ height: 32, width: 32 }}/>}
                    sx={{
                        py: 1,
                        backgroundColor: theme.palette.success.dark
                    }}
                >
                    Add Another?
                </Button>
            </Stack>
        </Stack>
    )
}