export const allSensors = [
    "ecg",
    "ecg-heartrate",
    "ecg-rr",
    "ecg-rrMs",
    "temp",
    "spO2",
    "eeg",
    "ppg",
    "ppg-heartrate",
    "PCT",
    "IL6",
    "Ferritin"
]

export const namesToSensors = {
    "Heartrate (ECG)": "ecg-heartrate",
    "ECG": "ecg",
    "Temperature": "temp",
    "O2": "spo2",
    "EEG": "eeg",
    "PPG": "ppg",
    "Heartrate (PPG)": "ppg-heartrate",
    "RR": "ecg-rr",
    "RR (ms)": "ecg-rrMS",
    "PCT": "PCT",
    "IL6": "IL6",
    "Ferritin": "Ferritin"
}

export const sensorsToNames = {
    "ecg-heartrate": "Heartrate (ECG)",
    "ecg": "ECG",
    "temp": "Temperature",
    "spo2": "O2",
    "eeg": "EEG",
    "ppg": "PPG",
    "ppg-heartrate": "Heartrate (PPG)",
    "ecg-rr": "RR",
    "ecg-rrMS": "RR (ms)",
    "PCT": "PCT",
    "IL6": "IL6",
    "Ferritin": "Ferritin"
}


export const units = {
    "ecg-heartrate": "bpm",
    "ppg-heartrate": "bpm",
    "ppg": "",
    "ecg": "mV",
    "temp": "˚F",
    "spO2": "%",
    "eeg": "",
    "ecg-rr": "bpm",
    "ecg-rrMs": "ms",
    "PCT": "",
    "IL6": "",
    "Ferritin": ""
}



export const symptoms = new Set([
    'inattention',
    'constipation',
    'diarrhea',
    'fatigue',
    'abdominal-pain',
    'shortness-of-breath',
    'nausea',
    'pain',
    'vomiting',
    'difficulty-writing',
    'rash',
    'somnolence',
    'stuttering',
    'perservative-speech',
]) 



export const SHORT_GRAPH_SENSORS = new Set([
    'ecg', 
    'eeg', 
    'ecg-rr',
    'ecg-rrMs', 
    'ppg', 
    'eeg-alpha-absolute', 
    'eeg-alpha-relative', 
    'eeg-alpha-score',
    'eeg-beta-absolute',
    'eeg-beta-relative',
    'eeg-beta-score',
    'eeg-delta-absolute',
    'eeg-delta-relative',
    'eeg-delta-score',
    'eeg-gamma-absolute',
    'eeg-gamma-relative',
    'eeg-gamma-score',
    'eeg-theta-absolute',
    'eeg-theta-relative',
    'eeg-theta-score' 
])